import { combineReducers } from 'redux';
import * as Actions from '../action-types';

const initialFetchingState = {
  fetching: null,
  fetchingError: null,
  success: null,
};

export const nationalIdNumberReducer = (state = initialFetchingState, action) => {
  switch (action.type) {
    case Actions.National_id_number_FETCH_START:
      return {
        ...state,
        fetching: true,
      };
    case Actions.National_id_number_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        success: null,
        fetchingError: action.payload,
      };
    case Actions.National_id_number_FETCH_SUCCESS:
      return {
        ...state,
        fetchingError: null,
        success: true,
      };
    default:
      return state;
  }
};
export default combineReducers({
    nationalIdNumberReducer
});
