import React from 'react';
import { Steps } from 'antd';
import './index.scss';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { useWindowSize } from '@react-hook/window-size';
import { useContext } from 'react';
import FormContext from './Context/FormContext';

export const useFormContext = () => {
  return useContext(FormContext);
};

function ApplicantFormSteps() {
  const { formSteps, current } = useFormContext();
  const [width] = useWindowSize();
  const items = formSteps.map((item) => ({ key: item.title, title: item.title, description: item.description }));

  return (
    <>
      {width > 767 ? (
        <div className='d-flex justify-content-between'>
          <div className='col-step-service'>
            <Steps direction='vertical' current={current} items={items} />
          </div>
          <div className='steps-content-service'>{formSteps[current].content}</div>
        </div>
      ) : (
        <div className='steps-content-service'>
          <Box>
            <Stepper activeStep={current} orientation='vertical'>
              {items.map((step, index) => (
                <Step key={index}>
                  <StepLabel>
                    <p className='description-desc'>{step.description}</p>
                  </StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <div>{formSteps[current].content}</div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      )}
    </>
  );
}

export default ApplicantFormSteps;
