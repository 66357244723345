import { addServices } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import { addServicesFetch, addServicesFinished, addServicesFetchFailed } from '../actions/addServices';

function* addServicesAsync(action) {
  yield put(addServicesFetch());
  try {
    yield call(addServices, action.payload);
    yield put(addServicesFinished());
  } catch (error) {
    yield put(addServicesFetchFailed(error.response.data.error_code));
  }
}

export default function* addServicesSaga() {
  yield all([takeLatest(Actions.ADD_SERVICES, addServicesAsync)]);
}
