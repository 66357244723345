import { otpLogin, otpServiceProviderLogin, anotherOtpLogin, anotherOtpServiceProviderLogin } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import {
  otpLoginFetch,
  otpLoginFetchFailed,
  otpLoginFetchFinished,
  AnotherOtpLoginFetch,
  AnotherOtpLoginFetchFinished,
  AnotherOtpLoginFetchFailed,
} from '../actions/otpLogin';

function* otpLoginValidationAsync(action) {
  yield put(otpLoginFetch());
  try {
    yield call(otpLogin, action.payload);
    yield put(otpLoginFetchFinished());
  } catch (error) {
    yield put(otpLoginFetchFailed(error.response.data));
  }
}

function* anotherOtpLoginAsync(action) {
  yield put(AnotherOtpLoginFetch());
  try {
    yield call(anotherOtpLogin, action.payload);
    yield put(AnotherOtpLoginFetchFinished());
  } catch (error) {
    yield put(AnotherOtpLoginFetchFailed(error.response.data));
  }
}

function* anotherOtpServiceProviderLoginAsync(action) {
  yield put(AnotherOtpLoginFetch());
  try {
    yield call(anotherOtpServiceProviderLogin, action.payload);
    yield put(AnotherOtpLoginFetchFinished());
  } catch (error) {
    yield put(AnotherOtpLoginFetchFailed(error.response.data));
  }
}

function* otpServiceProviderLoginAsync(action) {
  yield put(otpLoginFetch());
  try {
    yield call(otpServiceProviderLogin, action.payload);
    yield put(otpLoginFetchFinished());
  } catch (error) {
    yield put(otpLoginFetchFailed(error.response.data));
  }
}

export default function* otpLoginValidationSega() {
  yield all([
    takeLatest(Actions.OTP_LOGIN_VALIDATION, otpLoginValidationAsync),
    takeLatest(Actions.REQUEST_ANOTHER_OTP_LOGIN, anotherOtpLoginAsync),
    takeLatest(Actions.OTP_SERVICE_PROVIDER_LOGIN_VALIDATION, otpServiceProviderLoginAsync),
    takeLatest(Actions.REQUEST_ANOTHER_OTP_SERVICE_PROVIDER_LOGIN, anotherOtpServiceProviderLoginAsync),
  ]);
}
