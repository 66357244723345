import React from 'react'
import "./PageNotFound.scss"
import { LogoHeader} from "../../shared/components";
import img404 from '../../markup/images/img404lg.png'
import Footer from '../../shared/components/Footer/Footer'


export default function PageNotFound() {
    return (
        <div className="bg-404">
             <LogoHeader />
            <div className='d-flex-404'>
            <div className='container-404'>
                <img className='pg-404-img' src={img404} alt='' />
                <div className='txt-box'>
                    <p className='header-404'> عذراً!</p>
                    <p className='content-404'>
                        لم يتم العثور على الصفحة
                    </p>
                    <p className='content-404'>يمكنك بدلاً من ذلك الوصول الى صفحات اخرى</p>
                    <p className='content-404'><a className='underline' href='/'>الرئيسية</a></p>
                    <p className='content-404'><a className='underline' href='/applicant-login'>تسجيل الدخول</a></p>
                </div>
            </div>
            </div>
            <Footer mt={'mt-4'} />
        </div>
    )
}
