import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { ID_PATTERN } from '../../../shared/utils/regax';
import { useForm } from 'react-hook-form';
import sharedApi from '../../../shared';
import { message } from 'antd';
import updateProfileIcon from '../../../assets/images/shared/update_profile_icon.svg';
import { Link } from 'react-router-dom';

const { ButtonNormal, InputField } = sharedApi.components;
export default function UpdateProfileAuditor(props) {
  const { nationalIdNumberActions, error, userDataActions } = props;
  const { t } = useTranslation();
  const {
    register,
    setValue,
    resetField,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const [disabled, setDisabled] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [switchButton, setSwitchButton] = useState(true);
  const [style, setStyle] = useState({ backgroundColor: 'white', width: '220px', height: '36px', border: 'none' });
  const [styleId, setStyleId] = useState({ backgroundColor: 'white', width: '220px', height: '36px', border: 'none' });
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [btn, seBtn] = useState(false);
  // const [showResults, setShowResults] = useState({ message: '', type: '' })

  // start axios
  useEffect(() => {
    userDataActions.UserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.successData) {
      setEmail(props.auditorData.email);
      setName(props.auditorData.name);
      setMobileNumber(props.auditorData.mobile_number);
      if (props.auditorData.auditor_profile != null) {
        setValue('nationalId_auditor', props.auditorData.auditor_profile.national_id_number);
        seBtn(true);
        setShowButton(false);
      }
    } else if (props.errorData) {
      console.log('error', props.errorData);
    } // eslint-disable-next-line
  }, [props]);

  const handleUpdate = () => {
    setDisabled(!disabled);
    setSwitchButton(
      false,
      setStyle({ backgroundColor: '#EFEFEF', minWidth: '290px', height: '36px' }),
      setStyleId({ backgroundColor: 'white', minWidth: '290px', height: '36px' })
    );
  };

  const onSubmit = (data) => {
    nationalIdNumberActions.nationalIdNumber(data);
  };
  // }
  useEffect(() => {
    if (props.success) {
      setTimeout(
        () => {
          message.success({
            type: 'success',
            content: `تم تحديث المعلومات الشخصية بنجاح`,
            duration: 1,
          });
          setSwitchButton(true, setShowButton(false));
          setStyle({ backgroundColor: 'white', width: '220px', height: '36px', border: 'none' });
          setStyleId({ backgroundColor: 'white', width: '220px', height: '36px', border: 'none' });
        },

        1
      );

      // seBtn(true);

      // Navigate('/profile-auditor');
    } // eslint-disable-next-line
  }, [props]);

  const goBack = () => {
    setSwitchButton(true);
    setStyle({ backgroundColor: 'white', width: '220px', height: '36px', border: 'none' });
    setStyleId({ backgroundColor: 'white', width: '220px', height: '36px', border: 'none' });
    setDisabled(true);
    resetField('nationalId_auditor');
  };

  return (
    <>
      <div className='auditor_update_personal_info-auditorList-container'>
        <div className='auditor_update_personal_info-auditorList'>
          {switchButton ? (
            <div>
              <div className='auditor_personal_info-div-auditor'>
                <section>
                  <p className='auditor_personal_info-title'>{t('profile_Auditor')}</p>
                  <p className='auditor_personal_info-subtitle'>{t('p_profile_Auditor')} </p>
                </section>
                {showButton ? (
                  <button className='auditor_personal_info-btn' disabled={btn} onClick={handleUpdate}>
                    <img src={updateProfileIcon} alt='updateProfileIcon' className='auditor_personal_info-icon' />
                    <p> تحديث البيانات</p>
                  </button>
                ) : null}
              </div>{' '}
              <p className='auditor_personal_info-subtitle2'>المعلومات الشخصية</p>
            </div>
          ) : null}
          {switchButton ? null : (
            <section>
              <p className='auditor_personal_info-title'>{t('profile_Auditor')}</p>
              <p className='auditor_personal_info-subtitle'>{t('p_profile_Auditor')} </p>
            </section>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {error && (
                <small style={{ color: '#B41515' }} className_error='auditor_personal_info-plus_input_error'>
                  {t(error)}
                </small>
              )}
            </div>
            <div className='auditor_personal_info-family'>
              <div className={`${switchButton ? 'auditor_personal_info-wrapper2' : 'auditor_update_personal_info-input'}`}>
                <InputField title='fullname_arabice' type='text' value={name} name='fullname_arabice' disabled={true} style={style} />

                <div>
                  <InputField
                    title='nationalId'
                    type='text'
                    name='nationalId_auditor'
                    className={error ? 'auditor_personal_info-plus_input_error' : null}
                    disabled={disabled}
                    style={styleId}
                    error={errors.nationalId_auditor && errors.nationalId_auditor.message}
                    {...register('nationalId_auditor', {
                      required: 'fieldRequired',
                      pattern: {
                        value: ID_PATTERN,
                        message: 'nationalIdInvalid',
                      },
                      onChange: (e) =>
                        setValue(
                          'nationalId_auditor',
                          /[^\d+$]/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                        ),
                    })}
                  />
                </div>

                <InputField title='email' type='text' name='email_auditor' value={email} disabled={true} style={style} />

                <InputField
                  title='mobileNumber'
                  type='text'
                  name='mobileNumber_auditor'
                  value={mobileNumber}
                  disabled={true}
                  style={style}
                />
              </div>
            </div>
            {switchButton ? (
              <div className=''>
                <hr className='auditor_personal_info-line'></hr>
                <div className='auditor_personal_info-div'>
                  <Link className='auditor_personal_info-a' to='/change-password'>
                    تغيير كلمة المرور
                  </Link>
                  <p className='auditor_personal_info-arrow'>←</p>
                </div>
              </div>
            ) : null}
            {switchButton ? null : (
              <div>
                <div className='auditor_update_personal_info-steps-action'>
                  <ButtonNormal titleButton=' الغاء' classNameButton='step-btn-3' onClick={goBack} />
                  <ButtonNormal titleButton=' حفظ' disabled={!isValid} onClick={onSubmit} />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
