import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import '../../../../markup/bootstrap-grid.rtl.min.css';
import sharedApi from '../../../../shared';
import { message, Popconfirm } from 'antd';
import Tabs from '../Tabs/Tabs';
import { getBanksData, deleteBankData, postBankData, updateMemberData } from '../../../../services/axios/api';

const { ButtonNormal } = sharedApi.components;
export default function UpdateMembers() {
  const { t } = useTranslation();
  const [banksData, setBanksData] = useState([]);
  const [addBankName, setAddBankName] = useState('');
  const [addIBAN, setAddIBAN] = useState('');
  const [addIcon, setAddIcon] = useState([]);
  const [editedIdValue, setEditedIdValue] = useState();
  const [editedNameValue, setEditedNameValue] = useState('');
  const [editedIBANValue, setEditedIBANValue] = useState('');
  const [editedIconValue, setEditedIconValue] = useState([]);
  const [editedPositionValue, setEditedPositionValue] = useState('');
  const [isEditInputShow, setIsEditInputShow] = useState(false);
  const [isEditBtnDisabled, setIsEditBtnDisabled] = useState(false);
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);
  const [onSuccess, setOnSuccess] = useState(false);
  const [showAddBank, setShowAddBank] = useState(false);

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line
  }, [onSuccess]);

  const getBanks = async () => {
    const banks = await getBanksData();
    setBanksData(banks);
  };

  const deleteBank = async (id) => {
    try {
      await deleteBankData(`/admin/content/donBankAccounts/${id}`);
      success('تم الحذف بنجاح!');
    } catch (error) {}
  };

  const addBank = async (name, IBAN, icon) => {
    if (name === '' || IBAN === '' || icon === '') return;
    try {
      const FormDataBody = new FormData();
      FormDataBody.append('name', name);
      FormDataBody.append('iban_account', IBAN);
      FormDataBody.append('bank_icon', icon);
      await postBankData(FormDataBody);
      success('تمت الإضافة بنجاح!');
      setAddBankName('');
      setAddIBAN('');
      setAddIcon('');
      setShowAddBank(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnEdit = (id, name, IBAN, icon) => {
    setEditedIdValue(id);
    setEditedNameValue(name);
    setEditedIBANValue(IBAN);
    setEditedIconValue(icon);
    setIsEditInputShow(true);
    setIsEditBtnDisabled(false);
  };

  const editMember = async (id) => {
    try {
      const FormDataBody = new FormData();
      if (editedNameValue !== '') FormDataBody.append('name', editedNameValue);
      if (editedIBANValue !== '') FormDataBody.append('iban_account', editedIBANValue);
      if (editedIconValue !== undefined) FormDataBody.append('bank_icon', editedIconValue);
      await updateMemberData(FormDataBody, `/admin/content/donBankAccounts/${id}/`);
      setEditedNameValue('');
      setEditedIBANValue('');
      setEditedIconValue([]);
      setIsEditInputShow(false);
      success('تم التعديل بنجاح!');
    } catch (error) {
      console.log(error);
    }
  };

  const onEditInputChange = (e) => {
    if (e.target.id === 'editBankName') setEditedNameValue(e.target.value);
    if (e.target.id === 'editBankIBAN') setEditedIBANValue(e.target.value);
    if (e.target.id === 'editBankIcon') setEditedIconValue(e.target.files[0]);
    if (e.target.value === '') setIsEditBtnDisabled(true);
    else setIsEditBtnDisabled(false);
  };

  const success = (msg) => {
    setTimeout(() => {
      message.success({
        type: 'success',
        content: msg,
        duration: 2,
      });
    }, 1);
    setOnSuccess(!onSuccess);
  };

  const onAddInputsChange = (e) => {
    if (e.target.id === 'bankName') setAddBankName(e.target.value);
    if (e.target.id === 'bankIBAN') setAddIBAN(e.target.value);
    if (e.target.id === 'bankIcon') setAddIcon(e.target.files[0]);
    if (e.target.value === '') setIsAddBtnDisabled(true);
    else setIsAddBtnDisabled(false);
  };

  return (
    <>
      <div className='auditor_update_personal_info-auditorList-container'>
        <div className='auditor_update_personal_info-auditorList'>
          <div>
            <div className='auditor_personal_info-div-auditor'>
              <section>
                <p className='auditor_personal_info-title'>{t('cms_page_title')}</p>
                <p className='auditor_personal_info-subtitle'>{t('cms_page_subtitle')} </p>
              </section>
            </div>
            <Tabs />
          </div>
          <div className='purple-card-with-title'>
            <div className='card--title'>القسم السابع</div>
            <div>
              <div className='row px-3 px-lg-0 mt-5'>
                {banksData &&
                  banksData.map((bank, i) => (
                    <div className='col-xl-6' key={'bank-' + bank.id}>
                      <div className='member-card-name d-flex flex-column flex-sm-row'>
                        <img src={bank.bank_icon} width='100' height='50' className='me-3 flex-shrink-0' alt='' />
                        <div>
                          <span className='member--name'>{bank.name} </span>
                          <button
                            type='button'
                            className={'member--edit ms-1 ' + (isEditInputShow && bank.id === editedIdValue ? 'd-none' : 'd-inline')}
                            onClick={() => handleOnEdit(bank.id, bank.name, bank.iban_account)}
                          >
                            تعديل
                          </button>
                          <Popconfirm title='هل انت متأكد؟' onConfirm={() => deleteBank(bank.id)} okText='حذف' cancelText='العودة'>
                            <a
                              href='#'
                              className={'member--delete ms-1 ' + (isEditInputShow && bank.id === editedIdValue ? 'd-none' : 'd-inline')}
                            >
                              حذف
                            </a>
                          </Popconfirm>
                          <div className='member--name'>{bank.iban_account} </div>
                          <div className={isEditInputShow && bank.id === editedIdValue ? 'd-block' : 'd-none'}>
                            <input
                              type='text'
                              id='editBankName'
                              className='input__text input-style-show mt-0 mb-1'
                              value={editedNameValue}
                              onChange={(e) => onEditInputChange(e)}
                            />
                            <input
                              type='text'
                              id='editBankIBAN'
                              className='input__text input-style-show mt-0'
                              value={editedIBANValue}
                              onChange={(e) => onEditInputChange(e)}
                            />
                            <input type='file' id='editBankIcon' onChange={(e) => onEditInputChange(e)} />
                            <ButtonNormal
                              titleButton='تعديل'
                              onClick={() => editMember(bank.id)}
                              type='button'
                              disabled={isEditBtnDisabled}
                              classNameButton={'step-btn-2 btn-sm px-3 ms-1'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className='row px-3 px-lg-0 mb-3'>
                <div className='col-lg-4'>
                  <ButtonNormal titleButton='+ اضافة حساب' onClick={() => setShowAddBank(!showAddBank)} type='button' />
                </div>
              </div>
              {showAddBank && (
                <div className='row align-items-end'>
                  <div className='col-lg-3'>
                    <div className='mb-2'>اسم البنك</div>
                    <input
                      type='text'
                      id='bankName'
                      className='input__text input-style-show mt-0'
                      value={addBankName}
                      onChange={(e) => onAddInputsChange(e)}
                    />
                  </div>
                  <div className='col-lg-3 mb-3 mb-lg-0'>
                    <div className='mb-2'>الايبان</div>
                    <input
                      type='text'
                      id='bankIBAN'
                      className='input__text input-style-show mt-0'
                      value={addIBAN}
                      onChange={(e) => onAddInputsChange(e)}
                    />
                  </div>
                  <div className='col-lg-3 mb-3 mb-lg-0'>
                    <div className='mb-2'>ايقونة البنك</div>
                    <input type='file' id='bankIcon' onChange={(e) => onAddInputsChange(e)} />
                  </div>
                  <div className='col-lg-3'>
                    <ButtonNormal
                      titleButton='اضافة'
                      onClick={() => addBank(addBankName, addIBAN, addIcon)}
                      type='submit'
                      disabled={isAddBtnDisabled}
                      classNameButton={'step-btn-2 btn-sm px-3'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
