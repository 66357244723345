import * as Actions from '../action-types';

export const resetFetch = () => ({
  type: Actions.RESET_PASSWORD_FETCH_START,
});

export const resetFetchFinished = () => ({
  type: Actions.RESET_PASSWORD_FETCH_SUCCESS,
});

export const resetFetchFailed = (payload) => ({
  type: Actions.RESET_PASSWORD_FETCH_FAILED,
  payload,
});

export const resetPassword = (payload) => ({
  type: Actions.RESET_PASSWORD,
  payload,
});
export const resetServiceProviderPassword = (payload) => ({
  type: Actions.RESET_SERVICE_PROVIDER_PASSWORD,
  payload,
});
