import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import withRouter from '../../../shared/utils/with-router';
import composeEnhancers from '../../../shared/utils/compose-enhancers';
import ApplicantLogin from './ApplicantLogin';
import loginAndValidationApI from '..';

const mapStateToProps = () =>
  createStructuredSelector({
    fetching: loginAndValidationApI.loginApi.selectors.getLoginFetching,
    error: loginAndValidationApI.loginApi.selectors.getLoginError,
    success: loginAndValidationApI.loginApi.selectors.getLoginSuccess,
  });

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators(loginAndValidationApI.loginApi.actions, dispatch),
});

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(ApplicantLogin);
