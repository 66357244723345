import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment-hijri';
import { Manager, Reference, Popper } from 'react-popper';
import onClickOutside from 'react-onclickoutside';
import DayNames from './DayNames';
import MonthList from './MonthsList';
import YearsList from './YearsList';
import MonthDaysView from './MonthDaysView';
import './HijriDatePicker.scss';

const HijriCalender = styled.div`
  width: 266px;
  direction: rtl;
  background: #ffffff;
  padding: 0px 25px 25px 25px;
  border: 1px solid #ddd;
  font-family: serif;
  box-sizing: unset;
  -webkit-box-sizing: unset;
  font-size: 14px;
  border-radius: 4px;
  z-index: 1000;
  border: 1px solid #50456698;
  border-radius: 8px;
`;

class HijriDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: props.selectedDate || '',
      dateFormat: props.dateFormat || 'iYYYY/iMM/iDD',
      currentTime: moment('1979-11-20'),
      calenderShown: false,
    };
  }

  componentDidMount() {
    if (this.state.selectedDate) {
      this.setState({
        currentTime: moment(this.state.selectedDate, this.state.dateFormat),
      });
    }
  }
  componentDidUpdate(prevProps) {
    const { selectedDate: prevSelectedDate } = prevProps;
    const { selectedDate: nextSelectedDate } = this.props;
    if (prevSelectedDate !== nextSelectedDate) {
      this.setState({ ...this.state, selectedDate: nextSelectedDate });
    }
  }

  handleClickOutside = (evt) => {
    this.setState({
      calenderShown: false,
    });
  };

  subtractMonth = () => {
    this.setState((prevState) => ({
      currentTime: prevState.currentTime.subtract(1, 'iMonth'),
    }));
  };

  addMonth = () => {
    this.setState((prevState) => ({
      currentTime: prevState.currentTime.add(1, 'iMonth'),
    }));
  };

  setSelectedDate = (event) => {
    let time = this.state.currentTime;
    time.iDate(parseInt(event.target.value, 10));
    const selectedDate = time.format(this.state.dateFormat);
    this.setState({
      selectedDate,
      calenderShown: false,
    });
    this.handleChange(selectedDate);
  };

  getMonthStartDayName = () => {
    let time = this.state.currentTime;
    time.startOf('iMonth');
    return time.format('dd');
  };

  handleFocus = (event) => {
    const { onFocus = () => {} } = this.props;
    onFocus(event.target.value);
    this.showCalender();
  };

  handleChange = (value) => {
    const { onChange = () => {} } = this.props;
    onChange(value);
  };

  showCalender = () => {
    this.setState({
      calenderShown: true,
    });
  };

  handelMonthChange = (event) => {
    let time = this.state.currentTime;
    time.iMonth(event);
    this.setState({
      currentTime: time,
    });
  };
  handelYearChange = (event) => {
    let time = this.state.currentTime;
    time.iYear(event);
    this.setState({
      currentTime: time,
    });
  };
  render() {
    const { className, name, placeholder, input, disabled } = this.props;
    return (
      <div>
        <Manager>
          <Reference>
            {({ ref }) => (
              <input
                type='text'
                autoComplete='off'
                {...{ className, name, placeholder, disabled, ...input }}
                value={this.state.selectedDate || this.props.value}
                ref={ref}
                onFocus={this.handleFocus}
                readOnly
                style={{ height: this.props.smallerHeight && 34, width: this.props.maxWidth && '209px' }}
              />
            )}
          </Reference>
          {this.state.calenderShown && (
            <Popper
              placement='bottom'
              modifiers={[
                { name: 'hide', enabled: true },
                {
                  name: 'preventOverflow',
                  enabled: true,
                },
                {
                  name: 'computeStyles',
                  options: {
                    roundOffsets: ({ x, y }) => ({
                      x: Math.round(x),
                      y: Math.round(y + 4),
                    }),
                  },
                },
              ]}
            >
              {({ ref, style, placement, arrowProps }) => (
                <div>
                  <HijriCalender ref={ref} style={style} data-placement={placement}>
                    {this.props.quickSelect && (
                      <div className='datepicker-header'>
                        <YearsList currentTime={this.state.currentTime} onChange={this.handelYearChange} />
                        <MonthList currentTime={this.state.currentTime} onChange={this.handelMonthChange} />
                      </div>
                    )}

                    <DayNames arabicFullDayNames />
                    <MonthDaysView
                      currentTime={this.state.currentTime}
                      dateFormat={this.state.dateFormat}
                      selectedDate={this.state.selectedDate}
                      setSelectedDate={this.setSelectedDate}
                    />
                    <div ref={arrowProps.ref} style={arrowProps.style} />
                  </HijriCalender>
                </div>
              )}
            </Popper>
          )}
        </Manager>
      </div>
    );
  }
}

export default onClickOutside(HijriDatePicker);
