import * as Actions from '../action-types';

export const otpLoginFetch = () => ({
  type: Actions.OTP_LOGIN_FETCH_START,
});

export const otpLoginFetchFinished = () => ({
  type: Actions.OTP_LOGIN_FETCH_SUCCESS,
});

export const otpLoginFetchFailed = (payload) => ({
  type: Actions.OTP_LOGIN_FETCH_FAILED,
  payload,
});

export const otpLoginValidation = (payload) => ({
  type: Actions.OTP_LOGIN_VALIDATION,
  payload,
});
export const otpServiceProviderLogin = (payload) => ({
  type: Actions.OTP_SERVICE_PROVIDER_LOGIN_VALIDATION,
  payload,
});

export const requestAnotherOtpLogin = (payload) => ({
  type: Actions.REQUEST_ANOTHER_OTP_LOGIN,
  payload,
});
export const requestAnotherOtpServiceProviderLogin = (payload) => ({
  type: Actions.REQUEST_ANOTHER_OTP_SERVICE_PROVIDER_LOGIN,
  payload,
});

export const AnotherOtpLoginFetch = () => ({
  type: Actions.ANOTHER_OTP_LOGIN_START,
});

export const AnotherOtpLoginFetchFailed = (payload) => ({
  type: Actions.ANOTHER_OTP_LOGIN_FAILED,
  payload,
});

export const AnotherOtpLoginFetchFinished = () => ({
  type: Actions.ANOTHER_OTP_LOGIN_SUCCESS,
});
