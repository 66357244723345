/**
 * this file has been generate first time by @skyermustafaa
 * This should wrap the app and provide the context for the entire app.
 * Missons, include initial values like
 * - theme
 * - langauge
 * - current user
 * - ...etc
 * ------------------------------------
 * Doc ref of toasts => https://fkhadra.github.io/react-toastify/introduction
 * usage of toasts
 */

// ** imports
import { createContext, ReactNode, useCallback, useContext } from "react";
import { toast as RToast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastContainerBodyStyles } from "./root-context-styles";

// ** interfaces
type TType = 'success' | 'error' | 'info' | 'warning'
interface IRootContextProps {
    toast?: (message: string, type: TType, options: ToastOptions) => void;
    success?: (message: string, options: ToastOptions) => void;
    error?: (message: string, options: ToastOptions) => void;
    info?: (message: string, options: ToastOptions) => void;
    warning?: (message: string, options: ToastOptions) => void;
};


interface IProps {
    children: ReactNode;
};

// ** context
export const RootContext = createContext<IRootContextProps>({});

// ** exported hooks
export const useToastContext = () => useContext(RootContext).toast
export const useToastContextWithSuccess = () => useContext(RootContext).success
export const useToastContextWithError = () => useContext(RootContext).error
export const useToastContextWithInfo = () => useContext(RootContext).info
export const useToastContextWithWarning = () => useContext(RootContext).warning

// ** provider
export const RootContextProvider = (props: IProps) => {
    const toast = useCallback((message: string, type: TType = 'info', options?: ToastOptions) => {
        const _type = typeof type === 'string' && RToast[type] ? type : 'info';
        return RToast[_type](message, options);
    }, []);
    const success = useCallback((message: string, options?: ToastOptions) => {
        toast(message, 'success', options);
    }, [toast]);

    const error = useCallback((message: string, options?: ToastOptions) => {
        toast(message, 'error', options);
    }, [toast]);

    const info = useCallback((message: string, options?: ToastOptions) => {
        toast(message, 'info', options);
    }, [toast]);

    const warning = useCallback((message: string, options?: ToastOptions) => {
        toast(message, 'warning', options);
    }, [toast]);

    const contextProps = {
        toast,
        success,
        error,
        info,
        warning
    }

    return (
        <RootContext.Provider value={contextProps}>
            {props.children}
            <ToastContainer
                rtl
                closeButton={false}
                toastStyle={toastContainerBodyStyles}
            />
        </RootContext.Provider>
    )
};