import { resetPAssword } from '../../services/axios/api'
import { put, call, all, takeLatest } from 'redux-saga/effects'
import * as Actions from '../action-types'
import {
    resetAdminFetch,
    resetAdminFetchFailed,
    resetAdminFetchFinished,
} from '../actions/resetPasswordAdmin'

function* resetPasswordAdminAsync(action) {
  yield put(resetAdminFetch())
  try {
    yield call(resetPAssword, action.payload)
    yield put(resetAdminFetchFinished())
  } catch (error) {
    yield put(resetAdminFetchFailed(error.response.data))
  }
}



export default function* resetAdminSaga() {
  yield all([
    takeLatest(Actions.RESET_PASSWORD_ADMIN, resetPasswordAdminAsync),
  ])
}