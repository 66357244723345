import { createSelector } from 'reselect'

const registerSelector = ({ register }) => register

export const getRegisterFetching = createSelector(registerSelector, ({ registerReducer }) => registerReducer.fetching)
export const getRegisterError = createSelector(registerSelector, ({ registerReducer }) => registerReducer.fetchingError)
export const getRegisterSuccess = createSelector(registerSelector, ({ registerReducer }) => registerReducer.success)
export const getAuditorInvitationFetching = createSelector(
  registerSelector,
  ({ auditorInvitation }) => auditorInvitation.acceptInviteFetching
)
export const getAuditorInvitationError = createSelector(registerSelector, ({ auditorInvitation }) => auditorInvitation.acceptInviteError)
export const getAuditorInvitationSuccess = createSelector(
  registerSelector,
  ({ auditorInvitation }) => auditorInvitation.acceptInviteSuccess
)
