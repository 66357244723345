import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import eduSm from '../../../../../markup/images/edu-sm.svg';
import { useForm } from 'react-hook-form';
import useFormContext from '../../../Context/useFormContext';
import { useWindowSize } from '@react-hook/window-size';
import { getServices } from '../../../../../services/axios/api';
import moment from 'moment-hijri';

export default function Contact({ onServiceChange }) {
  const [validation, setValidation] = useState(true);
  const [Services, setServices] = useState([]);
  const [width] = useWindowSize();

  const { handleSubmit, setValue, getValues, watch } = useForm({
    mode: 'onChange',
  });

  const { formData, setFormData, setCurrent, current, handleChange, details } = useFormContext();
  useEffect(() => {
    // keep values in the form
    for (const key in formData) {
      if (formData[key] !== '') {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
    // eslint-disable-next-line
  }, [formData]);

  const watchChanges = watch([
    'attendanceEducation',
    'WorkshopsLectures',
    'VirtualEducation',
    'TrainingCourses',
    'AcademicGuidance',
    'CooperativeTraining',
    'SocialPsychologicalCounseling',
    'SupportMatchingEducationalPath',
    'SupportInSubmission',
    'SupportFindingOpportunities',
  ]);
  useEffect(() => {
    // check if at least one checkbox checked
    const serviceCheckBoxList = getValues([
      'attendanceEducation',
      'WorkshopsLectures',
      'VirtualEducation',
      'TrainingCourses',
      'AcademicGuidance',
      'CooperativeTraining',
      'SocialPsychologicalCounseling',
      'SupportMatchingEducationalPath',
      'SupportInSubmission',
      'SupportFindingOpportunities',
    ]);

    if (serviceCheckBoxList.includes(true)) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    // eslint-disable-next-line
  }, [watchChanges, validation]);

  const fetchServices = async () => {
    const services = await getServices();
    setServices(services);
  };
  useEffect(() => {
    if (!details?.comments) {
      fetchServices();
    }
    // eslint-disable-next-line
  }, []);

  const selectedServices = useRef([]);

  const addService = (e, id) => {
    const serviceCode = Services.find((service) => service.id === id)?.code;
    serviceCode && onServiceChange(serviceCode, e.target.checked);
    selectedServices.current.includes(id)
      ? (selectedServices.current = selectedServices.current.filter((prev) => prev !== id))
      : selectedServices.current.push(id);
    setFormData((prevData) => ({
      ...prevData,
      services: selectedServices.current,
    }));
  };
  return (
    <>
      {details?.comments ? (
        <div className='ServiResoinsive'>
          <form onSubmit={handleSubmit(handleChange)}>
            {width > 767 ? <p className='services-tit'>ملاحظات المدقق</p> : null}

            <div className='main p-3 p-lg-4'>
              <p className='main-comment mb-4'>الرجاء قراءة واتباع التعليمات التالية بعناية:</p>
              {details?.comments?.map((obj) => (
                <div className='mb-4' key={`comment-${obj.id}`}>
                  <span className='font-bold'>ملاحظة بتاريخ: {moment(obj.created_at).format('Do/MM/YYYY')}</span>
                  <p className='sub-comment'>{obj.comment}</p>
                </div>
              ))}
            </div>

            {width > 767 ? (
              <div className='steps-action'>
                <button className='step-btn'>التالي</button>

                <button
                  className='step-btn-prev'
                  onClick={() => {
                    setCurrent(current - 1);
                  }}
                  disabled={current === 0}
                >
                  السابق
                </button>
              </div>
            ) : (
              <div className='steps-action2'>
                <button className='step-btn'>التالي</button>

                <button
                  className='step-btn-prev'
                  onClick={() => {
                    setCurrent(current - 1);
                  }}
                  disabled={current === 0}
                >
                  السابق
                </button>
              </div>
            )}
            {width > 767 ? null : <hr className='personal_info-form-line'></hr>}
          </form>
        </div>
      ) : (
        <div className='ServiResoinsive'>
          <form onSubmit={handleSubmit(handleChange)}>
            {width > 767 ? <p className='services-tit'>تحديد الخدمة</p> : null}

            <p className={`${validation ? 'invalid_checkbox' : 'services-sub-tit'}`}>قم باختيار الخدمة التي ترغب بالتقديم عليها</p>
            <div className='main'>
              <div className='services-card'>
                {/* START SEVICES CONTENT */}
                {Services?.map((service) => {
                  return (
                    service.active && (
                      <div className='services-1 d-flex mb-4 mb-xxl-0' key={service.id}>
                        <div className='services-sm-card flex-shrink-0 me-2'>
                          <img src={eduSm} alt='' className='services-img-1' />
                          <label className='container-checkbox'>
                            <input type='checkbox' name='attendanceEducation' onChange={(e) => addService(e, service.id)} />
                            <span className='checkmark' />
                          </label>
                        </div>
                        <div>
                          <p className='text-serv-1'>{service.name}</p>
                          <p className='text-serv-2'> {service.description}</p>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>

            {width > 767 ? (
              <div className='steps-action'>
                <button className='step-btn' disabled={selectedServices.current.length === 0}>
                  التالي
                </button>

                <button
                  className='step-btn-prev'
                  onClick={() => {
                    setCurrent(current - 1);
                  }}
                  disabled={current === 0}
                >
                  السابق
                </button>
              </div>
            ) : (
              <div className='steps-action2'>
                <button className='step-btn' disabled={selectedServices.current.length === 0}>
                  التالي
                </button>

                <button
                  className='step-btn-prev'
                  onClick={() => {
                    setCurrent(current - 1);
                  }}
                  disabled={current === 0}
                >
                  السابق
                </button>
              </div>
            )}
            {width > 767 ? null : <hr className='personal_info-form-line'></hr>}
          </form>
        </div>
      )}
    </>
  );
}
