import moment from 'moment-hijri';
export const getYears = (start: number, end: number) => {
    const arr = [];
    for (let i = start; i <= end; i = i + 1) {
        arr.push(i);
    }
    return arr;
}

export const getHijriYearsForEducationalForm = () => {
    return getYears(1421, Number(moment().format('iYYYY')));
};

export const getGregorianYearsForEductionalForm = () => {
    return getYears(2000, Number(moment().format('YYYY')));
}