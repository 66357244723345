import { useState, useEffect, useMemo } from 'react';
import sharedApi from '../../../../../shared';
import './PersonalInfoForm.scss';
import moment from 'moment-hijri';
import { GENDER, SOCIAL_STATUS } from '../../../constants';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import avatarLogo from '../../../../../assets/images/shared/avatarLogo.svg';
import useFormContext from '../../../Context/useFormContext';
import { useWindowSize } from '@react-hook/window-size';
import { formatFileSizeInBytes } from 'utils';
import { useToastContext } from 'contexts/RootContext';

const { InputField, AvatarUploader, Checkbox, Upload } = sharedApi.components;
export default function PersonalInfoForm({
  dob,
  nationalId,
  email,
  mobileNumber,
  fullname,
  familyMembers,
  additionalMobileNumber,
  socialStatus,
  gender,
  codes,
  personalPhotoPath,
}) {
  const toast = useToastContext();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const [, setImgSize] = useState('');
  const [age, setAge] = useState('');
  const [datehijri, setDatehijri] = useState('');
  const { formData, setCurrent, current, handleChange } = useFormContext();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  useEffect(() => {
    // calculates age based on selected dob
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    const getBirthDate = moment(dob);
    setDatehijri(getBirthDate.format('iYYYY-iM-iD'));
    setAge(age);
  }, [dob]);

  useEffect(() => {
    // keep values in the form
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
  }, [formData, setValue]);

  const error = useMemo(() => {
    if (errors?.personalPhoto?.message === 'invalidImageSize') return '';
    if (errors?.personalPhoto?.message?.length > 0) return errors.personalPhoto.message;
    if (errors?.personalPhoto?.message === 'required') return 'الحقل مطلوب';
    return undefined;
  }, [errors.personalPhoto]);

  const onPrevious = () => {
    // if no custom inputs available, skip to first step
    !codes.length ? setCurrent(current - 2) : setCurrent(current - 1);
  };

  return (
    <>
      {width > 767 ? <p className='services-tit'>{t('PersonalInfo')}</p> : null}

      <form onSubmit={handleSubmit(handleChange)}>
        <div className='row row-cols-md-2 row-cols-xxl-3'>
          <Controller
            control={control}
            name='personalPhoto'
            rules={{
              validate: (file) => {
                if (!file) {
                  return true;
                }
                if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file?.type)) {
                  setValue('personalPhoto', undefined);
                  toast('امتداد الصورة غير صحيح', 'error');
                  return 'imageTypeInvalid';
                }
                // check if image sizes is greater than 5MB
                if (file?.size > 5242880) {
                  toast('حجم الصورة أكبر من 5 ميجا', 'error');
                  setValue('personalPhoto', undefined);
                  return 'invalidImageSize';
                }
                const _imgSize = formatFileSizeInBytes(file?.size);
                setImgSize(_imgSize);
              },
            }}
            render={({ field }) => <AvatarUploader {...field} logo={avatarLogo} cssClass='w-100' error={error} />}
          />
          {/* {!personalPhotoPath ? (
          ) : (
            <img src={personalPhotoPath} width={140} height={140} style={{ borderRadius: '50%', objectFit: 'cover' }} alt='' />
          )} */}

          <InputField
            title='fullName'
            type='text'
            required
            name='fullName'
            value={fullname}
            classNameLabel='input__label-personalInfo'
            className='inputText w-100'
            disabled={true}
            style={{ backgroundColor: '#EFEFEF' }}
          />

          <InputField
            title='nationalId'
            type='text'
            required
            name='nationalId'
            value={nationalId}
            classNameLabel='input__label-personalInfo'
            className='inputText w-100'
            disabled={true}
            style={{ backgroundColor: '#EFEFEF' }}
            error={errors.nationalId && errors.nationalId.message}
          />
          <InputField
            title='gender'
            value={GENDER.find((s) => s.value === gender)?.ar_label}
            classNameLabel='input__label-personalInfo pt-0'
            className='inputText w-100'
            style={{ backgroundColor: '#EFEFEF' }}
            disabled={true}
          />
          <InputField
            title='dateOfBirth'
            type='text'
            required
            name='dateOfBirth'
            value={datehijri}
            classNameLabel='input__label-personalInfo'
            className='inputText w-100'
            style={{ backgroundColor: '#EFEFEF' }}
            disabled={true}
          />
          <InputField
            required
            title='age'
            type='text'
            name='age'
            value={age}
            classNameLabel='input__label-personalInfo'
            className='inputText w-100'
            style={{ backgroundColor: '#EFEFEF' }}
            disabled={true}
          />
          <InputField
            title='socialStatus'
            value={SOCIAL_STATUS.find((s) => s.value === socialStatus)?.ar_label}
            classNameLabel='input__label-personalInfo pt-0'
            className='inputText w-100'
            style={{ backgroundColor: '#EFEFEF' }}
            disabled={true}
          />
          <InputField
            required
            title='familyNumber'
            type='number'
            value={familyMembers}
            name='familyNumber'
            classNameLabel='input__label-personalInfo pt-0'
            className='inputText w-100'
            style={{ backgroundColor: '#EFEFEF' }}
            disabled={true}
          />
          <InputField
            title='email'
            type='email'
            name='email'
            value={email}
            disabled={true}
            className='inputText w-100'
            required='fieldRequired'
          />
          <InputField
            title='mobileNumber'
            type='number'
            name='mobileNumber'
            value={mobileNumber}
            className='inputText w-100'
            required
            disabled={true}
          />

          <InputField
            type='number'
            title='additional_mobile'
            name='additional_mobile'
            value={additionalMobileNumber}
            className='inputText w-100'
            style={{ backgroundColor: '#EFEFEF' }}
            disabled={true}
          />
        </div>
        {width > 767 ? <hr className='personal_info-form-line'></hr> : null}
        <p className='personal_info-subtitle '>{t('SELECT_APPLICABLE_SPECIFICATIONS')}</p>

        <div className='personal_info-checkbox'>
          <Checkbox title='handiCapped' name='handiCapped' {...register('handiCapped')} />
          <Checkbox title='familyBbreadWinner' name='familyBbreadWinner' {...register('familyBbreadWinner')} />
          <Checkbox title='singleChild' name='singleChild' {...register('singleChild')} />
          <Checkbox title='orphan' name='orphan' {...register('orphan')} />
        </div>

        <div style={{ marginTop: '4rem' }}>
          <label className='form-label'>{t('Attach_of_Social_Security')}</label>

          <Controller
            control={control}
            rules={{
              required: 'fieldRequired',
            }}
            name='Attach_of_Social_Security'
            render={({ field: { onChange } }) => (
              <Upload
                allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                onChange={onChange}
                savedFileStatus={formData.Attach_of_Social_Security === '' ? false : true}
                submitedfile={formData.Attach_of_Social_Security ? formData.Attach_of_Social_Security : null}
                maxSize={10}
                t={t}
              />
            )}
          />
          <small className={'input__error'}>
            <Trans t={t}>{errors.Attach_of_Social_Security && errors.Attach_of_Social_Security.message}</Trans>
          </small>
        </div>

        {width > 767 ? (
          <div className='steps-action'>
            <button
              className='step-btn'
              //disabled={!isValid}
            >
              التالي
            </button>
            <button className='step-btn-prev' onClick={onPrevious} disabled={current === 0}>
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2'>
            <button
              className='step-btn'
              //disabled={!isValid}
            >
              التالي
            </button>
            <button className='step-btn-prev' onClick={onPrevious} disabled={current === 0}>
              السابق
            </button>
          </div>
        )}
        {width > 767 ? null : <hr className='personal_info-form-line'></hr>}
      </form>
    </>
  );
}
