import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import '../../../../markup/bootstrap-grid.rtl.min.css';
import { useForm, Controller } from 'react-hook-form';
import sharedApi from '../../../../shared';
import { message } from 'antd';
import updateProfileIcon from '../../../../assets/images/shared/update_profile_icon.svg';
import Tabs from '../Tabs/Tabs';
import { getHomePageData, updateHomePageData } from '../../../../services/axios/api';
import UploadImage from '../UploadImage/UploadImage';
import emptyImg from '../../../../assets/images/shared/empty-image.svg';

const { ButtonNormal, InputField } = sharedApi.components;
export default function UpdateHome() {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const [isEditModeDisabled, setIsEditModeDisabled] = useState(false);
  const [isUpdateBtnHidden, setIsUpdateBtnHidden] = useState(false);
  const [homePageData, setHomePageData] = useState([]);

  useEffect(() => {
    getHomeData();
    // eslint-disable-next-line
  }, [isEditModeDisabled]);

  useEffect(() => {
    if (homePageData.length) {
      homePageData &&
        homePageData?.map((obj) => {
          if (obj.identifier === 'main_section_6_3') return setValue(obj.identifier, obj.url_link);
          if (obj.data_type !== 'image') return setValue(obj.identifier, obj.title);
          return setValue(obj.identifier, undefined);
        });
    }
    // eslint-disable-next-line
  }, [homePageData]);

  const getHomeData = async () => {
    const data = await getHomePageData();
    setHomePageData(data);
  };

  const handleEditContent = () => {
    setIsEditModeDisabled(true);
    setIsUpdateBtnHidden(true);
  };

  const updateHomeData = async (newData) => {
    try {
      await updateHomePageData(newData);
      setIsEditModeDisabled(false);
      setIsUpdateBtnHidden(false);
      setTimeout(() => {
        message.success({
          type: 'success',
          content: `تم تحديث بيانات الصفحة الرئيسية بنجاح`,
          duration: 2,
        });
      }, 1);
    } catch (err) {
      console.log(err);
    }
  };

  const onCancel = () => {
    setIsUpdateBtnHidden(false);
    setIsEditModeDisabled(false);

    // clear error messages if any
    for (let i in errors) delete errors[i];
  };

  const onSubmit = (data) => {
    const FormDataBody = new FormData();
    homePageData.forEach((obj) => {
      for (const property in data) {
        if (obj.identifier === property && data[property] !== undefined && obj.title !== data[property])
          FormDataBody.append(property, data[property]);
      }
    });
    updateHomeData(FormDataBody);
  };
  const findImage = (fieldName) => (homePageData && homePageData?.find((obj) => obj.identifier === fieldName)?.image) || emptyImg;
  return (
    <>
      <div className='auditor_update_personal_info-auditorList-container'>
        <div className='auditor_update_personal_info-auditorList'>
          <div>
            <div className='auditor_personal_info-div-auditor'>
              <section>
                <p className='auditor_personal_info-title'>{t('cms_page_title')}</p>
                <p className='auditor_personal_info-subtitle'>{t('cms_page_subtitle')} </p>
              </section>
            </div>
            <Tabs />
          </div>
          <button
            className={`auditor_personal_info-btn mr-auto mb-3 ${isUpdateBtnHidden ? 'visibility-hidden' : ''}`}
            onClick={handleEditContent}
          >
            <img src={updateProfileIcon} alt='updateProfileIcon' className='auditor_personal_info-icon' />
            <p> تحديث المحتوى</p>
          </button>
          <form onSubmit={handleSubmit(onSubmit)} className={isEditModeDisabled ? 'edit-mode' : ''}>
            <div className='purple-card-with-title'>
              <div className='card--title'>عنوان الصفحة</div>
              <div className='row px-3 px-lg-0'>
                <div className='col-lg-4 col-xl-3'>
                  <InputField
                    title='pageTitle'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='main_titlte'
                    error={errors.main_titlte?.message}
                    {...register('main_titlte', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم الأول</div>
              <div className='row px-3 px-lg-0'>
                <div className='col-lg-4 col-xl-3'>
                  <InputField
                    title='sectionMainTitle'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='main_section_1'
                    error={errors.main_section_1?.message}
                    {...register('main_section_1', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
                <div className='col-lg-1 col-xl-1'></div>
                <div className='col-lg-6 col-xl-5'>
                  <InputField
                    title='sectionDesc2'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='main_section_1_1'
                    error={errors.main_section_1_1?.message}
                    {...register('main_section_1_1', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم الثاني</div>
              <div className='row px-3 px-lg-0'>
                <div className='col-lg-4 col-xl-3'>
                  <InputField
                    title='sectionTitle'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='main_section_2'
                    error={errors.main_section_2?.message}
                    {...register('main_section_2', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
                <div className='col-lg-1 col-xl-1'></div>
                <div className='col-lg-7 col-xl-8'>
                  <div className='input'>
                    <label className='input__label mb-2'>{t('sectionDesc')}</label>
                    <textarea
                      className='form-text-area'
                      readOnly={!isEditModeDisabled}
                      name='main_section_2_1'
                      {...register('main_section_2_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <small className='input__error'>{errors.main_section_2_1 && t('fieldRequired')}</small>
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم الثالث</div>
              <div>
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3'
                      error={errors.main_section_3?.message}
                      {...register('main_section_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <hr />
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_3_1_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_3_1_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-3 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_1_2'
                      error={errors.main_section_3_1_2?.message}
                      {...register('main_section_3_1_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-lg-4 col-xl-5'>
                    <InputField
                      title='sectionDesc'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_1_3'
                      error={errors.main_section_3_1_3?.message}
                      {...register('main_section_3_1_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_3_2_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_3_2_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-3 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_2_2'
                      error={errors.main_section_3_2_2?.message}
                      {...register('main_section_3_2_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-lg-4 col-xl-5'>
                    <InputField
                      title='sectionDesc'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_2_3'
                      error={errors.main_section_3_2_3?.message}
                      {...register('main_section_3_2_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_3_3_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_3_3_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-3 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_3_2'
                      error={errors.main_section_3_3_2?.message}
                      {...register('main_section_3_3_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-lg-4 col-xl-5'>
                    <InputField
                      title='sectionDesc'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_3_3'
                      error={errors.main_section_3_3_3?.message}
                      {...register('main_section_3_3_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_3_4_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_3_4_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-3 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_4_2'
                      error={errors.main_section_3_4_2?.message}
                      {...register('main_section_3_4_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-lg-4 col-xl-5'>
                    <InputField
                      title='sectionDesc'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_3_4_3'
                      error={errors.main_section_3_4_3?.message}
                      {...register('main_section_3_4_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم الرابع</div>
              <div className='row px-3 px-lg-0'>
                <div className='col-lg-4 col-xl-3'>
                  <InputField
                    title='sectionTitle'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='main_section_4'
                    error={errors.main_section_4?.message}
                    {...register('main_section_4', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
                <div className='col-lg-1 col-xl-1'></div>
                <div className='col-lg-3 col-xl-3'>
                  <InputField
                    title='sectionDesc2'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='main_section_4_1'
                    error={errors.main_section_4_1?.message}
                    {...register('main_section_4_1', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
                <div className='col-lg-4 col-xl-5'>
                  <div className='input'>
                    <label className='input__label mb-2'>{t('sectionDesc')}</label>
                    <textarea
                      className='form-text-area'
                      readOnly={!isEditModeDisabled}
                      name='main_section_4_2'
                      {...register('main_section_4_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <small className='input__error'>{errors.main_section_4_2 && t('fieldRequired')}</small>
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم الخامس</div>
              <div>
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_5'
                      error={errors.main_section_5?.message}
                      {...register('main_section_5', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-7 col-xl-8'>
                    <InputField
                      title='sectionDesc'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_5_1'
                      error={errors.main_section_5_1?.message}
                      {...register('main_section_5_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <hr />
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_5_2_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_5_2_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_5_2_2'
                      error={errors.main_section_5_2_2?.message}
                      {...register('main_section_5_2_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_5_3_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_5_3_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_5_3_2'
                      error={errors.main_section_5_3_2?.message}
                      {...register('main_section_5_3_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_5_4_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_5_4_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_5_4_2'
                      error={errors.main_section_5_4_2?.message}
                      {...register('main_section_5_4_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_5_5_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_5_5_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_5_5_2'
                      error={errors.main_section_5_5_2?.message}
                      {...register('main_section_5_5_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('imgORicon')}</label>
                      <div style={{ display: isEditModeDisabled ? 'block' : 'none' }}>
                        <Controller control={control} name='main_section_5_6_1' render={({ field }) => <UploadImage {...field} />} />
                      </div>
                      <img
                        style={{ display: isEditModeDisabled ? 'none' : 'inline' }}
                        src={findImage('main_section_5_6_1')}
                        width='100'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_5_6_2'
                      error={errors.main_section_5_6_2?.message}
                      {...register('main_section_5_6_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم السادس</div>
              <div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_6'
                      error={errors.main_section_6?.message}
                      {...register('main_section_6', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-lg-1 col-xl-1'></div>
                  <div className='col-lg-3 col-xl-3'>
                    <InputField
                      title='btnText'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_6_2'
                      error={errors.main_section_6_2?.message}
                      {...register('main_section_6_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-lg-4 col-xl-5'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('sectionDesc')}</label>
                      <textarea
                        className='form-text-area'
                        readOnly={!isEditModeDisabled}
                        name='main_section_6_1'
                        {...register('main_section_6_1', {
                          required: 'fieldRequired',
                        })}
                      />
                    </div>
                    <small className='input__error'>{errors.main_section_6_1 && t('fieldRequired')}</small>
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-12'>
                    <InputField
                      title='رابط الزر'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='main_section_6_3'
                      error={errors.main_section_6_3?.message}
                      {...register('main_section_6_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
            {!isEditModeDisabled ? null : (
              <div>
                <div className='auditor_update_personal_info-steps-action mt-0'>
                  <ButtonNormal titleButton=' الغاء' classNameButton='step-btn-3' onClick={onCancel} type='button' />
                  <ButtonNormal titleButton=' حفظ' disabled={!isValid} type='submit' />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
