import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { serviceProviderSubmit } from '../../../../services/axios/api';
import RemoveIcon from '../../../../markup/images/remove-icon.svg';
import sharedApi from '../../../../shared';
import { useFormContext } from '../../ServiceProviderFormSteps';
import { useWindowSize } from '@react-hook/window-size';

function AdditionalCertificates(props) {
  const { InputField, CertificatesUpload, Checkbox } = sharedApi.components;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const Navigate = useNavigate();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const { formData, setFormData, setCurrent, current } = useFormContext();

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Additional_Certificates',
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const FormDataBody = new FormData();

    // معلومات المنشأة
    FormDataBody.append('establishment_info.establishment_name', formData.institutionName);
    FormDataBody.append('establishment_info.establishment_region_location', formData.institutionLocation);
    FormDataBody.append('establishment_info.sector_type', formData.institutionType);
    FormDataBody.append('establishment_info.establishment_definition', formData.institutionDefine);
    formData.institutionDefine === '3' && FormDataBody.append('establishment_info.establishment_definition_other', formData.ID_Type);
    FormDataBody.append('establishment_info.establishment_definition_identification', formData.InstID);
    FormDataBody.append('establishment_info.establishment_eligibility_license', formData.institution_certificate);

    // // ممثل المنشأة
    FormDataBody.append('establishment_representative_info.full_name', formData.fullName);
    FormDataBody.append('establishment_representative_info.national_id', formData.ID);
    FormDataBody.append('establishment_representative_info.phone', formData.mobileNo);
    FormDataBody.append('establishment_representative_info.additional_phone', formData.Additional_Number);
    FormDataBody.append('establishment_representative_info.email', formData.email);
    FormDataBody.append(
      'establishment_representative_info.Authorization_of_the_establishment_representative',
      formData.representative_certificate
    );

    // //معلومات الخدمة
    FormDataBody.append('service_info.service_type', formData.serviceType);
    FormDataBody.append('service_info.service_details', formData.serviceDetail);
    formData.serviceType === '5' && FormDataBody.append('service_info.service_details_other', formData.serviceDetailOther);
    FormDataBody.append('service_info.expected_start_date', formData.startDate);
    FormDataBody.append('service_info.number_of_service_beneficiaries', formData.serviceAmount);
    formData.financialSupport.length && FormDataBody.append('service_info.financial_support', formData.financialSupport);
    FormDataBody.append('service_info.provided_service_description', formData.serviceDetailText);
    // المرفقات الإضافية
    for (const key in formData.Additional_Certificates) {
      FormDataBody.append(`additional_attachments[${parseInt(key)}]name`, formData.Additional_Certificates[key].name_Of_Certificate);
      FormDataBody.append(
        `additional_attachments[${parseInt(key)}]file`,
        formData.Additional_Certificates[key].Certification_of_Completion?.name
      );
    }

    try {
      await serviceProviderSubmit(FormDataBody);
      setIsSubmitting(false);
      Navigate(`/home`, { state: true });
    } catch (error) {
      console.log('there is no user id error', error);
    }
  };

  const handleAppend = () => {
    if (fields.length < 5) {
      append({});
    }
  };
  useEffect(() => {
    append({});
  }, [append]);

  useEffect(() => {
    const subscription = watch((data) => {
      for (const key in data) {
        data[key] &&
          setFormData((prevData) => ({
            ...prevData,
            [key]: data[key],
          }));
      }
    });
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <div className='header-app-contact'>{width > 767 && <p className='app-contact-title mb-1'>المرفقات الاضافية</p>}</div>
      <form>
        <div className='div-AdditionalCertificates'>
          <div className='header-AdditionalCertificates' style={{ paddingLeft: 0, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <p>عنوان المرفق</p>
            </div>
            <div style={{ marginInlineEnd: 56 }}>
              <p>{t('attachments')}</p>
            </div>
          </div>
          <hr className='line-header-AdditionalCertificates' />
          {fields.map(({ id }, index) => (
            <div className='sub-header-AdditionalCertificates' key={id}>
              <InputField
                name={`Additional_Certificates[${index}].name_Of_Certificate`}
                className='additionalField'
                className_div_input='service-attachments'
                maxLength={65}
                error={
                  errors.Additional_Certificates?.[index]?.name_Of_Certificate?.message ||
                  errors.Additional_Certificates?.[index]?.Certification_of_Completion?.message
                }
                {...register(`Additional_Certificates[${index}].name_Of_Certificate`, {
                  required: 'fieldRequired',
                })}
              />
              <div className='service-attachments-btns'>
                <Controller
                  control={control}
                  rules={{
                    required: 'fieldRequired',
                  }}
                  name={`Additional_Certificates[${index}].Certification_of_Completion.name`}
                  error={errors.Additional_Certificates?.[index]?.Certification_of_Completion?.message}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CertificatesUpload
                      uploaded={getValues(`Additional_Certificates[${index}].Certification_of_Completion.name.size`)}
                      allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                      onChange={onChange}
                      maxSize={10}
                      t={t}
                    />
                  )}
                />
                <button type='button' onClick={() => remove(index)}>
                  <img src={RemoveIcon} alt='' className='remove-row' />
                </button>
              </div>
            </div>
          ))}
          <div className='container'>
            <button className='btn-AdditionalCertificates' type='button' onClick={() => handleAppend()}>
              <b style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem', marginLeft: '0.4rem' }}>+</b> أضف مرفق آخر
            </button>
          </div>
        </div>

        <hr className='line-header-AdditionalCertificates2' />
        <Checkbox
          title='أقر أنا مقدم الخدمة بصحة ودقة المعلومات المدخلة والمستندات الرسمية أعلاه'
          name='firstDisclaimer'
          className='form-check__graduate'
          classNameLabel='form-check__check-labelu'
          style={{ backgroundSize: '18px 8px', width: '16px', height: '16px', fontSize: '20px', borderRadius: '4px' }}
          {...register('firstDisclaimer', {
            required: 'fieldRequired',
          })}
        />
        {errors.firstDisclaimer && <p className='error_text_form'>يجب الموافقة على الاقرار أعلاه</p>}

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button className='step-btn' data-loading={isSubmitting} onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
              إرسال
            </button>
            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn' data-loading={isSubmitting} onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
              إرسال
            </button>
            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
      </form>
    </>
  );
}

export default AdditionalCertificates;
