import React from 'react';
import './Checkbox.scss';
import { useTranslation, Trans } from 'react-i18next';

const Checkbox = ({ title, name, classNameLabel, style, value, onChange, className, error, className_error }, ref) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={className ? className : `form-check`}>
        <input
          type='checkbox'
          name={name}
          style={style}
          // className={`form-checkbox`}
          className={`form-checkbox`}
          ref={ref}
          value={value}
          onChange={onChange}
        />
        <label className={classNameLabel ? classNameLabel : 'checbox-label'} htmlFor={name}>
          {t(title)}
        </label>
      </div>
      {error && (
        <p className={className_error ? className_error : 'input__error'}>
          <Trans t={t}>{error}</Trans>
        </p>
      )}
    </>
  );
};

export default React.forwardRef(Checkbox);
