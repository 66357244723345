export const API_ENDPOINTS = {
  adminLogin: '/user/login/',
  applicantLogin: '/user/student/login/',
  serviceProviderLogin: '/user/service-Provider/login/',
  applicantRegistration: '/user/register/',
  serviceProviderRegistration: '/user/service-Provider/register/',
  otp: '/user/otp/validate/',
  otpServiceProvider: '/user/service-Provider/register/otp/validate/',
  anotherOtp: '/user/otp/resend/',
  anotherOtpServiceProvider: '/user/service-Provider/register/otp/resend/',
  auditorCreateInvitation: '/user/invitation/confirm/',
  resetPassword: '/user/forget_password/',
  resetServiceProviderPassword: '/user/forget_password/',
  otpLogin: '/user/student/login/otp/validate/',
  otpServiceProviderLogin: '/user/service-Provider/login/otp/validate/',
  anotherOtpLogin: '/user/student/login/otp/resend/',
  anotherOtpServiceProviderLogin: '/user/service-Provider/login/otp/resend/',
  setPassword: '/user/reset_password/',
  setServiceProviderPassword: '/user/reset_password/',
  getApplicantformInfo: '/user/users/me/',
  resetPasswordValidate: '/user/reset_password/validate/',
  applicantFormSubmit: '/student/requests/',
  applicationListNew: '/student/requests/auditor/unassigned/',
  applicationList: '/student/requests/auditor/',
  assignRequest: '/student/request_list/',
  manageRequest: '/student/requests/auditor/',
  manageRequestAdmin: '/student/requests/admin/',
  getApplicationDetails: '/student/requests/',
  getServiceProviderApplicationDetails: '/service-provider/',
  addAdutor: '/user/users/',
  getAuditorList: '/user/users/?account_type=2',
  exportAuditorListUrl :'/user/send_data_email_view/',
  exportRequestListUrl :'/student/send_data_email_view_requests/',
  activeInactiveAdutor: '/user/users/',
  resendInvitation: '/user/auditor/',
  userData: '/user/users/me/',
  adminApplicationList: '/student/requests/admin/',
  postNational_id_number: '/user/auditor_profile/',
  changePassword: '/user/change_password/',
  canCreateRequest: '/student/can_create_request/',
  serviceProvderFormSubmit: '/service-provider/create/',
  getCitiesList: '/student/cities/',
  getRegionsList: '/student/regions/',
  getHomePageData: '/admin/content/page/1',
  updateHomePageData: '/admin/content/edit_list/',
  postAboutPageData: '/test',
  postMembersData: '/test',
  getServices: '/service/',
  addServices: '/service/',
  getAboutPageData: '/admin/content/page/2',
  updateAboutPageData: '/admin/content/edit_list/',
  getMembersData: '/admin/content/member/',
  postMemberData: '/admin/content/member/',
  getBanksData: '/admin/content/donBankAccounts/',
  postBankData: '/admin/content/donBankAccounts/',
  getLandingPageData: '/admin/content/all/',
  getServicesAll: '/service/all/',
  validateRecaptchToken: '/user/check_captcha/',
  deleteService: '/service/',
  getUserQiyas: '/user/qiyas_verify_beneficiary/',
  updateUserStatus: '/user/update_status/',
  updateQiyasStatus: '/user/update_qiyas_exception/',
  getUserData: '/user/my_profile/',
  updateUserData: '/user/my_profile/',
  fetchSPLdata: '/student/get_spl_address/',
};
