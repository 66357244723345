import { applyMiddleware, compose } from 'redux';
import { legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'taalum',
  storage,
  whitelist: ['userData'],
};

const sagaMiddleware = createSagaMiddleware(rootSaga);

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(rootReducer, composeEnhancer(applyMiddleware(sagaMiddleware)));

// return store;
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureAppStore = () => {
  let store = createStore(persistedReducer, compose(applyMiddleware(sagaMiddleware)));
  let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};
