import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { Card, LogoHeader, Button, Toaster, InputField, PasswordValidation } from '../../../shared/components'
import { PASSWORD_PATTERN } from '../../../shared/utils/regax'
import './index.scss'

const SetPassword = (props) => {
  const Navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' })
  const { setPasswordRequest } = props
  const { token } = useParams()
  const [passworError] = useState('')
  const [specialChar, setSpecialChar] = useState('')
  const [number, setNumber] = useState('')
  const [uppercase, setUppercase] = useState('')
  const [lowercase, setLowercase] = useState('')
  const [showResults, setShowResults] = useState({ message: '', type: '' })

  const handleChange = (data) => {
    setPasswordRequest.setPassword({ new_password: data.password, re_new_password: data.confirmPassword, token })
  }

  useEffect(() => {
    if (props.setPasswordSuccess) {
      Navigate('/applicant-login')
    } else if (props.setPasswordError) {
      setShowResults({ message: 'انتهت صلاحية هذا الرابط، يمكنك طلب إعادة تعيين كلمة المرور من جديد', type: 'warning' })
    }

    // eslint-disable-next-line
  }, [props])
  return (
    <div className='background-full__screen'>
      <div className='secondbg-full__screen'></div>
      <div className='thirdbg-full__screen'></div>
      <div className='fivebg-full__screen'></div>
      <LogoHeader white />

      <div className='flex flex--center'>
        <Card title='CHANGE_PASSWORD'
          toaster={showResults.message.length ? <Toaster message={showResults.message} type={showResults.type} /> : <div className='without_toster' />}
        >
          <div className='validation-margin'>
            <PasswordValidation
              capsLetterFlag={uppercase}
              smallLetterFlag={lowercase}
              numberFlag={number}
              specialCharFlag={specialChar}
              customStyle={'custom-password-validation'}
            />
          </div>

          <form onSubmit={handleSubmit(handleChange)} className='form_padding'>
            <div className='card__body--wide'>
              <InputField
                name='password'
                title='NEW_PASSWORD'
                placeholder='password'
                type='password'
                error={passworError}
                {...register('password', {
                  validate: {
                    specialChar: (value) =>
                      /[#!@$%^&*]/.test(value)
                        ? setSpecialChar('valid-1')
                        : value.length
                          ? setSpecialChar('invalid-1')
                          : setSpecialChar('default-list'),
                    number: (value) =>
                      /[0-9]/.test(value) ? setNumber('valid-1') : value.length ? setNumber('invalid-1') : setNumber('default-list'),
                    uppercase: (value) =>
                      /[A-Z]/.test(value)
                        ? setUppercase('valid-1')
                        : value.length
                          ? setUppercase('invalid-1')
                          : setUppercase('default-list'),
                    lowercase: (value) =>
                      /[a-z]/.test(value)
                        ? setLowercase('valid-1')
                        : value.length
                          ? setLowercase('invalid-1')
                          : setLowercase('default-list'),
                    eightCharsOrGreater: (value) => PASSWORD_PATTERN.test(value),
                  },
                })}
              />

              <InputField
                name='confirmPassword'
                title='CONFIRM_NEW_PASSWORD'
                placeholder='password'
                type='password'
                error={errors.confirmPassword && errors.confirmPassword.message}
                {...register('confirmPassword', {
                  required: 'fieldRequired',
                  validate: (value) => value === watch('password') || 'matchPasswordInvalid',
                })}
              />
            </div>

            <div className='card__btn'>
              <Button
                buttonStyle={true ? 'btn--secondary' : 'btn--primary'}
                titleButton='CHANGE_PASSWORD'
                disabled={!isValid}
              // customStyle={"wide_button"}
              />
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default SetPassword
