import React, { useEffect, useState } from 'react';
import { customAuthGet, ExportAuditorList, ExportRequestList, getServicesAll } from '../../../services/axios/api';
import TableList from '../../../shared/components/TableList';
import { message } from 'antd';

export function ApplicationReq() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [count, setCount] = useState(0);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  const statues = [
    {
      label: '',
      value: '',
    },
    {
      label: 'بانتظار المعالجة',
      value: 1,
    },
    {
      label: 'قبول أولي',
      value: 2,
    },
    {
      label: 'معاد',
      value: 3,
    },
    {
      label: 'مرفوض',
      value: 4,
    },
    {
      label: 'قبول نهائي',
      value: 5,
    },
  ];
  const StatusSelect = () => {
    return (
      <div className='status-select-container'>
        <label htmlFor='status-select'>اختر الحالة:</label>
        <select
          id='status-select'
          className='status-select'
          value={selectedStatus}
          onChange={(event) => setSelectedStatus(event.target.value)}
        >
          {Object.keys(statues).map((key) => (
            <option key={key} value={statues[key].value}>
              {statues[key].label}
            </option>
          ))}
        </select>
      </div>
    );
  };
  const ServiceSelect = () => {
    const options = [{ id: '', name: '' }, ...Object.values(services)];
    return (
      <div className='status-select-container'>
        <label htmlFor='status-select'>اختر الخدمة :</label>
        <select
          id='status-select'
          className='status-select'
          value={selectedService}
          onChange={(event) => setSelectedService(event.target.value)}
        >
          {options.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name}
            </option>
          ))}
        </select>
      </div>
    );
  };
  const fetchData = async (query) => {
    setLoading(true);
    let data = {};
    query = query ? query : 1;
    let url = '/student/requests/admin/' + '?page=' + query;
    if (searchText !== '') url = url + '&search=' + searchText;
    if (selectedStatus !== '') url = url + '&status=' + selectedStatus;
    if (selectedService !== null) url = url + '&service=' + selectedService;
    if (startDate !== '') url = url + '&start_date=' + startDate;
    if (endDate !== '') url = url + '&end_date=' + endDate;
    data = await customAuthGet(url);
    data['results']['previous'] = data.previous;
    data['results']['next'] = data.next;
    data['results']['count'] = data.count;
    setCount(data.count);

    setApplications(data.results);
    setLoading(false);
  };
  const fetchServices = async () => {
    let togglePaginate ='?paginate=false';
    const data = await getServicesAll(togglePaginate);
    setServices(data.results);
  };
  const exportRequestList = async () => {
    let data = {};
    let url = '?';
    if (searchText !== '') url = url + '&search=' + searchText;
    if (selectedStatus !== '') url = url + '&status=' + selectedStatus;
    if (selectedService !== null) url = url + '&service=' + selectedService;
    if (startDate !== '') url = url + '&start_date=' + startDate;
    if (endDate !== '') url = url + '&end_date=' + endDate;

    setTimeout(() => {
      message.success({
        type: 'success',
        content: 'تم ارسال الملف الى البريد الإلكتروني ',
        duration: 3,
      });
    }, 2);
    await ExportRequestList(url);
  };
  useEffect(() => {
    fetchData();
    fetchServices();
    // eslint-disable-next-line
  }, []);

  const searchHanddler = async (e) => {
    e.preventDefault();
    fetchData();
  };
  return (
    <div className='auditorList-container'>
      <TableList
        headers={['رقم الطلب', 'تاريخ التقديم', 'الحالة', 'الإجراءات']}
        title='إدارة الطلبات'
        subTitle='يمكنك إدارة الطلبات الموافق عليها من قبل المدققين'
        data={applications}
        loading={loading}
        fetchNewPage={fetchData}
        isAdmin
        searchInput={
          //<div className='search-box-container'>
          // <div className='search-container'>
          <div className='request-search-input'>
            <form onSubmit={searchHanddler}>
              <div className='filters-container'>
                <div className='date-input-group'>
                  <label htmlFor='start-date' className='mb-1'>
                    ابحث هنا :
                  </label>

                  <input
                    placeholder='  البحث عن طلب محدد'
                    type='text'
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                    style={{
                      fontFamily: 'DIN Regular',
                      backgroundColor: '#12284F0D',
                      width: '205px',
                      height: '40px',
                      padding: '2px',
                      fontWeight: 'bold',
                      paddingRight: '20px',
                      borderRadius: '15px',
                      border: '1px solid #ccc',
                    }}
                    onBlur={searchHanddler}
                  />
                </div>
                <StatusSelect />
                <ServiceSelect />

                <div className='date-input-group'>
                  <label htmlFor='start-date'>اختر تاريخ البداية :</label>
                  <input
                    type='date'
                    id='start-date'
                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value)}
                    className='date-input'
                  />
                </div>
                <div className='date-input-group'>
                  <label htmlFor='end-date'>اختر تاريخ النهاية :</label>
                  <input
                    type='date'
                    id='end-date'
                    value={endDate}
                    onChange={(event) => setEndDate(event.target.value)}
                    className='date-input'
                  />
                </div>
                <div>
                  <button className='filter-button'>بحث</button>
                </div>
                <div>
                  <button onClick={exportRequestList} className='filter-button'>
                    تصدير
                  </button>
                </div>
              </div>
            </form>
            <div className='count-lable'>عدد الطلبات :{' ' + count}</div>
          </div>
          // </div>
          // </div>
        }
      />
    </div>
  );
}
