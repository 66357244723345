import { combineReducers } from 'redux';
import * as Actions from '../../action-types';

const initialFetchingState = {
  fetching: null,
  fetchingError: null,
  success: null,
};

export const addServicesReducer = (state = initialFetchingState, action) => {
  switch (action.type) {
    case Actions.ADD_SERVICES_FETCH_START:
      return {
        ...state,
        fetching: true,
      };
    case Actions.ADD_SERVICES_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        success: null,
        fetchingError: action.payload,
      };
    case Actions.ADD_SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        fetchingError: null,
        success: true,
      };
    case Actions.RESET_STATE:
      return initialFetchingState;
    default:
      return state;
  }
};
export default combineReducers({
  addServicesReducer,
});
