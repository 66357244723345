/**
 * Disable the next code, since DEVOPS will replace `REACT_APP_MAIN_URL` value to $env based on staging,
 * So from ourside will be set as `https://dev-taalum-backend.tamkeen.live/api` and will be replaced at the pipline build time
 * */
const envStagingUrl = process.env.REACT_APP_MAIN_URL;
// const hash = {
//   local: 'https://dev-taalum-backend.tamkeen.live/api',
//   staging: 'https://dev-taalum-backend.tamkeen.live/api',
//   uat: 'https://uat-taalum-backend.tamkeen.live/api',
//   master: 'https://uat-taalum-backend.tamkeen.live/api',
//   dev: 'https://dev-taalum-backend.tamkeen.live/api',
//   staging: 'https://dev-taalum-backend.tamkeen.live/api',
//   prod: 'https://taalum-backend.tamkeen.live/api',
// };

const REACT_APP_MAIN_URL = envStagingUrl;
// ** exports
const apiUrls = {
  mainUrl: REACT_APP_MAIN_URL,
};
export default apiUrls;
