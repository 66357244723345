import React, { useEffect, useState } from 'react';
import TableList from '../../../shared/components/TableList';
import './index.scss';
import { auditorApplicationsList, customAuthGet } from '../../../services/axios/api';

const ApplicationList = (props) => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async (query) => {
    setLoading(true);
    let data = {};
    if (query) {
      data = await customAuthGet('/student/requests/auditor/?page=' + query);
    } else {
      data = await auditorApplicationsList();
    }
    setApplications(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='auditorList-container'>
      <TableList
        headers={['رقم الطلب', 'تاريخ التقديم', 'الحالة', 'الإجراءات']}
        title='إدارة الطلبات'
        subTitle='يمكنك استعراض، قبول، رفض وإعادة الطلبات المرسلة'
        data={applications}
        loading={loading}
        fetchNewPage={fetchData}
        isAuditor
      />
    </div>
  );
};

export default ApplicationList;
