import React, { useEffect, useState, useRef } from 'react';
import sharedApi from '../../../../../shared';
import './ContactForm.scss';
import { useForm, Controller } from 'react-hook-form';
import { POSTAL_CODE } from '../../../../../shared/utils/regax';
import { useTranslation } from 'react-i18next';
import useFormContext from '../../../Context/useFormContext';
import { useWindowSize } from '@react-hook/window-size';
import { getListOfCities, getRegionsList } from '../../../../../services/axios/api';

const { SelectCustom, InputField } = sharedApi.components;

const ContactForm = ({ addressData }) => {
  const cityRef = useRef('');
  const [regionsList, setRegionsList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [hasSPLaddress, setHasSPLaddress] = useState(false);

  const [width] = useWindowSize();

  const { t } = useTranslation();

  const { formData, setCurrent, current, handleChange } = useFormContext();

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const { hasAddress } = addressData;
    if (!hasAddress) return;
    setHasSPLaddress(hasAddress);
    setValue('cityName', addressData.cityName);
    setValue('city', addressData.cityID);
    setValue('neighborhood', addressData.neighborhoodName);
    setValue('street_name', addressData.streetName);
    setValue('postalCode', addressData.postalCode);
    setValue('address_additional_number', addressData.address_additional_number);
  }, []);

  const fetchRegions = async () => {
    setRegionsList(await getRegionsList());
  };

  const fetchCities = async (id) => {
    let data = [{ id: 0, name_ar: 'اختر المدينة ...', name_en: ' select the city ...' }, ...(await getListOfCities(id))];
    setCitiesList(data);
  };

  const regionChangeHandler = (value) => {
    setValue('region', value.target.value, { shouldValidate: true });
    if (!hasSPLaddress) fetchCities(value.target.value);
  };

  const cityChangeHandler = (value) => {
    setValue('city', value.target.value, { shouldValidate: true });
  };

  useEffect(() => {
    fetchRegions();
    // keep values in the form
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        if (key === 'region' && !hasSPLaddress) {
          fetchCities(formData['region']);
        }
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
  }, [formData, setValue]);

  return (
    <>
      <div className='header-app-contact'>
        {width > 767 ? (
          <>
            <p className='app-contact-title ms-0'>{t('homeAddress')}</p>
            <p className='app-contact-subtitle'>{t('accommodation current')} </p>
          </>
        ) : null}
      </div>
      <form onSubmit={handleSubmit(handleChange)}>
        <div className='row row-cols-md-2 row-cols-xxl-3'>
          <Controller
            name='region'
            control={control}
            rules={{
              required: 'fieldRequired',
            }}
            render={({ field }) => (
              <SelectCustom
                {...field}
                required
                title='region'
                name='region'
                cssClass='w-100'
                options={regionsList}
                onChange={regionChangeHandler}
                error={errors.region && errors.region.message}
              />
            )}
          />

          {!hasSPLaddress ? (
            <Controller
              name='city'
              control={control}
              rules={{
                required: 'fieldRequired',
              }}
              render={({ field }) => (
                <SelectCustom
                  {...field}
                  title='city'
                  required
                  ref={cityRef}
                  cssClass='w-100'
                  onChange={cityChangeHandler}
                  options={citiesList}
                  error={errors.city && errors.city.message}
                />
              )}
            />
          ) : (
            <>
              <InputField
                title='city'
                type='text'
                className='inputText w-100'
                required
                disabled={true}
                error={errors.city && errors.city.message}
                maxLength={25}
                className_error='input__error_form'
                {...register('cityName')}
              />
              <input type='hidden' {...register('city')} />
            </>
          )}
          <InputField
            title='neighborhood'
            type='text'
            name='neighborhood'
            className='inputText w-100'
            required
            disabled={hasSPLaddress ? true : false}
            error={errors.neighborhood && errors.neighborhood.message}
            maxLength={25}
            className_error='input__error_form'
            {...register('neighborhood', {
              required: 'fieldRequired',
            })}
          />
          <InputField
            title='street_name'
            className='inputText w-100'
            required
            type='text'
            name='street_name'
            disabled={hasSPLaddress ? true : false}
            error={errors.street_name && errors.street_name.message}
            maxLength={25}
            className_error='input__error_form '
            {...register('street_name', {
              required: 'fieldRequired',
            })}
          />
          <InputField
            title='الرقم الاضافي'
            required
            type='number'
            maxLength={4}
            className='inputText w-100'
            name='address_additional_number'
            disabled={hasSPLaddress ? true : false}
            error={errors.address_additional_number && errors.address_additional_number.message}
            className_error='input__error_form '
            {...register('address_additional_number', {
              required: 'fieldRequired',
            })}
          />
          <InputField
            title='postalCode'
            type='number'
            name='postalCode'
            maxLength={10}
            className='inputText w-100'
            disabled={hasSPLaddress ? true : false}
            error={errors.postalCode && errors.postalCode.message}
            className_error='input__error_form '
            {...register('postalCode', {
              pattern: {
                value: POSTAL_CODE,
                message: 'postalcodeInvalid',
              },
              onChange: (e) =>
                setValue(
                  'postalCode',
                  !/^[0-9]*$/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                ),
            })}
          />
        </div>

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>
            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
        {width > 767 ? null : <hr className='line-form'></hr>}
      </form>
    </>
  );
};

export default ContactForm;
