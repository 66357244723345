import ApplicantForm from './ApplicantForm'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import * as userDataSelector from '../../../redux/selectors/userData'
import * as userDataActions from '../../../redux/actions/userData'
import * as FormSubmissionSelectors from '../../../redux/selectors/applicantFormSubmission'
import * as FormSubmissionActions from '../../../redux/actions/applicantFormSubmission'
const mapStateToProps = () =>
  createStructuredSelector({
    // retrive data in form steps
    fetching: userDataSelector.getUserDataFetching,
    error: userDataSelector.getUserDataError,
    applicantFormData: userDataSelector.getUserInfo,
    success: userDataSelector.getUserDataSuccess,
    // Form Submission 
    fetchingFormSubmission: FormSubmissionSelectors.getApplicantFormSubmissionFetching,
    errorFormSubmission: FormSubmissionSelectors.getApplicantFormSubmissionError,
    successFormSubmission: FormSubmissionSelectors.getApplicantFormSubmissionSuccess,
    responseData: FormSubmissionSelectors.getApplicantFormSubmissionResponse,
  })

const mapDispatchToProps = (dispatch) => ({
  applicantFormRequest: bindActionCreators(userDataActions, dispatch),
  formSubmission: bindActionCreators(FormSubmissionActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(ApplicantForm)
