// export { default as AdminDashboard } from './AdminDashboard'
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import withRouter from '../../../shared/utils/with-router';
import composeEnhancers from '../../../shared/utils/compose-enhancers';
import AdminDashboard from './AddAuditor';
import * as addAdutorSelectors from '../../../redux/selectors/addAdutor';
import * as addAdutorActions from '../../../redux/actions/addAdutor';

const mapStateToProps = () =>
  createStructuredSelector({
    fetching: addAdutorSelectors.getAddAdutorFetching,
    error: addAdutorSelectors.getAddAdutorError,
    success: addAdutorSelectors.getAddAdutorSuccess,
  });

const mapDispatchToProps = (dispatch) => ({
  addAdutorActions: bindActionCreators(addAdutorActions, dispatch),
});

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(AdminDashboard);
