// lib dependencies
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// component dependencies
import sharedApi from '../../../shared';
import LoginForm from '../Components/LoginForm';
const { Card, Toaster, LogoHeader } = sharedApi.components;

export default function ProviderLogin(props) {
  const { loginActions, error } = props;
  const location = useLocation();
  const Navigate = useNavigate();
  const [showResults, setShowResults] = useState({ message: '', type: '' });
  const submitted = useRef(false);
  const [searchParams] = useSearchParams();
  const otpLimit = !!+searchParams.get('otpLimit');
  console.log('ProviderLogin -> otpLimit', location);
  const showMessage = () => {
    if (showResults.message.length) return <Toaster message={showResults.message} type={showResults.type} />;
    if (otpLimit)
      return (
        <Toaster message='عذرًا، لقد تم إدخال كلمة المرور المؤقتة بشكل غير صحيح ثلاث مرات. الرجاء تسجيل الدخول مرة اخرى' type='warning' />
      );
    return null;
  };
  const onSubmit = async (data) => {
    submitted.current = true;
    data.email = data.email.toLowerCase();
    loginActions.serviceProviderLogin(data);
  };
  useEffect(() => {
    if (props.success && submitted.current) {
      Navigate('/otp-Login?provider=1', { state: { fromLogin: true } });
    } else if (props.error) {
      setShowResults({ message: error, type: 'warning' });
    } else if (location?.state?.registered) {
      setShowResults({ message: location.state.message, type: location.state.type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, location]);

  return (
    <div className='background-full__screen'>
      <div className='secondbg-full__screen'></div>
      <div className='thirdbg-full__screen'></div>
      <div className='fivebg-full__screen'></div>
      <LogoHeader white />
      <div className='flex flex--center'>
        <Card title='login' wide='mb-0 applicant-card' subTitle='قم بإدخال البيانات المطلوبة لبدء تقديم الخدمات' toaster={showMessage()}>
          <LoginForm serviceProvider onSubmit={onSubmit} {...props} />
        </Card>
      </div>
    </div>
  );
}
