import * as Actions from '../action-types';

export const applicantFormSubmissionStart = () => ({
  type: Actions.APPLICANT_FORM_SUBMISSION_START,
});

export const applicantFormSubmissionFinished = (payload) => ({
  type: Actions.APPLICANT_FORM_SUBMISSION_SUCCESS,
  payload,
});

export const applicantFormSubmissionFailed = (payload) => ({
  type: Actions.APPLICANT_FORM_SUBMISSION_FAILED,
  payload,
});

export const applicantFormSubmission = (payload) => ({
  type: Actions.REQUEST_APPLICANT_FORM_SUBMISSION,
  payload,
});