import React, { useEffect, useRef, useState } from 'react';
import TableList from '../../../shared/components/TableList';
import { getServicesAll, customAuthGet, toggleServiceStatus } from '../../../services/axios/api';
import { message } from 'antd';
import iconeClosePopup from '../../../markup/images/close-popup.png';

export default function ServicesList() {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [serviceStatus, setServiceStatus] = useState(true);
  const serviceID = useRef(0);

  const fetchData = async (query) => {
    setLoading(true);
    let data = {};
    if (query) {
      data = await customAuthGet('/service/all/?page=' + query);
    } else {
      data = await getServicesAll();
    }
    setServices(data);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handelClick = async () => {
    console.log(services);
    try {
      const FormDataBody = new FormData();
      FormDataBody.append('active', !serviceStatus);
      await toggleServiceStatus(serviceID.current, FormDataBody);
      message.success({
        type: 'success',
        content: `تم ${serviceStatus ? 'تعطيل' : 'تفعيل'} الخدمة بنجاح`,
        duration: 2,
      });
      toggleModal();
      fetchData();
    } catch (error) {
      message.error({
        type: 'error',
        content: 'فشل في تغيير حالة الخدمة',
        duration: 2,
      });
      toggleModal();
    }
  };

  const toggleModal = (id) => {
    serviceID.current = id;
    const currentServiceStatus = services.results.find((service) => service.id === serviceID.current)?.active;
    setServiceStatus(currentServiceStatus);
    setModal(!modal);
  };

  return (
    <div>
      <div className='auditorList-container'>
        <TableList
          headers={['رقم الخدمة', 'الخدمة ', 'الإجراءات']}
          title='إدارة الخدمات'
          subTitle='يمكنك إضافة خدمات جديدة، كما يمكنك تفعيل او تعطيل الخدمات المقدمة'
          addService={true}
          data={services}
          loading={loading}
          fetchNewPage={fetchData}
          toggleModal={toggleModal}
        />
      </div>
      {modal && (
        <div className='modal'>
          <div onClick={toggleModal} className='overlay'></div>
          <div className='modal-content'>
            <button onClick={toggleModal} className='icone-close-popup'>
              <img src={iconeClosePopup} alt='' width='35%' />
            </button>
            <p className='header-popup'> هل أنت متأكد من {serviceStatus ? 'تعطيل' : 'تفعيل'} الخدمة؟ </p>

            <button className='close_popup' onClick={handelClick}>
              {serviceStatus ? 'تعطيل' : 'تفعيل'}
            </button>
            <button type='button' className='cancel_close' onClick={toggleModal}>
              تراجع
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
