import * as Actions from '../action-types';

export const addServicesFetch = () => ({
  type: Actions.ADD_SERVICES_FETCH_START,
});

export const addServicesFinished = () => ({
  type: Actions.ADD_SERVICES_FETCH_SUCCESS,
});

export const addServicesFetchFailed = (payload) => ({
  type: Actions.ADD_SERVICES_FETCH_FAILED,
  payload,
});

export const addServices = (payload) => ({
  type: Actions.ADD_SERVICES,
  payload,
});
