import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES_CONSTANTS } from '../../../../services/routes/routes';
import './Tabs.scss';
const Tabs = () => {
  const [isHomeActive, setIsHomeActive] = useState(false);
  const [isAboutActive, setIsAboutActive] = useState(false);
  const [isMemberActive, setIsMemberActive] = useState(false);
  const [isBanksActive, setIsBanksActive] = useState(false);
  const currentPath = useLocation().pathname.substring(1);
  const { updateHome, updateAbout, updateMembers, updateBanks } = ROUTES_CONSTANTS.adminDashboard;
  useEffect(() => {
    if (currentPath === updateHome) setIsHomeActive(true);
    if (currentPath === updateAbout) setIsAboutActive(true);
    if (currentPath === updateMembers) setIsMemberActive(true);
    if (currentPath === updateBanks) setIsBanksActive(true);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className='cms-tabs'>
        <Link to={'/' + updateHome} className={`ml-20 ${isHomeActive ? 'active' : ''}`}>
          الصفحة الرئيسية
        </Link>
        <Link to={'/' + updateAbout} className={`ml-20 ${isAboutActive ? 'active' : ''}`}>
          عن الجمعية
        </Link>
        <Link to={'/' + updateMembers} className={`ml-20 ${isMemberActive ? 'active' : ''}`}>
          أعضاء الجمعية
        </Link>
        <Link to={'/' + updateBanks} className={isBanksActive ? 'active' : ''}>
          الحسابات البنكية
        </Link>
      </div>
    </>
  );
};

export default Tabs;
