import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';

export default function ButtonNormal({ titleButton, disabled, buttonStyle, classNameButton, onClick, type }) {
  const { t } = useTranslation();

  //   const STYLES = ['btn--primary', 'btn--secondary']
  //   const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]

  return (
    <>
      <button className={classNameButton ? classNameButton : 'step-btn-2'} disabled={disabled} onClick={onClick} type={type}>
        {t(titleButton)}
      </button>
    </>
  );
}
