import * as Actions from '../action-types';

export const nationalIdNumberFetch = () => ({
  type: Actions.National_id_number_FETCH_START,
});

export const nationalIdNumberFetchFinished = () => ({
  type: Actions.National_id_number_FETCH_SUCCESS,
});

export const nationalIdNumberFetchFailed = (payload) => ({
  type: Actions.National_id_number_FETCH_FAILED,
  payload,
});

export const nationalIdNumber = (payload) => ({
  type: Actions.National_id_number,
  payload,
});