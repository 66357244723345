//Project dependencies
import { Route, Routes } from 'react-router-dom';
import AddAuditor from '../../pages/Admin/AddAuditor';
import AddServices from '../../pages/Admin/AddServices';
import { AdminServiceProvider } from '../../pages/Admin/AdminServiceProvider';
import { ApplicationReq } from '../../pages/Admin/ApplicationReq/ApplicationReq';
import AuditorList from '../../pages/Admin/AuditorList/AuditorList';
import { ServiceProviderDetails } from '../../pages/Admin/ServiceProviderDetails';
import { ServicesList } from '../../pages/Admin/ServicesList';
import ApplicantForm from '../../pages/ApplicantForm/ApplicantForm';
import ApplicationList from '../../pages/Auditor/ApplicationList';
import ApplicationListNew from '../../pages/Auditor/ApplicationListNew';
import AuditorInvitation from '../../pages/Auditor/AuditorInvitation';
import RequestDetails from '../../pages/Auditor/RequestDetails';
import Home from '../../pages/Home';
import LandingPage from '../../pages/LandingPage/LandingPage';
import AdminLogin from '../../pages/Login/AdminLogin';
import ApplicantLogin from '../../pages/Login/ApplicantLogin';
import OtpLogin from '../../pages/Login/OtpLogin';
import ProviderLogin from '../../pages/Login/ProviderLogin';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import ApplicantRegistration from '../../pages/Registration/ApplicantRegistration';
import OtpPage from '../../pages/Registration/Otp';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import SetPassword from '../../pages/ResetPassword/SetPassword';
import ResetPasswordAdmin from '../../pages/ResetPasswordAdmin/ResetPasswordAdmin';
import SetPasswordAdmin from '../../pages/ResetPasswordAdmin/SetPasswordAdmin';
import NavbarApplicant from '../../shared/components/NavbarApplicant/NavbarApplicant';
import Navbar from '../../shared/components/navbar/Navbar';
import PrivateRoutes from './PrivateRoute';
import { ROUTES_CONSTANTS } from './routes';
// import PersonalInformation from '../../pages/Auditor/PersonalInformation';
import { UpdateAbout } from 'pages/Admin/CMS/UpdateAbout';
import { UpdateBanks } from 'pages/Admin/CMS/UpdateBanks';
import { UpdateHome } from 'pages/Admin/CMS/UpdateHome';
import { UpdateMembers } from 'pages/Admin/CMS/UpdateMembers';
import { MembersList } from 'pages/Admin/MembersList/MembersList';
import { ProfilePage } from 'pages/ProfilePage';
import { useSelector } from 'react-redux';
import ChangePassword from '../../pages/Auditor/ChangePassword';
import UpdateProfileAuditor from '../../pages/Auditor/UpdateProfileAuditor';
import ServiceProviderRequest from '../../pages/ServiceProvider';
import CleanState from '../../shared/utils/cleanState';

const RouteContainer = () => {
  const accountType = useSelector((state) => state?.userData?.fetchingReducer?.data?.account_type);

  return (
    <CleanState>
      <Routes>
        <Route exact path={ROUTES_CONSTANTS.landingPage} element={<LandingPage />} />
        <Route exact path={ROUTES_CONSTANTS.registration.applicantRegistration} element={<ApplicantRegistration />} />
        <Route exact path={ROUTES_CONSTANTS.resetPassword.resetPassword} element={<ResetPassword />} />
        <Route exact path={ROUTES_CONSTANTS.resetPasswordAdmin.resetPasswordAdmin} element={<ResetPasswordAdmin />} />
        <Route exact path={ROUTES_CONSTANTS.resetPasswordAdmin.setPasswordAdmin} element={<SetPasswordAdmin />} />
        <Route exact path={ROUTES_CONSTANTS.resetPassword.setPassword} element={<SetPassword />} />
        <Route
          exact
          path={ROUTES_CONSTANTS.registration.otp}
          element={
            <PrivateRoutes shouldBeLogged>
              <OtpPage />
            </PrivateRoutes>
          }
        />
        <Route
          exact
          path={ROUTES_CONSTANTS.login.otp}
          element={
            <PrivateRoutes shouldBeLogged>
              <OtpLogin />
            </PrivateRoutes>
          }
        />
        <Route
          exact
          path={ROUTES_CONSTANTS.login.adminAndAuditorLogin}
          element={
            <PrivateRoutes shouldBeLogged>
              <AdminLogin />
            </PrivateRoutes>
          }
        />
        <Route
          exact
          path={ROUTES_CONSTANTS.login.applicantLogin}
          element={
            <PrivateRoutes shouldBeLogged>
              <ApplicantLogin />
            </PrivateRoutes>
          }
        />
        <Route
          exact
          path={ROUTES_CONSTANTS.login.providerLogin}
          element={
            <PrivateRoutes shouldBeLogged>
              <ProviderLogin />
            </PrivateRoutes>
          }
        />
        {/* <Route
        exact
        path={ROUTES_CONSTANTS.login.serviceProviderLogin}
        element={
          <PrivateRoutes shouldBeLogged>
            <ServiceProviderLogin />
          </PrivateRoutes>
        }
      /> */}
        {accountType === 3 && (
          <Route
            element={
              <PrivateRoutes accountType={[3]}>
                <NavbarApplicant />
              </PrivateRoutes>
            }
          >
            <Route exact path={ROUTES_CONSTANTS.auditor.applicationRequestDetail} element={<RequestDetails />} />
          </Route>
        )}

        <Route
          element={
            <PrivateRoutes accountType={[1, 2]}>
              <Navbar />
            </PrivateRoutes>
          }
        >
          <Route exact path={ROUTES_CONSTANTS.auditor.applicationRequestDetail} element={<RequestDetails />} />
        </Route>

        <Route
          element={
            <PrivateRoutes accountType={[3, 4]}>
              <NavbarApplicant />
            </PrivateRoutes>
          }
        >
          <Route exact path={ROUTES_CONSTANTS.home} element={<Home />} />
          <Route exact path={ROUTES_CONSTANTS.applicantForm.applicantForm} element={<ApplicantForm />} />
          <Route exact path={ROUTES_CONSTANTS.applicantForm.resubmitApplicantForm} element={<ApplicantForm />} />
          <Route exact path={ROUTES_CONSTANTS.myprofile} element={<ProfilePage />} />
        </Route>

        <Route element={<NavbarApplicant />}>
          <Route exact path={ROUTES_CONSTANTS.serviceProvider.Request} element={<ServiceProviderRequest />} />
        </Route>

        <Route
          element={
            <PrivateRoutes accountType={1}>
              <Navbar />
            </PrivateRoutes>
          }
        >
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.updateHome} element={<UpdateHome />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.updateAbout} element={<UpdateAbout />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.updateMembers} element={<UpdateMembers />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.updateBanks} element={<UpdateBanks />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.AddAuditor} element={<AddAuditor />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.applicationReq} element={<ApplicationReq />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.auditorList} element={<AuditorList />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.serviceslist} element={<ServicesList />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.addservices} element={<AddServices />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.adminServiceProvider} element={<AdminServiceProvider />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.serviceProviderDetails} element={<ServiceProviderDetails />} />
          <Route exact path={ROUTES_CONSTANTS.adminDashboard.members} element={<MembersList />} />
        </Route>

        <Route
          element={
            <PrivateRoutes accountType={2}>
              <Navbar />
            </PrivateRoutes>
          }
        >
          <Route exact path={ROUTES_CONSTANTS.auditor.applicationList} element={<ApplicationList />} />
          <Route exact path={ROUTES_CONSTANTS.auditor.applicationListNew} element={<ApplicationListNew />} />
          {/* <Route exact path={ROUTES_CONSTANTS.auditor.profile_auditor} element={<PersonalInformation />} /> */}
          <Route exact path={ROUTES_CONSTANTS.auditor.ChangePassword} element={<ChangePassword />} />
          <Route exact path={ROUTES_CONSTANTS.auditor.profile_auditor} element={<UpdateProfileAuditor />} />
        </Route>

        <Route exact path={ROUTES_CONSTANTS.auditor.auditorAcceptInvitation} element={<AuditorInvitation />} />
        <Route exact path={ROUTES_CONSTANTS.pageNotFound} element={<PageNotFound />} />
      </Routes>
    </CleanState>
  );
};

export default RouteContainer;
