// Project dependency
import Call from '../../../markup/images/call.svg';
import Map from '../../../markup/images/map.svg';
import Email from '../../../markup/images/message.svg';
import Logo from '../../../markup/images/newLogo.png';
import TamkeenLogo from '../../../markup/images/tamkeen-logo.png';
import WhatsappLogo from '../../../markup/images/whatsapp-logo.png';
import xLogo from '../../../markup/images/x-logo-border.png';
import './Footer.scss';
// const xLogo = (
//   <svg xmlns='http://www.w3.org/2000/svg' width='30' height='20' version='1.1' fill='#ffffff' className='emailLogo'>
//     <path d='M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66' />
//   </svg>
// );
const Footer = (props) => (
  <div className={`footer ${props.mt}`}>
    <div className='flex-footer mt-Footer '>
      <div className=' footer-container'>
        <img src={Logo} alt='' className='footerLogo' />
      </div>
      <div className=''>
        <div>
          <h3 className='mt-info flex-info flex-footer'>تواصل معنا</h3>
        </div>
        <div className='flex-info flex-footer'>
          <div className='footer-item'>
            <img src={Call} alt='' className='mobileLogo' />
            <a className='text-white' href='tel:0116222012' style={{ color: 'white' }}>
              0116222012
            </a>
          </div>
          <div className='footer-item'>
            <img src={WhatsappLogo} alt='' style={{ filter: 'brightness(0) invert(1)', width: '35px' }} />
            <a className='text-white' href='https://wa.me/0116222012' style={{ color: 'white' }}>
              0116222012
            </a>
          </div>
          <div className='footer-item'>
            <img src={Email} alt='' className='emailLogo' />
            <a className='text-white' href='mailto:PR@taalum.org.sa' style={{ color: 'white' }}>
              PR@taalum.org.sa
            </a>
          </div>
          <div className='footer-item'>
            <img
              src={xLogo}
              alt=''
              width={20}
              style={{ filter: 'brightness(0) invert(1) drop-shadow(0 0 0 white)', padding: '7px 0 0 7px' }}
            />
            <a className='text-white' href='https://twitter.com/taalum_org' style={{ color: 'white', direction: 'ltr' }}>
              @taalum_org
            </a>
          </div>
          <div className='footer-item'>
            <img src={Map} alt='' className='locationLogo' />
            <div className='box-footer'>
              <span className=''>الرياض 13321, 6531, 3059 ,جمعية دعم التعليم</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='footer-div-end mt-footer footer-end flex-small'>
      <div>جميع الحقوق محفوظة لوزارة الموارد البشرية والتنمية الاجتماعية</div>
      <div className='footer-div-end'>
        <div>الشروط والاحكام</div>
        <div>سياسة الخصوصية </div>
      </div>
      <div>
        <img src={TamkeenLogo} alt='' className='tamkeenLogo' />
      </div>
    </div>
  </div>
);

export default Footer;
