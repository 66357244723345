import { combineReducers } from 'redux';
import * as Actions from '../../action-types';

const initialFetchingState = {
  fetching: null,
  fetchingError: null,
  success: null,
  setPasswordValidationFetching: null,
  setPasswordValidationFetchingError: null,
  setPasswordValidationSuccess: null,
};

const fetchingReducer = (state = initialFetchingState, action) => {
  switch (action.type) {
    case Actions.SET_PASSWORD_FETCH_START:
      return {
        ...state,
        fetching: true,
      };
    case Actions.SET_PASSWORD_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        fetchingError: action.payload,
      };
    case Actions.SET_PASSWORD_FETCH_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case Actions.RESET_PASSWORD_VALIDATION_FETCH_START:
      return {
        ...state,
        setPasswordValidationFetching: true,
      };
    case Actions.RESET_PASSWORD_VALIDATION_FETCH_FAILED:
      return {
        ...state,
        setPasswordValidationFetching: false,
        setPasswordValidationFetchingError: action.payload,
      };
    case Actions.RESET_PASSWORD_VALIDATION_FETCH_SUCCESS:
      return {
        ...state,
        setPasswordValidationSuccess: true,
      };
    case Actions.RESET_STATE:
      return initialFetchingState;
    default:
      return state;
  }
};

export default combineReducers({
  fetchingReducer,
});
