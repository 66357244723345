import * as Actions from '../action-types'

export const changePasswordFetch = () => ({
  type: Actions.Change_Password_FETCH_START,
})

export const changePasswordFetchFinished = () => ({
  type: Actions.Change_Password_FETCH_SUCCESS,
})

export const changePasswordFetchFailed = (payload) => ({
  type: Actions.Change_Password_FETCH_FAILED,
  payload,
})

export const changePassword = (payload) => ({
  type: Actions.Change_Password,
  payload,
})