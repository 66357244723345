import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';

import { loginFetch, loginFetchFinished, loginFetchFailed, setAccountType } from '../actions/login';
import { adminLogin, applicantLogin, serviceProviderLogin } from '../../services/axios/api';

function* loginAdminAsync(action) {
  yield put(loginFetch());
  try {
    const respone = yield call(adminLogin, action.payload);

    yield all([put(setAccountType(respone.account_type)), put(loginFetchFinished())]);
  } catch (error) {
    yield put(loginFetchFailed(error.response.data.error_code[0]));
  }
}

function* loginApplicantAsync(action) {
  yield put(loginFetch());
  try {
    const respone = yield call(applicantLogin, action.payload);

    yield all([put(setAccountType(respone.account_type)), put(loginFetchFinished())]);
  } catch (error) {
    yield put(loginFetchFailed(error.response.data.error_code[0]));
  }
}

function* loginServiceProviderAsync(action) {
  yield put(loginFetch());
  try {
    const respone = yield call(serviceProviderLogin, action.payload);

    yield all([put(setAccountType(respone.account_type)), put(loginFetchFinished())]);
  } catch (error) {
    yield put(loginFetchFailed(error.response.data.error_code[0]));
  }
}

export default function* loginSaga() {
  yield all([
    takeLatest(Actions.ADMIN_LOGIN, loginAdminAsync),
    takeLatest(Actions.APPLICANT_LOGIN, loginApplicantAsync),
    takeLatest(Actions.SERVICE_PROVIDER_LOGIN, loginServiceProviderAsync),
  ]);
}
