import React, { useEffect, useState } from 'react';
import TableList from '../../../shared/components/TableList';
import './index.scss';
import { auditorNewApplicationsList, customAuthGet } from '../../../services/axios/api';
import searchIcon from '../../../markup/images/search-icon.svg';

const ApplicationListNew = (props) => {
  const [applications, setApplications] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchData = async (query) => {
    setLoading(true);
    let data = {};
    query = query ? query : 1;
    if (query || searchText !== '') {
      data = await customAuthGet('/student/requests/auditor/unassigned/?page=' + query + '&search=' + searchText);
    } else {
      data = await auditorNewApplicationsList();
    }
    setApplications(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchHanddler = async (e) => {
    e.preventDefault();
    fetchData();
  };

  return (
    <div className='auditorList-container'>
      <TableList
        headers={['رقم الطلب', 'تاريخ التقديم', 'نوع الخدمة', 'الإجراءات']}
        title='طلبات جديدة'
        subTitle='يمكنك استعراض الطلبات وإسنادها لقائمة الطلبات لبدء معالجتها'
        searchInput={
          <div className='search-container'>
            <div className='search-input'>
              <form onSubmit={searchHanddler}>
                <input
                  placeholder=' ابحث برقم الطلب أو رقم الهوية أو المنطقة'
                  type='text'
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  style={{
                    fontFamily: 'DIN Regular',
                    backgroundColor: '#12284F0D',
                    width: '255px',
                    height: '40px',
                    fontWeight: 'bold',
                    paddingRight: '20px',
                    borderRadius: '15px',
                  }}
                  onBlur={searchHanddler}
                />
                <img
                  style={{ position: 'absolute', marginTop: '12px', marginRight: '-30px', cursor: 'pointer' }}
                  src={searchIcon}
                  onClick={searchHanddler}
                  alt=''
                />
              </form>
            </div>
          </div>
        }
        data={applications}
        newList
        loading={loading}
        fetchNewPage={fetchData}
        isAuditor
      />
    </div>
  );
};

export default ApplicationListNew;
