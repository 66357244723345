import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { REGION, INSTITUTION_DEFINE } from '../../constants';
import './InstitutionInfo.scss';
import { useForm, Controller } from 'react-hook-form';
import sharedApi from '../../../../shared';
import { useFormContext } from '../../ServiceProviderFormSteps';
import { useWindowSize } from '@react-hook/window-size';
import { USERNAME_PATTERN_AR_ONLY } from '../../../../shared/utils/regax';
import { getRegions } from 'services/axios/regions';
const { SelectField, InputField, Upload } = sharedApi.components;

const InstitutionInfo = () => {
  const [width] = useWindowSize();
  const [regions, setRegions] = React.useState([]);

  const { formData, setFormData, setCurrent, current, handleChange } = useFormContext();
  const { t } = useTranslation();

  const getRegionsData = useCallback(async () => {
    const res = await getRegions();
    if (res?.length) {
      const formatedRegions = res?.map((item) => {
        const { name_ar, name_en, id } = item;
        return {
          ar_label: name_ar,
          en_label: name_en,
          value: id,
        };
      });
      setRegions(formatedRegions);
    }
  }, []);
  useEffect(() => {
    getRegionsData();
  }, []);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const showOthers = watch('institutionDefine', '0') === '3';

  useEffect(() => {
    const subscription = watch((data) => {
      for (const key in data) {
        data[key] &&
          setFormData((prevData) => ({
            ...prevData,
            [key]: data[key],
          }));
      }
    });
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [watch]);

  // const fetchRegions = async () => {
  //   setRegionsList(await getRegionsList());
  // };

  return (
    <>
      {width > 767 && (
        <>
          <p className='app-contact-title '>معلومات المنشأة</p>
          <p className='app-contact-subtitle'>الرجاء ادخال بيانات المنشأة المقدمة للخدمة</p>
        </>
      )}

      <form onSubmit={handleSubmit(handleChange)}>
        <div className=' flex-row--column'>
          <div className='row row-cols-md-2 row-cols-xxl-3'>
            <InputField
              required
              title='institutionName'
              type='text'
              name='institutionName'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              error={errors.institutionName && errors.institutionName.message}
              maxLength={25}
              {...register('institutionName', {
                required: 'fieldRequired',
                pattern: {
                  value: USERNAME_PATTERN_AR_ONLY,
                  message: 'يجب ان يكون المدخل باللغة العربية',
                },
              })}
            />
            <Controller
              name='institutionLocation'
              control={control}
              rules={{
                required: 'fieldRequired',
              }}
              render={({ field }) => (
                <SelectField
                  {...field}
                  required
                  title='مقر المنشأة'
                  options={regions}
                  error={errors.institutionLocation && errors.institutionLocation.message}
                  smallerFontSize
                  cssClass='w-100'
                  classNameFormGroup='input'
                  classNameLabel='mt-0 text-grey'
                />
              )}
            />
            <InputField
              required
              title='نوع القطاع'
              type='text'
              name='institutionType'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              error={errors.institutionType && errors.institutionType.message}
              maxLength={25}
              {...register('institutionType', {
                required: 'fieldRequired',
                pattern: {
                  value: USERNAME_PATTERN_AR_ONLY,
                  message: 'يجب ان يكون المدخل باللغة العربية',
                },
              })}
            />
            <Controller
              name='institutionDefine'
              control={control}
              rules={{
                required: 'fieldRequired',
              }}
              render={({ field }) => (
                <SelectField
                  {...field}
                  required
                  title='تعريف بالمنشأة'
                  options={INSTITUTION_DEFINE}
                  error={errors.institutionDefine && errors.institutionDefine.message}
                  smallerFontSize
                  cssClass='w-100'
                  classNameFormGroup='input'
                  classNameLabel='mt-0 text-grey'
                />
              )}
            />
            {showOthers && (
              <InputField
                required
                title='نوع التعريف'
                type='text'
                name='ID_Type'
                classNameLabel='input__label-personalInfo'
                className='inputText w-100'
                error={errors.ID_Type && errors.ID_Type.message}
                maxLength={25}
                {...register('ID_Type', {
                  required: 'fieldRequired',
                })}
              />
            )}
            <InputField
              required
              title='رقم تعريف المنشأة'
              type='text'
              name='InstID'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              error={errors.InstID && errors.InstID.message}
              maxLength={25}
              {...register('InstID', {
                required: 'fieldRequired',
              })}
            />
          </div>

          <div style={{ marginTop: '4rem' }}>
            <label className='form-label' style={{ fontSize: '1.1rem' }}>
              أرفق نسخة من ترخيص أهلية المنشأة
              {/* <span className='invalid_checkbox'>*</span> */}
            </label>
            <Controller
              control={control}
              rules={{
                required: 'fieldRequired',
              }}
              name='institution_certificate'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Upload
                  allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                  onChange={onChange}
                  maxSize={10}
                  t={t}
                  required={errors.institution_certificate && 'يجب ارفاق نسخة من ترخيص أهلية المنشأة'}
                  savedFileStatus={formData.institution_certificate === '' ? false : true}
                  submitedfile={formData.institution_certificate ? formData.institution_certificate : null}
                />
              )}
            />
            {errors.institution_certificate && <small className='input__error'>يجب ارفاق نسخة من ترخيص أهلية المنشأة</small>}
          </div>
        </div>

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
                console.log(errors);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default InstitutionInfo;
