export const EMPLOYMENT_STATUS = [
  { en_label: 'choose', ar_label: 'اختر', value: 0 },
  { en_label: 'volunteering', ar_label: 'تطوع', value: 1 },
  { en_label: 'part time', ar_label: 'دوام جزئي', value: 2 },
  { en_label: 'Full time', ar_label: 'دوام كامل', value: 3 },
];
export const REGION = [
  { en_label: 'Area', ar_label: 'المنطقة', value: 0 },
  { en_label: 'Riyadh', ar_label: 'الرياض', value: 1 },
  { en_label: 'Makkah', ar_label: 'مكة المكرمة', value: 6 },
  { en_label: 'Madinah', ar_label: 'المدينة المنورة', value: 8 },
  { en_label: 'Qassim', ar_label: 'القصيم', value: 7 },
  { en_label: 'Eastern', ar_label: 'المنطقة الشرقية', value: 9 },
  { en_label: 'Aseer', ar_label: 'عسير', value: 12 },
  { en_label: 'Tabuk', ar_label: 'تبــوك', value: 10 },
  { en_label: 'Hail', ar_label: 'حائل', value: 5 },
  { en_label: 'Northern Borders', ar_label: 'الحدود الشمالية', value: 4 },
  { en_label: 'Jizan', ar_label: 'جيزان', value: 11 },
  { en_label: 'Najran', ar_label: 'نجران', value: 13 },
  { en_label: 'Al Baha', ar_label: 'الباحة', value: 2 },
  { en_label: 'Al Jawf', ar_label: 'الجوف', value: 3 },
  { en_label: 'Other', ar_label: 'اخرى', value: 14 },
];

export const CITY = [
  { en_label: 'City', ar_label: 'المدينة', value: 0 },
  { en_label: 'Tabuk', ar_label: 'تبوك', value: 2565 },
  { en_label: 'Riyadh', ar_label: 'الرياض', value: 44 },
  { en_label: 'Taif', ar_label: 'الطائف', value: 1220 },
  { en_label: 'Mecca', ar_label: 'مكة المكرمة', value: 1232 },
  { en_label: 'Hail', ar_label: 'حائل', value: 867 },
  { en_label: 'Buraydah', ar_label: 'بريدة', value: 1700 },
  { en_label: 'Hofuf', ar_label: 'الهفوف', value: 521 },
  { en_label: 'Dammam', ar_label: 'الدمام', value: 2370 },
  { en_label: 'Medina', ar_label: 'المدينة المنورة', value: 2047 },
  { en_label: 'Abha', ar_label: 'ابها', value: 2991 },
  { en_label: 'Jazan', ar_label: 'جيزان', value: 2718 },
  { en_label: 'Jeddah', ar_label: 'جدة', value: 1226 },
  { en_label: 'Majmaah', ar_label: 'المجمعة', value: 1126 },
  { en_label: 'Khobar', ar_label: 'الخبر', value: 2368 },
  { en_label: 'Hafar Al Batin', ar_label: 'حفر الباطن', value: 2336 },
  { en_label: 'Khamis Mushait', ar_label: 'خميس مشيط', value: 2997 },
  { en_label: 'Ahad Rafidah', ar_label: 'احد رفيده', value: 2993 },
  { en_label: 'Qatif', ar_label: 'القطيف', value: 2377 },
  { en_label: 'Unizah', ar_label: 'عنيزة', value: 1698 },
  { en_label: 'Jubail', ar_label: 'الجبيل', value: 2365 },
  { en_label: 'Nairyah', ar_label: 'النعيرية', value: 2485 },
  { en_label: 'Dhahran', ar_label: 'الظهران', value: 2372 },
  { en_label: 'Alwajh', ar_label: 'الوجه', value: 2564 },
  { en_label: 'Abqaiq', ar_label: 'بقيق', value: 2404 },
  { en_label: 'Zulfi', ar_label: 'الزلفي', value: 48 },
  { en_label: 'Khaibar', ar_label: 'خيبر', value: 2123 },
  { en_label: 'Ghat', ar_label: 'الغاط', value: 236 },
  { en_label: 'Umluj', ar_label: 'املج', value: 2574 },
  { en_label: 'Rabigh', ar_label: 'رابغ', value: 1425 },
  { en_label: 'Afif', ar_label: 'عفيف', value: 515 },
  { en_label: 'Thadiq', ar_label: 'ثادق', value: 354 },
  { en_label: 'Sihat', ar_label: 'سيهات', value: 2382 },
  { en_label: 'Tarot', ar_label: 'تاروت', value: 2380 },
  { en_label: 'Yanbu', ar_label: 'ينبع', value: 2286 },
  { en_label: 'Shqra', ar_label: 'شقراء', value: 4 },
  { en_label: 'Quwayiyah', ar_label: 'القويعية', value: 2087 },
  { en_label: 'Muzahmia', ar_label: 'المزاحمية', value: 57 },
  { en_label: 'Bader', ar_label: 'بدر', value: 2060 },
  { en_label: 'Kharj', ar_label: 'الخرج', value: 36 },
  { en_label: 'Ad Dilam', ar_label: 'الدلم', value: 489 },
  { en_label: 'Shanan', ar_label: 'الشنان', value: 866 },
  { en_label: 'Khurma', ar_label: 'الخرمة', value: 1298 },
  { en_label: 'Jamoum', ar_label: 'الجموم', value: 1386 },
  { en_label: 'Majarda', ar_label: 'المجاردة', value: 3182 },
  { en_label: 'Saleel', ar_label: 'السليل', value: 348 },
  { en_label: 'Tathlith', ar_label: 'تثليث', value: 3142 },
  { en_label: 'Baha', ar_label: 'الباحة', value: 986 },
  { en_label: 'Qunfudhah', ar_label: 'القنفذة', value: 1208 },
  { en_label: 'Muhail', ar_label: 'محايل', value: 3353 },
  { en_label: 'Thule', ar_label: 'ثول', value: 1224 },
  { en_label: 'Dhuba', ar_label: 'ضبا', value: 2611 },
  { en_label: 'Turbah', ar_label: 'تربه', value: 1385 },
  { en_label: 'Safwa', ar_label: 'صفوى', value: 2384 },
  { en_label: 'Ank', ar_label: 'عنك', value: 2535 },
  { en_label: 'Tarif', ar_label: 'طريف', value: 1850 },
  { en_label: 'Arar', ar_label: 'عرعر', value: 770 },
  { en_label: 'Qurayyat', ar_label: 'القريات', value: 727 },
  { en_label: 'Skaka', ar_label: 'سكاكا', value: 729 },
  { en_label: 'Rafha', ar_label: 'رفحاء', value: 769 },
  { en_label: 'Domt Aljndl', ar_label: 'دومة الجندل', value: 728 },
  { en_label: 'Raas', ar_label: 'الرس', value: 1704 },
  { en_label: 'Muthnib', ar_label: 'المذنب', value: 1701 },
  { en_label: 'Khafjy', ar_label: 'الخفجي', value: 2455 },
  { en_label: 'Riad Khabra', ar_label: 'رياض الخبراء', value: 1966 },
  { en_label: 'Badayea', ar_label: 'البدائع', value: 1001 },
  { en_label: 'Ras Tanura', ar_label: 'رأس تنورة', value: 2472 },
  { en_label: 'Bukayriyah', ar_label: 'البكيرية', value: 1707 },
  { en_label: 'Shamsiya', ar_label: 'الشماسية', value: 1696 },
  { en_label: 'Harig', ar_label: 'الحريق', value: 17 },
  { en_label: 'Hotat Bani Tamim', ar_label: 'حوطة بني تميم', value: 111 },
  { en_label: 'Layla', ar_label: 'ليلى', value: 455 },
  { en_label: 'Balsamer', ar_label: 'بللسمر', value: 3021 },
  { en_label: 'Sharurah', ar_label: 'شرورة', value: 3526 },
  { en_label: 'Najran', ar_label: 'نجران', value: 3533 },
  { en_label: 'Sabya', ar_label: 'صبيا', value: 2735 },
  { en_label: 'Abu Arish', ar_label: 'ابو عريش', value: 2715 },
  { en_label: 'Samta', ar_label: 'صامطة', value: 2731 },
  { en_label: 'Ahad Masarhah', ar_label: 'احد المسارحة', value: 2770 },
  { en_label: 'other', ar_label: 'اخرى', value: 3583 },
];

export const SALARY = [
  { en_label: '1000-2999', ar_label: '1000-2999', value: 1 },
  { en_label: '3000-5999', ar_label: '3000-5999', value: 2 },
  { en_label: '6000-8999', ar_label: '6000-8999', value: 3 },
  { en_label: 'other', ar_label: 'اخرى', value: 4 },
];

export const EDUCATION = [
  { en_label: 'Educational Qualifications', ar_label: 'آخر مؤهل تعليمي', value: 0 },
  { en_label: 'Intermediate School', ar_label: 'متوسط', value: 1 },
  { en_label: 'High School', ar_label: 'ثانوي', value: 2 },
  { en_label: 'Bachelor', ar_label: 'بكالوريوس', value: 3 },
  { en_label: 'Diploma', ar_label: 'دبلوم', value: 4 },
];

export const options = [
  { label: 'نظام المعدل', value: 0 },
  { label: '4 GPA', value: 4 },
  { label: '5 GPA', value: 5 },
  { label: '100 GPA', value: 100 },
];

export const GENDER = [
  { en_label: 'Gender', ar_label: 'الجنس', value: 0 },
  { en_label: 'Male', ar_label: 'ذكر', value: 1 },
  { en_label: 'Female', ar_label: 'أنثى', value: 2 },
];

export const SOCIAL_STATUS = [
  { en_label: 'Marital Status', ar_label: 'الحالة الاجتماعية', value: 0 },
  { en_label: 'Married', ar_label: 'متزوج/ة', value: 1 },
  { en_label: 'Single', ar_label: 'أعزب/عزباء', value: 2 },
  { en_label: 'Divorced', ar_label: 'منفصل/ة', value: 3 },
  { en_label: 'Widower', ar_label: 'أرمل/ة', value: 4 },
];

export const FORM_STATUS = [
  { en_label: 'close', ar_label: 'مرفوض', value: 4 },
  { en_label: 'open', ar_label: 'بانتظار المعالجة', value: 1 },
  { en_label: 'approved', ar_label: 'قبول أولي', value: 2 },
  { en_label: 'returned', ar_label: 'بانتظار التعديل', value: 3 },
  { en_label: 'Final Approve', ar_label: 'قبول نهائي', value: 5 },
];
export const APPLICANT_FORM_STATUS = [
  { en_label: 'close', ar_label: 'مرفوض', value: 4 },
  { en_label: 'open', ar_label: 'بانتظار المعالجة', value: 1 },
  { en_label: 'approved', ar_label: 'بانتظار المعالجة', value: 2 },
  { en_label: 'returned', ar_label: 'بانتظار التعديل', value: 3 },
  { en_label: 'Final Approve', ar_label: 'مقبول', value: 5 },
];

export const SERVICE_LIST = [
  { ar_label: 'التعليم الحضوري', value: 'attendance_education' },
  { ar_label: 'التعليم الافتراضي', value: 'virtual_education' },
  { ar_label: 'التوجية الاكاديمي', value: 'academic_guidance' },
  { ar_label: 'الاستشارات الاجتماعية والنفسية', value: 'social_psychological_counseling' },
  { ar_label: 'الدعم في إيجاد الفرص', value: 'career_guidance' },
  { ar_label: 'ورش عمل ومحاضرات', value: 'workshops_lectur' },
  { ar_label: 'دورات تدريبية', value: 'training_courses' },
  { ar_label: 'التدريب التعاوني', value: 'cooperative_training' },
  { ar_label: 'الدعم في موائمة المسار التعليمي', value: 'support_educational_path' },
  { ar_label: 'الدعم في التقديم', value: 'support_applying_job' },
];

export const FEATURES_LIST = [
  { ar_label: 'معيل للعائلة', value: 'breadwinner' },
  { ar_label: 'احتياجات خاصة', value: 'social_needs' },
  { ar_label: 'وحيد العائلة', value: 'single_family' },
  { ar_label: 'فاقد الاب (يتيم)', value: 'orphan' },
];

export const ENGLISH_LEVELS = [
  { ar_label: 'اختر', value: 0 },
  { ar_label: 'مبتدئ', value: 1 },
  { ar_label: 'متوسط', value: 2 },
  { ar_label: 'متقدم', value: 3 },
];
export const studyDesiresList = [
  { en_label: 'Study Desires', ar_label: 'اختر', value: 0 },
  { en_label: 'Bachelor', ar_label: 'بكالوريوس', value: 1 },
  { en_label: 'Diploma', ar_label: 'دبلوم', value: 2 },
  { en_label: 'Training Institute', ar_label: 'معاهد التدريب', value: 3 },
];
export const housingCostList = [
  { en_label: 'Housing ownership', ar_label: 'اختر', value: 0 },
  { en_label: 'Owner', ar_label: 'ملك', value: 1 },
  { en_label: 'Rent', ar_label: 'ايجار', value: 2 },
];
export const trainingPeriodList = [
  { en_label: 'Training Period', ar_label: 'اختر', value: 0 },
  { en_label: '3 Months', ar_label: '3 شهور', value: 3 },
  { en_label: '6 Months', ar_label: '6 شهور', value: 6 },
  { en_label: '9 Months', ar_label: '9 شهور', value: 9 },
];
export const cooperativeTraining_availableOpportunitiesList = [
  { en_label: 'Available Opportunities', ar_label: 'اختر', value: 0 },
  { en_label: 'Opportunity 1', ar_label: 'فرصة 1', value: 1 },
  { en_label: 'Opportunity 2', ar_label: 'فرصة 2', value: 2 },
  { en_label: 'Opportunity 3', ar_label: 'فرصة 3', value: 3 },
];
export const futureSkillsList = [
  { en_label: 'Future Skills', ar_label: 'اختر', value: 0 },
  { en_label: 'Skill 1', ar_label: 'مهارة 1', value: 1 },
  { en_label: 'Skill 2', ar_label: 'مهارة 2', value: 2 },
  { en_label: 'Skill 3', ar_label: 'مهارة 3', value: 3 },
];
export const workshopsAndLectures_availableOpportunitiesList = [
  { en_label: 'Available Opportunities', ar_label: 'اختر', value: 0 },
  { en_label: 'Opportunity 1', ar_label: 'فرصة 1', value: 1 },
  { en_label: 'Opportunity 2', ar_label: 'فرصة 2', value: 2 },
  { en_label: 'Opportunity 3', ar_label: 'فرصة 3', value: 3 },
];
