import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import { userDataFailed, userDataFetch, userDataFinished } from '../actions/userData';
import { userData } from '../../services/axios/api';

function* fetchUserData() {
  yield put(userDataFetch());
  try {
    const respone = yield call(userData);
    yield put(userDataFinished(respone));
  } catch (error) {
    yield put(userDataFailed(error.response.data.error_code[0]));
  }
}

export default function* UserDataSaga() {
  yield all([takeLatest(Actions.USER_DATA, fetchUserData)]);
}
