import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import '../../../../markup/bootstrap-grid.rtl.min.css';
import { useForm } from 'react-hook-form';
import sharedApi from '../../../../shared';
import { message } from 'antd';
import updateProfileIcon from '../../../../assets/images/shared/update_profile_icon.svg';
import Tabs from '../Tabs/Tabs';
import { getAboutPageData, updateAboutPageData } from '../../../../services/axios/api';

const { ButtonNormal, InputField } = sharedApi.components;
export default function UpdateAbout() {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const [isEditModeDisabled, setIsEditModeDisabled] = useState(false);
  const [isUpdateBtnHidden, setIsUpdateBtnHidden] = useState(false);
  const [aboutPageData, setAboutPageData] = useState([]);

  useEffect(() => {
    getAboutData();
    // eslint-disable-next-line
  }, [isEditModeDisabled]);

  useEffect(() => {
    if (aboutPageData.length) aboutPageData.map((obj) => setValue(obj.identifier, obj.title));
    // eslint-disable-next-line
  }, [aboutPageData]);

  const getAboutData = async () => {
    const data = await getAboutPageData();
    setAboutPageData(data);
  };

  const handleEditContent = () => {
    setIsEditModeDisabled(true);
    setIsUpdateBtnHidden(true);
  };

  const updateAboutData = async (newData) => {
    try {
      await updateAboutPageData(newData);
      setIsEditModeDisabled(false);
      setIsUpdateBtnHidden(false);
      setTimeout(() => {
        message.success({
          type: 'success',
          content: `تم تحديث بيانات الصفحة الرئيسية بنجاح`,
          duration: 2,
        });
      }, 1);
    } catch (err) {
      console.log(err);
    }
  };

  const onCancel = () => {
    setIsUpdateBtnHidden(false);
    setIsEditModeDisabled(false);

    // clear error messages if any
    for (let i in errors) delete errors[i];
  };

  const onSubmit = (data) => {
    const FormDataBody = new FormData();
    let tempAboutData = [];
    aboutPageData.forEach((obj) => {
      for (const property in data) {
        if (obj.identifier === property && obj.title !== data[property]) {
          const updatedAboutData = aboutPageData.map((section) => {
            if (section.identifier === property) {
              FormDataBody.append(section.identifier, data[property]);
              return { ...section, title: data[property] };
            }
            return section;
          });
          tempAboutData.push(updatedAboutData);
        }
      }
    });
    setAboutPageData(tempAboutData);
    updateAboutData(FormDataBody);
  };
  return (
    <>
      <div className='auditor_update_personal_info-auditorList-container'>
        <div className='auditor_update_personal_info-auditorList'>
          <div>
            <div className='auditor_personal_info-div-auditor'>
              <section>
                <p className='auditor_personal_info-title'>{t('cms_page_title')}</p>
                <p className='auditor_personal_info-subtitle'>{t('cms_page_subtitle')} </p>
              </section>
            </div>
            <Tabs />
          </div>
          <button
            className={`auditor_personal_info-btn mr-auto mb-3 ${isUpdateBtnHidden ? 'visibility-hidden' : ''}`}
            onClick={handleEditContent}
          >
            <img src={updateProfileIcon} alt='updateProfileIcon' className='auditor_personal_info-icon' />
            <p> تحديث المحتوى</p>
          </button>
          <form onSubmit={handleSubmit(onSubmit)} className={isEditModeDisabled ? 'edit-mode' : ''}>
            <div className='purple-card-with-title'>
              <div className='card--title'>عنوان الصفحة</div>
              <div className='row px-3 px-lg-0'>
                <div className='col-lg-4 col-xl-3'>
                  <InputField
                    title='pageTitle'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='about_title'
                    error={errors.about_title?.message}
                    {...register('about_title', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم الأول</div>
              <div className='row px-3 px-lg-0'>
                <div className='col-lg-4 col-xl-3'>
                  <InputField
                    title='sectionTitle'
                    type='text'
                    readonly={!isEditModeDisabled}
                    name='about_section_1'
                    error={errors.about_section_1?.message}
                    {...register('about_section_1', {
                      required: 'fieldRequired',
                    })}
                  />
                </div>
                <div className='col-xl-1'></div>
                <div className='col-lg-7 col-xl-8'>
                  <div className='input'>
                    <label className='input__label mb-2'>{t('sectionDesc')}</label>
                    <textarea
                      className='form-text-area'
                      readOnly={!isEditModeDisabled}
                      name='about_section_1_1'
                      {...register('about_section_1_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <small className='input__error'>{errors.about_section_1_1 && t('fieldRequired')}</small>
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم الثاني</div>
              <div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_2'
                      error={errors.about_section_2?.message}
                      {...register('about_section_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-7 col-xl-8'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('sectionDesc')}</label>
                      <textarea
                        className='form-text-area'
                        readOnly={!isEditModeDisabled}
                        name='about_section_2_1'
                        {...register('about_section_2_1', {
                          required: 'fieldRequired',
                        })}
                      />
                    </div>
                    <small className='input__error'>{errors.about_section_2_1 && t('fieldRequired')}</small>
                  </div>
                </div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_3'
                      error={errors.about_section_3?.message}
                      {...register('about_section_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-7 col-xl-8'>
                    <div className='input'>
                      <label className='input__label mb-2'>{t('sectionDesc')}</label>
                      <textarea
                        className='form-text-area'
                        readOnly={!isEditModeDisabled}
                        name='about_section_3_1'
                        {...register('about_section_3_1', {
                          required: 'fieldRequired',
                        })}
                      />
                    </div>
                    <small className='input__error'>{errors.about_section_3_1 && t('fieldRequired')}</small>
                  </div>
                </div>
              </div>
            </div>
            <div className='row px-3 px-lg-0'>
              <div className='col-lg-6'>
                <div className='purple-card-with-title'>
                  <div className='card--title'>القسم الثالث</div>
                  <div>
                    <div className='row px-3 px-lg-0'>
                      <div className='col-12'>
                        <InputField
                          title='sectionTitle'
                          type='text'
                          readonly={!isEditModeDisabled}
                          name='about_section_4'
                          error={errors.about_section_4?.message}
                          {...register('about_section_4', {
                            required: 'fieldRequired',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='purple-card-with-title'>
                  <div className='card--title'>القسم الرابع</div>
                  <div>
                    <div className='row px-3 px-lg-0'>
                      <div className='col-12'>
                        <InputField
                          title='sectionTitle'
                          type='text'
                          readonly={!isEditModeDisabled}
                          name='about_section_5'
                          error={errors.about_section_5?.message}
                          {...register('about_section_5', {
                            required: 'fieldRequired',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row px-3 px-lg-0'>
              <div className='col-lg-6'>
                <div className='purple-card-with-title'>
                  <div className='card--title'>القسم الخامس</div>
                  <div>
                    <div className='row px-3 px-lg-0'>
                      <div className='col-12'>
                        <InputField
                          title='sectionTitle'
                          type='text'
                          readonly={!isEditModeDisabled}
                          name='about_section_6'
                          error={errors.about_section_6?.message}
                          {...register('about_section_6', {
                            required: 'fieldRequired',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='purple-card-with-title'>
                  <div className='card--title'>القسم السادس</div>
                  <div>
                    <div className='row px-3 px-lg-0'>
                      <div className='col-12'>
                        <InputField
                          title='sectionTitle'
                          type='text'
                          readonly={!isEditModeDisabled}
                          name='about_section_7'
                          error={errors.about_section_7?.message}
                          {...register('about_section_7', {
                            required: 'fieldRequired',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row px-3 px-lg-0'>
              <div className='col-lg-6'>
                <div className='purple-card-with-title'>
                  <div className='card--title'>القسم السابع</div>
                  <div>
                    <div className='row px-3 px-lg-0'>
                      <div className='col-12'>
                        <InputField
                          title='sectionTitle'
                          type='text'
                          readonly={!isEditModeDisabled}
                          name='about_section_8'
                          error={errors.about_section_8?.message}
                          {...register('about_section_8', {
                            required: 'fieldRequired',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='purple-card-with-title'>
                  <div className='card--title'>القسم الثامن</div>
                  <div>
                    <div className='row px-3 px-lg-0'>
                      <div className='col-12'>
                        <InputField
                          title='sectionTitle'
                          type='text'
                          readonly={!isEditModeDisabled}
                          name='about_section_9'
                          error={errors.about_section_9?.message}
                          {...register('about_section_9', {
                            required: 'fieldRequired',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='purple-card-with-title'>
                  <div className='card--title'>القسم التاسع</div>
                  <div>
                    <div className='row px-3 px-lg-0'>
                      <div className='col-12'>
                        <InputField
                          title='sectionTitle'
                          type='text'
                          readonly={!isEditModeDisabled}
                          name='about_section_12_5'
                          error={errors.about_section_12_5?.message}
                          {...register('about_section_12_5', {
                            required: 'fieldRequired',
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='purple-card-with-title'>
              <div className='card--title'>القسم العاشر</div>
              <div>
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10'
                      error={errors.about_section_10?.message}
                      {...register('about_section_10', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_1'
                      error={errors.about_section_10_1?.message}
                      {...register('about_section_10_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <hr />
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='firstCol'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_2'
                      error={errors.about_section_10_2?.message}
                      {...register('about_section_10_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='firstCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_2_1'
                      error={errors.about_section_10_2_1?.message}
                      {...register('about_section_10_2_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='secondCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_2_2'
                      error={errors.about_section_10_2_2?.message}
                      {...register('about_section_10_2_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='thirdCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_2_3'
                      error={errors.about_section_10_2_3?.message}
                      {...register('about_section_10_2_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='fourthCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_2_4'
                      error={errors.about_section_10_2_4?.message}
                      {...register('about_section_10_2_4', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <hr />
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='secondCol'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_3'
                      error={errors.about_section_10_3?.message}
                      {...register('about_section_10_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='firstCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_3_1'
                      error={errors.about_section_10_3_1?.message}
                      {...register('about_section_10_3_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='secondCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_3_2'
                      error={errors.about_section_10_3_2?.message}
                      {...register('about_section_10_3_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='thirdCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_3_3'
                      error={errors.about_section_10_3_3?.message}
                      {...register('about_section_10_3_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='fourthCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_3_4'
                      error={errors.about_section_10_3_4?.message}
                      {...register('about_section_10_3_4', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <hr />
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='thirdCol'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_4'
                      error={errors.about_section_10_4?.message}
                      {...register('about_section_10_4', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='firstCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_4_1'
                      error={errors.about_section_10_4_1?.message}
                      {...register('about_section_10_4_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='secondCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_4_2'
                      error={errors.about_section_10_4_2?.message}
                      {...register('about_section_10_4_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='thirdCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_4_3'
                      error={errors.about_section_10_4_3?.message}
                      {...register('about_section_10_4_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='fourthCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_10_4_4'
                      error={errors.about_section_10_4_4?.message}
                      {...register('about_section_10_4_4', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <hr />
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionTitle'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_11'
                      error={errors.about_section_11?.message}
                      {...register('about_section_11', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionDesc'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_11_1'
                      error={errors.about_section_11_1?.message}
                      {...register('about_section_11_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='sectionDesc'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_11_2'
                      error={errors.about_section_11_2?.message}
                      {...register('about_section_11_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <hr />
                <div className='row px-3 px-lg-0'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='secondCol'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_12'
                      error={errors.about_section_12?.message}
                      {...register('about_section_12', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='firstCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_12_1'
                      error={errors.about_section_12_1?.message}
                      {...register('about_section_12_1', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='secondCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_12_2'
                      error={errors.about_section_12_2?.message}
                      {...register('about_section_12_2', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
                <div className='row px-3 px-lg-0 mb-5'>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='thirdCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_12_3'
                      error={errors.about_section_12_3?.message}
                      {...register('about_section_12_3', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                  <div className='col-xl-1'></div>
                  <div className='col-lg-4 col-xl-3'>
                    <InputField
                      title='fourthCard'
                      type='text'
                      readonly={!isEditModeDisabled}
                      name='about_section_12_4'
                      error={errors.about_section_12_4?.message}
                      {...register('about_section_12_4', {
                        required: 'fieldRequired',
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
            {!isEditModeDisabled ? null : (
              <div>
                <div className='auditor_update_personal_info-steps-action mt-0'>
                  <ButtonNormal titleButton=' الغاء' classNameButton='step-btn-3' onClick={onCancel} type='button' />
                  <ButtonNormal titleButton=' حفظ' disabled={!isValid} type='submit' />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
