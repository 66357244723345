import { createContext, useEffect, useState } from 'react';
import ContactForm from '../ApplicantForm/Components/ContactForm/ContactForm';
import AdditionalFields from '../ApplicantForm/Components/AdditionalFields/AdditionalFields';
import ServicesForm from '../ApplicantForm/Components/ServicesForm/ServicesForm';
import EducationalForm from '../ApplicantForm/Components/EducationalForm/EducationalForm';
import PracticalExperiences from '../ApplicantForm/Components/PracticalExperiences/PracticalExperiences';
import PersonalInfoForm from '../ApplicantForm/Components/PersonalInfoForm/PersonalInfoForm';
import AdditionalCertificates from '../ApplicantForm/Components/AdditionalCertificates/AdditionalCertificates';
import { useTranslation } from 'react-i18next';

const FormContext = createContext({});

export const FormProvider = ({
  children,
  fullname,
  nationalId,
  mobileNumber,
  familyMembers,
  socialStatus,
  personalPhotoPath,
  applicantAddressObj,
  gender,
  additionalMobileNumber,
  email,
  dob,
  formSubmission,
  successFormSubmission,
  errorFormSubmission,
  responseData,
  details,
}) => {
  const [current, setCurrent] = useState(0);
  const [codes, setCodes] = useState([]);
  const { t } = useTranslation();

  const formSteps = [
    {
      title: details ? 'الملاحظات' : 'الخطوة الأولى',
      description: details ? 'ملاحظات المدقق' : 'تحديد الخدمة',
      content: <ServicesForm onServiceChange={handleServiceChange} />,
    },
    {
      title: 'الخطوة الثانية',
      description: 'حقول اضافية',
      content: <AdditionalFields codes={codes} setCodesEmpty={setCodesEmpty} />,
    },
    {
      title: 'الخطوة الثالثة',
      description: 'المعلومات الشخصية',
      //DELETE IT FOR ADD PAGE
      status: errorFormSubmission?.hasOwnProperty('info') ? 'error' : '',
      content: (
        <PersonalInfoForm
          dob={dob}
          nationalId={nationalId}
          additionalMobileNumber={additionalMobileNumber}
          mobileNumber={mobileNumber}
          personalPhotoPath={personalPhotoPath}
          email={email}
          familyMembers={familyMembers}
          fullname={fullname}
          socialStatus={socialStatus}
          gender={gender}
          codes={codes}
        />
      ),
    },
    {
      title: 'الخطوة الرابعة',
      description: t('homeAddress'),
      status: errorFormSubmission?.hasOwnProperty('contacts') ? 'error' : '',
      //DELETE IT FOR ADD PAGE
      content: <ContactForm addressData={applicantAddressObj} />,
    },
    {
      title: 'الخطوة الخامسة',
      description: 'المؤهل التعليمي',
      //DELETE IT FOR ADD PAGE
      status: errorFormSubmission?.hasOwnProperty('service') ? 'error' : '',
      content: <EducationalForm />,
    },
    {
      title: 'الخطوة السادسة',
      description: 'الخبرات العملية',
      status: errorFormSubmission?.hasOwnProperty('financial') ? 'error' : '',
      content: <PracticalExperiences />,
    },
    {
      title: 'الخطوة السابعة',
      description: 'الدورات التدريبية',
      status: errorFormSubmission?.hasOwnProperty('course') ? 'error' : '',
      content: (
        <AdditionalCertificates
          additionalMobileNumber={additionalMobileNumber}
          mobileNumber={mobileNumber}
          personalPhotoPath={personalPhotoPath}
          email={email}
          familyMembers={familyMembers}
          fullname={fullname}
          socialStatus={socialStatus}
          gender={gender}
          codes={codes}
        />
      ),
    },
  ];

  function handleServiceChange(code, isChecked) {
    if (isChecked) setCodes((prevState) => [...prevState, code]);
    else setCodes(codes.filter((c) => c !== code));
  }
  function setCodesEmpty() {
    if (details?.service.length) return;
    setCodes([]);
  }
  useEffect(() => {
    if (!details?.service.length) return;
    const codesArr = details?.service.filter((serviceObj) => serviceObj.code).map((obj) => obj.code);
    setCodes(codesArr);
  }, [details]);

  const [formData, setFormData] = useState({
    // service
    services: [],
    // Additional Fields
    studyDesires: details?.additional_info?.study_desires ? details?.additional_info?.study_desires : 0,
    housingCost: details?.additional_info?.residential_property ? details?.additional_info?.residential_property : 0,
    locationDistance: details?.additional_info?.residence_distance ? details?.additional_info?.residence_distance : null,
    studentsHousing: details?.additional_info?.is_institution_has_housing ? details?.additional_info?.is_institution_has_housing : false,
    transportation_entityName: details?.additional_info?.transportation_institution_name
      ? details?.additional_info?.transportation_institution_name
      : null,
    transportationAvailability: details?.additional_info?.has_transportation ? details?.additional_info?.has_transportation : false,
    transportationService: details?.additional_info?.is_institution_has_transportation
      ? details?.additional_info?.is_institution_has_transportation
      : false,
    booksNeed: details?.additional_info?.is_books_needed ? details?.additional_info?.is_books_needed : false,
    laptopNeed: details?.additional_info?.is_laptops_needed ? details?.additional_info?.is_laptops_needed : false,
    uniformNeed: details?.additional_info?.is_uniforms_needed ? details?.additional_info?.is_uniforms_needed : false,
    uniformNeedDetails: details?.additional_info?.uniforms_details ? details?.additional_info?.uniforms_details : null,
    cooperativeTraining_entityName: details?.additional_info?.train_institution_name
      ? details?.additional_info?.train_institution_name
      : null,
    trainingPeriod: details?.additional_info?.training_duration ? details?.additional_info?.training_duration : 0,
    cooperativeTraining_availableOpportunities: details?.additional_info?.training_available_opportunitie
      ? details?.additional_info?.training_available_opportunitie?.id
      : 0,
    careerInclinations: details?.additional_info?.professional_inclination ? details?.additional_info?.professional_inclination : null,
    universityMajor: details?.additional_info?.university_specialization ? details?.additional_info?.university_specialization : null,
    futureSkills: details?.additional_info?.future_skills ? details?.additional_info?.future_skills?.id : 0,
    workshopsAndLectures_availableOpportunities: details?.additional_info?.workshop_available_opportunitie
      ? details?.additional_info?.workshop_available_opportunitie?.id
      : 0,
    academicAdvising_entityName: details?.additional_info?.academic_institution_name
      ? details?.additional_info?.academic_institution_name
      : null,
    difficulties: details?.additional_info?.difficulties_describe ? details?.additional_info?.difficulties_describe : null,

    // personal
    fullName: details?.info.full_name ? details.info.full_name : fullname,
    familyNumber: details?.info.family_members ? details.info.family_members : 0,
    handiCapped: details?.info.social_needs ? details.info.social_needs : false,
    familyBbreadWinner: details?.info.breadwinner ? details.info.breadwinner : false,
    singleChild: details?.info.single_family ? details.info.single_family : false,
    orphan: details?.info.orphan ? details.info.orphan : false,
    personalPhoto: details?.info.personalPhoto ? details.info.personalPhoto : '',
    gender: details?.info.gender ? details.info.gender : 0,
    socialStatus: details?.info.social_status ? details.info.social_status : 0,
    additional_mobile: details?.contacts.additional_number ? details.contacts.additional_number : '',
    Attach_of_Social_Security: details?.info.social_security_attachment ? details.info.social_security_attachment : '',

    //contact
    city: details?.contacts.city ? details.contacts.city : 0,
    neighborhood: details?.contacts.Neighborhood ? details.contacts.Neighborhood : '',
    postalCode: details?.contacts.postal_code ? details.contacts.postal_code : '',
    region: details?.contacts.region ? details.contacts.region : 0,
    street_name: details?.contacts.street ? details.contacts.street : '',
    address_additional_number: details?.contacts.address_additional_number ? details.contacts.address_additional_number : '',

    //education
    Copy_of_The_Latest_academic_qualification: details?.education.graduation_document ? details?.education.graduation_document : '',
    Copy_of_qudurat_certificate: details?.education.alqudurat_document ? details?.education.alqudurat_document : '',
    Copy_of_tahsseli_certificate: details?.education.tahsseli_document ? details?.education.tahsseli_document : '',
    Alqudurat_Degree: details?.education.alqudurat_Degree ? details.education.alqudurat_Degree : 0,
    GPA: details?.education.gpa ? details.education.gpa : 0,
    GraduateYear: details?.education.graduation_year ? details.education.graduation_year : 0,
    IsGraduateYearUseGregorianDate: details?.education.IsGraduateYearUseGregorianDate
      ? details.education.IsGraduateYearUseGregorianDate
      : false,
    Tahsseli_Degree: details?.education.tahsseli_score ? details.education.tahsseli_score : 0,
    uniform_mark: details?.education.uniform_mark ? details.education.uniform_mark : 0,
    The_last_qualification_degree: details?.education.last_qualification_degree ? details.education.last_qualification_degree : 0,
    GPA_System: details?.education.gpa_system ? details.education.gpa_system : 0,
    english_level: details?.education.english_level ? details.education.english_level : 0,

    //Experiences
    employmentStatus: details?.financial.current_employment_status ? details.financial.current_employment_status : 0,
    salary: details?.financial.salary ? details.financial.salary : '',
    salary_optional: details?.financial.salary_optional ? details.financial.salary_optional : '',
    Entity_name: details?.financial.name_volunteering ? details.financial.name_volunteering : '',
    Number_of_hours: details?.financial.hours_volunteering ? details.financial.hours_volunteering : '',
    Attach_experiences_certificates: details?.financial.skill_certificates ? details.financial.skill_certificates : '',

    //AdditionalCertificates
    Additional_Certificates: details?.courses ? details?.courses : [],
  });
  const handleChange = (data) => {
    // if no custom inputs available, skip to third step
    current === 0 && !codes.length ? setCurrent(current + 2) : setCurrent(current + 1);
    for (const key in data) {
      setFormData((prevData) => ({
        ...prevData,
        [key]: data[key],
      }));
    }
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        formSteps,
        current,
        setCurrent,
        handleChange,
        formSubmission,
        successFormSubmission,
        errorFormSubmission,
        responseData,
        details,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
