import * as Actions from '../action-types';

export const otpFetch = () => ({
  type: Actions.OTP_FETCH_START,
});

export const otpFetchFinished = () => ({
  type: Actions.OTP_FETCH_SUCCESS,
});

export const otpFetchFailed = (payload) => ({
  type: Actions.OTP_FETCH_FAILED,
  payload,
});

export const otpValidation = (payload) => ({
  type: Actions.OTP_VALIDATION,
  payload,
});
export const otpServiceProviderValidation = (payload) => ({
  type: Actions.OTP_SERVICE_PROVIDER_VALIDATION,
  payload,
});

export const requestAnotherOtp = (payload) => ({
  type: Actions.REQUEST_ANOTHER_OTP,
  payload,
});
export const requestAnotherOtpServiceProvider = (payload) => ({
  type: Actions.REQUEST_ANOTHER_OTP_SERVICE_PROVIDER,
  payload,
});

export const AnotherOtpFetch = () => ({
  type: Actions.ANOTHER_OTP_START,
});

export const AnotherOtpFetchFailed = (payload) => ({
  type: Actions.ANOTHER_OTP_FAILED,
  payload,
});

export const AnotherOtpFetchFinished = () => ({
  type: Actions.ANOTHER_OTP_SUCCESS,
});
