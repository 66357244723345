/**
 * This regex matches strings that contain only 10 digits and the starting character is 1
 */
export const ID_PATTERN = /^[1]{1}.{9}$/;

/**
 * This regex checks for email validation
 */
export const EMAIL_PATTERN = /^[a-zA-Z0-9._-]+@([A-Za-z]+\.[A-Za-z]+|[A-Za-z]+\.[A-Za-z]+\.[A-Za-z]+)$/;

/**
 * This regex checks for password validation, (minimum 6 characters - maximum 16 - must contain: a small letter, a capital letter, a number, and a special character)
 */
export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

/**
 * This regex checks for mobile validation
 */
export const MOBILE_PATTERN = /^9665[0-9]{8}$/;

/**
 * This regex checks for arabic username validation
 */
export const USERNAME_PATTERN_AR_ONLY = /^[\u0621-\u064A ]*$/;

/**
 * This regex checks for username validation
 */
export const USERNAME_PATTERN = /^[a-zA-Z0-9_]{4,18}$/;

export const POSTAL_CODE = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const GPAs = /^(0|[1-9]\d?|100)$/;
export const New = /^\d*(\.\d{1,9})?$/;

export const IS_ARABIC = /^[\u0600-\u06FF|0-9]+$/;
export const FULLNAME_PATTERN = /(([\u0621-\u064A]{2,18}) ){2}[\u0621-\u064A]{2,18}/;
