// export { default as AddServices } from './AddServices'
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import withRouter from '../../../shared/utils/with-router';
import composeEnhancers from '../../../shared/utils/compose-enhancers';
import AddServices from './AddServices';
import * as addServicesSelector from '../../../redux/selectors/addServices';
import * as addServicesActions from '../../../redux/actions/addServices';

const mapStateToProps = () =>
  createStructuredSelector({
    fetching: addServicesSelector.getaddServicesFetching,
    error: addServicesSelector.getaddServicesError,
    success: addServicesSelector.getaddServicesSuccess,
  });

const mapDispatchToProps = (dispatch) => ({
  addServicesActions: bindActionCreators(addServicesActions, dispatch),
});

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddServices);
