import { createSelector } from 'reselect'

const setPasswordSelector = ({ setPassword }) => setPassword

export const getSetPasswordFetching = createSelector(setPasswordSelector, ({ fetchingReducer }) => fetchingReducer.fetching)
export const getSetPasswordError = createSelector(setPasswordSelector, ({ fetchingReducer }) => fetchingReducer.fetchingError)
export const getSetPasswordSuccess = createSelector(setPasswordSelector, ({ fetchingReducer }) => fetchingReducer.success)
export const getPassworValidationdFetching = createSelector(setPasswordSelector, ({ fetchingReducer }) => fetchingReducer.setPasswordValidationFetching)
export const getPassworValidationdError = createSelector(setPasswordSelector, ({ fetchingReducer }) => fetchingReducer.setPasswordValidationFetchingError)
export const getPassworValidationdSuccess = createSelector(setPasswordSelector, ({ fetchingReducer }) => fetchingReducer.setPasswordValidationSuccess)

 