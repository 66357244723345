import React from 'react';
import './SelectField.scss';
import { useTranslation } from 'react-i18next';
import '../InputField/InputField.scss';

const SelectCustom = (
  { classNameFormGroup, classNameLabel, options, title, name, value, onChange, lang = 'ar', error, hasValue, required, cssClass },
  ref
) => {
  const { t } = useTranslation();
  return (
    <div className={classNameFormGroup ? classNameFormGroup : 'form-group'}>
      <label htmlFor={name} className={classNameLabel ? classNameLabel : 'form-label-c'}>
        {t(title)}
        {/* <span className='invalid_checkbox'>{required && '*'}</span> */}
      </label>

      <select onChange={onChange} value={value} id={name} className={'form-select form-input ' + cssClass} name={name} ref={ref}>
        {options.map((item, index) => (
          <option value={item.id} key={index} hidden={index === 0}>
            {lang === 'ar' ? item.name_ar : item.name_en}
          </option>
        ))}
      </select>
      <span className='error-text'>{t(error)}</span>
    </div>
  );
};

export default React.forwardRef(SelectCustom);
