import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import './NavbarApplicant.scss';
import LogoHeader from '../LogoHeader/LogoHeader';
import userProfile from 'assets/images/shared/user-profile.svg';
import authManager from '../../../services/axios/authManager';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../services/axios';
import { resetUserData } from '../../../redux/actions/userData';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const NavbarApplicant = () => {
  const navigate = useNavigate();
  const userType = authManager.getAccountType();
  const accountType = userType === 'Student' ? 3 : userType === 'ServiceProvider' ? 4 : null;
  const user = useSelector((state: any) => state?.userData?.fetchingReducer?.data);
  const dispatch = useDispatch();
  // hide navbar when account type is null
  if (!accountType) {
    return <> </>;
  }
  const logMeOut = () => {
    dispatch(resetUserData());
    logOut();
    navigate('/');
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={'/home'} aria-current={'page'}>
          {accountType === 3 ? 'طلباتي' : 'خدماتي'}
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={'/myprofile'} aria-current={'page'}>
          الملف الشخصي
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: <div onClick={logMeOut}>تسجيل خروج</div>,
      danger: true,
    },
  ];

  return (
    <div className='container flex-column container-appliicant-provider'>
      <div className='d-flex align-items-center  justify-content-between' data-account-type={accountType}>
        <LogoHeader noMargin='logo-header_noMargin' marginTop='logo-margin' />
        <Space direction='vertical'>
          <Space wrap>
            <Dropdown menu={{ items }} trigger={['click']} placement='bottom'>
              <Button className='d-flex justify-content-between align-items-center'>
                <img src={userProfile} alt='' width='24' />
                <span className='ps-2'>{user.name}</span>
                <DownOutlined className='show-icon' style={{ fontSize: '10px' }} />
              </Button>
            </Dropdown>
          </Space>
        </Space>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};
export default NavbarApplicant;
