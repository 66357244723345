// Project dependency
import {Link} from 'react-router-dom';

// asset dependency
import './LogoHeader.scss'
import White from '../../../assets/images/shared/White.svg'
import Color from '../../../assets/images/shared/Color.svg'

const LogoHeader = ({ white = false, noMargin, marginTop }) => {
  return (
    <Link to = '/'> 
    <header className={`logo-header ${noMargin} ${marginTop}`}>
      <img src={white ? White : Color} alt='logo' className='logo-header__img' />
    </header>
    </Link>

  )
}

export default LogoHeader
