import UpdateProfileAuditor from './UpdateProfileAuditor'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import * as userDataSelector from '../../../redux/selectors/userData'
import * as userDataActions from '../../../redux/actions/userData'
import * as nationalIdNumberSelectors from '../../../redux/selectors/nationalIdNumber';
import * as nationalIdNumberActions from '../../../redux/actions/nationalIdNumber';



const mapStateToProps = () =>
  createStructuredSelector({
    //get
    fetchingData: userDataSelector.getUserDataFetching,
    errorData:userDataSelector.getUserDataError,
    successData: userDataSelector.getUserDataSuccess,
    auditorData:userDataSelector.getUserInfo,
    //post

    fetching: nationalIdNumberSelectors.getNationalIdNumberFetching,
    error: nationalIdNumberSelectors.getNationalIdNumberError,
    success: nationalIdNumberSelectors.getNationalIdNumberSuccess,
  })

const mapDispatchToProps = (dispatch) => ({
    userDataActions: bindActionCreators(userDataActions, dispatch),
    nationalIdNumberActions: bindActionCreators(nationalIdNumberActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(UpdateProfileAuditor)


