import SetPasswordAdminAndAuditor from './SetPasswordAdmin'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import resetPassworApplicantdApi from '../../ResetPassword/index'
const mapStateToProps = () =>
  createStructuredSelector({
    setPasswordFetching: resetPassworApplicantdApi.setPasswordApi.setPasswordSelectors.getSetPasswordFetching,
    setPasswordError: resetPassworApplicantdApi.setPasswordApi.setPasswordSelectors.getSetPasswordError,
    setPasswordSuccess: resetPassworApplicantdApi.setPasswordApi.setPasswordSelectors.getSetPasswordSuccess,
    setPassworValidationdFetching: resetPassworApplicantdApi.setPasswordApi.setPasswordSelectors.getPassworValidationdFetching,
    setPassworValidationdError: resetPassworApplicantdApi.setPasswordApi.setPasswordSelectors.getPassworValidationdError,
    setPassworValidationdSuccess: resetPassworApplicantdApi.setPasswordApi.setPasswordSelectors.getPassworValidationdSuccess,
  
  })

const mapDispatchToProps = (dispatch) => ({
  setPasswordRequest: bindActionCreators(resetPassworApplicantdApi.setPasswordApi.setPasswordActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(SetPasswordAdminAndAuditor)
