import * as Actions from '../../action-types';
import { combineReducers } from 'redux';

const initialUserData = {
  data: {},
  fetching: null,
  fetchingError: null,
  success: null,
};

const fetchingReducer = (state = initialUserData, action) => {
  switch (action.type) {
    case Actions.USER_DATA_FETCH_START:
      return {
        ...state,
        fetching: true,
      };
    case Actions.USER_DATA_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        fetchingError: action.payload,
      };
    case Actions.USER_DATA_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        success: true,
      };
    case Actions.RESET_USER_DATA:
      return initialUserData;
    default:
      return state;
  }
};

export default combineReducers({
  fetchingReducer,
});
