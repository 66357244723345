import JWTToken from './jwtToken'

class AuthManager {
  accessToken = new JWTToken('accessToken')
  refreshToken = new JWTToken('refreshToken')

  getAccessToken() {
    return this.accessToken.token
  }

  getRefreshToken() {
    return this.refreshToken.token
  }

  getAccountType() {
    return this.accessToken.getUserType()
  }

  setAuthData({ accessToken = this.accessToken, refreshToken = this.refreshToken }) {
    this.accessToken.token = accessToken
    this.refreshToken.token = refreshToken
  }

  logOut() {
    this.accessToken.removeToken()
    this.refreshToken.removeToken()
    localStorage.removeItem("persist:taalum")
  }
}

const authManager = new AuthManager()

export default authManager
