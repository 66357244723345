import * as Actions from '../action-types';

export const registerFetch = () => ({
  type: Actions.REGISTER_FETCH_START,
});

export const registerFetchFinished = () => ({
  type: Actions.REGISTER_FETCH_SUCCESS,
});

export const registerFetchFailed = (payload) => ({
  type: Actions.REGISTER_FETCH_FAILED,
  payload,
});

export const applicantRegister = (payload) => ({
  type: Actions.APPLICANT_REGISTER,
  payload,
});
export const serviceProviderRegister = (payload) => ({
  type: Actions.SERVICE_PROVIDER_REGISTER,
  payload,
});

export const auditorInvitationFetch = () => ({
  type: Actions.AUDITOR_INVITATION_FETCH_START,
});

export const auditorInvitationFetchFinished = () => ({
  type: Actions.AUDITOR_INVITATION_FETCH_SUCCESS,
});

export const auditorInvitationFetchFailed = (payload) => ({
  type: Actions.AUDITOR_INVITATION_FETCH_FAILED,
  payload,
});

export const auditorInvitationAccept = (payload) => ({
  type: Actions.REQUEST_AUDITOR_INVITATION,
  payload,
});
