import { RootContextProvider } from 'contexts'
import RouteContainer from './services/routes/RouteContainer'

const App = () => {
  return (
    <RootContextProvider >
      <RouteContainer />
    </RootContextProvider>
  )
}

export default App
