import React, { useState, useCallback, useEffect, useRef } from 'react';
import '../../Registration/Otp/OtpPage.scss';
import { Card, LogoHeader, Toaster, Button } from '../../../shared/components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import authManager from '../../../services/axios/authManager';

function OtpLogin(props) {
  const currentOtpIndex = useRef(0);
  const submitted = useRef(false);
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { otpLoginValidationActions, requestUserData } = props;
  const [otp, setOtp] = useState(new Array(5).fill(''));
  const [otpErr, setOtpErr] = useState(false);
  const [otpAttempts, setOtpAttempts] = useState(0);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [showResults, setShowResults] = useState({ message: 'تم إرسال الرمز إلى البريد الإلكتروني ', type: 'success' });
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const isProvider = !!+searchParams.get('provider');
  const isApplicant = !!+searchParams.get('applicant');

  //// TIMER ///
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const timeOutCallback = useCallback(() => {
    setMinutes((currTimer) => currTimer - 1);
    setSeconds((currTimer) => currTimer - 1);
  }, []);
  useEffect(() => {
    minutes > 0 && seconds > 0 && setTimeout(timeOutCallback, 1000);
  }, [minutes, seconds, timeOutCallback]);
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);
  const handleChange = (element, index) => {
    if (!/^[0-9|\u0660-\u0669]*$/.test(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === currentOtpIndex.current ? element.value : d))]);
    //Focus next input
    if (!element.value) {
      setActiveOtpIndex(currentOtpIndex.current - 1);
      setOtpErr(false);
    } else {
      setActiveOtpIndex(currentOtpIndex.current + 1);
      setOtpErr(false);
    }
  };
  const handleKeyDownChange = (e, index) => {
    currentOtpIndex.current = index;
    if (e.key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex.current - 1);
    } else if (!isNaN(e.key)) {
      setActiveOtpIndex(currentOtpIndex.current + 1);
    }
  };
  const sendAnotherOtp = (e) => {
    e.preventDefault();
    if (!seconds && !minutes) {
      setMinutes(1);
      setSeconds(0);
    }
    if (isApplicant) otpLoginValidationActions.requestAnotherOtpLogin();
    if (isProvider) otpLoginValidationActions.requestAnotherOtpServiceProviderLogin();
  };
  const onSubmit = async () => {
    submitted.current = true;
    let joinedOtp = otp.join('');
    joinedOtp = joinedOtp.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
    if (isApplicant) otpLoginValidationActions.otpLoginValidation({ joinedOtp });
    if (isProvider) otpLoginValidationActions.otpServiceProviderLogin({ joinedOtp });
  };

  useEffect(() => {
    if (otp.every((a) => a.length === 1) && otp.length === 5) {
      onSubmit();
    }
    // eslint-disable-next-line
  }, [otp]);

  const otpSuccess = async () => {
    if (authManager.getAccessToken()?.length) await requestUserData.UserData();
    Navigate('/home');
  };

  useEffect(() => {
    if (props.success && submitted.current) {
      otpSuccess();
    } else if (props.error) {
      setShowResults({ message: 'رمز التحقق غير صحيح', type: 'warning' });
      setOtpErr(true);
      setOtpAttempts((prevState) => prevState + 1);
      // if (otpAttempts === 3) Navigate('/applicant-login?otpLimit=1');
      // window.location.replace() used instead of Navigate()
      // to clear errors state in otp page
      // also to make sure that the applicant can't press back button
      if (otpAttempts === 3) window.location.replace('/applicant-login?otpLimit=1');
    }
    if (props.anotherOtpLoginSuccess) {
      setShowResults({ message: 'تم إعادة ارسال رمز جديد بنجاح', type: 'success' });
      setOtpErr(false);
    } else if (props.anotherOtpLoginFetchingError) {
      setShowResults({ message: 'فشل في إعادة ارسال الرمز', type: 'warning' });
      setOtpErr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!(state && state.fromLogin)) Navigate(-1);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='background-full__screen'>
        <div className='secondbg-full__screen'></div>
        <div className='thirdbg-full__screen'></div>
        <div className='fivebg-full__screen'></div>
        <LogoHeader white />
        <div className='otp-body'>
          <Card
            wide='card--wide-2'
            customHeader='card__header__otp'
            title='التحقق'
            subTitle='تم إرسال الرمز إلى البريد الإلكتروني المدخل'
            toaster={
              showResults.message.length ? <Toaster message={showResults.message} type={showResults.type} /> : <div className='no-toster' />
            }
          >
            <form>
              <div>
                <button className='resend-text' disabled={seconds > 0 || minutes > 0} onClick={(e) => sendAnotherOtp(e)}>
                  اعادة ارسال الرمز
                  {seconds > 0 || minutes > 0 ? (
                    <span>
                      {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  ) : (
                    props.anotherOtpLoginFetching
                  )}
                </button>
                <div className='otp'>
                  <div>
                    <div className='otp-box'>
                      {otp.map((data, index) => {
                        return (
                          <input
                            ref={index === activeOtpIndex ? inputRef : null}
                            className={otpErr ? 'otp-field_error' : 'otp-field'}
                            type='text'
                            name='otp'
                            maxLength='1'
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyDown={(e) => handleKeyDownChange(e, index)}
                          />
                        );
                      })}
                    </div>
                    {otpErr && <small className='otp__input__error'>{'عذرًا الرمز المدخل غير صحيح، حاول مرة أخرى'}</small>}
                  </div>
                </div>
                <div className='card__btn'>
                  <Button
                    buttonStyle='btn--primary'
                    titleButton='تسجيل الدخول'
                    disabled={otp.includes('')}
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit();
                    }}
                  />
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
}
export default OtpLogin;
