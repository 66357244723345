import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import moment from 'moment-hijri';
import { MOBILE_PATTERN } from '../../shared/utils/regax';
import { GENDER, SOCIAL_STATUS } from '../ApplicantForm/constants';
import { useForm, Controller } from 'react-hook-form';
import sharedApi from '../../shared';
import { message, Skeleton } from 'antd';
import updateProfileIcon from '../../assets/images/shared/update_profile_icon.svg';
import { Link } from 'react-router-dom';
import { useToastContext } from 'contexts/RootContext';
import avatarLogo from '../../assets/images/shared/avatarLogo.svg';
import { formatFileSizeInBytes } from 'utils';
import { getUserData, updateUserData } from '../../services/axios/api';
import authManager from '../../services/axios/authManager';

const { ButtonNormal, InputField, AvatarUploader, SelectField } = sharedApi.components;

export default function ProfilePage() {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const inputEnabled = { backgroundColor: '#fff', height: '40px' };
  const inputReadOnly = { backgroundColor: 'white', height: '40px', border: 'none' };
  const userType = authManager.getAccountType();

  const toast = useToastContext();
  const [, setImgSize] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [style, setStyle] = useState(inputReadOnly);
  const [genderSelected, setGenderSelected] = useState('');
  const [socialStatus, setSocialStatus] = useState('');
  const [profilePreview, setProfilePreview] = useState('');
  const [DOB, setDOB] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isProvider, setIsProvider] = useState(false);

  useEffect(() => {
    if (userType === 'Student') getDataApplicant();
    if (userType === 'ServiceProvider') getDataSP();
  }, []);

  const getDataApplicant = async () => {
    try {
      const response = await getUserData();
      const {
        email,
        gender,
        mobile_number,
        name,
        personal_photo_path,
        social_status,
        user: { additional_mobile_number = 0, age, date_of_birth, family_members, national_id_number },
      } = response;
      // reset() method from React-Form-Hook
      // will put the values from api into the fields
      const getBirthDate = moment(date_of_birth);
      reset({
        national_id_number,
        email,
        mobile_number,
        name,
        gender,
        age,
        datehijri: getBirthDate.format('iD-iM-iYYYY').replaceAll('-', '/'),
        date_of_birth: getBirthDate.format('iDD/iMM/iYYYY'),
        family_members,
        additional_mobile_number,
        social_status,
      });
      setGenderSelected(gender);
      setSocialStatus(social_status);
      setProfilePreview(personal_photo_path);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const getDataSP = async () => {
    setIsProvider(true);
    try {
      const response = await getUserData();
      const {
        email,
        mobile_number,
        name,
        service_provider: { national_id_number, additional_mobile_number },
      } = response;
      // reset() method from React-Form-Hook
      // will put the values from api into the fields
      reset({
        national_id_number,
        email,
        mobile_number,
        name,
        additional_mobile_number,
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = () => {
    setDisabled(!disabled);
    setIsEditMode(true);
    setStyle(inputEnabled);
  };

  const onSubmit = async (formInputs) => {
    //convert hijri date to gregorian
    let momentDOB = moment(DOB, 'iDD/iMM/iYYYY');
    let formaedDate = momentDOB.format('YYYY/M/D').replaceAll('/', '-');
    // update transformed data
    const data = {
      mobile_number: formInputs.mobile_number,
      personal_photo_path: formInputs.personal_photo_path,
      name: formInputs.name,
      gender: formInputs.gender,
      social_status: formInputs.social_status,
      user: {
        national_id_number: formInputs.national_id_number,
        family_members: formInputs.family_members,
        date_of_birth: formaedDate,
        additional_mobile_number: formInputs.additional_mobile_number,
      },
    };
    const FormDataBody = new FormData();
    for (const property in data) {
      if (property === 'user') {
        for (const key in data[property]) {
          data[property][key] && FormDataBody.append(`user.${key}`, data[property][key]);
        }
      } else data[property] && FormDataBody.append(property, data[property]);
    }
    try {
      const response = await updateUserData(FormDataBody);
      const {
        gender,
        social_status,
        personal_photo_path,
        user: { age },
      } = response;
      message.success({
        type: 'success',
        content: `تم تحديث المعلومات الشخصية بنجاح`,
        duration: 2,
      });
      setStyle(inputReadOnly);
      setValue('datehijri', formInputs.date_of_birth);
      setValue('age', age);
      setGenderSelected(gender);
      setSocialStatus(social_status);
      setProfilePreview(personal_photo_path);
      setIsEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };
  const onProviderSubmit = async (formInputs) => {
    // update transformed data
    const FormDataBody = new FormData();
    FormDataBody.append('mobile_number', formInputs.mobile_number);
    FormDataBody.append('service_provider.additional_mobile_number', formInputs.additional_mobile_number);
    try {
      await updateUserData(FormDataBody);
      message.success({
        type: 'success',
        content: `تم تحديث المعلومات الشخصية بنجاح`,
        duration: 2,
      });
      setStyle(inputReadOnly);
      setIsEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelEdit = () => {
    setIsEditMode(false);
    setStyle(inputReadOnly);
    setDisabled(true);
    reset();
  };
  const validateDate = (dateOfBirth) => {
    var today = moment().format('iDD/iMM/iYYYY');
    var todayYear = moment(today, 'iDD/iMM/iYYYY').iYear();
    var dateOfBirthYear = moment(dateOfBirth, 'iDD/iMM/iYYYY').iYear();
    var age = todayYear - dateOfBirthYear;
    setDOB(dateOfBirth);
    return age > 11;
  };

  const imgError = useMemo(() => {
    if (errors?.personalPhoto?.message === 'invalidImageSize') return '';
    if (errors?.personalPhoto?.message?.length > 0) return errors.personalPhoto.message;
    if (errors?.personalPhoto?.message === 'required') return 'الحقل مطلوب';
    return undefined;
  }, [errors.personalPhoto]);
  return (
    <>
      <div className='auditor_update_personal_info-auditorList-container'>
        <div className='auditor_update_personal_info-auditorList'>
          <div className='auditor_personal_info-div-auditor mb-4'>
            <section>
              <p className='auditor_personal_info-title'>{t('profile_Auditor')}</p>
              <p className='auditor_personal_info-subtitle'>{t('p_profile_Auditor')} </p>
            </section>
            {!isEditMode ? (
              <button className='auditor_personal_info-btn' onClick={handleUpdate}>
                <img src={updateProfileIcon} alt='updateProfileIcon' className='auditor_personal_info-icon' />
                <p> تحديث البيانات</p>
              </button>
            ) : null}
          </div>
          <form onSubmit={handleSubmit(isProvider ? onProviderSubmit : onSubmit)}>
            {isLoading ? (
              <div>
                <Skeleton.Image active style={{ borderRadius: '50%' }} className='mb-3' />
                <div className='row row-cols-md-2 row-cols-xxl-4'>
                  <Skeleton active className='mb-4' />
                  <Skeleton active className='mb-4' />
                  <Skeleton active className='mb-4' />
                  <Skeleton active className='mb-4' />
                  <Skeleton active className='mb-4' />
                  <Skeleton active className='mb-4' />
                </div>
              </div>
            ) : (
              <div className='profile-form'>
                {!isProvider &&
                  (isEditMode ? (
                    <Controller
                      control={control}
                      name='personal_photo_path'
                      rules={{
                        validate: (file) => {
                          if (!file) {
                            return true;
                          }
                          if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file?.type)) {
                            setValue('personal_photo_path', undefined);
                            toast('امتداد الصورة غير صحيح', 'error');
                            return 'imageTypeInvalid';
                          }
                          // check if image sizes is greater than 5MB
                          if (file?.size > 5242880) {
                            toast('حجم الصورة أكبر من 5 ميجا', 'error');
                            setValue('personal_photo_path', undefined);
                            return 'invalidImageSize';
                          }
                          const _imgSize = formatFileSizeInBytes(file?.size);
                          setImgSize(_imgSize);
                        },
                      }}
                      render={({ field }) => <AvatarUploader {...field} logo={avatarLogo} error={imgError} />}
                    />
                  ) : (
                    profilePreview && (
                      <img src={profilePreview} width={150} height={150} style={{ borderRadius: '50%', objectFit: 'cover' }} alt='' />
                    )
                  ))}
                <div className='row row-cols-md-2 row-cols-xxl-4'>
                  <InputField
                    title='fullName'
                    type='text'
                    className='input__text w-100'
                    style={inputReadOnly}
                    disabled={true}
                    {...register('name')}
                  />
                  <InputField
                    title='nationalId'
                    type='text'
                    className='input__text w-100'
                    disabled={true}
                    style={inputReadOnly}
                    {...register('national_id_number')}
                  />
                  <InputField
                    title='email'
                    type='text'
                    className='input__text w-100'
                    disabled={true}
                    style={inputReadOnly}
                    {...register('email')}
                  />
                  {!isProvider && (
                    <InputField
                      required
                      title='age'
                      type='text'
                      className='input__text w-100'
                      disabled={true}
                      style={inputReadOnly}
                      {...register('age')}
                    />
                  )}
                  <InputField
                    title='mobileNumber'
                    type='number'
                    readonly={!isEditMode}
                    style={style}
                    placeholder='9665XXXXXXXX'
                    className='input__text w-100'
                    error={errors.mobile_number && errors.mobile_number.message}
                    {...register('mobile_number', {
                      required: 'fieldRequired',
                      pattern: {
                        value: MOBILE_PATTERN,
                        message: 'mobileInvalid',
                      },
                    })}
                  />
                  <InputField
                    title='additional_mobile'
                    type='number'
                    readonly={!isEditMode}
                    style={style}
                    placeholder='9665XXXXXXXX'
                    className='input__text w-100'
                    error={errors?.additional_mobile_number && errors?.additional_mobile_number?.message}
                    {...register('additional_mobile_number', {
                      pattern: {
                        value: MOBILE_PATTERN,
                        message: 'mobileInvalid',
                      },
                    })}
                  />
                  {!isProvider &&
                    (isEditMode ? (
                      <Controller
                        name='gender'
                        control={control}
                        rules={{
                          required: 'fieldRequired',
                        }}
                        render={({ field }) => (
                          <SelectField
                            {...field}
                            title='gender'
                            options={GENDER}
                            classNameFormGroup='input'
                            classNameLabel='form-label-c_personal pt-0'
                            cssClass='w-100'
                            required
                            error={errors.gender && errors.gender.message}
                          />
                        )}
                      />
                    ) : (
                      <div className='input'>
                        <label className='form-label-c mt-0'>الجنس</label>
                        <span className='text-purple'>{GENDER[genderSelected]?.ar_label}</span>
                      </div>
                    ))}
                  {!isProvider &&
                    (isEditMode ? (
                      <Controller
                        title='dateOfBirth'
                        control={control}
                        name='date_of_birth'
                        rules={{
                          required: 'fieldRequired',
                          validate: {
                            validateDa: (v) => validateDate(v) || 'ageInvalid',
                          },
                        }}
                        render={({ field: { onChange } }) => (
                          <InputField
                            cssClass='w-100'
                            onChange={onChange}
                            value={DOB}
                            title='dateOfBirth'
                            placeholder='selectDate'
                            type='date'
                            error={errors.date_of_birth && errors.date_of_birth.message}
                          />
                        )}
                      />
                    ) : (
                      <InputField
                        title='dateOfBirth'
                        type='text'
                        required
                        readonly={true}
                        className='input__text w-100'
                        style={style}
                        {...register('datehijri')}
                      />
                    ))}
                  {!isProvider &&
                    (isEditMode ? (
                      <Controller
                        name='social_status'
                        control={control}
                        rules={{
                          required: 'fieldRequired',
                        }}
                        render={({ field }) => (
                          <SelectField
                            {...field}
                            title='socialStatus'
                            options={SOCIAL_STATUS}
                            classNameFormGroup='input'
                            classNameLabel='form-label-c_personal pt-0'
                            cssClass='w-100'
                            required
                            error={errors.social_status && errors.social_status.message}
                          />
                        )}
                      />
                    ) : (
                      <div className='input'>
                        <label className='form-label-c mt-0'>الحالة الاجتماعية</label>
                        <span className='text-purple'>{SOCIAL_STATUS[socialStatus]?.ar_label}</span>
                      </div>
                    ))}
                  {!isProvider && (
                    <InputField
                      required
                      readonly={!isEditMode}
                      title='familyNumber'
                      type='number'
                      name='family_members'
                      className='input__text w-100'
                      maxLength={2}
                      style={style}
                      error={errors?.family_members && errors?.family_members?.message}
                      className_error='input__error_form'
                      {...register('family_members', {
                        required: 'fieldRequired',
                        onChange: (e) =>
                          setValue(
                            'family_members',
                            !/^[0-9]{0,2}$/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                          ),
                      })}
                    />
                  )}
                </div>
              </div>
            )}
            <hr className='auditor_personal_info-line'></hr>
            {!isEditMode ? (
              <div className=''>
                <div className='auditor_personal_info-div'>
                  <Link className='auditor_personal_info-a' to='/change-password'>
                    تغيير كلمة المرور
                  </Link>
                  <p className='auditor_personal_info-arrow'>←</p>
                </div>
              </div>
            ) : (
              <div>
                <div className='auditor_update_personal_info-steps-action m-0'>
                  <ButtonNormal titleButton=' الغاء' classNameButton='step-btn-3' onClick={cancelEdit} />
                  <ButtonNormal titleButton=' حفظ' disabled={!isValid} type='submit' />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
