export const ROUTES_CONSTANTS = Object.freeze({
  landingPage: '/',
  home: '/home',
  myprofile: '/myprofile',
  pageNotFound: '*',
  login: {
    adminAndAuditorLogin: 'login',
    applicantLogin: 'applicant-login',
    providerLogin: 'provider-login',
    otp: 'otp-Login',
  },
  registration: {
    applicantRegistration: 'applicant-registration',
    otp: 'otp',
  },
  AuditorDashBoard: {
    AuditorDashBoard: 'auditor-dashBoard',
  },
  resetPassword: {
    resetPassword: 'applicant-reset-password',
    setPassword: 'applicant-set-password/:token',
  },
  resetPasswordAdmin: {
    resetPasswordAdmin: 'reset-password',
    setPasswordAdmin: 'set-password/:token',
  },
  applicantForm: {
    applicantForm: 'applicant-form',
    resubmitApplicantForm: 'applicant-form/:id',
  },
  ApplicationDetails: {
    ApplicationDetails: 'Application-details/:request_id',
  },
  adminDashboard: {
    AddAuditor: 'add-auditor',
    auditorList: 'auditor-list',
    applicationReq: 'application-request',
    serviceslist: 'services-list',
    addservices: 'add-services',
    adminServiceProvider: 'admin-services-provider',
    serviceProviderDetails: 'admin-services-provider-details/:id',
    applicationReqDetails: 'application-requests-Details',
    updateHome: 'update-home',
    updateAbout: 'update-about',
    updateMembers: 'update-members',
    updateBanks: 'update-banks',
    members: 'members',
  },
  auditor: {
    auditorAcceptInvitation: 'invitation-accept/:form_id',
    // dashboard: 'auditor-dashboard',
    applicationList: 'application-list',
    applicationListNew: 'applications',
    applicationRequestDetail: 'request-details/:request_id',
    profile_auditor: 'profile',
    // UpdateProfileAuditor:'update-profile-auditor',
    ChangePassword: 'change-password',
  },
  serviceProvider: {
    Request: 'service-provider/new',
  },
});
