// export { default as ResetPasswordAdmin } from './ResetPasswordAdmin'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import ResetPasswordAdmin from './ResetPasswordAdmin'
import resetPassworAdminApi from '..'

const mapStateToProps = () =>
  createStructuredSelector({
    // fetching: resetPassworAdminApi.resetAdminApi.resetSelectors.getResetFetching,
    fetching: resetPassworAdminApi.resetAdminApi.resetAdminSelectors.getResetAdminFetching,
    error: resetPassworAdminApi.resetAdminApi.resetAdminSelectors.getResetAdminError,
    success: resetPassworAdminApi.resetAdminApi.resetAdminSelectors.getResetAdminSuccess,
  })

const mapDispatchToProps = (dispatch) => ({
  resetAdminActions: bindActionCreators(resetPassworAdminApi.resetAdminApi.resetAdminActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResetPasswordAdmin)
