import React, { useRef, useState } from 'react';
// import T from "prop-types";
// import UploadLogo from '../../../markup/images/upload.png'
// import FileLogo from '../../../markup/images/file-icon.svg'
import logoCertificates from '../../../markup/images/logoCertificates.svg';
import RemoveIcon from '../../../markup/images/remove-icon.svg';
import { useTranslation, Trans } from 'react-i18next';

const CertificatesUpload = ({ allowedTypes, onChange, maxSize = 1, value, uploaded = '' }, ref) => {
  const { t } = useTranslation();
  const uploadRef = useRef();
  // eslint-disable-next-line
  const [name, setName] = useState('');
  const [size, setSize] = useState(uploaded ? uploaded : '0 Bytes');
  const [dragged, setDragged] = useState('');
  const [fileUploaded, setFileUploaded] = useState(uploaded > 0);
  // const [typeErrors, setTypeErrors] = useState(false)
  const [sizeErrors, setSizeErrors] = useState(false);

  // const validateAttachment = (value) => {
  //   const acceptedTypes = ['jpg', 'png', 'pdf'];

  //   if (!acceptedTypes.includes(value.type)) {
  //     return 'Please upload a valid file type';
  //   }

  //   return true;
  // }

  const validate = (upload) => {
    let valid = true;

    if (upload) {
      const arr = upload.name.split('.');
      const name = `.${arr[arr.length - 1]}`;

      if (allowedTypes && !allowedTypes.includes(name.toLowerCase())) {
        valid = false;
        // setTypeErrors(true)
      } else {
        // setTypeErrors(false)
      }

      const sizeLimit = 10000000;

      if (upload.size > sizeLimit) {
        valid = false;
        setSizeErrors(true);
      } else {
        setSizeErrors(false);
      }
      return valid;
    }
    return valid;
  };

  const onUploadChange = (e) => {
    e.preventDefault();
    const upload = e.target.files[0];
    const valid = validate(upload);
    if (valid) {
      upload ? setName(upload.name) : setName('');
      setFileUploaded(true);
      calculateSize(upload.size);
      onChange(upload);
    }
  };

  const onUploadDrag = (e) => {
    e.preventDefault();
    setDragged('');
    const upload = e.dataTransfer.files[0];
    const valid = validate(upload);
    if (valid) {
      upload ? setName(upload.name) : setName('');
      setFileUploaded(true);
      calculateSize(upload.size);
      onChange(upload);
    }
  };

  const calculateSize = (size, decimals = 2) => {
    if (size === 0) {
      setSize('0 Bytes');
    } else {
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB'];
      const i = Math.floor(Math.log(size) / Math.log(k));
      !uploaded && setSize(parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]);
      return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  };

  const removeFile = () => {
    setName('');
    setSize('0 Bytes');
    setFileUploaded(false);
    // setTypeErrors(false)
    setSizeErrors(false);
    onChange(null);
  };

  return (
    <div>
      {fileUploaded ? (
        <div className='div-upload-file'>
          <div className='upload-prompt-AdditionalCertificates' style={{ fontSize: '15px' }}>
            <p className='file-size'>{uploaded > 0 ? calculateSize(uploaded) : size}</p>
            <div onClick={removeFile}>
              <img src={RemoveIcon} alt='' className='remove-icon-Certificates' />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <input
            value={value}
            type='file'
            onChange={onUploadChange}
            ref={uploadRef}
            style={{ display: 'none' }}
            accept={allowedTypes && allowedTypes.join(',')}
          />
          <div
            className={`div-upload-file ${dragged}`}
            onClick={() => uploadRef.current.click()}
            onDragOver={(e) => {
              e.preventDefault();
              setDragged('upload-btn-dragged');
            }}
            onDrop={onUploadDrag}
            onDragEnd={(e) => {
              setDragged('');
            }}
            onDragLeave={(e) => {
              setDragged('');
            }}
          >
            <div className='upload-prompt-AdditionalCertificates'>
              <img src={logoCertificates} alt='' className='upload-logo' />
              <span>
                <span className='invisible-small'>ارفق ملف</span>
              </span>
            </div>
          </div>
        </div>
      )}

      {sizeErrors && (
        <small className='input__error_cer'>
          <Trans t={t}>
            {'10 MB '}
            {''}
            {'حجم الملف يجب ان يكون'}
          </Trans>
        </small>
      )}
      {/* {sizeErrors && <span className='error-text'>{t('sizeError')}</span>} */}
      {/* {typeErrors && (<span className='error-text'>{t('typeError')}</span>)} */}
    </div>
  );
};

// Upload.propTypes = {
//   allowedTypes: T.array,
//   onChange: T.func.isRequired,
//   maxSize: T.number,
// };

export default React.forwardRef(CertificatesUpload);
