// LOGIN
export const ADMIN_LOGIN = 'login/ADMIN_LOGIN';
export const APPLICANT_LOGIN = 'login/APPLICANT_LOGIN';
export const SERVICE_PROVIDER_LOGIN = 'login/SERVICE_PROVIDER_LOGIN';
export const SET_ACCOUNT_TYPE = 'login/SET_ACCOUNT_TYPE';
export const CLEAR_ACCOUNT_TYPE = 'login/CLEAR_ACCOUNT_TYPE';
export const LOGIN_FETCH_START = 'login/LOGIN_FETCH_START';
export const LOGIN_FETCH_FAILED = 'login/LOGIN_FETCH_FAILED';
export const LOGIN_FETCH_SUCCESS = 'login/LOGIN_FETCH_SUCCESS';

// REGISTRATION
export const APPLICANT_REGISTER = 'registration/APPLICANT_REGISTER';
export const SERVICE_PROVIDER_REGISTER = 'registration/SERVICE_PROVIDER_REGISTER';
export const REGISTER_FETCH_START = 'register/REGISTER_FETCH_START';
export const REGISTER_FETCH_FAILED = 'register/REGISTER_FETCH_FAILED';
export const REGISTER_FETCH_SUCCESS = 'register/REGISTER_FETCH_SUCCESS';

// OTP
export const OTP_VALIDATION = 'otp/OTP_VALIDATION';
export const OTP_SERVICE_PROVIDER_VALIDATION = 'otp/OTP_SERVICE_PROVIDER_VALIDATION';
export const OTP_FETCH_START = 'otp/OTP_FETCH_START';
export const OTP_FETCH_FAILED = 'otp/OTP_FETCH_FAILED';
export const OTP_FETCH_SUCCESS = 'otp/OTP_FETCH_SUCCESS';
export const ANOTHER_OTP_START = 'otp/ANOTHER_OTP_START';
export const ANOTHER_OTP_FAILED = 'otp/ANOTHER_OTP_FAILED';
export const ANOTHER_OTP_SUCCESS = 'otp/ANOTHER_OTP_SUCCESS';
export const REQUEST_ANOTHER_OTP = 'otp/REQUEST_ANOTHER_OTP';
export const REQUEST_ANOTHER_OTP_SERVICE_PROVIDER = 'otp/REQUEST_ANOTHER_OTP_SERVICE_PROVIDER';
export const RESET_OTP = 'otp/RESET_OTP';

// AUDITOR INVITATION
export const AUDITOR_INVITATION_FETCH_START = 'auditor/AUDITOR_INVITATION_FETCH_START';
export const AUDITOR_INVITATION_FETCH_FAILED = 'auditor/AUDITOR_INVITATION_FETCH_FAILED';
export const AUDITOR_INVITATION_FETCH_SUCCESS = 'auditor/AUDITOR_INVITATION_FETCH_SUCCESS';
export const REQUEST_AUDITOR_INVITATION = 'auditor/REQUEST_AUDITOR_INVITATION';

// RRESET PASSWORD
export const RESET_PASSWORD = 'reset/RESET_PASSWORD';
export const RESET_SERVICE_PROVIDER_PASSWORD = 'reset/RESET_SERVICE_PROVIDER_PASSWORD';
export const RESET_PASSWORD_FETCH_START = 'reset/RESET_PASSWORD_FETCH_START';
export const RESET_PASSWORD_FETCH_FAILED = 'reset/RESET_PASSWORD_FETCH_FAILED';
export const RESET_PASSWORD_FETCH_SUCCESS = 'reset/RESET_PASSWORD_FETCH_SUCCESS';

// RRESET PASSWORD ADMIN
export const RESET_PASSWORD_ADMIN = 'resetAdmin/RESET_PASSWORD_ADMIN';
export const RESET_PASSWORD_ADMIN_FETCH_START = 'resetAdmin/RESET_PASSWORD_ADMIN_FETCH_START';
export const RESET_PASSWORD_ADMIN_FETCH_FAILED = 'resetAdmin/RESET_PASSWORD_ADMIN_FETCH_FAILED';
export const RESET_PASSWORD_ADMIN_FETCH_SUCCESS = 'resetAdmin/RESET_PASSWORD_ADMIN_FETCH_SUCCESS';
// OTP LOGIN
export const OTP_LOGIN_VALIDATION = 'otpLogin/OTP_LOGIN_VALIDATION';
export const OTP_SERVICE_PROVIDER_LOGIN_VALIDATION = 'otpLogin/OTP_SERVICE_PROVIDER_LOGIN_VALIDATION';
export const OTP_LOGIN_FETCH_START = 'otpLogin/OTP_LOGIN_FETCH_START';
export const OTP_LOGIN_FETCH_FAILED = 'otpLogin/OTP_LOGIN_FETCH_FAILED';
export const OTP_LOGIN_FETCH_SUCCESS = 'otpLogin/OTP_LOGIN_FETCH_SUCCESS';
export const ANOTHER_OTP_LOGIN_START = 'otpLogin/ANOTHER_OTP_LOGIN_START';
export const ANOTHER_OTP_LOGIN_FAILED = 'otpLogin/ANOTHER_OTP_LOGIN_FAILED';
export const ANOTHER_OTP_LOGIN_SUCCESS = 'otpLogin/ANOTHER_OTP_LOGIN_SUCCESS';
export const REQUEST_ANOTHER_OTP_LOGIN = 'otpLogin/REQUEST_ANOTHER_OTP_LOGIN';
export const REQUEST_ANOTHER_OTP_SERVICE_PROVIDER_LOGIN = 'otpLogin/REQUEST_ANOTHER_OTP_SERVICE_PROVIDER_LOGIN';
export const RESET_FETCH = 'otpLogin/RESET_FETCH';

// SETPASSWORD
export const SET_PASSWORD_FETCH_START = 'setPassword/SET_PASSWORD_FETCH_START';
export const SET_PASSWORD_FETCH_FAILED = 'setPassword/SET_PASSWORD_FETCH_FAILED';
export const SET_PASSWORD_FETCH_SUCCESS = 'setPassword/SET_PASSWORD_FETCH_SUCCESS';
export const REQUEST_SET_PASSWORD = 'setPassword/REQUEST_SET_PASSWORD';
export const REQUEST_SET_PASSWORD_SERVICE_PROVIDER = 'setPassword/REQUEST_SET_PASSWORD_SERVICE_PROVIDER';

// RESETPASSWORD VALIDATION
export const RESET_PASSWORD_VALIDATION_FETCH_START = 'resetPasswordValidation/RESET_PASSWORD_VALIDATION_FETCH_START';
export const RESET_PASSWORD_VALIDATION_FETCH_SUCCESS = 'resetPasswordValidation/RESET_PASSWORD_VALIDATION_FETCH_SUCCESS';
export const RESET_PASSWORD_VALIDATION_FETCH_FAILED = 'resetPasswordValidation/RESET_PASSWORD_VALIDATION_FETCH_FAILED';
export const REQUEST_RESET_PASSWORD_VALIDATION = 'resetPasswordValidation/REQUEST_RESET_PASSWORD_VALIDATION';

// APPLICANT FORM SUBMISSION
export const APPLICANT_FORM_SUBMISSION_START = 'applicantFormSubmission/APPLICANT_FORM_SUBMISSION_START';
export const APPLICANT_FORM_SUBMISSION_SUCCESS = 'applicantFormSubmission/APPLICANT_FORM_SUBMISSION_SUCCESS';
export const APPLICANT_FORM_SUBMISSION_FAILED = 'applicantFormSubmission/APPLICANT_FORM_SUBMISSION_FAILED';
export const REQUEST_APPLICANT_FORM_SUBMISSION = 'applicantFormSubmission/REQUEST_APPLICANT_FORM_SUBMISSION';
export const RESET_REQUEST_APPLICANT_FORM_SUBMISSION = 'applicantFormSubmission/RESET_REQUEST_APPLICANT_FORM_SUBMISSION';

// ApplicationDetails
export const APPLICATION_DETAILS_START = 'ApplicationDetails/APPLICATION_DETAILS_START';
export const APPLICATION_DETAILS_FETCH_SUCCESS = 'ApplicationDetails/APPLICATION_DETAILS_FETCH_SUCCESS';
export const APPLICATION_DETAILS_FETCH_FAILED = 'ApplicationDetails/APPLICATION_DETAILS_FETCH_FAILED';
export const REQUEST_APPLICATION_DETAILS = 'ApplicationDetails/REQUEST_APPLICATION_DETAILS';

// ADD ADUTOR
export const ADD_ADUTOR = 'addAdutor/ADD_ADUTOR';
export const ADD_ADUTOR_FETCH_START = 'addAdutor/ADD_ADUTOR_FETCH_START';
export const ADD_ADUTOR_FETCH_FAILED = 'addAdutor/ADD_ADUTOR_FETCH_FAILED';
export const ADD_ADUTOR_FETCH_SUCCESS = 'addAdutor/ADD_ADUTOR_FETCH_SUCCESS';

// USER DATA
export const USER_DATA_FETCH_START = 'account/USER_DATA_FETCH_START';
export const USER_DATA_FETCH_FAILED = 'account/USER_DATA_FETCH_FAILED';
export const USER_DATA_FETCH_SUCCESS = 'account/USER_DATA_FETCH_SUCCESS';
export const RESET_USER_DATA = 'account/RESET_USER_DATA';
export const USER_DATA = 'account/USER_DATA';

// send National_id_number ADUTOR
export const National_id_number = 'nationalIdNumber/National_id_number';
export const National_id_number_FETCH_START = 'nationalIdNumber/National_id_number_FETCH_START';
export const National_id_number_FETCH_FAILED = 'nationalIdNumber/National_id_number_FETCH_FAILED';
export const National_id_number_FETCH_SUCCESS = 'nationalIdNumber/National_id_number_FETCH_SUCCESS';

// change PASSWORD
export const Change_Password = 'changePassword/Change_Password';
export const Change_Password_FETCH_START = 'changePassword/Change_Password_FETCH_START';
export const Change_Password_FETCH_FAILED = 'changePassword/Change_Password_FETCH_FAILED';
export const Change_Password_FETCH_SUCCESS = 'changePassword/Change_Password_FETCH_SUCCESS';

// ADD Services
export const ADD_SERVICES = 'addServices/ADD_SERVICES';
export const ADD_SERVICES_FETCH_START = 'addServices/ADD_SERVICES_FETCH_START';
export const ADD_SERVICES_FETCH_FAILED = 'addServices/ADD_SERVICES_FETCH_FAILED';
export const ADD_SERVICES_FETCH_SUCCESS = 'addServices/ADD_ADUTOR_FETCH_SUCCESS';


export const RESET_STATE = 'RESET_STATE';