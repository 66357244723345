import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OrderDetails from '../../../shared/components/OrderDetails';
import iconeClosePopup from '../../../markup/images/close-popup.png';
import './index.scss';
import { assignRequest, customAuthGet, manageRequest, manageRequestAdmin } from '../../../services/axios/api';
import { message } from 'antd';
import { useToastContextWithError } from 'contexts/RootContext';
import { useSelector } from 'react-redux';
import { accountTypes } from 'utils';

const RequestDetails = (props) => {
  const navigate = useNavigate();
  const accountType = useSelector((state) => state?.userData?.fetchingReducer?.data?.account_type);
  const { newList, details: stateDetails, noBottomBtns, newRequest } = useLocation()?.state ?? {};
  const [details, setDetails] = useState(stateDetails);
  const errorToast = useToastContextWithError();
  const requestId = useParams().request_id;
  const isApplicant = accountType === 3;

  const getRequestDetails = useCallback(async (requestId, accountType) => {
    if (accountType in accountTypes) {
      const url = accountTypes[accountType](requestId);
      const res = await customAuthGet(url);
      if (res?.id) {
        setDetails(res);
      } else {
        errorToast('حدث خطأ ما، يرجى المحاولة مرة أخرى');
      }
    } else {
      errorToast('حدث خطأ ما، يرجى المحاولة مرة أخرى');
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!details) {
      // should fetch data and set it to details state
      getRequestDetails(requestId, accountType);
    }
    // eslint-disable-next-line
  }, [details, requestId]);
  const [modal, setModal] = useState(false);
  const [isCommentEmpty, setIsCommentEmpty] = useState(false);
  const [popUpText, setPopUpText] = useState({ title: '', subTitle: '', buttonText: '' });
  const auditorComment = useRef('');

  const toggleModal = () => setModal((prev) => !prev);

  const assign = async () => {
    try {
      await assignRequest(requestId);
      navigate('.', { state: { newList: false, details } });
      message.success({
        type: 'success',
        content: `تم إسناد الطلب بنجاح`,
        duration: 2,
      });
    } catch (error) {
      message.error({
        type: 'error',
        content: `فشل في إسناد الطلب`,
        duration: 2,
      });
    }
  };

  const accept = async (isAdminAccount) => {
    try {
      isAdminAccount
        ? await manageRequestAdmin(
            {
              status: 5,
            },
            requestId
          )
        : await manageRequest(
            {
              status: 2,
            },
            requestId
          );
      accountType === 1 ? navigate('/application-request') : navigate('/application-list');
      message.success({
        type: 'success',
        content: `تم قبول الطلب بنجاح`,
        duration: 2,
      });
    } catch (error) {
      message.error({
        type: 'error',
        content: `فشل في قبول الطلب`,
        duration: 2,
      });
    }
  };

  const reject = async () => {
    onReturnOrReject(auditorComment.current.trim());
    if (auditorComment.current.trim() === '') return;
    try {
      accountType === 1
        ? await manageRequestAdmin(
            {
              status: 4,
              auditor_comment: auditorComment.current,
            },
            requestId
          )
        : await manageRequest(
            {
              status: 4,
              auditor_comment: auditorComment.current,
            },
            requestId
          );
      accountType === 1 ? navigate('/application-request') : navigate('/application-list');
      message.success({
        type: 'success',
        content: `تم رفض الطلب بنجاح`,
        duration: 2,
      });
    } catch (error) {
      message.error({
        type: 'error',
        content: `فشل في رفض الطلب`,
        duration: 2,
      });
    }
  };

  const again = async () => {
    onReturnOrReject(auditorComment.current.trim());
    if (auditorComment.current.trim() === '') return;
    try {
      accountType === 1
        ? await manageRequestAdmin(
            {
              status: 3,
              auditor_comment: auditorComment.current,
            },
            requestId
          )
        : await manageRequest(
            {
              status: 3,
              auditor_comment: auditorComment.current,
            },
            requestId
          );
      accountType === 1 ? navigate('/application-request') : navigate('/application-list');
      message.success({
        type: 'success',
        content: `تم إعادة الطلب بنجاح`,
        duration: 2,
      });
    } catch (error) {
      message.error({
        type: 'error',
        content: `فشل في إعادة الطلب`,
        duration: 2,
      });
    }
  };

  const rejectModal = () => {
    setPopUpText({ title: 'أسباب رفض الطلب', subTitle: 'الرجاء كتابة أسباب رفض الطلب', buttonText: 'رفض الطلب' });
    toggleModal();
  };
  const againModal = () => {
    setPopUpText({ title: 'الملاحظات و التعديلات', subTitle: 'الرجاء كتابة الملاحظات و التعديلات المطلوبة', buttonText: 'إعادة الطلب' });
    toggleModal();
  };
  const onReturnOrReject = (comment) => {
    if (comment === '') setIsCommentEmpty(true);
    else setIsCommentEmpty(false);
  };
  return (
    <div>
      <div className={`request-container ${isApplicant ? 'request-applicant-container' : ''}`}>
        <div className='request-header-link'>
          <div className='underline pointer' onClick={() => navigate(-1)}>
            قائمة الطلبات <i className='arrow_ left'></i>
          </div>
          <p href='/'>تفاصيل الطلب</p>
        </div>
        {modal && (
          <div className='modal z-100'>
            <div onClick={toggleModal} className='overlay' />
            <div className='modal-content modal-flex'>
              <button onClick={toggleModal} className='icone-close-popup modal-close-icon'>
                <img src={iconeClosePopup} alt='' width='35%' />
              </button>
              <p className='header-popup'>{popUpText.title}</p>
              <p className='sub-title-popup'>{popUpText.subTitle}</p>
              <textarea
                cols='30'
                rows='7'
                className='modal-textarea'
                onChange={(e) => (auditorComment.current = e.target.value)}
              ></textarea>
              {isCommentEmpty && <p style={{ color: '#b41515' }}>حقل مطلوب</p>}
              <div className='flex modal-btns'>
                <button
                  className='close_popup'
                  onClick={() => {
                    popUpText.buttonText === 'رفض الطلب' ? reject() : popUpText.buttonText === 'إعادة الطلب' && again();
                  }}
                >
                  {popUpText.buttonText}
                </button>
                <button type='button' className='cancel_close' onClick={toggleModal}>
                  رجوع
                </button>
              </div>
            </div>
          </div>
        )}
        {newRequest && (
          <div className='header-message'>
            <div className=' header-message-title'>
              <p> تم إرسال الطلب بنجاح</p>
            </div>
            <div className='header-message-sub'>
              <p>سيتم معالجة طلبك والتحقق من صحة البيانات المقدمة</p>
            </div>
          </div>
        )}
        <OrderDetails
          applicantDetailsData={details}
          referenceBtn={newList}
          bottomBtns={false}
          accept={accept}
          rejectModal={rejectModal}
          againModal={againModal}
          assign={assign}
          noBottomBtns={noBottomBtns}
          isAdmin={accountType === 1}
          isApplicant={isApplicant}
        />
      </div>
    </div>
  );
};

export default RequestDetails;
