import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import momentHijri from 'moment-hijri';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SERVICE_TYPE, SERVICE_DETAILS_ADMIN, INSTITUTION_DEFINE } from '../../ServiceProvider/constants';
import IconBrowseFile from '../../../markup/images/iconBrowseFile.svg';
import './index.scss';
import { customAuthGet } from 'services/axios/api';
import { useToastContextWithError } from 'contexts/RootContext';

export default function ServiceProviderDetails() {
  const state = useLocation()?.state ?? {};
  const { details: stateDetails } = state ?? {};
  const id = useParams()?.id;
  const [details, setDetails] = useState(stateDetails);
  const errorToast = useToastContextWithError();
  const getRequestDetails = useCallback(async (requestId) => {
    const url = `/service-provider/${requestId}/`;
    // const url = `/student/requests/admin/${requestId}/retrieve/`;
    const res = await customAuthGet(url);
    if (res?.id) {
      setDetails(res);
    } else {
      errorToast('حدث خطأ ما، يرجى المحاولة مرة أخرى');
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!details) {
      // should fetch data and set it to details state
      getRequestDetails(id);
    }
    // eslint-disable-next-line
  }, [details, id]);
  const navigate = useNavigate();

  return (
    <div className='request-container'>
      <div className='request-header-link'>
        <div className='underline-admin pointer' onClick={() => navigate(-1)}>
          مقدمي الخدمات<i className='arrow_ left'></i>
        </div>
        <p href='/'>تفاصيل الطلب</p>
      </div>

      <div className='orders-details'>
        {/* START FIRST SECTION */}
        <div className='details-header-admin '>
          <p>تفاصيل الطلب</p>
        </div>
        <>
          <div className='grid-container-admin-first  '>
            <div className='content-small-admin'>
              الرقم <p> {details?.id}</p>
            </div>
            <div className='content-small-admin'>
              تاريخ التقديم <p>{moment(details?.service?.created_at).format('YYYY/MM/DD')}</p>
            </div>
          </div>
          {/* END FIRST SECTION */}
          <div className='details_line-admin' />
          {/* START SECOND SECTION */}
          <div className='details-header-admin '>
            <p> معلومات المنشأة</p>
          </div>
          <div className='grid-container-admin '>
            <div className='content-small-admin'>
              اسم المنشأة<p>{details?.establishment_info?.establishment_name}</p>
            </div>
            <div className='content-small-admin'>
              مقر المنشأة<p>{details?.establishment_info?.establishment_region_location_name}</p>
            </div>
            <div className='content-small-admin'>
              نوع القطاع
              <p>{details?.establishment_info?.sector_type}</p>
            </div>
            <div className='content-small-admin'>
              تعريف المنشأة
              <p>
                {INSTITUTION_DEFINE.filter((x) => x.value === details?.establishment_info.establishment_definition).map((x) => x.ar_label)}
              </p>
            </div>
            <div className='content-small-admin'>
              رقم تعريف المنشأة<p>{details?.establishment_info?.establishment_definition_identification}</p>
            </div>
            <div className='content-small-admin'>
              ترخيص أهلية المنشأة
              <p className='underline-admin'>
                <a
                  className='underline-admin'
                  href={details?.establishment_info?.establishment_eligibility_license}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {/* eslint-disable-next-line */}
                  عرض ترخيص أهلية المنشأة
                  <img src={IconBrowseFile} alt='' className='Icon-Browse-File-admin' />
                </a>
              </p>
            </div>
          </div>
          {/* END SECOND SECTION */}
          <div className='details_line-admin' />
          {/* START 3 SECTION */}
          <div className='details-header-admin '>
            <p> معلومات ممثل المنشأة</p>
          </div>
          <div className='grid-container-admin '>
            <div className='content-small-admin'>
              الاسم الثلاثي <p> {details?.establishment_representative_info?.full_name}</p>
            </div>
            <div className='content-small-admin'>
              الهوية الوطنية <p> {details?.establishment_representative_info?.national_id}</p>
            </div>
            <div className='content-small-admin'>
              رقم الجوال <p> {details?.establishment_representative_info?.phone}</p>
            </div>
            <div className='content-small-admin'>
              رقم إضافي <p> {details?.establishment_representative_info?.additional_phone}</p>
            </div>
            <div className='content-small-admin'>
              البريد الإلكتروني
              <p className='email-size'>{details?.establishment_representative_info?.email}</p>
            </div>
            <div className='content-small-admin'>
              تفويض ممثل المنشأة
              <p className='underline-admin'>
                <a
                  className='underline-admin'
                  href={details?.establishment_representative_info?.Authorization_of_the_establishment_representative}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {/* eslint-disable-next-line */}
                  عرض تفويض ممثل المنشأة
                  <img src={IconBrowseFile} alt='' className='Icon-Browse-File-admin' />
                </a>
              </p>
            </div>
          </div>
          {/* END 3 SECTION */}
          <div className='details_line-admin' />
          {/* START 4 SECTION */}
          <div className='details-header-admin '>
            <p> معلومات الخدمة</p>
          </div>

          <div className='grid-container-admin-info  '>
            <div className='content-small-admin'>
              نوع الخدمة
              <p>{SERVICE_TYPE.filter((x) => x.value === details?.service_info?.service_type).map((x) => x.ar_label)}</p>
            </div>

            <div className='content-small-admin'>
              تفاصيل الخدمة
              <p>
                {details?.service_info?.service_type === 5
                  ? details?.service_info?.service_details_other
                  : SERVICE_DETAILS_ADMIN.filter((x) => x.value === details?.service_info?.service_details).map((x) => x.ar_label)}
              </p>
            </div>

            <div className='content-small-admin'>
              التاريخ المتوقع لبدء الخدمة
              <p>{momentHijri(details?.service_info?.expected_start_date.replaceAll('-', '/')).format('iYYYY/iM/iD')}</p>
            </div>
            <div className='content-small-admin'>
              عدد المستفيدين من الخدمة <p>{details?.service_info?.number_of_service_beneficiaries}</p>
            </div>
            <div className='content-small-admin'>
              الدعم المادي -ان وجد- <p>{details?.service_info?.financial_support}</p>
            </div>
          </div>
          <div className='content-small-admin-description '>
            وصف الخدمة المقدمة <p>{details?.service_info?.provided_service_description}</p>
          </div>
          {/* END 4 SECTION */}

          <div className='details_line-admin' />
          {/* START 6 SECTION */}
          <div className='details-header-admin '>
            <p> المرفقات الاضافية</p>
          </div>
          {details?.additional_attachments?.length > 0 ? (
            <>
              <div className='attachment-sub__title'>
                <p> مرفقات داعمة</p>
              </div>
              <div className='grid-container-admin-attachment'>
                {details?.additional_attachments?.[0] && (
                  <div className='grid-container-admin-attachment-child'>
                    {details?.additional_attachments?.[0].name}
                    <p className='underline-admin'>
                      <a
                        className='underline-admin'
                        href={details?.additional_attachments?.[0].file}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {/* eslint-disable-next-line */}
                        عرض المرفق
                        <img src={IconBrowseFile} alt='' className='Icon-Browse-File-admin' />
                      </a>
                    </p>
                  </div>
                )}

                {details?.additional_attachments?.[1] && (
                  <div className='grid-container-admin-attachment-child'>
                    {details?.additional_attachments?.[1].name}
                    <p className='underline-admin'>
                      <a
                        className='underline-admin'
                        href={details?.additional_attachments?.[1].file}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {/* eslint-disable-next-line */}
                        عرض المرفق
                        <img src={IconBrowseFile} alt='' className='Icon-Browse-File-admin' />
                      </a>
                    </p>
                  </div>
                )}
                {details?.additional_attachments?.[2] && (
                  <div className='grid-container-admin-attachment-child'>
                    {details?.additional_attachments?.[2].name}
                    <p className='underline-admin'>
                      <a
                        className='underline-admin'
                        href={details?.additional_attachments?.[2].file}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {/* eslint-disable-next-line */}
                        عرض المرفق
                        <img src={IconBrowseFile} alt='' className='Icon-Browse-File-admin' />
                      </a>
                    </p>
                  </div>
                )}

                {details?.additional_attachments?.[3] && (
                  <div className='grid-container-admin-attachment-child'>
                    {details?.additional_attachments?.[3].name}
                    <p className='underline-admin'>
                      <a
                        className='underline-admin'
                        href={details?.additional_attachments?.[3].file}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {/* eslint-disable-next-line */}
                        عرض المرفق
                        <img src={IconBrowseFile} alt='' className='Icon-Browse-File-admin' />
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className='attachment-sub__title'>
              <p> لا يوجد</p>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
