import { createContext, useState, useEffect } from 'react';
import InstitutionInfo from '../Components/Institution-Info';
import InstitutionRepresntitive from '../Components/InstitutionRepresntitive';
import ServiceInfo from '../Components/ServiceInfo';
import ExtraAttachments from '../Components/ExtraAttachments';
import { getUserData } from '../../../services/axios/api';

const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({
    // معلومات المنشأة
    institutionName: '',
    institutionLocation: '',
    institutionType: '',
    institutionDefine: '',
    ID_Type: '',
    InstID: '',
    institution_certificate: '',

    // ممثل المنشأة
    fullName: '',
    ID: '',
    mobileNo: '',
    Additional_Number: '',
    email: '',
    representative_certificate: '',

    //معلومات الخدمة
    serviceType: '',
    serviceDetail: '',
    serviceDetailOther: '',
    startDate: '',
    serviceAmount: 0,
    financialSupport: 0,
    serviceDetailText: '',

    // المرفقات الإضافية
    Additional_Certificates: [],
  });
  const getData = async () => {
    try {
      const response = await getUserData();
      const {
        email,
        mobile_number,
        name,
        service_provider: { national_id_number, additional_mobile_number },
      } = response;
      setFormData((prevData) => {
        return {
          ...prevData,
          email,
          mobileNo: mobile_number,
          fullName: name,
          ID: national_id_number,
          Additional_Number: additional_mobile_number,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const formSteps = [
    {
      title: 'الخطوة الأولى',
      description: 'معلومات المنشأة',
      content: <InstitutionInfo />,
    },
    {
      title: 'الخطوة الثانية',
      description: 'معلومات ممثل المنشأة',
      content: <InstitutionRepresntitive />,
    },
    {
      title: 'الخطوة الثالثة',
      description: 'معلومات الخدمة',
      content: <ServiceInfo />,
    },
    {
      title: 'الخطوة الرابعة',
      description: 'مرفقات إضافية',
      content: <ExtraAttachments />,
    },
  ];

  const handleChange = (e) => {
    setCurrent(current + 1);
    for (const key in e) {
      setFormData((prevData) => ({
        ...prevData,
        [key]: e[key],
      }));
    }
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        formSteps,
        current,
        setCurrent,
        handleChange,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
