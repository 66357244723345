import * as Actions from '../action-types';

export const addAdutorFetch = () => ({
  type: Actions.ADD_ADUTOR_FETCH_START,
});

export const addAdutorFetchFinished = () => ({
  type: Actions.ADD_ADUTOR_FETCH_SUCCESS,
});

export const addAdutorFetchFailed = (payload) => ({
  type: Actions.ADD_ADUTOR_FETCH_FAILED,
  payload,
});

export const addAdutor = (payload) => ({
  type: Actions.ADD_ADUTOR,
  payload,
});
