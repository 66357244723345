import React, { useState ,useEffect} from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Card, LogoHeader, Button, InputField, PasswordValidation } from '../../../shared/components'
import { PASSWORD_PATTERN } from '../../../shared/utils/regax'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import authManager from '../../../services/axios/authManager';
import { resetUserData } from 'redux/actions/userData'
import { useDispatch } from 'react-redux'
const AuditorInvitation = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' })
  const [passworError] = useState('')
  const Dispatch = useDispatch();
  const [specialChar, setSpecialChar] = useState('')
  const [number, setNumber] = useState('')
  const [uppercase, setUppercase] = useState('')
  const [lowercase, setLowercase] = useState('')
  const Navigate = useNavigate()
  const { acceptRequest } = props
  const { form_id } = useParams()
  const handleChange = (element) => {
    acceptRequest.auditorInvitationAccept({ form_id, password: element.password, repassword: element.repassword })
  }
  useEffect(() => {
    if (props.acceptInviteSuccess) {
      Navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])
  useEffect(() => {
    authManager.logOut();
    Dispatch(resetUserData());


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='admin__page--background'>
      <LogoHeader noMargin='logo-header_noMargin'/>

      <div className='flex flex--center'>
        <Card title='CREATE_ACCOUNT' subTitle={'CREATE_ACCOUNT_SUB'}>
          <form onSubmit={handleSubmit(handleChange)}
          //  className='form_padding'
           >
            <div className='card__body--wide'>
              <InputField
                name='password'
                title='password'
                placeholder='password'
                type='password'
                error={passworError}
                {...register('password', {
                  validate: {
                    specialChar: (value) =>
                      /[#!@$%^&*]/.test(value)
                        ? setSpecialChar('valid-1')
                        : value.length
                        ? setSpecialChar('invalid-1')
                        : setSpecialChar('default-list'),
                    number: (value) =>
                      /[0-9]/.test(value) ? setNumber('valid-1') : value.length ? setNumber('invalid-1') : setNumber('default-list'),
                    uppercase: (value) =>
                      /[A-Z]/.test(value)
                        ? setUppercase('valid-1')
                        : value.length
                        ? setUppercase('invalid-1')
                        : setUppercase('default-list'),
                    lowercase: (value) =>
                      /[a-z]/.test(value)
                        ? setLowercase('valid-1')
                        : value.length
                        ? setLowercase('invalid-1')
                        : setLowercase('default-list'),
                    eightCharsOrGreater: (value) => PASSWORD_PATTERN.test(value),
                  },
                })}
              />
              <div className='validation-margin'>
                {getValues('password')?.length ? (
                  <PasswordValidation
                    capsLetterFlag={uppercase}
                    smallLetterFlag={lowercase}
                    numberFlag={number}
                    specialCharFlag={specialChar}
                    customStyle={'custom-password-validation'}
                  />
                ) : (
                  <></>
                )}
              </div>
              <InputField
                name='repassword'
                title='CONFIRM_PASSWORD'
                placeholder='password'
                type='password'
                error={errors.confirmPassword && errors.confirmPassword.message}
                {...register('repassword', {
                  required: 'fieldRequired',
                  validate: (value) => value === watch('password') || 'matchPasswordInvalid',
                })}
              />
            </div>

            <div className='card__btn'>
              <Button
                buttonStyle={true ? 'btn--secondary' : 'btn--primary'}
                titleButton='CREATE_ACCOUNT'
                disabled={!isValid}
                // customStyle={"wide_button"}
              />
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default AuditorInvitation
