import React, { useState, useCallback, useEffect, useRef } from 'react';
import './OtpPage.scss';
import { Card, LogoHeader, Toaster, Button } from '../../../shared/components';
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom';
let currentOtpIndex = 0;

function OtpPage(props) {
  const Navigate = useNavigate();
  const location = useLocation();
  const { otpValidationActions } = props;
  const [otp, setOtp] = useState(new Array(5).fill(''));
  const [otpErr, setOtpErr] = useState(false);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [showResults, setShowResults] = useState({ message: 'تم إرسال رمز التحقق إلى البريد الالكتروني', type: 'success' });
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const isProvider = !!+searchParams.get('provider');
  const isApplicant = !!+searchParams.get('applicant');

  //// TIMER ///
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const timeOutCallback = useCallback(() => {
    setMinutes((currTimer) => currTimer - 1);
    setSeconds((currTimer) => currTimer - 1);
  }, []);

  const email = location.state.email;

  useEffect(() => {
    minutes > 0 && seconds > 0 && setTimeout(timeOutCallback, 1000);
  }, [minutes, seconds, timeOutCallback]);
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const handleChange = (element) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === currentOtpIndex ? element.value : d))]);
    //Focus next input
    if (!element.value) {
      setActiveOtpIndex(currentOtpIndex - 1);
      setOtpErr(false);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
      setOtpErr(false);
    }
  };
  const handleKeyDownChange = (e, index) => {
    currentOtpIndex = index;
    if (e.key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
  };
  useEffect(() => {
    !location?.state?.registered && Navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendAnotherOtp = (e) => {
    e.preventDefault();
    if (!seconds && !minutes) {
      setMinutes(1);
      setSeconds(0);
    }
    if (isApplicant) otpValidationActions.requestAnotherOtp();
    if (isProvider) otpValidationActions.requestAnotherOtpServiceProvider();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let joinedOtp = otp.join('');
    if (isApplicant) otpValidationActions.otpValidation({ joinedOtp });
    if (isProvider) otpValidationActions.otpServiceProviderValidation({ joinedOtp });
  };

  useEffect(() => {
    if (props.success) {
      Navigate('/applicant-login', {
        state: {
          registered: true,
          message: 'تم إنشاء حسابك بنجاح',
          type: 'success',
        },
      });
    } else if (props.error) {
      setShowResults({ message: 'رمز التحقق غير صحيح', type: 'warning' });
      setOtpErr(true);
    }

    if (props.anotherOtpSuccess) {
      setShowResults({ message: 'تم إعادة ارسال رمز جديد بنجاح', type: 'success' });
      setOtpErr(false);
    } else if (props.anotherOtpError) {
      setShowResults({ message: 'فشل في إعادة ارسال الرمز', type: 'warning' });
      setOtpErr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <div className='background-full__screen'>
        <div className='secondbg-full__screen'></div>
        <div className='thirdbg-full__screen'></div>
        <div className='fivebg-full__screen'></div>
        <LogoHeader white />

        <div className='otp-body'>
          <Card
            wide='card--wide-2'
            customHeader='card__header__otp'
            title='التحقق'
            subTitle='تم إرسال الرمز إلى البريد الإلكتروني المدخل'
            subTitle2={email}
            toaster={
              showResults.message.length ? <Toaster message={showResults.message} type={showResults.type} /> : <div className='no-toster' />
            }
          >
            <form>
              <div>
                <button className='resend-text' disabled={seconds > 0 || minutes > 0} onClick={(e) => sendAnotherOtp(e)}>
                  اعادة ارسال الرمز
                  {seconds > 0 || minutes > 0 ? (
                    <span>
                      {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  ) : (
                    props.anotherOtpFetching
                  )}
                </button>
                <div className='otp'>
                  <div>
                    <div className='otp-box'>
                      {otp.map((data, index) => {
                        return (
                          <input
                            ref={index === activeOtpIndex ? inputRef : null}
                            className={otpErr ? 'otp-field_error' : 'otp-field'}
                            type='text'
                            name='otp'
                            maxLength='1'
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e.target)}
                            onKeyDown={(e) => handleKeyDownChange(e, index)}
                          />
                        );
                      })}
                    </div>
                    {otpErr && <small className='otp__input__error'>{'عذرًا الرمز المدخل غير صحيح، حاول مرة أخرى'}</small>}
                  </div>
                </div>

                <div className='card__btn'>
                  <Button buttonStyle='btn--primary' titleButton='أنشئ حساب' disabled={otp.includes('')} onClick={(e) => onSubmit(e)} />
                </div>
                <p className='card__desc' style={{ textAlign: 'center' }}>
                  هل لديك حساب؟
                  <Link className='card__desc__link' to={'/applicant-login'}>
                    تسجيل الدخول
                  </Link>
                </p>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
}

export default OtpPage;
