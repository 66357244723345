import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import iconeClosePopup from '../../../markup/images/close-popup.png';
import { message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './AddServices.scss';
import sharedApi from '../../../shared';
import { useTranslation } from 'react-i18next';

const { InputField } = sharedApi.components;

export default function AddServices(props) {
  const { addServicesActions, error } = props;
  const Navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [submited, setSubmited] = useState(false);
  const { t } = useTranslation();

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name_Of_Servic: '',
      Desc_Of_Servic: '',
    },

    mode: 'onChange',
  });

  const onSubmit = (data) => {
    addServicesActions.addServices(data);
    setSubmited(true);
  };

  useEffect(() => {
    if (props.success && submited) {
      // setOtpErr(false)
      Navigate('/services-list');
      setTimeout(() => {
        message.success({
          type: 'success',
          content: 'تمت إضافة الخدمة بنجاح.',
          duration: 2,
        });
      }, 1);
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, submited]);

  return (
    <div className='home-container-2'>
      <div className='request-header-link' style={{ marginTop: '10vh' }}>
        <Link className='underline' href='/services-list'>
          الخدمات<i className='arrow_ left'></i>
        </Link>
        <p href='/'> إضافة خدمة</p>
      </div>
      <div className='orders-details-2'>
        <div className='header-page'>
          <p> إضافة خدمة</p>
        </div>
        <div className='second-header-page'>
          <p>عند إضافة خدمة سيقوم النظام بتحديث قائمة الخدمات في نموذج تقديم طلب الالتحاق</p>
        </div>
        {error && (
          <small style={{ color: '#B41515' }} className='otp__input__error'>
            {t(error)}
          </small>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='container-page'>
            <div className='header-number'></div>
            <div className='sub-filed' style={{ alignItems: 'flex-start', columnGap: 20 }}>
              <InputField
                title='ServiceName'
                type='text'
                name={`name_Of_Servic`}
                // placeholder='name_Of_Servics'
                className_div_input='inputAdditionalCertificates'
                // className={otpErr ? 'add_input_error' : null}
                style={{
                  backgroundColor: 'white',
                  width: '16vw',
                  minWidth: '140px',
                  height: '24px',
                  fontWeight: 'bold',
                  border: '1px solid #6c6c6c44',
                }}
                error={errors.name_Of_Servic && errors.name_Of_Servic.message}
                {...register(`name_Of_Servic`, {
                  required: 'fieldRequired',
                })}
              />
              <InputField
                title='Desc_service'
                type='text'
                // placeholder='Desc_Of_Servics'
                name={`Desc_Of_Servic`}
                className_div_input='inputAdditionalCertificates'
                // className={otpErr ? 'add_input_error' : null}
                style={{
                  backgroundColor: 'white',
                  minWidth: '35vw',
                  width: '100%',
                  height: '24px',
                  fontWeight: 'bold',
                  border: ' 1px solid #6c6c6c44',
                }}
                error={errors.Desc_Of_Servic && errors.Desc_Of_Servic.message}
                {...register(`Desc_Of_Servic`, {
                  required: 'fieldRequired',
                })}
              />
            </div>
          </div>

          <div className='addAuditorBtns btn-sm' style={{ alignSelf: 'flex-end' }}>
            <button className='add-adutor-btn' type='submit' disabled={!isValid}>
              إضافة
              <span style={{ marginRight: '6px' }}>{/* ({fields.length}) */}</span>
            </button>
            <button type='button' className='cancel' onClick={toggleModal}>
              تراجع
            </button>
          </div>

          {modal && (
            <div className='modal'>
              <div onClick={toggleModal} className='overlay'></div>
              <div className='modal-content'>
                <button onClick={toggleModal} className='icone-close-popup'>
                  <img src={iconeClosePopup} alt='' width='35%' />
                </button>
                <p className='header-popup'>هل أنت متأكد </p>
                <p className='sub-title-popup'>لن يقوم النظام بحفظ معلومات الطلب عند مغادرتك الصفحة</p>

                <button
                  className='close_popup'
                  onClick={() => {
                    toggleModal();
                    reset({
                      Adding_Services: [{ name_Of_Servic: '', Desc_Of_Servic: '' }],
                    });
                    Navigate('/services-list');
                  }}
                >
                  مغادرة
                </button>
                <button type='button' className='cancel_close' onClick={toggleModal}>
                  تراجع
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
