import React, { Fragment, useEffect, useState } from 'react';
import ImgEmpty from '../../../markup/images/emptyIcone.svg';
import IconBrowseFile from '../../../markup/images/iconBrowseFile.svg';
import './index.scss';
import moment from 'moment-hijri';
import {
  FEATURES_LIST,
  FORM_STATUS,
  SALARY,
  EDUCATION,
  EMPLOYMENT_STATUS,
  GENDER,
  SOCIAL_STATUS,
  APPLICANT_FORM_STATUS,
  studyDesiresList,
  housingCostList,
  trainingPeriodList,
} from '../../../pages/ApplicantForm/constants';
import { useTranslation } from 'react-i18next';
const OrderDetails = ({
  referenceBtn,
  bottomBtns,
  accept,
  rejectModal,
  againModal,
  referenceBtnAdmin,
  assign,
  applicantDetailsData,
  noBottomBtns,
  isAdmin,
  isApplicant,
}) => {
  const [assignBtns, setAssignBtns] = useState(!referenceBtn);
  const [features, setFeatures] = useState([]);
  const [age, setAge] = useState('');
  const [datehijri, setDatehijri] = useState('');
  const [hasServiceCustomFields, setHasServiceCustomFields] = useState(false);
  const [educationCost, setEducationCost] = useState(false);
  const [housingCost, setHousingCost] = useState(false);
  const [transportationCostsData, setTransportationCostsData] = useState(false);
  const [educationalSuppliesCosts, setEducationalSuppliesCosts] = useState(false);
  const [cooperativeTraining, setCooperativeTraining] = useState(false);
  const [workshopsAndLectures, setWorkshopsAndLectures] = useState(false);
  const [academicAdvising, setAcademicAdvising] = useState(false);
  const notAvailable = 'لا يوجد';
  const notMentioned = 'غير مذكور';
  const StatusFilter = isApplicant ? APPLICANT_FORM_STATUS : FORM_STATUS;
  const { t } = useTranslation();
  useEffect(() => {
    // calculates age based on selected dob
    var today = new Date();
    var birthDate = new Date(applicantDetailsData?.student?.date_of_birth);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    const getBirthDate = moment(applicantDetailsData?.student?.date_of_birth);
    setDatehijri(getBirthDate.format('iYYYY/iM/iD'));
    setAge(age);
  }, [applicantDetailsData?.student?.date_of_birth]);

  const fetchAdditionalProps = () => {
    let featuresList = [];
    for (const key in applicantDetailsData?.info) {
      if (applicantDetailsData?.info[key] === true) {
        featuresList.push(FEATURES_LIST.filter((x) => x.value === key).map((x) => x.ar_label));
      }
    }
    if (featuresList.length === 0) featuresList.push(notAvailable);
    setFeatures(featuresList);
  };

  useEffect(() => {
    fetchAdditionalProps();
    if (applicantDetailsData?.service.some((s) => s.code !== null)) setHasServiceCustomFields(true);
    const codes = applicantDetailsData?.service?.map((serviceObj) => serviceObj.code);
    if (codes.includes('education_cost')) setEducationCost(true);
    if (codes.includes('housing_cost')) setHousingCost(true);
    if (codes.includes('transportation_costs')) setTransportationCostsData(true);
    if (codes.includes('educational_supplies_costs')) setEducationalSuppliesCosts(true);
    if (codes.includes('cooperative_training')) setCooperativeTraining(true);
    if (codes.includes('workshops_and_lectures')) setWorkshopsAndLectures(true);
    if (codes.includes('academic_advising')) setAcademicAdvising(true);
    // eslint-disable-next-line
  }, []);
  let englishLevel = '';
  const englishLevelValue = applicantDetailsData?.education?.english_level;
  if (englishLevelValue === 1) englishLevel = 'مبتدئ';
  if (englishLevelValue === 2) englishLevel = 'متوسط';
  if (englishLevelValue === 3) englishLevel = 'متقدم';

  return (
    <div className='orders-details'>
      {/* START FIRST SECTION */}
      {referenceBtn && !assignBtns && (
        <button
          className='refBtn refBtnPosition'
          onClick={() => {
            setAssignBtns(true);
            assign();
          }}
        >
          إسناد الطلب
        </button>
      )}
      <div className='details-header'>
        <p>تفاصيل الطلب</p>
      </div>
      {applicantDetailsData && (
        <>
          <div className='grid-container grid-container-1'>
            <div className='content-small'>
              الرقم <p> {applicantDetailsData?.id}</p>
            </div>
            <div className='content-small'>
              تاريخ التقديم <p>{moment(applicantDetailsData?.created_at).format('YYYY/MM/DD')}</p>
            </div>
            {isAdmin && (
              <div className='content-small'>
                اسم المدقق
                <p>{applicantDetailsData?.auditor_name || notAvailable}</p>
              </div>
            )}
            <div className='content-small'>
              الحالة
              <span
                className='status-style'
                style={{
                  backgroundColor:
                    applicantDetailsData?.status === 4
                      ? '#F4DBDB'
                      : applicantDetailsData?.status === 3
                      ? '#F4B654'
                      : (applicantDetailsData?.status === 2 && !isApplicant) || applicantDetailsData?.status === 5
                      ? '#D6EAD7'
                      : '#B5D3ED',
                }}
              >
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    color:
                      applicantDetailsData?.status === 4
                        ? '#B41515'
                        : applicantDetailsData?.status === 5 || (applicantDetailsData?.status === 2 && !isApplicant)
                        ? '#005031'
                        : applicantDetailsData?.status === 3
                        ? '#66380F'
                        : '#1B2D58',
                  }}
                >
                  {StatusFilter.filter((x) => x.value === applicantDetailsData?.status).map((x) => x.ar_label)}
                </p>
              </span>
            </div>
          </div>
          <div className='content-small'>
            نوع الخدمة
            <p style={{ display: 'flex', columnGap: 7, flexWrap: 'wrap' }}>
              {applicantDetailsData?.service?.map((item, index) => (
                <span className='spreat-item' key={item.id}>
                  {item.name}
                  {applicantDetailsData?.service?.length - 1 !== index && '، '}
                </span>
              )) || notMentioned}
            </p>
          </div>
          {/* END FIRST SECTION */}
          <div className='details_line' />

          {hasServiceCustomFields && (
            <div className='custom-fields-container'>
              {educationCost && (
                <>
                  <div className='details-header'>
                    <p> حقول خاصة بخدمة الرسوم الدراسية</p>
                  </div>
                  <div className='content-small d-block'>
                    فرصة التعليم{' '}
                    <p> {studyDesiresList.find((obj) => obj.value === applicantDetailsData?.additional_info?.study_desires)?.ar_label}</p>
                  </div>
                </>
              )}
              {housingCost && (
                <>
                  <div className='details-header'>
                    <p> حقول خاصة بخدمة تكاليف السكن</p>
                  </div>
                  <div className='row row-cols-md-3'>
                    <div className='content-small d-block'>
                      ملكية السكن
                      <p>
                        {housingCostList.find((obj) => obj.value === applicantDetailsData?.additional_info?.residential_property)?.ar_label}
                      </p>
                    </div>
                    <div className='content-small d-block'>
                      بعد موقع الإقامة عن المقر التعليمي <p> {applicantDetailsData?.additional_info?.residence_distance}</p>
                    </div>
                    <div className='content-small d-block'>
                      هل يوجد لدى الجهة التعليمية المنتسب لها سكن طلاب/طالبات؟
                      <p> {applicantDetailsData?.additional_info?.is_institution_has_housing ? 'نعم' : 'لا'}</p>
                    </div>
                  </div>
                </>
              )}
              {transportationCostsData && (
                <>
                  <div className='details-header'>
                    <p> حقول خاصة بخدمة تكاليف المواصلات</p>
                  </div>
                  <div className='row row-cols-md-3'>
                    <div className='content-small d-block'>
                      اسم الجهة التعليمية الملتحق بها
                      <p>{applicantDetailsData?.additional_info?.transportation_institution_name}</p>
                    </div>
                    <div className='content-small d-block'>
                      هل تملك وسيلة نقل خاصه بك او أحد افراد اسرتك؟
                      <p> {applicantDetailsData?.additional_info?.has_transportation ? 'نعم' : 'لا'}</p>
                    </div>
                    <div className='content-small d-block'>
                      هل يوجد نقل تعليمي تابع للجهة التعليمية؟
                      <p> {applicantDetailsData?.additional_info?.is_institution_has_transportation ? 'نعم' : 'لا'}</p>
                    </div>
                  </div>
                </>
              )}
              {educationalSuppliesCosts && (
                <>
                  <div className='details-header'>
                    <p> حقول خاصة بخدمة تكاليف المستلزمات التعليمية</p>
                  </div>
                  <div className='row row-cols-md-3'>
                    <div className='content-small d-block'>
                      هل يوجد احتياج كتب؟
                      <p> {applicantDetailsData?.additional_info?.is_books_needed ? 'نعم' : 'لا'}</p>
                    </div>
                    <div className='content-small d-block'>
                      هل يوجد احتياج أجهزة كمبيوتر محمول؟
                      <p> {applicantDetailsData?.additional_info?.is_laptops_needed ? 'نعم' : 'لا'}</p>
                    </div>
                    <div className='content-small d-block'>
                      هل يوجد احتياج زي تعليمي (معاطف وغيرها)؟
                      <p> {applicantDetailsData?.additional_info?.is_uniforms_needed ? 'نعم' : 'لا'}</p>
                    </div>
                    {applicantDetailsData?.additional_info?.is_uniforms_needed && (
                      <div className='content-small d-block'>
                        الاحتياجات
                        <p>{applicantDetailsData?.additional_info?.uniforms_details}</p>
                      </div>
                    )}
                  </div>
                </>
              )}
              {cooperativeTraining && (
                <>
                  <div className='details-header'>
                    <p> حقول خاصة بخدمة التدريب التعاوني </p>
                  </div>
                  <div className='row row-cols-md-3'>
                    <div className='content-small d-block'>
                      اسم الجهة التعليمية الملتحق بها
                      <p>{applicantDetailsData?.additional_info?.train_institution_name}</p>
                    </div>
                    <div className='content-small d-block'>
                      مدة التدريب
                      <p>
                        {trainingPeriodList.find((obj) => obj.value === applicantDetailsData?.additional_info?.training_duration)?.ar_label}
                      </p>
                    </div>
                    <div className='content-small d-block'>
                      الفرص المتاحة
                      <p>{applicantDetailsData?.additional_info?.training_available_opportunitie?.name}</p>
                    </div>
                  </div>
                </>
              )}
              {workshopsAndLectures && (
                <>
                  <div className='details-header'>
                    <p> حقول خاصة بخدمة ورش عمل ومحاضرات </p>
                  </div>
                  <div className='row row-cols-md-3'>
                    <div className='content-small d-block'>
                      الميول المهنية
                      <p>{applicantDetailsData?.additional_info?.professional_inclination}</p>
                    </div>
                    <div className='content-small d-block'>
                      التخصص الجامعي
                      <p>{applicantDetailsData?.additional_info?.university_specialization}</p>
                    </div>
                    <div className='content-small d-block'>
                      مهارات المستقبل
                      <p>{applicantDetailsData?.additional_info?.future_skills?.name}</p>
                    </div>
                    <div className='content-small d-block'>
                      الفرص المتاحة
                      <p>{applicantDetailsData?.additional_info?.workshop_available_opportunitie?.name}</p>
                    </div>
                  </div>
                </>
              )}
              {academicAdvising && (
                <>
                  <div className='details-header'>
                    <p> حقول خاصة بخدمة الإرشاد الأكاديمي </p>
                  </div>
                  <div className='row row-cols-md-3'>
                    <div className='content-small d-block'>
                      اسم الجهة التعليمية الملتحق بها
                      <p>{applicantDetailsData?.additional_info?.academic_institution_name}</p>
                    </div>
                    <div className='content-small d-block'>
                      وصف الصعوبات التي تواجهها
                      <p>{applicantDetailsData?.additional_info?.difficulties_describe}</p>
                    </div>
                  </div>
                </>
              )}
              <div className='details_line' />
            </div>
          )}

          <div className='details-header'>
            <p> المعلومات الشخصية </p>
          </div>
          <div className='container-details'>
            <div className='content-large'>
              <img
                className='avatar-border'
                src={applicantDetailsData.info?.personal_photo_path ? applicantDetailsData.info?.personal_photo_path : ImgEmpty}
                alt=''
                width='124'
                height='124'
              />
            </div>
            <div className='content-small'>
              الإسم الثلاثي <p>{applicantDetailsData.info?.full_name}</p>
            </div>
            <div className='content-small'>
              الهوية الوطنية <p>{applicantDetailsData?.student?.national_id_number}</p>
            </div>
            <div className='content-small'>
              الحالة الإجتماعية
              <p>{SOCIAL_STATUS.filter((x) => x.value === applicantDetailsData.info?.social_status).map((x) => x.ar_label)}</p>
            </div>
            <div className='content-small'>
              تاريخ الميلاد <p>{datehijri}</p>
            </div>
            <div className='content-small'>
              العمر <p>{age}</p>
            </div>
            <div className='content-small'>
              الجنس <p>{GENDER.filter((x) => x.value === applicantDetailsData.info?.gender).map((x) => x.ar_label)}</p>
            </div>
            <div className='content-small'>
              عدد أفراد الأسرة <p>{applicantDetailsData?.info?.family_members}</p>
            </div>
            <div className='content-small'>
              رقم الجوال <p>{applicantDetailsData.student?.user?.mobile_number}</p>
            </div>
            <div className='content-small'>
              البريد الإلكتروني <p>{applicantDetailsData.student?.user?.email}</p>
            </div>
            <div className='content-small'>
              المواصفات الإضافية
              <p>
                {!features?.length
                  ? notAvailable
                  : features.map((item) => (
                      <span className='spreat-item' key={item}>
                        {item}
                      </span>
                    ))}
              </p>
            </div>
            <div className='content-small'>
              مشهد الضمان الاجتماعي
              <p className='underline'>
                <a
                  className='underline'
                  href={applicantDetailsData?.info?.social_security_attachment}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  عرض المرفق
                  <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                </a>
              </p>
            </div>
          </div>
          {/* END SECOND SECTION */}
          <div className='details_line' />
          {/* START 3 SECTION */}
          <div className='details-header'>
            <p> {t('homeAddress')}</p>
          </div>
          <div className='grid-container grid-container-2'>
            <div className='content-small'>
              المنطقة <p> {applicantDetailsData.contacts?.region_name}</p>
            </div>
            <div className='content-small'>
              المدينة <p> {applicantDetailsData.contacts?.city_name}</p>
            </div>
            <div className='content-small'>
              الحي <p> {applicantDetailsData.contacts?.Neighborhood}</p>
            </div>
            <div className='content-small'>
              الشارع <p> {applicantDetailsData.contacts?.street}</p>
            </div>
            <div className='content-small'>
              الرمز البريدي <p> {applicantDetailsData.contacts?.postal_code || notAvailable}</p>
            </div>
          </div>
          {/* END 3 SECTION */}

          <div className='details_line' />
          {/* START 4 SECTION */}
          <div className='details-header'>
            <p> المؤهل التعليمي</p>
          </div>

          <div className='grid-container'>
            <div className='content-small'>
              المؤهل التعليمي
              <p>{EDUCATION.filter((x) => x.value === applicantDetailsData.education?.last_qualification_degree).map((x) => x.ar_label)}</p>
            </div>
            <div className='content-small'>
              سنة التخرج <p> {applicantDetailsData?.education?.graduation_year}</p>
            </div>
            <div className='content-small'>
              نظام المعدل <p className='gpa_spacing'>{applicantDetailsData?.education?.gpa_system}&nbsp;GPA</p>
            </div>
            <div className='content-small'>
              درجة المعدل{' '}
              <p>
                {applicantDetailsData?.education?.gpa}
                {applicantDetailsData?.education?.gpa_system == 100 && '%'}
              </p>
            </div>

            <div className='content-small'>
              درجة القياس <p> {applicantDetailsData?.education?.alqudurat_Degree}%</p>
            </div>
            <div className='content-small'>
              درجة التحصيلي <p>{applicantDetailsData?.education?.tahsseli_score}%</p>
            </div>
            <div className='content-small'>
              الدرجة الموزونة <p>{applicantDetailsData?.education?.uniform_mark}%</p>
            </div>
            <div className='content-small'>
              مستوى اللغة الانجليزية <p>{englishLevel}</p>
            </div>
            <div className='content-small'>
              وثيقة التخرج
              <p className='underline'>
                <a
                  className='underline'
                  href={applicantDetailsData?.education?.graduation_document}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  عرض المرفق
                  <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                </a>
              </p>
            </div>
            <div className='content-small'>
              وثيقة القدرات
              <p className='underline'>
                <a
                  className='underline'
                  href={applicantDetailsData?.education?.alqudurat_document}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  عرض المرفق
                  <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                </a>
              </p>
            </div>
            <div className='content-small'>
              وثيقة التحصيلي
              <p className='underline'>
                <a
                  className='underline'
                  href={applicantDetailsData?.education?.tahsseli_document}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  عرض المرفق
                  <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                </a>
              </p>
            </div>
          </div>
          {/* END 4 SECTION */}
          <div className='details_line' />
          {/* START 5 SECTION */}
          <div className='details-header'>
            <p> الخبرات العملية </p>
          </div>
          <div className='grid-container grid-container-3'>
            <div className='content-small'>
              الحالة الوظيفية
              <p>
                {applicantDetailsData.financial?.current_employment_status
                  ? EMPLOYMENT_STATUS.filter((x) => x.value === applicantDetailsData.financial?.current_employment_status).map(
                      (x) => x.ar_label
                    )
                  : notAvailable}
              </p>
            </div>
            {applicantDetailsData.financial?.current_employment_status !== 1 ? (
              <>
                <div className='content-small'>
                  المرتب الشهري
                  <p>
                    {applicantDetailsData.financial?.salary_optional !== 4
                      ? applicantDetailsData.financial?.salary_optional
                        ? SALARY.filter((x) => x.value === applicantDetailsData.financial?.salary_optional).map((x) => x.ar_label)
                        : notAvailable
                      : applicantDetailsData.financial?.salary
                      ? applicantDetailsData.financial?.salary
                      : notMentioned}
                  </p>
                </div>
                <div className='content-small'>
                  اسم الجهة
                  <p>{applicantDetailsData.financial?.name_volunteering || notAvailable}</p>
                </div>
              </>
            ) : (
              <>
                <div className='content-small'>
                  اسم الجهة
                  <p>{applicantDetailsData.financial?.name_volunteering || notAvailable}</p>
                </div>
                <div className='content-small'>
                  عدد الساعات
                  <p>{applicantDetailsData.financial?.hours_volunteering || notAvailable}</p>
                </div>
              </>
            )}

            <div className='content-small'>
              المهارات المرفقة
              {applicantDetailsData.financial?.skill_certificates ? (
                <p className='underline'>
                  <a
                    className='underline'
                    href={applicantDetailsData?.financial?.skill_certificates}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    عرض المرفق
                    <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                  </a>
                </p>
              ) : (
                <p>{notAvailable}</p>
              )}
            </div>
          </div>
          {/* END 5 SECTION */}
          <div className='details_line' />
          {/* START 6 SECTION */}
          <div className='details-header'>
            <p> الشهادات الإضافية </p>
          </div>
          <div className='application-sub__content'>
            <p> الدورات/ البرامج</p>
          </div>
          {applicantDetailsData?.courses.length > 0 ? (
            <div className='grid-container-last'>
              <div className='content-small-last'>
                {applicantDetailsData.courses?.[0]?.title_Course}
                <p className='underline ms-2'>
                  <a className='underline' href={applicantDetailsData.courses?.[0]?.certificates} target='_blank' rel='noopener noreferrer'>
                    عرض المرفق
                    <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                  </a>
                </p>
              </div>

              {applicantDetailsData.courses?.[1] && (
                <div className='content-small-last'>
                  {applicantDetailsData.courses?.[1]?.title_Course}
                  <p className='underline ms-2'>
                    <a
                      className='underline'
                      href={applicantDetailsData.courses?.[1]?.certificates}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      عرض المرفق
                      <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                    </a>
                  </p>
                </div>
              )}
              {applicantDetailsData.courses?.[2] && (
                <div className='content-small-last'>
                  {applicantDetailsData.courses?.[2]?.title_Course}
                  <p className='underline ms-2'>
                    <a
                      className='underline'
                      href={applicantDetailsData.courses?.[2]?.certificates}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      عرض المرفق
                      <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                    </a>
                  </p>
                </div>
              )}

              {applicantDetailsData.courses?.[3] && (
                <div className='content-small-last'>
                  {applicantDetailsData.courses?.[3].title_Course}
                  <p className='underline ms-2'>
                    <a
                      className='underline'
                      href={applicantDetailsData.courses?.[3]?.certificates}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      عرض المرفق
                      <img src={IconBrowseFile} alt='' className='Icon-Browse-File' />
                    </a>
                  </p>
                </div>
              )}
            </div>
          ) : (
            notAvailable
          )}
          {applicantDetailsData?.comments?.length > 0 && (
            <Fragment>
              <div className='details_line' />
              <div className='details-header'>
                <p>أسباب تعديل/رفض الطلب</p>
              </div>
              <div className='rejection-reason' style={{ color: '#1b223c' }}>
                {applicantDetailsData?.comments?.map((obj) => (
                  <div className='mb-3'>
                    <div className='font-bold'>
                      {moment(obj.created_at).format('Do/MM/YYYY')} - <span>{obj.status === 3 ? 'إعادة الطلب للتعديل ' : 'رفض '}</span>
                    </div>
                    <span> {obj.comment}</span>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
        </>
      )}

      {!referenceBtnAdmin &&
        ((isAdmin && applicantDetailsData?.status === 2) || (!isAdmin && applicantDetailsData?.status === 1)) &&
        !noBottomBtns && (
          <>
            <div className='details_line' />
            <div className={`bottomBtns position-unset`}>
              <button
                className={`bottomBtn acceptBtn ${(bottomBtns || assignBtns) && 'activeAcceptBtn'}`}
                disabled={!(bottomBtns || assignBtns)}
                onClick={() => (isAdmin ? accept(true) : accept(false))}
              >
                {isAdmin ? 'قبول نهائي' : 'قبول'}
              </button>
              <button
                className={`bottomBtn ${(bottomBtns || assignBtns) && 'activeBtn'}`}
                disabled={!(bottomBtns || assignBtns)}
                onClick={rejectModal}
              >
                رفض
              </button>
              <button
                className={`bottomBtn ${(bottomBtns || assignBtns) && 'activeBtn'}`}
                disabled={!(bottomBtns || assignBtns)}
                onClick={againModal}
              >
                إعادة الطلب
              </button>
            </div>
          </>
        )}
      {referenceBtnAdmin && !noBottomBtns && (
        <>
          <div className='details_line' />
          <div className='bottomBtns'>
            <button className='bottomBtn acceptBtn activeAcceptBtn' onClick={accept}>
              قبول
            </button>
            <button className='bottomBtn activeBtn' onClick={rejectModal}>
              رفض
            </button>
            <button className='bottomBtn activeBtn' onClick={againModal}>
              إعادة الطلب
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
