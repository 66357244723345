import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetState } from '../../redux/actions/userData';

function CleanState({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState());
    // eslint-disable-next-line
  }, [location.pathname]);

  return <>{children}</>;
}

export default CleanState;
