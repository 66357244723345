// export { default as ResetPasswordAdmin } from './ResetPasswordAdmin'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import ChangePassword from'./ChangePassword'
import * as changePasswordSelectors from '../../../redux/selectors/changePassword';
import * as changePasswordActions from '../../../redux/actions/changePassword';

const mapStateToProps = () =>
  createStructuredSelector({
    // fetching: resetPassworAdminApi.resetAdminApi.resetSelectors.getResetFetching,
    fetching: changePasswordSelectors.getChangePasswordFetching,
    error: changePasswordSelectors.getChangePasswordError,
    success: changePasswordSelectors.getChangePasswordSuccess,
  })

const mapDispatchToProps = (dispatch) => ({
    changePasswordActions: bindActionCreators(changePasswordActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(ChangePassword)
