import React, { Fragment, useEffect, useState } from 'react';
import './AdditionalCertificates.scss';
import { useFieldArray } from 'react-hook-form';
import moment from 'moment-hijri';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { resubmitApplication } from '../../../../../services/axios/api';
import RemoveIcon from '../../../../../markup/images/remove-icon.svg';
import sharedApi from '../../../../../shared';
import useFormContext from '../../../Context/useFormContext';
import { useWindowSize } from '@react-hook/window-size';
import { Row } from './styles';
import { ENGLISH_LEVELS } from '../../../constants';

function AdditionalCertificates(props) {
  const { mobileNumber, email, fullname, socialStatus, familyMembers, gender, additionalMobileNumber, codes } = props;
  const { errorFormSubmission, successFormSubmission, formSubmission, formData, setCurrent, current, responseData, details } =
    useFormContext();
  const [courses] = useState(details?.courses);
  const id = useParams()?.id;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setBackendErrorList] = useState();
  const Navigate = useNavigate();
  const [, setUrl] = useState('');
  const { InputField, CertificatesUpload, Checkbox, SelectField } = sharedApi.components;
  const [width] = useWindowSize();
  const { t } = useTranslation();
  function isValidHttpUrl(s) {
    try {
      setUrl(new URL(s).href);
    } catch (e) {
      return true;
    }
  }
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: 'onChange', defaultValues: { firstDisclaimer: false } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Additional_Certificates',
  });

  useEffect(() => setValue('english_level', formData['english_level'], { shouldValidate: true }), []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const FormDataBody = new FormData();
    let gradYear = formData.GraduateYear;
    try {
      if (gradYear < 2000) {
        gradYear = moment(String(gradYear).toString(), 'iYYYY').format('YYYY');
      }
    } catch (e) {
      console.log(e);
    }

    // Additional Fields
    if (codes.includes('education_cost')) FormDataBody.append('additional_infos.study_desires', formData.studyDesires);
    if (codes.includes('housing_cost')) {
      FormDataBody.append('additional_infos.residential_property', formData.housingCost);
      FormDataBody.append('additional_infos.residence_distance', formData.locationDistance);
      FormDataBody.append('additional_infos.is_institution_has_housing', formData.studentsHousing);
    }
    if (codes.includes('transportation_costs')) {
      FormDataBody.append('additional_infos.transportation_institution_name', formData.transportation_entityName);
      FormDataBody.append('additional_infos.has_transportation', formData.transportationAvailability);
      FormDataBody.append('additional_infos.is_institution_has_transportation', formData.transportationService);
    }
    if (codes.includes('educational_supplies_costs')) {
      FormDataBody.append('additional_infos.is_books_needed', formData.booksNeed);
      FormDataBody.append('additional_infos.is_laptops_needed', formData.laptopNeed);
      FormDataBody.append('additional_infos.is_uniforms_needed', formData.uniformNeed);
      FormDataBody.append('additional_infos.uniforms_details', formData.uniformNeedDetails);
    }
    if (codes.includes('cooperative_training')) {
      FormDataBody.append('additional_infos.train_institution_name', formData.cooperativeTraining_entityName);
      FormDataBody.append('additional_infos.training_duration', formData.trainingPeriod);
      FormDataBody.append('additional_infos.training_available_opportunitie', formData.cooperativeTraining_availableOpportunities);
    }
    if (codes.includes('workshops_and_lectures')) {
      FormDataBody.append('additional_infos.professional_inclination', formData.careerInclinations);
      FormDataBody.append('additional_infos.university_specialization', formData.universityMajor);
      FormDataBody.append('additional_infos.future_skills', formData.futureSkills);
      FormDataBody.append('additional_infos.workshop_available_opportunitie', formData.workshopsAndLectures_availableOpportunities);
    }
    if (codes.includes('academic_advising')) {
      FormDataBody.append('additional_infos.academic_institution_name', formData.academicAdvising_entityName);
      FormDataBody.append('additional_infos.difficulties_describe', formData.difficulties);
    }

    //education
    FormDataBody.append('education.last_qualification_degree', formData.The_last_qualification_degree);
    FormDataBody.append('education.uniform_mark', formData.uniform_mark);
    FormDataBody.append('education.english_level', data.english_level);
    FormDataBody.append('education.graduation_year', gradYear);
    FormDataBody.append('education.gpa_system', formData.GPA_System);
    FormDataBody.append('education.gpa', formData.GPA);
    FormDataBody.append('education.alqudurat_Degree', formData.Alqudurat_Degree);
    FormDataBody.append('education.tahsseli_score', formData.Tahsseli_Degree);

    if (isValidHttpUrl(formData.Copy_of_The_Latest_academic_qualification)) {
      FormDataBody.append('education.graduation_document', formData.Copy_of_The_Latest_academic_qualification);
    }
    if (isValidHttpUrl(formData.Copy_of_qudurat_certificate)) {
      FormDataBody.append('education.alqudurat_document', formData.Copy_of_qudurat_certificate);
    }
    if (isValidHttpUrl(formData.Copy_of_tahsseli_certificate)) {
      FormDataBody.append('education.tahsseli_document', formData.Copy_of_tahsseli_certificate);
    }

    //financial
    if (formData.employmentStatus !== 0) {
      FormDataBody.append('financial.current_employment_status', formData.employmentStatus);
    }

    if (formData.salary !== '') {
      FormDataBody.append('financial.salary', formData.salary);
    }
    if (formData.salary_optional !== '') {
      FormDataBody.append('financial.salary_optional', formData.salary_optional);
    }
    if (formData.Entity_name !== '') {
      FormDataBody.append('financial.name_volunteering', formData.Entity_name);
    }
    if (formData.Number_of_hours !== '') {
      FormDataBody.append('financial.hours_volunteering', formData.Number_of_hours);
    }
    // eslint-disable-next-line
    {
      isValidHttpUrl(formData.Attach_experiences_certificates) &&
        formData.Attach_experiences_certificates !== undefined &&
        FormDataBody.append('financial.skill_certificates', formData.Attach_experiences_certificates);
    }
    //contacts
    FormDataBody.append('contacts.city', formData.city);
    FormDataBody.append('contacts.region', formData.region);
    FormDataBody.append('contacts.Neighborhood', formData.neighborhood);
    FormDataBody.append('contacts.street', formData.street_name);
    FormDataBody.append('contacts.postal_code', formData.postalCode);
    FormDataBody.append('contacts.address_additional_number', formData.address_additional_number);
    //info
    FormDataBody.append('info.social_needs', formData.handiCapped);
    FormDataBody.append('info.orphan', formData.orphan);
    FormDataBody.append('info.breadwinner', formData.familyBbreadWinner);
    FormDataBody.append('info.single_family', formData.singleChild);
    if (formData.personalPhoto !== undefined) {
      FormDataBody.append('info.personal_photo_path', formData.personalPhoto);
    }
    if (isValidHttpUrl(formData.Attach_of_Social_Security)) {
      FormDataBody.append('info.social_security_attachment', formData.Attach_of_Social_Security);
    }

    FormDataBody.append('contacts.additional_number', additionalMobileNumber);
    FormDataBody.append('info.family_members', familyMembers);
    FormDataBody.append('info.full_name', fullname);
    FormDataBody.append('info.social_status', socialStatus);
    FormDataBody.append('info.gender', gender);
    FormDataBody.append('info.user.email', email);
    FormDataBody.append('info.user.mobile_number', mobileNumber);
    //service
    for (const key in formData.services) {
      FormDataBody.append(`services[${parseInt(key)}]`, formData.services[parseInt(key)]);
    }
    //course
    for (const key in data.Additional_Certificates) {
      FormDataBody.append(`courses[${parseInt(key)}]title_Course`, data.Additional_Certificates[parseInt(key)].name_Of_Certificate);
    }
    for (const key in data.Additional_Certificates) {
      FormDataBody.append(`courses[${parseInt(key)}]certificates`, data.Additional_Certificates[parseInt(key)].Certification_of_Completion);
    }
    if (details) {
      try {
        const data = await resubmitApplication(details.id, FormDataBody);
        setIsSubmitting(false);
        Navigate(`/request-details/${data.id}`, { state: { newRequest: true, details: details, noBottomBtns: true } });
      } catch (error) {
        setBackendErrorList(error.response);
      }
    } else {
      formSubmission.applicantFormSubmission(FormDataBody);
    }
  };
  useEffect(() => {
    if (successFormSubmission) {
      Navigate(`/request-details/${responseData.id}`, { state: { newRequest: true, details: responseData, noBottomBtns: true } });
    }
    if (errorFormSubmission) {
      console.log('errorFormSubmission', errorFormSubmission);
    }
    setIsSubmitting(false);
  }, [successFormSubmission, errorFormSubmission, Navigate, responseData]);

  const handleAppend = () => {
    if (fields.length < 5) {
      append({});
    }
  };
  // refactor
  useEffect(() => {
    if (!!id) {
    } else {
      append({});
    }
  }, [append, id]);

  let canSubmit = isDirty && isValid && !isSubmitting;
  return (
    <>
      <div className='header-app-contact'>
        {width > 767 ? <p className='app-contact-title mb-1'>{t('AdditionalCertificates')}</p> : null}
      </div>
      <form>
        <div className='div-AdditionalCertificates'>
          <div className='header-AdditionalCertificates'>
            <p>{t('Course/training title')}</p>

            <p>{t('attachments')}</p>
          </div>
          <hr className='line-header-AdditionalCertificates'></hr>
          {fields.map(({ id }, index) => (
            <div className='sub-header-AdditionalCertificates' key={id}>
              <InputField
                name={`Additional_Certificates[${index}].name_Of_Certificate`}
                className_div_input='inputAdditionalCertificates'
                className='additionalField'
                maxLength={65}
                error={errors.Additional_Certificates?.[index]?.name_Of_Certificate?.message}
                {...register(`Additional_Certificates[${index}].name_Of_Certificate`)}
              />

              <Controller
                control={control}
                name={`Additional_Certificates[${index}].Certification_of_Completion`}
                error={errors.Additional_Certificates?.[index]?.Certification_of_Completion?.message}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CertificatesUpload allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']} onChange={onChange} maxSize={10} t={t} />
                )}
              />
              <button type='button' onClick={() => remove(index)}>
                <img src={RemoveIcon} alt='' className='remove-row' />
              </button>
            </div>
          ))}
          {courses?.length > 0 ? (
            <Fragment>
              <p style={{ marginTop: '20px' }}>مرفوع سابقاً</p>
              {courses.map((a, index) => (
                <Row>
                  <a href={a.certificates} target='_blank' style={{ fontSize: 12, color: 'darkblue' }} rel='noopener noreferrer'>
                    {'عرض المرفق'}
                  </a>
                </Row>
              ))}
            </Fragment>
          ) : (
            ''
          )}
          <div className='container'>
            <button className='btn-AdditionalCertificates' type='button' onClick={() => handleAppend()}>
              <b style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem', marginLeft: '0.4rem' }}>+</b> أضف شهادة اخرى
            </button>
          </div>
        </div>
        <div className='row row-cols-md-2 row-cols-xxl-3'>
          <Controller
            name='english_level'
            control={control}
            rules={{
              required: 'fieldRequired',
            }}
            render={({ field }) => {
              return (
                <SelectField
                  {...field}
                  title='english_level'
                  options={ENGLISH_LEVELS}
                  cssClass='w-100'
                  classNameFormGroup='input'
                  classNameLabel='mt-0'
                  required
                  onChange={(e) => {
                    field?.onChange?.(e.target.value);
                  }}
                />
              );
            }}
          />
          {/* <InputField
            title='english_level'
            type='text'
            required
            name='english_level'
            classNameLabel='input__label-personalInfo'
            className='inputText w-100'
            {...register('english_level', {
              required: 'english_level',
            })}
          /> */}
        </div>
        <hr className='line-header-AdditionalCertificates2'></hr>
        <Checkbox
          title='أقر أنا مقدم الطلب بأني مستفيد ضمان اجتماعي وأن المعلومات المدخلة بالمستند صحيحة'
          name='firstDisclaimer'
          className='form-check__graduate'
          classNameLabel='form-check__check-labelu'
          style={{ backgroundSize: '18px 8px', width: '16px', height: '16px', fontSize: '20px', borderRadius: '4px' }}
          {...register('firstDisclaimer', {
            required: 'fieldRequired',
          })}
        />
        <div>
          {Array.isArray(errorFormSubmission) &&
            errorFormSubmission.map((item, index) => (
              <div key={index} style={{ backgroundColor: '#ffd0d0', padding: '10px', borderRadius: '5px', marginTop: '5px' }}>
                {item}
              </div>
            ))}
        </div>
        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button className='step-btn' disabled={!canSubmit} data-loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
              إرسال الطلب
            </button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn' disabled={!canSubmit} data-loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
              إرسال الطلب
            </button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
      </form>
    </>
  );
}

export default AdditionalCertificates;
