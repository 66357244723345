import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Card, LogoHeader, Toaster, Button, InputField } from '../../../shared/components'
import { EMAIL_PATTERN } from '../../../shared/utils/regax'
import '../../ResetPassword/ResetPassword/index.scss'
const ResetPasswordAdmin = (props) => {
  const { resetAdminActions } = props
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, },
  } = useForm({ mode: 'onChange' })
  const [showResults, setShowResults] = useState({ message: '', type: '' })

  const handleChange = (data) => {
    resetAdminActions.resetAdmin(data)

  }

  useEffect(() => {
    if (props.success) {
      setShowResults({ message: 'تم إرسال الرابط إلى بريدك الإلكتروني', type: 'success' })
    } else if (props.error) {
      setShowResults({ message: 'تم إرسال الرابط إلى بريدك الإلكتروني', type: 'success' })
      // setShowResults({ message: {error}, type: 'warning' })
      // setShowResults({ message: '', type: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <div className='admin__page--background'>
      <LogoHeader />

      <div className='flex flex--center'>
        <Card
          title='RESET_PASSWORD_HEADER'
          subTitle='RESET_PASSWORD_SUB'
          // toaster={
          //   showResults &&
          //   (!isSubmitSuccessful ? (
          //     error && <Toaster message={error} type='warning' />
          //   ) : (
          //     <Toaster message='RESET_PASSWORD_SENT_SUCCESS' type='success' />
          //   ))
          // }
          toaster={showResults.message.length ? <Toaster message={showResults.message} type={showResults.type} /> : <div className='without_toster' />}
        >
          <form onSubmit={handleSubmit(handleChange)} className='form_padding'>
            <div className='flex flex--center input-padding'>
              <InputField
                title='email'
                error={errors.email && errors.email.message}
                placeholder='RESET_PASSWORD_INPUT_PLACEHOLDER'
                type='email'
                name='email'
                {...register('email', {
                  required: 'fieldRequired',
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: 'emailInvalid',
                  },
                  // onChange: () => showResults && setShowResults(false),
                })}
              />
            </div>

            <div className='card__btn'>
              <Button
                buttonStyle={true ? 'btn--secondary' : 'btn--primary'}
                titleButton='RESET_PASSWORD_BUTTON'
                disabled={!isValid}
                customStyle={'wide_button'}
              />
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default ResetPasswordAdmin
