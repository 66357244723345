import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, InputField } from '../../../shared/components';
import { EMAIL_PATTERN } from '../../../shared/utils/regax';

const LoginForm = ({ fetching, admin, applicant, serviceProvider, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        title='email'
        error={errors.email && errors.email.message}
        placeholder='البريد الإلكتروني'
        type='email'
        name='email'
        {...register('email', {
          required: 'fieldRequired',
          pattern: {
            value: EMAIL_PATTERN,
            message: 'emailInvalid',
          },
        })}
      />

      <InputField
        name='password'
        error={errors.password && errors.password.message}
        title='password'
        placeholder='password'
        {...register('password', {
          required: 'fieldRequired',
        })}
        type='password'
      />

      <div className='card__footer'>
        {applicant && (
          <a href='/applicant-reset-password?applicant=1' className='card__link'>
            نسيت كلمة المرور؟
          </a>
        )}
        {serviceProvider && (
          <a href='/applicant-reset-password?provider=1' className='card__link'>
            نسيت كلمة المرور؟
          </a>
        )}
        {admin && (
          <a href='/reset-password' className='card__link'>
            نسيت كلمة المرور؟
          </a>
        )}

        <div className='card__btn'>
          <span>
            <Button buttonStyle={admin ? 'btn--secondary' : 'btn--primary'} titleButton='login' disabled={!isValid || fetching} />
          </span>
        </div>
        {(applicant || serviceProvider) && (
          <p className='card__desc'>
            ليس لديك حساب؟
            <Link className='card__desc__link' to={`/applicant-registration?${serviceProvider ? 'provider=1' : 'applicant=1'}`}>
              أنشئ حساب
            </Link>
          </p>
        )}
      </div>
    </form>
  );
};

export default LoginForm;
