import React from 'react';
import { useState, useEffect } from 'react';
import { FormProvider } from '../Context/FormContext';
import { useLocation } from 'react-router-dom';
import ApplicantFormSteps from '../ApplicantFormSteps';
import { fetchSPLdata } from '../../../services/axios/api';

function ApplicantForm(props) {
  const { details } = useLocation().state;
  const { applicantFormRequest, formSubmission, successFormSubmission, errorFormSubmission, responseData } = props;
  const [fullname, setFullname] = useState('');
  const [nationalId, setNationalId] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [additionalMobileNumber, setAdditionalMobileNumber] = useState('');
  const [dob, setDOB] = useState('');
  const [familyMembers, setFamilyMembers] = useState('');
  const [gender, setGender] = useState('');
  const [socialStatus, setSocialStatus] = useState('');
  const [personalPhotoPath, setPersonalPhotoPath] = useState('');
  const [applicantAddressObj, setApplicantAddressObj] = useState({});

  // start axios
  useEffect(() => {
    applicantFormRequest.UserData();
    getSPLdata();
    // eslint-disable-next-line
  }, []);
  const getSPLdata = async () => {
    const response = await fetchSPLdata();
    const {
      has_address: hasAddress,
      data: {
        postal_code: postalCode,
        city: { Name: cityName, id: cityID },
        Neighborhood: { Name: neighborhoodName },
        street: { Name: streetName },
        address_additional_number,
      },
    } = response;
    const addressData = {
      hasAddress,
      postalCode,
      cityName,
      cityID,
      neighborhoodName,
      streetName,
      address_additional_number,
    };
    setApplicantAddressObj(addressData);
  };
  // get response
  useEffect(() => {
    if (props.success) {
      setFullname(props.applicantFormData?.name);
      setNationalId(props.applicantFormData.user?.national_id_number);
      setFamilyMembers(props.applicantFormData.user?.family_members);
      setEmail(props.applicantFormData.email);
      setMobileNumber(props.applicantFormData.mobile_number);
      setAdditionalMobileNumber(props.applicantFormData.user?.additional_mobile_number);
      setDOB(props.applicantFormData.user?.date_of_birth);
      setSocialStatus(props.applicantFormData.social_status);
      setGender(props.applicantFormData.gender);
      setPersonalPhotoPath(props.applicantFormData.personal_photo_path);
    } else {
      console.log('error');
    }
    if (props.error) {
      console.log('error');
    }
  }, [props]);

  return (
    <div className='bg-ApplicantForm'>
      <div className='row-header py-5'>
        <div className='col-header'>
          <div className='header-link'>
            <a className='underline' href='/'>
              الرئيسية <i className='arrow_ left'></i>
            </a>
            <a className='underline' href='/home'>
              طلباتي <i className='arrow_ left'></i>
            </a>
            <p href='/'>نموذج الالتحاق</p>
          </div>
        </div>
      </div>
      <FormProvider
        fullname={fullname}
        nationalId={nationalId}
        mobileNumber={mobileNumber}
        familyMembers={familyMembers}
        socialStatus={socialStatus}
        applicantAddressObj={applicantAddressObj}
        gender={gender}
        personalPhotoPath={personalPhotoPath}
        additionalMobileNumber={additionalMobileNumber}
        email={email}
        dob={dob}
        formSubmission={formSubmission}
        successFormSubmission={successFormSubmission}
        errorFormSubmission={errorFormSubmission}
        responseData={responseData}
        details={details}
      >
        <ApplicantFormSteps />
      </FormProvider>
    </div>
  );
}

export default ApplicantForm;
