import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import '../../../../markup/bootstrap-grid.rtl.min.css';
import sharedApi from '../../../../shared';
import { message, Popconfirm } from 'antd';
import Tabs from '../Tabs/Tabs';
import { getAboutPageData, getMembersData, deleteMemberData, postMemberData, updateMemberData } from '../../../../services/axios/api';
import { MEMBERS_POSITIONS } from './membersPositions';

const { ButtonNormal } = sharedApi.components;
export default function UpdateMembers() {
  const { t } = useTranslation();

  const [aboutPageData, setAboutPageData] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [addMemberName, setAddMemberName] = useState('');
  const [addMemberPosition, setAddMemberPosition] = useState(4);
  const [editedNameValue, setEditedNameValue] = useState('');
  const [editedPositionValue, setEditedPositionValue] = useState('');
  const [editedIdValue, setEditedIdValue] = useState();
  const [isEditInputShow, setIsEditInputShow] = useState(false);
  const [isEditBtnDisabled, setIsEditBtnDisabled] = useState(false);
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);
  const [onSuccess, setOnSuccess] = useState(false);

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, [onSuccess]);

  const handleOnEdit = (key, name, position) => {
    setEditedNameValue(name);
    setEditedIdValue(key);
    setEditedPositionValue(position);
    setIsEditInputShow(true);
    setIsEditBtnDisabled(false);
  };
  const getMembers = async () => {
    const data = await getAboutPageData();
    const members = await getMembersData();
    const newMembers = members.map((member) => ({
      id: member.id,
      name: member.name,
      data_type: member.data_type,
      username: 'member' + member.id,
      position: member.position,
    }));
    setAboutPageData(data);
    setMembersData(newMembers);
  };

  const deleteMember = async (id) => {
    try {
      await deleteMemberData(`/admin/content/member/${id}`);
      getMembers();
      success('تم الحذف بنجاح!');
    } catch (error) {}
  };

  const confirmDelete = (id) => deleteMember(id);

  const addMember = async (type, name, position) => {
    if (name === '') return;
    try {
      const FormDataBody = new FormData();
      FormDataBody.append('name', name);
      FormDataBody.append('data_type', type);
      FormDataBody.append('position', position);
      await postMemberData(FormDataBody);
      success('تمت الإضافة بنجاح!');
      setAddMemberName('');
      setAddMemberPosition(4);
      const div = document.getElementById(`addMember-${type}`);
      if (div.hidden === true) div.hidden = false;
      else div.hidden = true;
    } catch (error) {
      console.log(error);
    }
  };

  const editMember = async (id) => {
    if (editedNameValue === '') return;
    try {
      const FormDataBody = new FormData();
      FormDataBody.append('name', editedNameValue);
      FormDataBody.append('position', editedPositionValue);
      await updateMemberData(FormDataBody, `/admin/content/member/${id}/`);
      setEditedNameValue('');
      setEditedPositionValue('');
      setIsEditInputShow(false);
      success('تم التعديل بنجاح!');
    } catch (error) {
      console.log(error);
    }
  };

  const success = (msg) => {
    setTimeout(() => {
      message.success({
        type: 'success',
        content: msg,
        duration: 2,
      });
    }, 1);
    setOnSuccess(!onSuccess);
  };

  const firstCard = membersData?.filter((member) => member.data_type === 0);
  const secondCard = membersData?.filter((member) => member.data_type === 1);
  const thirdCard = membersData?.filter((member) => member.data_type === 2);
  const fourthCard = membersData?.filter((member) => member.data_type === 3);
  const fifthCard = membersData?.filter((member) => member.data_type === 4);
  const sixthCard = membersData?.filter((member) => member.data_type === 5);
  const seventhCard = membersData?.filter((member) => member.data_type === 6);
  const firstCardTitle = aboutPageData?.find((section) => section.identifier === 'about_section_4')?.title;
  const secondCardTitle = aboutPageData?.find((section) => section.identifier === 'about_section_5')?.title;
  const thirdCardTitle = aboutPageData?.find((section) => section.identifier === 'about_section_6')?.title;
  const fourthCardTitle = aboutPageData?.find((section) => section.identifier === 'about_section_7')?.title;
  const fifthCardTitle = aboutPageData?.find((section) => section.identifier === 'about_section_8')?.title;
  const sixthCardTitle = aboutPageData?.find((section) => section.identifier === 'about_section_9')?.title;
  const seventhCardTitle = aboutPageData?.find((section) => section.identifier === 'about_section_12_5')?.title;
  const cards = [firstCard, secondCard, thirdCard, fourthCard, fifthCard, sixthCard, seventhCard];

  const showEditInput = (id) => {
    setIsAddBtnDisabled(true);
    setAddMemberName('');
    setAddMemberPosition(4);
    const div = document.getElementById(id);
    if (div.hidden === true) div.hidden = false;
    else div.hidden = true;
  };

  const onEditInputChange = (e) => {
    setEditedNameValue(e.target.value);
    if (e.target.value === '') setIsEditBtnDisabled(true);
    else setIsEditBtnDisabled(false);
  };

  const onAddInputChange = (e) => {
    setAddMemberName(e.target.value);
    if (e.target.value === '') setIsAddBtnDisabled(true);
    else setIsAddBtnDisabled(false);
  };
  return (
    <>
      <div className='auditor_update_personal_info-auditorList-container'>
        <div className='auditor_update_personal_info-auditorList'>
          <div>
            <div className='auditor_personal_info-div-auditor'>
              <section>
                <p className='auditor_personal_info-title'>{t('cms_page_title')}</p>
                <p className='auditor_personal_info-subtitle'>{t('cms_page_subtitle')} </p>
              </section>
            </div>
            <Tabs />
          </div>
          {cards &&
            cards.map((card, i) => (
              <div className='purple-card-with-title' key={`card-${i}`}>
                <div className='card--title'>
                  {i === 0 && 'القسم الأول'}
                  {i === 1 && 'القسم الثاني'}
                  {i === 2 && 'القسم الثالث'}
                  {i === 3 && 'القسم الرابع'}
                  {i === 4 && 'القسم الخامس'}
                  {i === 5 && 'القسم السادس'}
                  {i === 6 && 'القسم السابع'}
                </div>
                <div>
                  <div className='row px-3 px-lg-0 mt-5'>
                    <div className='col-12'>
                      <div className='member-card-name'>
                        <div className='member--title'>{t('sectionTitle')}</div>
                        <span className='member--name'>
                          {i === 0 && firstCardTitle}
                          {i === 1 && secondCardTitle}
                          {i === 2 && thirdCardTitle}
                          {i === 3 && fourthCardTitle}
                          {i === 4 && fifthCardTitle}
                          {i === 5 && sixthCardTitle}
                          {i === 6 && seventhCardTitle}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row px-3 px-lg-0'>
                    {card.map((member) => (
                      <div className='col-lg-4' key={member.username}>
                        <div className='member-card-name'>
                          <div className='member--title'>الاسم</div>
                          <span className='member--name'>{member.name} </span>
                          <div className={isEditInputShow && member.username === editedIdValue ? 'd-flex' : 'd-none'}>
                            <input
                              type='text'
                              className='input__text input-style-show mt-0'
                              value={editedNameValue}
                              onChange={(e) => onEditInputChange(e)}
                            />
                            <ButtonNormal
                              titleButton='تعديل'
                              onClick={() => editMember(member.id)}
                              type='button'
                              disabled={isEditBtnDisabled}
                              classNameButton={'step-btn-2 btn-sm px-3 ms-1'}
                            />
                          </div>
                          <button
                            type='button'
                            className={
                              'member--edit ms-1 ' + (isEditInputShow && member.username === editedIdValue ? 'd-none' : 'd-inline')
                            }
                            onClick={() => handleOnEdit(member.username, member.name, member.position)}
                          >
                            تعديل
                          </button>
                          <Popconfirm title='هل انت متأكد؟' onConfirm={() => confirmDelete(member.id)} okText='حذف' cancelText='العودة'>
                            <a
                              href='#'
                              className={
                                'member--delete ms-1 ' + (isEditInputShow && member.username === editedIdValue ? 'd-none' : 'd-inline')
                              }
                            >
                              حذف
                            </a>
                          </Popconfirm>
                          <div
                            className={'member--position ' + (isEditInputShow && member.username === editedIdValue ? 'd-none' : 'd-block')}
                          >
                            {MEMBERS_POSITIONS.find((pos) => pos.value === member.position)?.ar_label}
                          </div>
                          <select
                            value={editedPositionValue}
                            className={
                              'form-select form-input h-30px ' +
                              (isEditInputShow && member.username === editedIdValue ? 'd-block' : 'd-none')
                            }
                            onChange={(e) => setEditedPositionValue(e.target.value)}
                          >
                            {MEMBERS_POSITIONS.map((option) => (
                              <option value={option.value}>{option.ar_label}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='row px-3 px-lg-0 mb-3'>
                    <div className='col-lg-4'>
                      <ButtonNormal titleButton='+ بطاقة اخرى' onClick={() => showEditInput(`addMember-${i}`)} type='button' />
                    </div>
                  </div>
                  <div hidden={true} id={`addMember-${i}`}>
                    <div className='row align-items-end'>
                      <div className='col-lg-4'>
                        <div className='mb-2'>الاسم</div>
                        <input
                          type='text'
                          className='input__text input-style-show mt-0'
                          value={addMemberName}
                          onChange={(e) => onAddInputChange(e)}
                        />
                      </div>
                      <div className='col-lg-4 mb-3 mb-lg-0'>
                        <div className='mb-2'>المنصب</div>
                        <select
                          value={addMemberPosition}
                          className='form-select form-input w-100 m-0 h-30px'
                          onChange={(e) => setAddMemberPosition(e.target.value)}
                        >
                          {MEMBERS_POSITIONS.map((option) => (
                            <option value={option.value}>{option.ar_label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='col-lg-4'>
                        <ButtonNormal
                          titleButton='اضافة'
                          onClick={() => addMember(i, addMemberName, addMemberPosition)}
                          type='submit'
                          disabled={isAddBtnDisabled}
                          classNameButton={'step-btn-2 btn-sm px-3'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
