import React from 'react';
import './SelectField.scss';
import { useTranslation } from 'react-i18next';
import '../InputField/InputField.scss';

const SelectField = (
  {
    classNameFormGroup,
    classNameLabel,
    options,
    title,
    name,
    value,
    onChange,
    lang = 'ar',
    error,
    hasValue,
    smallerFontSize = false,
    required,
    cssClass,
    style,
    disabled,
  },
  ref
) => {
  const { t } = useTranslation();
  return (
    <div className={classNameFormGroup ? classNameFormGroup : 'form-group'}>
      <label htmlFor={name} className={classNameLabel ? classNameLabel : 'form-label-c'}>
        {t(title)}
        {/* <span className='invalid_checkbox'>{required && '*'}</span> */}
      </label>

      <select
        onChange={onChange}
        value={value}
        id={name}
        style={style}
        className={'form-select form-input ' + cssClass}
        name={name}
        ref={ref}
        disabled={disabled}
        //   onChange(e.target.value);
      >
        {name === 'GraduateYear'
          ? options.map((i, index) => (
              <option value={i} key={index} hidden={index === 0}>
                {i}
              </option>
            ))
          : options.map((item, index) => (
              <option
                value={item.value}
                //  className='OP'
                key={index}
                hidden={index === 0}
                // disabled={index === 0}
                // selected={index === 0}
              >
                {lang === 'ar' ? t(`${item.ar_label}`) : t(`${item.en_label}`)}
              </option>
            ))}
      </select>
      <span className='error-text' style={{ fontSize: smallerFontSize && '13.33px' }}>
        {t(error)}
      </span>
    </div>
  );
};

export default React.forwardRef(SelectField);
