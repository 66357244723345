import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import registerAndValidationApI from '..'
import OtpPage from './OtpPage'

const mapStateToProps = () =>
  createStructuredSelector({
    fetching: registerAndValidationApI.otpApi.otpSelectors.getOtpFetching,
    error: registerAndValidationApI.otpApi.otpSelectors.getOtpError,
    success: registerAndValidationApI.otpApi.otpSelectors.getOtpSuccess,
    anotherOtpFetching: registerAndValidationApI.otpApi.otpSelectors.getAnotherOtpFetching,
    anotherOtpError: registerAndValidationApI.otpApi.otpSelectors.getAnotherOtpError,
    anotherOtpSuccess: registerAndValidationApI.otpApi.otpSelectors.getAnotherOtpSuccess,
  })

const mapDispatchToProps = (dispatch) => ({
  otpValidationActions: bindActionCreators(registerAndValidationApI.otpApi.otpActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(OtpPage)
