import { all } from 'redux-saga/effects';

import loginSaga from '../../redux/sagas/login';
import registerSega from '../../redux/sagas/register';
import resetSaga from '../../redux/sagas/resetPassword';
import resetAdminSaga from '../../redux/sagas/resetPasswordAdmin';
import otpSaga from '../../redux/sagas/otp';
import otpLoginSega from '../../redux/sagas/otpLogin';
import setPasswordSaga from '../../redux/sagas/setPassword';
import applicantFormSubmissionSaga from '../../redux/sagas/applicantFormSubmission';
import UserDataSaga from 'redux/sagas/userData';
import addAdutorSaga from '../../redux/sagas/addAdutor';
import nationalIdNumberSaga from '../../redux/sagas/nationalIdNumber';
import changePasswordSaga from '../../redux/sagas/changePassword';
import addServicesSaga from '../../redux/sagas/addServices';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    registerSega(),
    otpSaga(),
    otpLoginSega(),
    setPasswordSaga(),
    resetSaga(),
    resetAdminSaga(),
    applicantFormSubmissionSaga(),
    UserDataSaga(),
    addAdutorSaga(),
    nationalIdNumberSaga(),
    changePasswordSaga(),
    addServicesSaga(),
  ]);
}
