// Project dependency
import React from 'react'
import { useTranslation } from 'react-i18next'

import './Card.scss'

function Card({ title, subTitle, subTitle2, toaster, children, wide, customHeader }) {
  const { t } = useTranslation()

  return (
    <div className={`card ${wide}`}>
      {toaster}
      <div className={customHeader ? customHeader : 'card__header'}>
        <p className='card__header__title'>{t(title)}</p>
        <p className='card__header__subtitle'>{t(subTitle)}</p>
        <p className='card__header__subtitle2'>{t(subTitle2)}</p>
      </div>
      <div className='card__body'>{children}</div>
    </div>
  )
}

export default Card
