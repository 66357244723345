import React from 'react'
import styled from 'styled-components'

const MonthDays = styled.div`
  text-align: right;
  display: flex;
  flex-flow: wrap;
`

const MonthDay = styled.div`
  margin: 2px;
  width: 30px;
  padding: 1px;
  border: 1px solid #fff;
  text-align: center;
  -webkit-box-sizing: unset !important;
  box-sizing: unset;
  border-radius: 4px;
  color: #404040;
`

const MonthDayButton = styled.button`
  cursor: pointer;
  border: 0px;
  width: 30px;
  padding: 5px;
  font-size: 12px;
  color: #404040;

  :focus {
    outline: unset;
  }
  :hover {
    background-color: #dde8e3;
    color: #504566;
  }
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? '#DDE8E3;' : 'white;')};
  color: ${(props) => (props.selected ? '#504566;' : '')};
`

class MonthDaysView extends React.Component {
  state = {
    englishDayNames: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  }

  getMonthStartDayName = () => {
    let time = this.props.currentTime
    time.startOf('iMonth')
    return time.format('dd')
  }

  monthDays = () => {
    return this.props.currentTime.iDaysInMonth()
  }

  isSelectedDate = (i) => {
    let time = this.props.currentTime
    time.iDate(parseInt(i, 10))
    return this.props.selectedDate === time.format(this.props.dateFormat)
  }

  render() {
    let daysList = []
    for (let i = this.state.englishDayNames.indexOf(this.getMonthStartDayName()); i > 0; i--) {
      daysList.push(<MonthDay key={daysList.length.toString()} noHover></MonthDay>)
    }
    for (let i = 1; i < this.monthDays() + 1; i++) {
      daysList.push(
        <MonthDay selected={this.isSelectedDate(i)} key={daysList.length.toString()}>
          <MonthDayButton selected={this.isSelectedDate(i)} onClick={this.props.setSelectedDate} value={i} type='button'>
            {i}
          </MonthDayButton>
        </MonthDay>
      )
    }

    return <MonthDays>{daysList}</MonthDays>
  }
}

export default MonthDaysView
