import * as Actions from '../../action-types'

const initialAccountState = {
  accountType: null,
}

export const userReducer = (state = initialAccountState, action) => {
  switch (action.type) {
    case Actions.SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload,
      }
    case Actions.CLEAR_ACCOUNT_TYPE:
      return initialAccountState
    default:
      return state
  }
}
