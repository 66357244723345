import * as Actions from '../action-types'


export const userDataFetch = () => ({
  type: Actions.USER_DATA_FETCH_START,
})

export const userDataFinished = (payload) => ({
  type: Actions.USER_DATA_FETCH_SUCCESS,
  payload,
})

export const userDataFailed = (payload) => ({
  type: Actions.USER_DATA_FETCH_FAILED,
  payload,
})

export const resetUserData = () => ({
  type: Actions.RESET_USER_DATA,
})

export const UserData = () => ({
  type: Actions.USER_DATA,
})

export const resetState = () => ({
  type: Actions.RESET_STATE,
})