import { createSelector } from 'reselect'

const otpSelector = ({ otp }) => otp

export const getOtpFetching = createSelector(otpSelector, ({ fetchingReducer }) => fetchingReducer.fetching)
export const getOtpError = createSelector(otpSelector, ({ fetchingReducer }) => fetchingReducer.fetchingError)
export const getOtpSuccess = createSelector(otpSelector, ({ fetchingReducer }) => fetchingReducer.success)
export const getAnotherOtpFetching = createSelector(otpSelector, ({ fetchingReducer }) => fetchingReducer.anotherOtpFetching)
export const getAnotherOtpError = createSelector(otpSelector, ({ fetchingReducer }) => fetchingReducer.anotherOtpFetchingError)
export const getAnotherOtpSuccess = createSelector(otpSelector, ({ fetchingReducer }) => fetchingReducer.anotherOtpSuccess)
 

