import React, { useEffect } from 'react';
import { SERVICE_TYPE, SERVICE_DETAILS } from '../../constants';
import { useForm, Controller } from 'react-hook-form';
import sharedApi from '../../../../shared';
import { useFormContext } from '../../ServiceProviderFormSteps';
import { useWindowSize } from '@react-hook/window-size';
import moment from 'moment-hijri';
const { SelectField, InputField } = sharedApi.components;

const InstitutionInfo = () => {
  const [width] = useWindowSize();
  const { formData, setFormData, setCurrent, current, handleChange } = useFormContext();

  const {
    register,
    control,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const selectedServiceType = watch('serviceType', 0) || 0;
  const textareaContent = watch('serviceDetailText', 0) || 0;
  // TODO:  fix startDate when previous button pressed
  // useEffect(() => setValue('startDate', ''), []);

  useEffect(() => {
    const subscription = watch((data) => {
      // setValue('startDate', undefined);
      for (const key in data) {
        data[key] &&
          setFormData((prevData) => ({
            ...prevData,
            [key]: data[key],
          }));
      }
    });
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [watch]);

  const onSubmit = (_, e) => {
    e.preventDefault();
    const startDateFormatted = moment(getValues('startDate'), 'iDD/iMM/iYYYY').format('YYYY/M/D').replaceAll('/', '-');
    setValue('startDate', startDateFormatted);
    handleChange();
  };
  return (
    <>
      {width > 767 && (
        <>
          <p className='app-contact-title '>معلومات الخدمة</p>
          <p className='app-contact-subtitle'>الرجاء ادخال بيانات وتفاصيل الخدمة المقدمة</p>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=' flex-row--column'>
          <div className='row row-cols-md-2 row-cols-xxl-3'>
            <Controller
              name='serviceType'
              control={control}
              rules={{
                required: 'fieldRequired',
              }}
              render={({ field }) => (
                <SelectField
                  {...field}
                  required
                  title='نوع الخدمة'
                  options={SERVICE_TYPE}
                  error={errors.serviceType && errors.serviceType.message}
                  smallerFontSize
                  cssClass='w-100'
                  classNameFormGroup='input'
                  classNameLabel='mt-0 text-grey'
                />
              )}
            />
            {getValues('serviceType') !== '5' ? (
              <Controller
                name='serviceDetail'
                control={control}
                rules={{
                  required: 'fieldRequired',
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    title='تفاصيل الخدمة'
                    options={SERVICE_DETAILS[Number(selectedServiceType)]}
                    error={errors.serviceDetail && errors.serviceDetail.message}
                    smallerFontSize
                    cssClass='w-100'
                    classNameFormGroup='input'
                    classNameLabel='mt-0 text-grey'
                  />
                )}
              />
            ) : (
              <InputField
                title='اذكر نوع الخدمة'
                type='text'
                name='serviceDetailOther'
                className='inputText'
                required
                error={errors.serviceDetailOther && errors.serviceDetailOther.message}
                maxLength={25}
                {...register('serviceDetailOther', {
                  required: 'fieldRequired',
                })}
              />
            )}
            <Controller
              control={control}
              name='startDate'
              rules={{
                required: 'fieldRequired',
              }}
              render={({ field: { onChange } }) => (
                <InputField
                  onChange={onChange}
                  title='التاريخ المتوقع لبدء الخدمة'
                  placeholder='selectDate'
                  type='date'
                  required
                  error={errors.startDate && errors.startDate.message}
                  smallerHeight
                  maxWidth={true}
                  value={formData.startDate}
                  cssClass='w-100'
                  classNameFormGroup='input'
                  classNameLabel='mt-0 text-grey'
                />
              )}
            />
            <InputField
              title='عدد المستفيدين من الخدمة'
              type='number'
              name='serviceAmount'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              required
              error={errors.serviceAmount && errors.serviceAmount.message}
              maxLength={25}
              {...register('serviceAmount', {
                required: 'fieldRequired',
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'يجب ان يحتوي المدخل على أرقام فقط',
                },
              })}
            />
            <InputField
              title='الدعم المادي -ان وجد-'
              type='number'
              name='financialSupport'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              maxLength={25}
              error={errors.financialSupport && errors.financialSupport.message}
              {...register('financialSupport', {
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'يجب ان يحتوي المدخل على أرقام فقط',
                },
              })}
            />
          </div>
          <div style={{ marginTop: '4rem' }}>
            <label className='form-label' style={{ fontSize: '17.6px' }}>
              وصف الخدمة المقدمة
              {/* <span className='invalid_checkbox'>*</span> */}
            </label>
            <textarea
              placeholder='وصف الخدمة التي تقدمها المنشأة للطلاب من مستفيدي الضمان الإجتماعي'
              rows={6}
              style={{ fontFamily: 'DIN Regular' }}
              className='form-text-area'
              maxLength={500}
              name='serviceDetailText'
              {...register('serviceDetailText', {
                required: 'fieldRequired',
              })}
            />
            <div className='text-below-textarea'>
              <p>{errors.serviceDetailText && 'الحقل مطلوب'}</p>
              <p>{textareaContent?.length > 0 ? `عدد الأحرف المتبقي: ${500 - textareaContent?.length}` : 'الحد الأعلى للأحرف: 500'}</p>
            </div>
          </div>
        </div>

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default InstitutionInfo;
