import classNames from 'classnames';
import { Link } from 'react-router-dom';
import sharedApi from '../../../shared';
import './Header.scss';
const { LogoHeader } = sharedApi.components;
const Header = ({ activeNav, setActiveNav, user, handelTab, homeLinkText, aboutLinkText }) => {
  const firstNavBarStyle = classNames('nav-bar first-bar', {
    'first-bar--active': activeNav,
  });
  const middleNavBarStyle = classNames('nav-bar middle-bar', {
    'middle-bar--active': activeNav,
  });
  const lastNavBarStyle = classNames('nav-bar last-bar', {
    'last-bar--active': activeNav,
  });
  const navButtonStyle = classNames('nav-small', {
    'nav-small--active': activeNav,
  });
  return (
    <>
      <div className='about-nav'>
        <LogoHeader white noMargin='logo-header_noMargin' marginTop='logo-margin' />
        <div className='layout'>
          <a href='#main' className='nav-item' onClick={() => handelTab(1)}>
            {homeLinkText || 'الرئيسية'}
          </a>
          <a href='#governance' className='nav-item' onClick={() => handelTab(5)}>
            {'الحوكمة'}
          </a>
          <a href='#about' className='nav-item' onClick={() => handelTab(2)}>
            {aboutLinkText || 'عن الجمعية'}
          </a>
          <a href='#members' className='nav-item' onClick={() => handelTab(3)}>
            الأعضاء
          </a>
          <a href='https://store.taalum.org.sa' className='nav-item'>
            المتجر الالكتروني
          </a>
          <a href='#contact' className='nav-item' onClick={() => handelTab(4)}>
            تواصل معنا
          </a>
        </div>

        <button className={navButtonStyle} onClick={() => setActiveNav(!activeNav)}>
          <span className={firstNavBarStyle}></span>
          <span className={middleNavBarStyle}></span>
          <span className={lastNavBarStyle}></span>
        </button>
        <div className='invisible'>
          {!user?.email ? (
            <div>
              <Link to='/applicant-login' className='login-btn me-2 hide-btn'>
                المستفيدين
              </Link>

              <Link to='/provider-login' className='login-btn hide-btn'>
                مزودين الخدمات
              </Link>
            </div>
          ) : (
            <Link
              to={
                user.account_type === 1
                  ? `/application-request`
                  : user.account_type === 2
                  ? `/applications`
                  : user.account_type === 3
                  ? `/home`
                  : user.account_type === 4 && `/home`
              }
              className='login-btn loggedin hide-btn'
            >
              {user.account_type === 1
                ? 'لوحة التحكم '
                : user.account_type === 2
                ? 'لوحة التحكم '
                : user.account_type === 3
                ? 'طلباتي'
                : user.account_type === 4 && 'طلباتي'}

              <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='arrow bi bi-arrow-left' viewBox='0 0 16 16'>
                <path
                  fillRule='evenodd'
                  d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
                />
              </svg>
            </Link>
          )}
        </div>
      </div>
      <hr className='nav-line' />
    </>
  );
};

export default Header;
