// lib dependencies
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { customAuthGet } from '../../../services/axios/api';

// asset dependencies
import './ApplicantLogin.scss';
// component dependencies
import sharedApi from '../../../shared';
import LoginForm from '../Components/LoginForm';
const { Card, Toaster, LogoHeader } = sharedApi.components;

export default function ApplicantLogin(props) {
  const { loginActions, error } = props;
  const location = useLocation();
  const Navigate = useNavigate();
  const [showResults, setShowResults] = useState({ message: '', type: '' });
  const [isUserDisabled, setIsUserDisabled] = useState(false);
  const submitted = useRef(false);
  const [searchParams] = useSearchParams();
  const otpLimit = !!+searchParams.get('otpLimit');
  const showMessage = () => {
    if (isUserDisabled) return <Toaster message='الحساب متوقف مؤقتا, يرجى التواصل معنا' type='warning' />;
    if (showResults.message.length) return <Toaster message={showResults.message} type={showResults.type} />;
    if (otpLimit)
      return (
        <Toaster message='عذرًا، لقد تم إدخال كلمة المرور المؤقتة بشكل غير صحيح ثلاث مرات. الرجاء تسجيل الدخول مرة اخرى' type='warning' />
      );
    return null;
  };
  const onSubmit = async (data) => {
    submitted.current = true;
    data.email = data.email.toLowerCase();
    const userInfo = await customAuthGet('/user/fetch_status/' + data.email);
    const userStatus = userInfo?.status && userInfo?.status === 2 ? false : true;
    if (userInfo?.status === 2 || userInfo?.status === 3) setIsUserDisabled(userStatus);
    if (isUserDisabled) return;
    loginActions.applicantLogin(data);
  };
  useEffect(() => {
    if (isUserDisabled) return;
    if (props.success && submitted.current) {
      Navigate('/otp-Login?applicant=1', { state: { fromLogin: true } });
    } else if (props.error) {
      setShowResults({ message: error, type: 'warning' });
    } else if (location?.state?.registered) {
      setShowResults({ message: location.state.message, type: location.state.type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, location]);

  return (
    <div className='background-full__screen'>
      <div className='secondbg-full__screen'></div>
      <div className='thirdbg-full__screen'></div>
      <div className='fivebg-full__screen'></div>
      <LogoHeader white />
      <div className='flex flex--center'>
        <Card title='login' wide='mb-0 applicant-card' subTitle='قم بإدخال البيانات المطلوبة لبدء رحلة التعلُم' toaster={showMessage()}>
          <LoginForm applicant onSubmit={onSubmit} {...props} />
        </Card>
      </div>
    </div>
  );
}
