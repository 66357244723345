// Project dependency
import React, { useRef, useState,useEffect } from 'react';
// import T from "prop-types";
import './Upload.scss';
import UploadLogo from '../../../markup/images/upload.png';
import FileLogo from '../../../markup/images/file-icon.svg';
import RemoveIcon from '../../../markup/images/remove-icon.svg';
import { useTranslation, Trans } from 'react-i18next';

const Upload = ({ allowedTypes, onChange, maxSize = 1, value, required = '', uploaded, error, savedFileStatus, submitedfile  }, ref) => {
  const { t } = useTranslation();
  const uploadRef = useRef();
  const [name, setName] = useState('');
  const [size, setSize] = useState('0 Bytes');
  const [dragged, setDragged] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  // const [typeErrors, setTypeErrors] = useState(false)
  const [sizeErrors, setSizeErrors] = useState(false);
  const [fileStatus, setFileStatus] = useState(savedFileStatus)

  useEffect(() => {
    if (size === 0) {
      setSize('0 Bytes')
    } else {
      const decimals = 2
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB']
      const i = Math.floor(Math.log(submitedfile?.size) / Math.log(k))
      setSize(parseFloat((submitedfile?.size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i])
    }
    // eslint-disable-next-line 
  }, [])

  const validate = (upload) => {
    let valid = true;

    if (upload) {
      const arr = upload.name.split('.');
      const name = `.${arr[arr.length - 1]}`;

      if (allowedTypes && !allowedTypes.includes(name.toLowerCase())) {
        valid = false;
        // setTypeErrors(true)
      } else {
        // setTypeErrors(false)
      }
      const sizeLimit = maxSize * 1024 * 1024;

      if (upload.size > sizeLimit) {
        valid = false;
        setSizeErrors(true);
      } else {
        setSizeErrors(false);
      }
      return valid;
    }
    return valid;
  };

  const onUploadChange = (e) => {
    e.preventDefault();
    const upload = e.target.files[0];
    const valid = validate(upload);
    if (valid) {
      upload ? setName(upload.name) : setName('');
      setFileUploaded(true);
      calculateSize(upload.size);
      onChange(upload);
    }
  };

  const onUploadDrag = (e) => {
    e.preventDefault();
    setDragged('');
    const upload = e.dataTransfer.files[0];
    const valid = validate(upload);
    if (valid) {
      upload ? setName(upload.name) : setName('');
      setFileUploaded(true);
      calculateSize(upload.size);
      onChange(upload);
    }
  };

  const calculateSize = (size, decimals = 2) => {
    if (size === 0) {
      setSize('0 Bytes');
    } else {
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB'];
      const i = Math.floor(Math.log(size) / Math.log(k));
      setSize(parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]);
    }
  };

  const removeFile = () => {
    setName('');
    setSize('0 Bytes');
    setFileUploaded(false);
    // setTypeErrors(false)
    setSizeErrors(false);
    onChange(null);
    setFileStatus(false)
  };

  return (
    <div>
      {fileStatus && submitedfile && !fileUploaded && (
        <div className='uploaded-file' >
          <div className='flex-row flex-row--align-center'>
            <img src={FileLogo} alt='' className='file-logo' />
            <div>
              <p className='file-name'>{
                submitedfile.name ? submitedfile.name : new URL(submitedfile).href.split('/').pop()
              }</p>
              <p className='file-name'>{submitedfile.size ? size : ""}</p>

            </div>
          </div>
          <div onClick={removeFile}>
            <img src={RemoveIcon} alt='' className='remove-icon' />
          </div>
        </div>
      )}

      {fileUploaded && (
        <div className='uploaded-file'>
          <div className='flex-row flex-row--align-center'>
            <img src={FileLogo} alt='' className='file-logo' />
            <div>
              <p className='file-name'>{name}</p>
              <p className='file-size'>{size}</p>
            </div>
          </div>
          <div onClick={removeFile} >
            <img src={RemoveIcon} alt='' className='remove-icon' />
          </div>
        </div>
      )}
      {!fileUploaded && !fileStatus && (
        <div >
          <input
            value={value}
            type='file'
            onChange={onUploadChange}
            ref={uploadRef}
            style={{ display: 'none' }}
            accept={allowedTypes && allowedTypes.join(',')}
          />
          <div
            className={`upload-btn ${dragged}`}
            onClick={() => uploadRef.current.click()}
            onDragOver={(e) => {
              e.preventDefault()
              setDragged('upload-btn-dragged')
            }}
            onDrop={onUploadDrag}
            onDragEnd={(e) => {
              setDragged('')
            }}
            onDragLeave={(e) => {
              setDragged('')
            }}
          >
            <div className='upload-prompt'>
              <img src={UploadLogo} alt='' className='upload-logo' />
              <span>
                <span className="invisible-small">
                  قم بسحب وافلات الملف او{" "}
                </span>
                <span className="underline">  تصفح الملفات</span>

              </span>
            </div>
            <p className="upload-text">
              الحد الاعلى للملفات هو {`${maxSize}`}MB
            </p>
            <p className="upload-text">
              الصيغ المتاحة: {`${allowedTypes.join(" ").toUpperCase()}`}
            </p>
          </div>
        </div>
      )}
      {sizeErrors && <span className='error-text'>{t('sizeError')}</span>}
      {error && (
        <small className= 'input__error_form'>
          <Trans t={t}>{error}</Trans>
        </small>
      )}
    </div>
  )
}


export default React.forwardRef(Upload);
