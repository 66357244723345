import React, { useEffect, useState } from 'react';
import { getServiceProviderApplicationDetails, customAuthGet } from '../../../services/axios/api';
import TableList from '../../../shared/components/TableList';

export default function AdminServiceProvider() {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async (query) => {
    setLoading(true);
    let data = {};
    if (query) {
      data = await customAuthGet('/service-provider/?page=' + query);
    } else {
      data = await getServiceProviderApplicationDetails();
    }
    setServices(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className='auditorList-container'>
        <TableList
          headers={['اسم المنشأة', 'نوع الخدمة', 'الإجراءات']}
          title='مقدمي الخدمات'
          subTitle='يمكنك الاطلاع على تفاصيل طلبات مقدمي الخدمات'
          data={services}
          displayServiceForAdmin
          loading={loading}
          serviseProviderDetails={true}
          fetchNewPage={fetchData}
        />
      </div>
    </div>
  );
}
