import { applicantRegister, auditorCreateInvitation, serviceProviderRegister } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import {
  registerFetch,
  registerFetchFailed,
  registerFetchFinished,
  auditorInvitationFetch,
  auditorInvitationFetchFailed,
  auditorInvitationFetchFinished,
} from '../actions/register';

function* applicantRegisterAsync(action) {
  yield put(registerFetch());
  try {
    yield call(applicantRegister, action.payload);
    yield put(registerFetchFinished());
  } catch (error) {
    yield put(registerFetchFailed(error.response.data));
  }
}

function* serviceProviderRegisterAsync(action) {
  yield put(registerFetch());
  try {
    yield call(serviceProviderRegister, action.payload);
    yield put(registerFetchFinished());
  } catch (error) {
    yield put(registerFetchFailed(error.response.data));
  }
}

function* auditorInvitationAsync(action) {
  yield put(auditorInvitationFetch());
  try {
    yield call(auditorCreateInvitation, action.payload);
    yield put(auditorInvitationFetchFinished());
  } catch (error) {
    yield put(auditorInvitationFetchFailed(error.response.data));
  }
}

export default function* registerSega() {
  yield all([
    takeLatest(Actions.APPLICANT_REGISTER, applicantRegisterAsync),
    takeLatest(Actions.SERVICE_PROVIDER_REGISTER, serviceProviderRegisterAsync),
    takeLatest(Actions.REQUEST_AUDITOR_INVITATION, auditorInvitationAsync),
  ]);
}
