import { addAdutors } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import { addAdutorFetch, addAdutorFetchFinished, addAdutorFetchFailed } from '../actions/addAdutor';

function* addAdutorAsync(action) {
  yield put(addAdutorFetch());
  try {
    yield call(addAdutors, action.payload);
    yield put(addAdutorFetchFinished());
  } catch (error) {
    yield put(addAdutorFetchFailed(error.response.data.error_code));
  }
}

export default function* addAdutorSaga() {
  yield all([takeLatest(Actions.ADD_ADUTOR, addAdutorAsync)]);
}
