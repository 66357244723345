// Project dependency
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import HijriDatePicker from '../DatePicker/HijriDatePicker';

// asset dependency
import './InputField.scss';
import hidePasswordIcon from '../../../assets/images/form/eye-slash.svg';
import showPasswordIcon from '../../../assets/images/form/eye.svg';

function InputField(
  {
    maxLength,
    disabled,
    type,
    title,
    style,
    name,
    value,
    placeholder,
    onBlur,
    onFocus,
    onChange,
    className,
    className_error,
    cssClass,
    error,
    onKeyUp,
    classNameLabel,
    className_div_input,
    step,
    smallerHeight = false,
    maxWidth = false,
    required,
    readonly,
  },
  ref
) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={className_div_input ? className_div_input : 'input'}>
      <label htmlFor={name} className={classNameLabel ? classNameLabel : 'input__label'}>
        {t(title)}
        {/* <span className='invalid_checkbox'>{required && '*'}</span> */}
      </label>
      <div className='input__field'>
        {type === 'date' ? (
          <HijriDatePicker
            className={'date-input ' + cssClass}
            dateFormat='iDD/iMM/iYYYY'
            onChange={onChange}
            value={value}
            name={name}
            placeholder={t(placeholder)}
            quickSelect
            smallerHeight={smallerHeight}
            maxWidth={maxWidth}
          />
        ) : (
          <input
            value={value}
            name={name}
            type={showPassword ? 'text' : type}
            placeholder={t(placeholder)}
            className={className ? className : 'input__text'}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={maxLength}
            onKeyUp={onKeyUp}
            style={style}
            ref={ref}
            disabled={disabled}
            step={step}
            readOnly={readonly}
          />
        )}

        {type === 'password' && (
          <img
            alt=''
            className='input__password-icon'
            src={showPassword ? hidePasswordIcon : showPasswordIcon}
            onClick={() => setShowPassword((prevState) => !prevState)}
          />
        )}
      </div>
      {error && (
        <small className={className_error ? className_error : 'input__error'}>
          <Trans t={t}>{error}</Trans>
        </small>
      )}
    </div>
  );
}

export default React.forwardRef(InputField);
