import { applicantFormSubmit } from '../../services/axios/api'
import { put, call, all, takeLatest } from 'redux-saga/effects'
import * as Actions from '../action-types'
import {
  applicantFormSubmissionStart,
  applicantFormSubmissionFailed,
  applicantFormSubmissionFinished,
} from '../actions/applicantFormSubmission'

function* applicantFormSubmissionAsync(action) {
  yield put(applicantFormSubmissionStart())
  try {
    const respone =  yield call(applicantFormSubmit, action.payload)
    yield put(applicantFormSubmissionFinished(respone))
  } catch (error) {
    yield put(applicantFormSubmissionFailed(error.response.data))
  }
}

export default function* applicantFormSubmissionSaga() {
  yield all([
    takeLatest(Actions.REQUEST_APPLICANT_FORM_SUBMISSION, applicantFormSubmissionAsync)])
}
