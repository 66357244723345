import React, { useState } from 'react';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import './Navbar.scss';
import LogoHeader from '../LogoHeader/LogoHeader';
import userProfile from 'assets/images/shared/user-profile.svg';
import logoutIcon from 'assets/images/shared/logout-icon.svg';
// import settingsIcon from 'markup/images/settingsIcon.svg';
import auditorIcon from 'markup/images/adutor-icone.svg';
import ordersIcon from 'markup/images/orders-admin--icone.svg';
import servicesList from 'markup/images/services-list.svg';
import adminInfo from 'markup/images/admin-info.svg';
import logoCertificates from 'markup/images/logoCertificates.svg';
import menuLine from '../../../markup/images/menu_line.svg';
import logoWT from '../../../assets/images/shared/White.svg';
import authManager from '../../../services/axios/authManager';
import iconeLight from '../../../markup/images/light-icon.svg';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../services/axios';
import { resetUserData } from '../../../redux/actions/userData';

type menu = { label: string; icon: string; link: string }[];

const Navbar = () => {
  const navigate = useNavigate();
  const [smallNav, setSmallNav] = useState<boolean>(false);
  const userType = authManager.getAccountType();
  const accountType = userType === 'Admin' ? 1 : userType === 'Auditor' ? 2 : null;
  const currentPath = useLocation().pathname;
  const user = useSelector((state: any) => state?.userData?.fetchingReducer?.data);
  const dispatch = useDispatch();
  // hide navbar when account type is null
  if (!accountType) {
    return <> </>;
  }
  const menuItems: { 1: menu; 2: menu } = {
    1: [
      {
        label: 'المحتوى',
        icon: auditorIcon,
        link: '/update-home',
      },
      {
        label: 'المدققين',
        icon: auditorIcon,
        link: '/auditor-list',
      },
      {
        label: 'الطلبات',
        icon: ordersIcon,
        link: '/application-request',
      },
      {
        label: 'قائمة الخدمات المقدمة',
        icon: servicesList,
        link: '/services-list',
      },
      {
        label: 'مقدمي الخدمات',
        icon: iconeLight,
        link: '/admin-services-provider',
      },
      {
        label: 'الملف الشخصي',
        icon: adminInfo,
        link: '/profile',
      },
      {
        label: 'الأعضاء المسجلين',
        icon: servicesList,
        link: '/members',
      },
    ],
    2: [
      {
        label: 'طلبات جديدة',
        icon: logoCertificates,
        link: '/applications',
      },
      {
        label: 'قائمة الطلبات',
        icon: ordersIcon,
        link: '/application-list',
      },
      {
        label: 'الملف الشخصي',
        icon: adminInfo,
        link: '/profile',
      },
    ],
  };

  const logMeOut = () => {
    dispatch(resetUserData());
    logOut();
    navigate('/');
  };

  return (
    <>
      <nav data-account-type={accountType}>
        <LogoHeader white noMargin='logo-header_noMargin' marginTop='logo-margin' />
        <hr />
        <div className='menu'>
          {menuItems[accountType].map((menuItem, index) => {
            return (
              <Link
                key={index}
                to={menuItem.link}
                className='menu-item'
                aria-current={
                  menuItem.link === currentPath
                    ? 'page'
                    : (currentPath === '/update-about' || currentPath === '/update-members') && menuItem.link === '/update-home'
                    ? 'page'
                    : false
                }
              >
                <span style={{ backgroundImage: `url(${menuItem.icon})` }} />
                {menuItem.label}
              </Link>
            );
          })}
        </div>
        <div className='user-section'>
          <div className='flex-none'>
            <img src={userProfile} alt='' className='user-profile-img' />
          </div>
          <div className='user-name'>
            <p>{user.name}</p>
            <p>{user.email}</p>
          </div>
          <div onClick={logMeOut} className='logout-container'>
            <span style={{ backgroundImage: `url(${logoutIcon})` }} className='logout-icon' />
          </div>
        </div>
      </nav>

      <div className='backdrop' data-open={smallNav} onClick={() => setSmallNav(false)} />

      <div className='tool-bar-admin' data-account-type={accountType}>
        <div>
          <img src={logoWT} alt='' style={{ width: '89px', height: '75px' }} />
        </div>
        <div onClick={() => setSmallNav(true)}>
          <img src={menuLine} alt='' style={{ width: '35px', height: '26px' }} />
        </div>
      </div>

      <div className='SmallNav' data-open={smallNav}>
        <div className='user-section-small'>
          <NavLink to='/'>
            <img src={userProfile} alt='' className='user-profile-img' />
          </NavLink>
          <div className='user-name'>
            <p className='user-name-1'>{user.name}</p>
            <p className='user-name-2'>{user.email}</p>
          </div>
          <div onClick={logMeOut} className='logout-container'>
            <img src={logoutIcon} alt='' className='logout-icon' />
          </div>
        </div>
        <div className='menu'>
          {menuItems[accountType].map((menuItem, index) => {
            return (
              <Link
                key={index}
                to={menuItem.link}
                className='menu-item'
                aria-current={menuItem.link === currentPath ? 'page' : false}
                onClick={() => setSmallNav(false)}
              >
                <span style={{ backgroundImage: `url(${menuItem.icon})` }} />
                {menuItem.label}
              </Link>
            );
          })}
        </div>
      </div>

      <div>
        <Outlet />
      </div>
    </>
  );
};
export default Navbar;
