import React, { Component } from 'react'
import styled from 'styled-components'

const DayNamesList = styled.div`
  text-align: right;
  border-bottom: 1px solid #ddd;
  margin-top: 50px;
`

const DayName = styled.div``

class DayNames extends Component {
  state = {
    arabicDayNames: ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س'],
    arabicFullDayNames: ['أحد', 'إثنين', 'ثلاثاء', 'إربعاء', 'خميس', 'جمعة', 'سبت'],
    englishDayNames: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  }

  render() {
    return (
      <DayNamesList>
        {this.state.arabicFullDayNames.map((item, key) => (
          <DayName className='day_name' key={key.toString()}>
            {item}
          </DayName>
        ))}
      </DayNamesList>
    )
  }
}
export default DayNames
