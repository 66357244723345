import { message } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from './apiConfig';
import authManager from './authManager';
import { ERROR_CODES_MESSAGES } from './response-handlers';

message.config({
  duration: 5,
  maxCount: 3,
  rtl: true,
});

export const Auth = async ({ body, apiUrl }) => {
  return axios.post(config.mainUrl + apiUrl, body, { withCredentials: true }).then((res) => {
    authManager.setAuthData({
      accessToken: res.data.access,
      refreshToken: res.data.refresh,
    });
    return res.data;
  });
};

// add access token to headers in post method
export const AuthPost = async ({ body, apiUrl, headers }) => {
  return axiosInstance.post(config.mainUrl + apiUrl, body, { withCredentials: true, ...headers }).then((res) => {
    return res.data;
  });
};

export const POST = async ({ body, apiUrl }) => {
  return axios.post(config.mainUrl + apiUrl, body, { withCredentials: true }).then((res) => {
    return res.data;
  });
};
// add access token to headers in get method
export const AuthGet = async ({ body, apiUrl }) => {
  return axiosInstance.get(config.mainUrl + apiUrl, { withCredentials: true }).then((res) => {
    return res.data;
  });
};

export const GET = async ({ body, apiUrl, params = {} }) => {
  return axios.get(config.mainUrl + apiUrl, { withCredentials: true, params }).then((res) => {
    return res.data;
  });
};

export const AuthPatch = async ({ body, apiUrl }) => {
  return axiosInstance.patch(config.mainUrl + apiUrl, body, { withCredentials: true }).then((res) => {
    return res.data;
  });
};
export const AuthPut = async ({ body, apiUrl }) => {
  return axiosInstance.put(config.mainUrl + apiUrl, body, { withCredentials: true }).then((res) => {
    return res.data;
  });
};

export const AuthDelete = async ({ body, apiUrl }) => {
  return axiosInstance.delete(config.mainUrl + apiUrl, { data: body }, { withCredentials: true }).then((res) => {
    return res.data;
  });
};

export const logOut = () => {
  authManager.logOut();
};

export const getAccountType = () => {
  return authManager.getAccountType();
};

// creating a new axios instance
const axiosInstance = axios.create({
  baseURL: config.mainUrl,
});

// intercept axios calls to add access token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = authManager.getAccessToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// if access token is expired use refresh token to get a new one
axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    console.log('--error--stack', err);
    const originalConfig = err.config;
    if (originalConfig.url !== ('/login/' || '/otp-Login/') && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const res = await axios
            .post(config.mainUrl + '/user/token/refresh/', {
              refresh: authManager.getRefreshToken(),
            })
            .catch((err) => {
              logOut();
              window.history.pushState({}, 'LandingPage', '/');
              window.location.reload();
            });
          const { access, refresh } = res.data;
          authManager.setAuthData({
            accessToken: access,
            refreshToken: refresh,
          });

          return axiosInstance(originalConfig);
        } catch (_error) {
          console.log('error', _error);
          return Promise.reject(_error);
        }
      }
    }

    if (ERROR_CODES_MESSAGES[err.response.status]) {
      toast.error(ERROR_CODES_MESSAGES[err.response.status]);
    } else if (err.response.status >= 400 && err.response.status < 500) {
      toast.error('برجاء التحقق من البيانات و الصلاحيات المطلوبة');
    }

    // toast
    return Promise.reject(err);
  }
);

export default axiosInstance;
