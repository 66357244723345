import { resetPAssword, resetServiceProviderPassword } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import { resetFetch, resetFetchFailed, resetFetchFinished } from '../actions/resetPassword';

function* resetPasswordAsync(action) {
  yield put(resetFetch());
  try {
    yield call(resetPAssword, action.payload);
    yield put(resetFetchFinished());
  } catch (error) {
    yield put(resetFetchFailed(error.response.data));
  }
}
function* resetServiceProviderPasswordAsync(action) {
  yield put(resetFetch());
  try {
    yield call(resetServiceProviderPassword, action.payload);
    yield put(resetFetchFinished());
  } catch (error) {
    yield put(resetFetchFailed(error.response.data));
  }
}

export default function* resetSaga() {
  yield all([
    takeLatest(Actions.RESET_PASSWORD, resetPasswordAsync),
    takeLatest(Actions.RESET_SERVICE_PROVIDER_PASSWORD, resetServiceProviderPasswordAsync),
  ]);
}
