import { combineReducers } from 'redux';
import login from '../../redux/reducers/login/reducers';
import register from '../../redux/reducers/register/reducers';
import otp from '../../redux/reducers/otp';
import resetPassword from '../../redux/reducers/resetPassword';
import resetPasswordAdmin from '../../redux/reducers/resetPasswordAdmin';
import otpLogin from '../../redux/reducers/otpLogin';
import setPassword from '../../redux/reducers/setPassword';
import applicantFormSubmission from '../../redux/reducers/applicantFormSubmission';
import userData from 'redux/reducers/userData';
import addAdutor from '../../redux/reducers/addAdutor';
import nationalIdNumber from '../../redux/reducers';
import changePassword from '../../redux/reducers/changePassword';
import addServices from '../../redux/reducers/addServices';

export const rootReducer = combineReducers({
  login,
  register,
  otp,
  resetPassword,
  resetPasswordAdmin,
  otpLogin,
  setPassword,
  applicantFormSubmission,
  userData,
  addAdutor,
  nationalIdNumber,
  changePassword,
  addServices,
});
