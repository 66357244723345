import { SendNational_id_number } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import { nationalIdNumberFetch, nationalIdNumberFetchFinished, nationalIdNumberFetchFailed } from '../actions/nationalIdNumber';

function* nationalIdNumberAsync(action) {
  yield put(nationalIdNumberFetch());
  try {
    yield call(SendNational_id_number, action.payload);
    yield put(nationalIdNumberFetchFinished());
  } catch (error) {
    yield put(nationalIdNumberFetchFailed(error.response.data.non_field_errors||error.response.data.national_id_number));
  }
}

export default function* nationalIdNumberSaga() {
  yield all([takeLatest(Actions.National_id_number, nationalIdNumberAsync)]);
}
