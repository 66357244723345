// lib dependency
import React from 'react'
import { useTranslation } from 'react-i18next'

// asset dependecny
import './Toaster.scss'
import warningIcon from '../../../assets/images/shared/warning.svg'
import messageIcon from '../../../assets/images/shared/message-icon.svg'

const Toaster = ({ type, message }) => {
  const { t } = useTranslation()
  const icon = type === 'warning' ? warningIcon : messageIcon

  return (
    <div>
      <div className={`toaster toaster--${type}`}>
        <span>
          <img src={icon} className='toaster__icon' alt='' />
        </span>
        <div className='toaster__message'>{t(message)}</div>
      </div>
    </div>
  )
}

export default Toaster
