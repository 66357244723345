// eslint-disable-next-line
import { Auth, AuthGet, AuthPost, GET, POST, AuthPatch, AuthDelete, AuthPut } from '.';
import { API_ENDPOINTS } from './constants';

export const applicantRegister = async ({ username, email, mobileNumber, password, confirmPassword, nationalId, dateOfBirth }) => {
  const response = POST({
    body: {
      name: username,
      email,
      mobile_number: mobileNumber,
      password,
      password2: confirmPassword,
      student: {
        national_id_number: nationalId,
        date_of_birth: dateOfBirth,
      },
    },
    apiUrl: API_ENDPOINTS.applicantRegistration,
  });
  return response;
};

export const serviceProviderRegister = async ({ username, email, mobileNumber, password, confirmPassword, nationalId, dateOfBirth }) => {
  const response = POST({
    body: {
      name: username,
      email,
      mobile_number: mobileNumber,
      password,
      password2: confirmPassword,
      service_provider: {
        national_id_number: nationalId,
        date_of_birth: dateOfBirth,
      },
    },
    apiUrl: API_ENDPOINTS.serviceProviderRegistration,
  });
  return response;
};

export const resetPAssword = async ({ email }) => {
  const response = POST({
    body: {
      email,
    },
    apiUrl: API_ENDPOINTS.resetPassword,
  });
  return response;
};
export const resetServiceProviderPassword = async ({ email }) => {
  const response = POST({
    body: {
      email,
    },
    apiUrl: API_ENDPOINTS.resetServiceProviderPassword,
  });
  return response;
};

export const otp = async ({ joinedOtp }) => {
  const response = POST({
    body: {
      otp: joinedOtp,
    },
    apiUrl: API_ENDPOINTS.otp,
  });
  return response;
};

export const otpServiceProvider = async ({ joinedOtp }) => {
  const response = POST({
    body: {
      otp: joinedOtp,
    },
    apiUrl: API_ENDPOINTS.otpServiceProvider,
  });
  return response;
};

export const anotherOtp = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.anotherOtp,
  });
  return response;
};
export const anotherOtpServiceProvider = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.anotherOtpServiceProvider,
  });
  return response;
};

export const adminLogin = async (body) => {
  const response = Auth({
    body,
    apiUrl: API_ENDPOINTS.adminLogin,
  });
  return response;
};

export const applicantLogin = async ({ email, password }) => {
  const response = POST({
    body: { email, password },
    apiUrl: API_ENDPOINTS.applicantLogin,
  });
  return response;
};
export const serviceProviderLogin = async ({ email, password }) => {
  const response = POST({
    body: { email, password },
    apiUrl: API_ENDPOINTS.serviceProviderLogin,
  });
  return response;
};

export const auditorCreateInvitation = async ({ form_id, password, repassword }) => {
  const response = POST({
    body: { form_id, password, repassword },
    apiUrl: API_ENDPOINTS.auditorCreateInvitation,
  });
  return response;
};
export const otpLogin = async ({ joinedOtp }) => {
  const response = Auth({
    body: {
      otp: joinedOtp,
    },
    apiUrl: API_ENDPOINTS.otpLogin,
  });
  return response;
};
export const otpServiceProviderLogin = async ({ joinedOtp }) => {
  const response = Auth({
    body: {
      otp: joinedOtp,
    },
    apiUrl: API_ENDPOINTS.otpServiceProviderLogin,
  });
  return response;
};

export const anotherOtpLogin = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.anotherOtpLogin,
  });
  return response;
};
export const anotherOtpServiceProviderLogin = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.anotherOtpServiceProviderLogin,
  });
  return response;
};

export const setPassword = async ({ new_password, re_new_password, token }) => {
  const response = POST({
    body: { new_password, re_new_password, token },
    apiUrl: API_ENDPOINTS.setPassword,
  });
  return response;
};
export const setServiceProviderPassword = async ({ new_password, re_new_password, token }) => {
  const response = POST({
    body: { new_password, re_new_password, token },
    apiUrl: API_ENDPOINTS.setServiceProviderPassword,
  });
  return response;
};

export const passwordValidate = async ({ token }) => {
  const response = POST({
    body: { token },
    apiUrl: API_ENDPOINTS.resetPasswordValidate,
  });
  return response;
};

export const applicantFormSubmit = async (FormDataBody) => {
  const response = AuthPost({
    body: FormDataBody,
    apiUrl: API_ENDPOINTS.applicantFormSubmit,
  });

  return response;
};

export const getApplicationDetails = async (id) => {
  const response = AuthGet({
    apiUrl: id ? API_ENDPOINTS.getApplicationDetails + id + '/' : API_ENDPOINTS.getApplicationDetails,
  });
  return response;
};
export const getServiceProviderApplicationDetails = async (id) => {
  const response = AuthGet({
    apiUrl: id ? API_ENDPOINTS.getServiceProviderApplicationDetails + id + '/' : API_ENDPOINTS.getServiceProviderApplicationDetails,
  });
  return response;
};

export const auditorNewApplicationsList = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.applicationListNew,
  });
  return response;
};

export const auditorApplicationsList = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.applicationList,
  });
  return response;
};

export const adminApplicationsList = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.adminApplicationList,
  });
  return response;
};

export const assignRequest = async (id) => {
  const response = AuthPatch({
    body: {},
    apiUrl: API_ENDPOINTS.assignRequest + id + '/assign/',
  });
  return response;
};

export const manageRequest = async (body, id) => {
  const response = AuthPatch({
    body,
    apiUrl: API_ENDPOINTS.manageRequest + id + '/',
  });
  return response;
};

export const manageRequestAdmin = async (body, id) => {
  const response = AuthPatch({
    body,
    apiUrl: API_ENDPOINTS.manageRequestAdmin + id + '/',
  });
  return response;
};

export const customAuthGet = async (link) => {
  const response = AuthGet({
    apiUrl: link,
  });
  return response;
};

export const addAdutors = async ({ name_Of_aduitor, email_Of_aduitor, mobileNumber_Of_aduitor }) => {
  const response = AuthPost({
    body: {
      name: name_Of_aduitor,
      email: email_Of_aduitor,
      mobile_number: mobileNumber_Of_aduitor,
    },
    apiUrl: API_ENDPOINTS.addAdutor,
  });
  return response;
};

export const getAuditorList = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.getAuditorList,
  });

  return response;
};

export const customeAuthGetAuditorList = async (link) => {
  const response = AuthGet({
    apiUrl: link,
  });
  return response;
};
export const userData = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.userData,
  });
  return response;
};

export const activeInactiveAdutor = async (id, status) => {
  const response = AuthPatch({
    body: { status: status },
    apiUrl: API_ENDPOINTS.activeInactiveAdutor + id + '/change_status/',
  });
  return response;
};
export const resubmitApplication = async (id, FormDataBody) => {
  const response = AuthPatch({
    body: FormDataBody,
    apiUrl: API_ENDPOINTS.getApplicationDetails + id + '/',
  });
  return response;
};
export const resendInvitation = async (id) => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.resendInvitation + id + '/resend-invitation/',
  });
  return response;
};
export const SendNational_id_number = async ({ nationalId_auditor }) => {
  const response = AuthPost({
    body: { national_id_number: nationalId_auditor },
    apiUrl: API_ENDPOINTS.postNational_id_number,
  });
  return response;
};

export const changePassword = async ({ old_password, password, confirmPassword }) => {
  const response = AuthPost({
    body: { old_password: old_password, new_password: password, re_new_password: confirmPassword },
    apiUrl: API_ENDPOINTS.changePassword,
  });
  return response;
};

export const canCreateRequest = async (id) => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.canCreateRequest + id + '/',
  });
  return response;
};

export const serviceProviderSubmit = async (FormDataBody) => {
  const response = AuthPost({
    body: FormDataBody,
    apiUrl: API_ENDPOINTS.serviceProvderFormSubmit,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });

  return response;
};
export const getRegionsList = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.getRegionsList,
  });
  return response;
};

export const getListOfCities = async (id) => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.getCitiesList + '?region_id=' + id,
  });
  return response;
};
export const getLandingPageData = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.getLandingPageData,
  });
  return response;
};
export const getHomePageData = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.getHomePageData,
  });
  return response;
};
export const updateHomePageData = async (body) => {
  const response = AuthPatch({
    body: body,
    apiUrl: API_ENDPOINTS.updateHomePageData,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });

  return response;
};
export const getAboutPageData = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.getAboutPageData,
  });
  return response;
};
export const updateAboutPageData = async (FormDataBody) => {
  const response = AuthPatch({
    body: FormDataBody,
    apiUrl: API_ENDPOINTS.updateAboutPageData,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });

  return response;
};
export const getMembersData = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.getMembersData,
  });
  return response;
};
export const getBanksData = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.getBanksData,
  });
  return response;
};
export const updateMemberData = async (FormDataBody, url) => {
  const response = AuthPatch({
    body: FormDataBody,
    apiUrl: url,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });
  return response;
};
export const updateBankData = async (FormDataBody, url) => {
  const response = AuthPatch({
    body: FormDataBody,
    apiUrl: url,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });
  return response;
};
export const postMemberData = async (FormDataBody) => {
  const response = AuthPost({
    body: FormDataBody,
    apiUrl: API_ENDPOINTS.postMemberData,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });
  return response;
};
export const postBankData = async (FormDataBody) => {
  const response = AuthPost({
    body: FormDataBody,
    apiUrl: API_ENDPOINTS.postBankData,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });
  return response;
};
export const getServices = async () => {
  const response = GET({
    apiUrl: API_ENDPOINTS.getServices,
  });
  return response;
};
export const deleteMemberData = async (url) => {
  const response = AuthDelete({
    apiUrl: url,
  });

  return response;
};
export const deleteBankData = async (url) => {
  const response = AuthDelete({
    apiUrl: url,
  });

  return response;
};
export const addServices = async ({ name_Of_Servic, Desc_Of_Servic }) => {
  const response = AuthPost({
    body: {
      name: name_Of_Servic,
      description: Desc_Of_Servic,
    },
    apiUrl: API_ENDPOINTS.addServices,
  });
  return response;
};
export const getServicesAll = async (togglePaginate) => {
  let url = togglePaginate ? API_ENDPOINTS.getServicesAll + togglePaginate : API_ENDPOINTS.getServicesAll;
  const response = GET({
    apiUrl: url,
  });
  return response;
};
export const validateRecaptchToken = async (token) => {
  const response = GET({
    params: token,
    apiUrl: API_ENDPOINTS.validateRecaptchToken,
  });
  return response;
};
export const DeleteService = async (serviceID) => {
  const response = AuthDelete({
    apiUrl: API_ENDPOINTS.deleteService + serviceID + '/',
  });
  return response;
};
export const toggleServiceStatus = async (serviceID, status) => {
  const response = AuthPatch({
    body: status,
    apiUrl: API_ENDPOINTS.deleteService + serviceID + '/',
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });
  return response;
};
export const getUserQiyas = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.getUserQiyas,
  });
  return response;
};
export const fetchSPLdata = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.fetchSPLdata,
  });
  return response;
};
export const updateUserStatus = async (id, status) => {
  const response = AuthPut({
    body: { status: status },
    apiUrl: API_ENDPOINTS.updateUserStatus + id + '/',
  });
  return response;
};
export const updateQiyasStatus = async (id, status) => {
  const response = AuthPut({
    body: { qiyas_exception: status },
    apiUrl: API_ENDPOINTS.updateQiyasStatus + id + '/',
  });
  return response;
};
export const getUserData = async () => {
  const response = AuthGet({
    apiUrl: API_ENDPOINTS.getUserData,
  });
  return response;
};
export const updateUserData = async (body) => {
  const response = AuthPatch({
    body: body,
    apiUrl: API_ENDPOINTS.updateUserData,
    headers: { headers: { 'content-type': 'multipart/form-data' } },
  });

  return response;
};

export const ExportAuditorList = async () => {
  const response = AuthPost({
    body: {},
    apiUrl: API_ENDPOINTS.exportAuditorListUrl,
  });
  return response;
};
export const ExportRequestList = async (url) => {
  const response = AuthPost({
    body: {},
    apiUrl: API_ENDPOINTS.exportRequestListUrl + url,
  });
  return response;
};
