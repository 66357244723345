import React, { useEffect, useState } from 'react';
import { customAuthGet, updateUserStatus, updateQiyasStatus } from '../../../services/axios/api';
import TableList from '../../../shared/components/TableList';
import searchIcon from '../../../markup/images/search-icon.svg';
import styles from './MembersList.module.scss';

export function MembersList() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [updateUI, setUpdateUI] = useState(true);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [updateUI]);

  const searchHanddler = async (e, query) => {
    e.preventDefault();
    fetchData(query);
  };

  const fetchData = async (query) => {
    setLoading(true);
    const data = await customAuthGet('/user/users/?account_type=3&page=' + (query || 1) + '&search=' + searchText);
    setApplications(data);
    setLoading(false);
  };
  const handleUserStatus = async (id, status) => {
    if (status === 2) await updateUserStatus(id, 3);
    if (status === 3) await updateUserStatus(id, 2);
    setUpdateUI(!updateUI);
  };
  const handleQiyasStatus = async (id, qiyasStatus) => {
    if (qiyasStatus === false) await updateQiyasStatus(id, true);
    if (qiyasStatus === true) await updateQiyasStatus(id, false);
    setUpdateUI(!updateUI);
  };

  return (
    <div className='auditorList-container'>
      <TableList
        headers={['رقم الهوية', 'البريد الإلكتروني', 'رقم الجوال', 'الحالة', 'الإجراءات', 'شرط قياس']}
        title='قائمة الأعضاء'
        subTitle='يمكنك إدارة الأعضاء من خلال هذه الصفحة'
        data={applications}
        loading={loading}
        fetchNewPage={fetchData}
        isAdmin
        isMemberList
        onUserStatusChange={handleUserStatus}
        onQiyasStatusChange={handleQiyasStatus}
        searchInput={
          <div className='search-container'>
            <div className={styles['search-input']}>
              <form onSubmit={searchHanddler}>
              <input
                placeholder='ابحث عن مستفيد بالاسم أو البريد الإلكتروني أو رقم الجوال'
                type='text'
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                className={styles.searchInputStyle}
                style={{
                  borderRadius: '15px',
                }}
                onBlur={searchHanddler}
              />
              <img className={styles.searchIconStyle} src={searchIcon} onClick={searchHanddler} alt='search button' />
              </form>
            </div>
          </div>
        }
      />
    </div>
  );
}
