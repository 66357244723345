import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { Card, LogoHeader, Toaster, Button, InputField } from '../../../shared/components';
import { EMAIL_PATTERN } from '../../../shared/utils/regax';
import './index.scss';
const ResetPassword = (props) => {
  const { resetActions } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const [showResults, setShowResults] = useState({ message: '', type: '' });
  const [searchParams] = useSearchParams();
  const isProvider = !!+searchParams.get('provider');
  const isApplicant = !!+searchParams.get('applicant');

  const handleChange = (data) => {
    if (isApplicant) resetActions.resetPassword(data);
    if (isProvider) resetActions.resetServiceProviderPassword(data);
  };

  useEffect(() => {
    if (props.success) {
      setShowResults({ message: 'تم إرسال الرابط إلى بريدك الإلكتروني', type: 'success' });
    } else if (props.error) {
      setShowResults({ message: 'تم إرسال الرابط إلى بريدك الإلكتروني', type: 'success' });
      // setShowResults({ message: {error}, type: 'warning' })
      // setShowResults({ message: '', type: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div className='background-full__screen'>
      <div className='secondbg-full__screen'></div>
      <div className='thirdbg-full__screen'></div>
      <div className='fivebg-full__screen'></div>
      <LogoHeader white />

      <div className='flex flex--center'>
        <Card
          title='RESET_PASSWORD_HEADER'
          subTitle='RESET_PASSWORD_SUB'
          toaster={
            showResults.message.length ? (
              <Toaster message={showResults.message} type={showResults.type} />
            ) : (
              <div className='without_toster' />
            )
          }
        >
          <form onSubmit={handleSubmit(handleChange)} className='form_padding'>
            <div className='flex flex--center input-padding'>
              <InputField
                title='email'
                error={errors.email && errors.email.message}
                placeholder='RESET_PASSWORD_INPUT_PLACEHOLDER'
                type='email'
                name='email'
                {...register('email', {
                  required: 'fieldRequired',
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: 'emailInvalid',
                  },
                  // onChange: () => showResults && setShowResults(false),
                })}
              />
            </div>

            <div className='card__btn'>
              <Button
                buttonStyle={true ? 'btn--secondary' : 'btn--primary'}
                titleButton='RESET_PASSWORD_BUTTON'
                disabled={!isValid}
                customStyle={'wide_button'}
              />
            </div>
            <div className='flex flex--center'>
              <p className='card__desc'>
                ليس لديك حساب؟{' '}
                <Link className='card__desc__link' to={`/applicant-registration?${isProvider ? 'provider=1' : 'applicant=1'}`}>
                  أنشئ حساب
                </Link>
              </p>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
