import { combineReducers } from 'redux';
import * as Actions from '../../action-types';

const initialFetchingState = {
  fetching: null,
  fetchingError: null,
  success: null,
  anotherOtpLoginFetching: null,
  anotherOtpLoginFetchingError: null,
  anotherOtpLoginSuccess: null,
};

const fetchingReducer = (state = initialFetchingState, action) => {
  switch (action.type) {
    case Actions.OTP_LOGIN_FETCH_START:
      return {
        ...state,
        fetching: true,
      };
    case Actions.OTP_LOGIN_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        anotherOtpLoginFetching: null,
        anotherOtpLoginFetchingError: null,
        anotherOtpLoginSuccess: null,
        fetchingError: action.payload,
      };
    case Actions.OTP_LOGIN_FETCH_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case Actions.ANOTHER_OTP_LOGIN_START:
      return {
        ...state,
        anotherOtpLoginFetching: true,
      };
    case Actions.ANOTHER_OTP_LOGIN_FAILED:
      return {
        ...state,
        anotherOtpLoginFetching: false,
        anotherOtpLoginFetchingError: action.payload,
      };
    case Actions.ANOTHER_OTP_LOGIN_SUCCESS:
      return {
        ...state,
        anotherOtpLoginSuccess: true,
      };
    case Actions.RESET_STATE:
      return initialFetchingState;
    default:
      return state;
  }
};

export default combineReducers({
  fetchingReducer,
});
