import * as Actions from '../action-types';

// fetching
export const loginFetch = () => ({
  type: Actions.LOGIN_FETCH_START,
});

export const loginFetchFinished = () => ({
  type: Actions.LOGIN_FETCH_SUCCESS,
});

export const loginFetchFailed = (payload) => ({
  type: Actions.LOGIN_FETCH_FAILED,
  payload,
});

export const adminLogin = (payload) => ({
  type: Actions.ADMIN_LOGIN,
  payload,
});

export const applicantLogin = (payload) => ({
  type: Actions.APPLICANT_LOGIN,
  payload,
});

export const serviceProviderLogin = (payload) => ({
  type: Actions.SERVICE_PROVIDER_LOGIN,
  payload,
});

export const setAccountType = (payload) => {
  let accountType;

  switch (payload) {
    case '1' || 1:
      accountType = 'Admin';
      break;
    case '2' || 2:
      accountType = 'Auditor';
      break;
    case '3' || 3:
      accountType = 'Student';
      break;
    case '4' || 4:
      accountType = 'ServiceProvider';
      break;
    default:
      accountType = 'Illegal account type!';
  }

  return {
    type: Actions.SET_ACCOUNT_TYPE,
    payload: accountType,
  };
};
