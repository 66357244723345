import { changePassword } from '../../services/axios/api'
import { put, call, all, takeLatest } from 'redux-saga/effects'
import * as Actions from '../action-types'
import {
    changePasswordFetch,
    changePasswordFetchFailed,
    changePasswordFetchFinished,
} from '../actions/changePassword'

function* changePasswordAsync(action) {
  yield put(changePasswordFetch())
  try {
    yield call(changePassword, action.payload)
    yield put(changePasswordFetchFinished())
  } catch (error) {
    yield put(changePasswordFetchFailed(error.response.data.error_code))
  }
}



export default function* changePasswordSaga() {
  yield all([
    takeLatest(Actions.Change_Password, changePasswordAsync),
  ])
}