import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import ResetPassword from './ResetPassword'
import resetPassworApplicantdApi from '..'

const mapStateToProps = () =>
  createStructuredSelector({
    fetching: resetPassworApplicantdApi.resetApi.resetSelectors.getResetFetching,
    error: resetPassworApplicantdApi.resetApi.resetSelectors.getResetError,
    success: resetPassworApplicantdApi.resetApi.resetSelectors.getResetSuccess,
  })

const mapDispatchToProps = (dispatch) => ({
  resetActions: bindActionCreators(resetPassworApplicantdApi.resetApi.resetActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResetPassword)
