import React, { useEffect, useState } from 'react';
import sharedApi from '../../../../../shared';
import './AdditionalFields.scss';
import { useForm, Controller } from 'react-hook-form';
import useFormContext from '../../../Context/useFormContext';
import { useWindowSize } from '@react-hook/window-size';
import {
  studyDesiresList,
  housingCostList,
  trainingPeriodList,
  cooperativeTraining_availableOpportunitiesList,
  futureSkillsList,
  workshopsAndLectures_availableOpportunitiesList,
} from '../../../constants';

const { SelectField, InputField, Checkbox } = sharedApi.components;

const AdditionalFields = ({ codes, setCodesEmpty }) => {
  const [educationCost, setEducationCost] = useState(false);
  const [housingCost, setHousingCost] = useState(false);
  const [transportationCostsData, setTransportationCostsData] = useState(false);
  const [educationalSuppliesCosts, setEducationalSuppliesCosts] = useState(false);
  const [cooperativeTraining, setCooperativeTraining] = useState(false);
  const [workshopsAndLectures, setWorkshopsAndLectures] = useState(false);
  const [academicAdvising, setAcademicAdvising] = useState(false);

  const [width] = useWindowSize();

  const { formData, setCurrent, current, handleChange } = useFormContext();
  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    // keep values in the form
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
  }, [formData, setValue]);

  useEffect(() => {
    if (codes.includes('education_cost')) setEducationCost(true);
    if (codes.includes('housing_cost')) setHousingCost(true);
    if (codes.includes('transportation_costs')) setTransportationCostsData(true);
    if (codes.includes('educational_supplies_costs')) setEducationalSuppliesCosts(true);
    if (codes.includes('cooperative_training')) setCooperativeTraining(true);
    if (codes.includes('workshops_and_lectures')) setWorkshopsAndLectures(true);
    if (codes.includes('academic_advising')) setAcademicAdvising(true);
  }, [codes]);

  const watchUniformNeed = watch('uniformNeed');
  return (
    <>
      <div className='header-app-contact'>{width > 767 ? <p className='app-contact-title ms-0 mb-4'>حقول اضافية</p> : null}</div>
      <form onSubmit={handleSubmit(handleChange)}>
        {educationCost && (
          <>
            <p className='additoinal-fields-subtitle'>حقول خاصة بخدمة الرسوم الدراسية</p>
            <p className='mt-3'>
              وصف الخدمة:{' '}
              <span className='text-purple'>تقديم خدمات تمويل دراسي لمساندة مستفيدي الجمعية في الحصول على فرص تعليم نوعية.</span>
            </p>
            <div className='row row-cols-md-2 row-cols-xxl-3'>
              <Controller
                name='studyDesires'
                control={control}
                rules={{
                  required: 'fieldRequired',
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    title='فرصة التعليم'
                    name='studyDesires'
                    cssClass='w-100'
                    onChange={(e) => {
                      field?.onChange?.(e.target.value);
                    }}
                    options={studyDesiresList}
                    error={errors.studyDesires && errors.studyDesires.message}
                  />
                )}
              />
            </div>
          </>
        )}
        {housingCost && (
          <>
            <p className='additoinal-fields-subtitle'>حقول خاصة بخدمة تكاليف السكن</p>
            <p className='mt-3'>
              وصف الخدمة: <span className='text-purple'>مساندة مستفيدي الجمعية في توفير حلول إسكان جامعي أو سكن خارج الحرم الدراسي.</span>
            </p>
            <div className='row row-cols-md-2 row-cols-xxl-3'>
              <Controller
                name='housingCost'
                control={control}
                rules={{
                  required: 'fieldRequired',
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    title='ملكية السكن'
                    name='housingCost'
                    cssClass='w-100'
                    onChange={(e) => {
                      field?.onChange?.(e.target.value);
                    }}
                    options={housingCostList}
                    error={errors.housingCost && errors.housingCost.message}
                  />
                )}
              />
              <InputField
                title='بعد موقع الإقامة عن المقر التعليمي'
                type='text'
                name='locationDistance'
                className='inputText w-100'
                required
                error={errors.locationDistance && errors.locationDistance.message}
                className_error='input__error_form'
                {...register('locationDistance', {
                  required: 'fieldRequired',
                })}
              />
            </div>
            <Checkbox
              title='هل يوجد لدى الجهة التعليمية المنتسب لها سكن طلاب/طالبات؟'
              name='studentsHousing'
              className='form-check__graduate checbox-style mt-4'
              classNameLabel='form-check__check-labelu'
              {...register('studentsHousing')}
            />
          </>
        )}
        {transportationCostsData && (
          <>
            <p className='additoinal-fields-subtitle'>حقول خاصة بخدمة تكاليف المواصلات</p>
            <p className='mt-3'>
              وصف الخدمة:{' '}
              <span className='text-purple'>تذليل العقبات على المستفيدين عبر إيجاد وسائل مواصلات للوصول للمؤسسة التعليمية.</span>
            </p>
            <div className='row row-cols-md-2 row-cols-xxl-3'>
              <InputField
                title='اسم الجهة التعليمية الملتحق بها'
                type='text'
                name='transportation_entityName'
                className='inputText w-100'
                required
                error={errors.transportation_entityName && errors.transportation_entityName.message}
                className_error='input__error_form'
                {...register('transportation_entityName', {
                  required: 'fieldRequired',
                })}
              />
            </div>
            <Checkbox
              title='هل تملك وسيلة نقل خاصه بك او أحد افراد اسرتك؟'
              name='transportationAvailability'
              className='form-check__graduate checbox-style mt-4'
              classNameLabel='form-check__check-labelu'
              {...register('transportationAvailability')}
            />
            <Checkbox
              title='هل يوجد نقل تعليمي تابع للجهة التعليمية؟'
              name='transportationService'
              className='form-check__graduate checbox-style mt-4'
              classNameLabel='form-check__check-labelu'
              {...register('transportationService')}
            />
          </>
        )}
        {educationalSuppliesCosts && (
          <>
            <p className='additoinal-fields-subtitle'>حقول خاصة بخدمة تكاليف المستلزمات التعليمية</p>
            <p className='mt-3'>
              وصف الخدمة: <span className='text-purple'>مساعدة المستفيدين في توفير المستلزمات الدراسة المناسبة.</span>
            </p>
            <Checkbox
              title='هل يوجد احتياج كتب؟'
              name='booksNeed'
              className='form-check__graduate checbox-style mt-4'
              classNameLabel='form-check__check-labelu'
              {...register('booksNeed')}
            />
            <Checkbox
              title='هل يوجد احتياج أجهزة كمبيوتر محمول؟'
              name='laptopNeed'
              className='form-check__graduate checbox-style mt-4'
              classNameLabel='form-check__check-labelu'
              {...register('laptopNeed')}
            />
            <Checkbox
              title='هل يوجد احتياج زي تعليمي (معاطف وغيرها)؟'
              name='uniformNeed'
              className='form-check__graduate checbox-style mt-4'
              classNameLabel='form-check__check-labelu'
              {...register('uniformNeed')}
            />
            {watchUniformNeed && (
              <div className='row row-cols-1'>
                <InputField
                  title='اذكر الاحتياجات'
                  type='text'
                  name='uniformNeedDetails'
                  className='inputText w-100'
                  required
                  error={errors.uniformNeedDetails && errors.uniformNeedDetails.message}
                  className_error='input__error_form'
                  {...register('uniformNeedDetails', {
                    required: 'fieldRequired',
                  })}
                />
              </div>
            )}
          </>
        )}
        {cooperativeTraining && (
          <>
            <p className='additoinal-fields-subtitle'>حقول خاصة بخدمة التدريب التعاوني </p>
            <p className='mt-3'>
              وصف الخدمة: <span className='text-purple'>تدريب المستفيد على ما تم تعلمه خلال الرحلة التعليمية.</span>
            </p>
            <div className='row row-cols-md-2 row-cols-xxl-3'>
              <InputField
                title='اسم الجهة التعليمية الملتحق بها'
                type='text'
                name='cooperativeTraining_entityName'
                className='inputText w-100'
                required
                error={errors.cooperativeTraining_entityName && errors.cooperativeTraining_entityName.message}
                className_error='input__error_form'
                {...register('cooperativeTraining_entityName', {
                  required: 'fieldRequired',
                })}
              />
              <Controller
                name='trainingPeriod'
                control={control}
                rules={{
                  required: 'fieldRequired',
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    title='مدة التدريب'
                    name='trainingPeriod'
                    cssClass='w-100'
                    onChange={(e) => {
                      field?.onChange?.(e.target.value);
                    }}
                    options={trainingPeriodList}
                    error={errors.trainingPeriod && errors.trainingPeriod.message}
                  />
                )}
              />
              <Controller
                name='cooperativeTraining_availableOpportunities'
                control={control}
                rules={{
                  required: 'fieldRequired',
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    title='الفرص المتاحة'
                    name='cooperativeTraining_availableOpportunities'
                    cssClass='w-100'
                    onChange={(e) => {
                      field?.onChange?.(e.target.value);
                    }}
                    options={cooperativeTraining_availableOpportunitiesList}
                    error={errors.cooperativeTraining_availableOpportunities && errors.cooperativeTraining_availableOpportunities.message}
                  />
                )}
              />
            </div>
          </>
        )}
        {workshopsAndLectures && (
          <>
            <p className='additoinal-fields-subtitle'>حقول خاصة بخدمة ورش عمل ومحاضرات </p>
            <p className='mt-3'>
              وصف الخدمة: <span className='text-purple'>دعم وتطوير المستفيد لتنمية المهارات العملية.</span>
            </p>
            <div className='row row-cols-md-2 row-cols-xxl-3'>
              <InputField
                title='الميول المهنية'
                type='text'
                name='careerInclinations'
                className='inputText w-100'
                required
                error={errors.careerInclinations && errors.careerInclinations.message}
                className_error='input__error_form'
                {...register('careerInclinations', {
                  required: 'fieldRequired',
                })}
              />
              <InputField
                title='التخصص الجامعي'
                type='text'
                name='universityMajor'
                className='inputText w-100'
                required
                error={errors.universityMajor && errors.universityMajor.message}
                className_error='input__error_form'
                {...register('universityMajor', {
                  required: 'fieldRequired',
                })}
              />
              <Controller
                name='futureSkills'
                control={control}
                rules={{
                  required: 'fieldRequired',
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    title='مهارات المستقبل'
                    name='futureSkills'
                    cssClass='w-100'
                    onChange={(e) => {
                      field?.onChange?.(e.target.value);
                    }}
                    options={futureSkillsList}
                    error={errors.futureSkills && errors.futureSkills.message}
                  />
                )}
              />
              <Controller
                name='workshopsAndLectures_availableOpportunities'
                control={control}
                rules={{
                  required: 'fieldRequired',
                }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    required
                    title='الفرص المتاحة'
                    name='workshopsAndLectures_availableOpportunities'
                    cssClass='w-100'
                    onChange={(e) => {
                      field?.onChange?.(e.target.value);
                    }}
                    options={workshopsAndLectures_availableOpportunitiesList}
                    error={errors.workshopsAndLectures_availableOpportunities && errors.workshopsAndLectures_availableOpportunities.message}
                  />
                )}
              />
            </div>
          </>
        )}
        {academicAdvising && (
          <>
            <p className='additoinal-fields-subtitle'>حقول خاصة بخدمة الإرشاد الأكاديمي </p>
            <p className='mt-3'>
              وصف الخدمة:{' '}
              <span className='text-purple'>تقديم خدمة للمستفيد تهدف الى التعرف علي المشكلات التي تعرقل العملية التعليمية.</span>
            </p>
            <div className='row'>
              <InputField
                title='اسم الجهة التعليمية الملتحق بها'
                type='text'
                name='academicAdvising_entityName'
                className='inputText w-100'
                className_div_input='input col-md-6 col-xxl-4'
                required
                error={errors.academicAdvising_entityName && errors.academicAdvising_entityName.message}
                className_error='input__error_form'
                {...register('academicAdvising_entityName', {
                  required: 'fieldRequired',
                })}
              />
              <InputField
                title='وصف الصعوبات التي تواجهها'
                type='text'
                name='difficulties'
                className='inputText w-100'
                className_div_input='input col-md-6 col-xxl-8'
                required
                error={errors.difficulties && errors.difficulties.message}
                className_error='input__error_form'
                {...register('difficulties', {
                  required: 'fieldRequired',
                })}
              />
            </div>
          </>
        )}

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>
            <button
              className='step-btn-prev'
              onClick={() => {
                setCodesEmpty();
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCodesEmpty();
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
        {width > 767 ? null : <hr className='line-form'></hr>}
      </form>
    </>
  );
};

export default AdditionalFields;
