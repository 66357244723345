import React from 'react'
import { useEffect } from 'react'
import $ from 'jquery'

function MonthsList({ onChange, currentTime }) {
  const months = [
    { number: 0, name: 'محرم' },
    { number: 1, name: 'صفر' },
    { number: 2, name: 'ربيع أول' },
    { number: 3, name: 'ربيع ثاني' },
    { number: 4, name: 'جمادي أول' },
    { number: 5, name: 'جمادي ثاني' },
    { number: 6, name: 'رجب' },
    { number: 7, name: 'شعبان' },
    { number: 8, name: 'رمضان' },
    { number: 9, name: 'شوال' },
    { number: 10, name: 'ذو القعدة' },
    { number: 11, name: 'ذو الحجة' },
  ]

  useEffect(() => {
    $('.month-select ul li.option').click(function () {
      $(this).siblings().children().remove()
      $(this).siblings().toggle()
      var selectedMonth = $(this).attr('value')
      var intMonth = parseInt(selectedMonth)
      onChange(intMonth)
    })
  }, [onChange])

  return (
    <div className='month-select'>
      <ul style={{ overflow: 'auto' }} className='month-select__input closed' id='selectboxMonth'>
        {months.map((item, key) => (
          <li className='option' key={item.number} value={item.number}>
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MonthsList
