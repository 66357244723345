import React, { useEffect, useState } from 'react';
import './index.scss';
import TableList from '../../shared/components/TableList';
import { customAuthGet, getApplicationDetails, getServiceProviderApplicationDetails, canCreateRequest } from '../../services/axios/api';
import { useDispatch, useSelector } from 'react-redux';
import { UserData } from '../../redux/actions/userData';
import authManager from '../../services/axios/authManager';
import { useLocation } from 'react-router-dom';
const Home = () => {
  const dispatch = useDispatch();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canOpenRequest, setCanOpenRequest] = useState(false);

  const hasDontaed = useLocation()?.state;
  const user = useSelector((state) => state?.userData?.fetchingReducer?.data);
  const isApplicant = user.account_type === 3;
  const isProvider = user.account_type === 4;

  const CanAdd = async () => {
    const res = await canCreateRequest(user?.user.id);
    setCanOpenRequest(res);
  };
  const fetchData = async (query) => {
    setLoading(true);
    let data = {};
    if (query) {
      if (isApplicant) {
        data = await customAuthGet('/student/requests/?page=' + query);
      }
      if (isProvider) {
        data = await customAuthGet('/service-provider/?page=' + query);
      }
    } else {
      if (isApplicant) {
        data = await getApplicationDetails();
      }
      if (isProvider) data = await getServiceProviderApplicationDetails();
    }
    setApplications(data);
    setLoading(false);
  };

  useEffect(() => {
    if (user?.id) {
      fetchData();
      if (isApplicant) CanAdd();
    }
    // eslint-disable-next-line
  }, [user]);
  const getUserData = async () => {
    if (authManager.getAccessToken()?.length && !user?.id) await dispatch(UserData());
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='home-container'>
      {hasDontaed && (
        <div className='header-message-home'>
          <div className=' header-message-title'>
            <p>شكراً، دعمكم يُمَكّننا من بناء كفاءات تصنع أثراً مستدام</p>
          </div>
          <div className='header-message-sub'>
            <p>تم ارسال طلب تقديم الخدمة بنجاح، سيتم التواصل معكم قريباً</p>
          </div>
        </div>
      )}
      {isApplicant ? (
        <TableList
          headers={['رقم الطلب', 'تاريخ التقديم', 'نوع الطلب', 'الحالة', 'الإجراءات']}
          title='طلباتي'
          subTitle='تمكنك هذه الصفحة من رفع طلبات جديدة ومتابعه حالات الطلبات السابقة'
          data={applications}
          loading={loading}
          fetchNewPage={fetchData}
          isApplicant
          canOpenRequest={canOpenRequest}
        />
      ) : (
        <TableList
          headers={['رقم الخدمة', 'تاريخ التقديم', 'نوع الخدمة']}
          title='خدماتي'
          subTitle='تمكنك هذه الصفحة من تقديم خدمات جديدة كما تمكنك من الاطلاع على الخدمات المقدمة مسبقاً'
          data={applications}
          loading={loading}
          fetchNewPage={fetchData}
          isProvider
        />
      )}
    </div>
  );
};

export default Home;
