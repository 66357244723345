import React, { useState, useEffect } from 'react';
import './PracticalExperiences.scss';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFormContext from '../../../Context/useFormContext';
import { useWindowSize } from '@react-hook/window-size';
import sharedApi from '../../../../../shared';

const { InputField, Upload } = sharedApi.components;

export default function PracticalExperiences() {
  const [width] = useWindowSize();

  const { t } = useTranslation();
  const { formData, setCurrent, current, handleChange } = useFormContext();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const [isSalaryInputVisible, setIsSalaryInputVisible] = useState(false);
  const [showhide, setShowhide] = useState('');
  useEffect(() => {
    // keep values in the form
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
    // eslint-disable-next-line
  }, []);

  // show extra field depend on partTimeSalary and fullTimeSalary value
  useEffect(() => {
    const salaryOptional = +getValues('salary_optional');
    const formDataSalaryOptional = +formData.salary_optional;
    if (formDataSalaryOptional === 4 || salaryOptional === 4) {
      setIsSalaryInputVisible(true);
    } else {
      setValue('salary', '');
      setIsSalaryInputVisible(false);
    }
    // eslint-disable-next-line
  }, [watch('salary_optional'), formData]);

  // show salary depend on employmentStatus value
  useEffect(() => {
    const employmentStatus = +getValues('employmentStatus');
    const formDataEmploymentStatus = +formData.employmentStatus;
    setShowhide(employmentStatus);

    if (formDataEmploymentStatus === 1 || employmentStatus === 1) {
      setValue('salary_optional', '');
      setValue('salary', '');
    } else {
      setValue('Entity_name', '');
      setValue('Number_of_hours', '');
    }

    if (employmentStatus === 2 || employmentStatus === 3) {
      setValue('salary_optional', formData.salary_optional !== '' ? formData.salary_optional : '1');
    }

    // eslint-disable-next-line
  }, [watch('employmentStatus'), showhide, getValues, setValue, formData]);

  return (
    <div>
      {width > 767 ? <p className='services-tit'>الخبرات العملية</p> : null}

      <form onSubmit={handleSubmit(handleChange)}>
        <div className='row row-cols-md-2 row-cols-xxl-3'>
          <div className='form-group-select'>
            <label htmlFor='employmentStatus' className='form-label'>
              الحالة الوظيفية (اختياري)
            </label>

            <select name='employmentStatus' {...register('employmentStatus')} className='form-select form-input options w-100'>
              <option value=''>اختر</option>
              <option value='2'>عمل جزئي</option>
              <option value='3'>عمل كامل</option>
              <option value='1'> تطوع</option>
            </select>
          </div>

          {(showhide === 2 || showhide === 3) && (
            <div className='form-group-select'>
              <label htmlFor='salary_optional' className='form-label'>
                الراتب الشهري (اختياري)
              </label>
              <select name='salary_optional' {...register('salary_optional')} className='form-select form-input options w-100'>
                <option value='1'>1000-2999</option>
                <option value='2'>3000-5999</option>
                <option value='3'>6000-8999</option>
                <option value='4'>اخرى</option>
              </select>
            </div>
          )}

          {isSalaryInputVisible && (showhide === 3 || showhide === 2) && (
            <InputField
              required
              title='extraField'
              type='text'
              className='inputText w-100 mt-0'
              className_div_input='input pt-0'
              classNameLabel='form-label'
              name='salary'
              error={errors?.salary && errors?.salary?.message}
              style={{ backgroundColor: 'white', width: '100%', height: '36px' }}
              {...register('salary', {
                required: 'fieldRequired',
                onChange: (e) =>
                  setValue('salary', /[^\d+$]/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value),
              })}
            />
          )}

          {(showhide === 1 || showhide === 2 || showhide === 3) && (
            <InputField
              title='Entity_name'
              type='text'
              name='Entity_name'
              classNameLabel='form-label'
              className='inputText w-100 mt-0'
              className_div_input='input pt-0'
              style={{ backgroundColor: 'white', width: '100%', height: '36px' }}
              {...register('Entity_name')}
            />
          )}
          {showhide === 1 && (
            <InputField
              title='Number_of_hours'
              type='text'
              name='Number_of_hours'
              className='inputText w-100 mt-0'
              className_div_input='input pt-0'
              classNameLabel='form-label'
              style={{ backgroundColor: 'white', width: '100%', height: '36px' }}
              {...register('Number_of_hours', {
                onChange: (e) =>
                  setValue(
                    'Number_of_hours',
                    /[^\d+$]/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                  ),
              })}
            />
          )}
        </div>

        <div>
          <label className='AttachExCertificates'>{t('Attach_experiences_certificates')}</label>
          <Controller
            control={control}
            name='Attach_experiences_certificates'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Upload
                allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                onChange={onChange}
                savedFileStatus={
                  formData.Attach_experiences_certificates === '' ||
                  formData.Attach_experiences_certificates === undefined ||
                  formData.Attach_experiences_certificates === null
                    ? false
                    : true
                }
                submitedfile={formData.Attach_experiences_certificates ? formData.Attach_experiences_certificates : null}
                maxSize={10}
                t={t}
              />
            )}
          />
        </div>

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '182px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '182px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
        {width > 767 ? null : <hr className='line-form'></hr>}
      </form>
    </div>
  );
}
