import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import sharedApi from '../../../../shared';
import { useFormContext } from '../../ServiceProviderFormSteps';
import { useWindowSize } from '@react-hook/window-size';
const { InputField, Upload } = sharedApi.components;

const InstitutionRepresntitive = () => {
  const [width] = useWindowSize();

  const { formData, setFormData, setCurrent, current, handleChange } = useFormContext();
  const { t } = useTranslation();

  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const subscription = watch((data) => {
      for (const key in data) {
        data[key] &&
          setFormData((prevData) => ({
            ...prevData,
            [key]: data[key],
          }));
      }
    });
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
    }
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [watch]);
  console.log(formData);
  return (
    <>
      {width > 767 && (
        <>
          <p className='app-contact-title '>معلومات ممثل المنشأة</p>
          <p className='app-contact-subtitle'>الرجاء ادخال بيانات ممثل المنشأة المقدمة للخدمة</p>
        </>
      )}

      <form onSubmit={handleSubmit(handleChange)}>
        <div className=' flex-row--column'>
          <div className='row row-cols-md-2 row-cols-xxl-3'>
            <InputField
              title='الاسم الثلاثي'
              type='text'
              name='fullName'
              required
              disabled={true}
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              error={errors.fullName && errors.fullName.message}
              {...register('fullName')}
            />
            <InputField
              title='الهوية الوطنية'
              type='text'
              name='ID'
              required
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              error={errors.ID && errors.ID.message}
              disabled={true}
              {...register('ID')}
            />
            <InputField
              type='number'
              title='رقم الجوال'
              placeholder='05x xxx xxxx'
              name='mobileNo'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              required
              disabled={true}
              error={errors.mobileNo && errors.mobileNo.message}
              {...register('mobileNo')}
            />
            <InputField
              type='number'
              title='additional_mobile'
              placeholder='05x xxx xxxx'
              name='Additional_Number'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              disabled={true}
              error={errors.Additional_Number && errors.Additional_Number.message}
              {...register('Additional_Number')}
            />
            <InputField
              title='email'
              type='email'
              name='email'
              classNameLabel='input__label-personalInfo'
              className='inputText w-100'
              required
              disabled={true}
              error={errors.email && errors.email.message}
              {...register('email')}
            />
          </div>
          <div style={{ marginTop: '4rem' }}>
            <label className='form-label' style={{ fontSize: '1.1rem' }}>
              أرفق نسخة من وثيقة تفويض ممثل الخدمة
              {/* <span className='invalid_checkbox'>*</span> */}
            </label>
            <Controller
              control={control}
              rules={{
                required: 'fieldRequired',
              }}
              name='representative_certificate'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Upload
                  allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                  onChange={onChange}
                  maxSize={10}
                  t={t}
                  required={errors.representative_certificate && 'يجب ارفاق نسخة من وثيقة تفويض ممثل المنشأة'}
                  savedFileStatus={formData.representative_certificate === '' ? false : true}
                  submitedfile={formData.representative_certificate ? formData.representative_certificate : null}
                />
              )}
            />
          </div>
          {errors.representative_certificate && <small className='input__error'>يجب ارفاق نسخة من وثيقة تفويض ممثل المنشأة</small>}
        </div>

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>
            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn'>التالي</button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default InstitutionRepresntitive;
