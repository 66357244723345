import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import errors from './resources/errors.json'
import form from './resources/form.json'
import resetPassword from './resources/reset_password.json'

i18n.use(initReactI18next).init({
  lng: 'ar',
  resources: {
    ar: {
      translation: { ...errors, ...form, ...resetPassword },
    },
  },
})

export default i18n
