import * as resetSelectors from '../../redux/selectors/resetPassword'
import * as resetActions from '../../redux/actions/resetPassword'
import * as setPasswordSelectors from '../../redux/selectors/setPassword'
import * as setPasswordActions from '../../redux/actions/setPassword'

const setPasswordApi = { setPasswordSelectors, setPasswordActions }
const resetApi = { resetActions, resetSelectors }
const resetPassworApplicantdApi = { resetApi, setPasswordApi }

export default resetPassworApplicantdApi

