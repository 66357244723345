import * as Actions from '../action-types'

export const resetAdminFetch = () => ({
  type: Actions.RESET_PASSWORD_ADMIN_FETCH_START,
})

export const resetAdminFetchFinished = () => ({
  type: Actions.RESET_PASSWORD_ADMIN_FETCH_SUCCESS,
})

export const resetAdminFetchFailed = (payload) => ({
  type: Actions.RESET_PASSWORD_ADMIN_FETCH_FAILED,
  payload,
})

export const resetAdmin = (payload) => ({
  type: Actions.RESET_PASSWORD_ADMIN,
  payload,
})