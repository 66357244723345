import * as Actions from '../action-types';

export const setPasswordFetch = () => ({
  type: Actions.SET_PASSWORD_FETCH_START,
});

export const setPasswordFetchFinished = () => ({
  type: Actions.SET_PASSWORD_FETCH_SUCCESS,
});

export const setPasswordFetchFailed = (payload) => ({
  type: Actions.SET_PASSWORD_FETCH_FAILED,
  payload,
});

export const setPassword = (payload) => ({
  type: Actions.REQUEST_SET_PASSWORD,
  payload,
});

export const setServiceProviderPassword = (payload) => ({
  type: Actions.REQUEST_SET_PASSWORD_SERVICE_PROVIDER,
  payload,
});

// RESETPASSWORD VALIDATION

export const resetPasswordValidationFetch = () => ({
  type: Actions.RESET_PASSWORD_VALIDATION_FETCH_START,
});

export const resetPasswordValidationFetchFinished = () => ({
  type: Actions.RESET_PASSWORD_VALIDATION_FETCH_SUCCESS,
});

export const resetPasswordValidationFetchFailed = (payload) => ({
  type: Actions.RESET_PASSWORD_VALIDATION_FETCH_FAILED,
  payload,
});

export const resetPasswordValidation = (payload) => ({
  type: Actions.REQUEST_RESET_PASSWORD_VALIDATION,
  payload,
});
