import AuditorInvitation from './AuditorInvitation'
import * as AuditorInvitationSelectors from '../../../redux/selectors/register'
import * as AuditorInvitationActions from '../../../redux/actions/register'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'

const mapStateToProps = () =>
  createStructuredSelector({
    acceptInviteFetching: AuditorInvitationSelectors.getAuditorInvitationFetching,
    acceptInviteError: AuditorInvitationSelectors.getAuditorInvitationError,
    acceptInviteSuccess: AuditorInvitationSelectors.getAuditorInvitationSuccess,
  })

const mapDispatchToProps = (dispatch) => ({
  acceptRequest: bindActionCreators(AuditorInvitationActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(AuditorInvitation)
