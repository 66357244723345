import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { EDUCATION, options } from '../../../constants';
import './EducationalForm.scss';
import { useForm, Controller } from 'react-hook-form';
import sharedApi from '../../../../../shared';
import useFormContext from '../../../Context/useFormContext';
import { useWindowSize } from '@react-hook/window-size';
import { GPAs, New } from '../../../../../shared/utils/regax';
import { getGregorianYearsForEductionalForm, getHijriYearsForEducationalForm } from 'shared/utils';
const { SelectField, InputField, Upload, Checkbox } = sharedApi.components;

const grYears = getGregorianYearsForEductionalForm();
const hiYears = getHijriYearsForEducationalForm();
const hashedYears = {};
// eslint-disable-next-line
grYears.map((year, idx) => {
  hashedYears[year] = hiYears[idx];
  hashedYears[hiYears[idx]] = year;
});
const EducationalForm = () => {
  const [width] = useWindowSize();
  const gregorianCalendarRef = React.useRef();
  const { formData, setCurrent, current, handleChange } = useFormContext();
  const [isGregorianDate, setIsGregorianDate] = useState(undefined);
  const [currentYear, setCurrentYear] = useState(formData?.GraduateYear);
  const { t } = useTranslation();
  const [gpaSysValue, setGpaSysValue] = useState('');
  const [GPAValue, setGPAValue] = useState('');
  const [qualificationType, setQualificationType] = useState('');
  const [uniformMarkVal, setUniformMarkVal] = useState('');
  const [showUniformMarkInput, setShowUniformMarkInput] = useState(false);
  const graduateYear = t('GraduateYear');
  const yearsList = useMemo(() => {
    const arr = [graduateYear];
    if (isGregorianDate) return arr.concat(grYears);
    return arr.concat(hiYears);
    // eslint-disable-next-line
  }, [isGregorianDate, grYears, hiYears]);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    resetField,
    getValues,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  useEffect(() => {
    // keep values in the form
    for (const key in formData) {
      if (formData[key] !== '' && formData[key] !== 0) {
        setValue(`${[key]}`, formData[key], { shouldValidate: true });
      }
      if (key === 'GPA_System') setGpaSysValue(formData[key]);
      if (key === 'GPA') setGPAValue(formData[key]);
    }

    /**
     * set checkbox value to the form data by ref,
     * ther's an issue with react-hook-form and checkbox to set it, so this workaround works as expected.
     */
    gregorianCalendarRef.current.checked = formData.IsGraduateYearUseGregorianDate;
    setIsGregorianDate(formData.IsGraduateYearUseGregorianDate);
    setCurrentYear(formData.GraduateYear);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentYear) {
      const foundIndex = yearsList.findIndex((year) => year === currentYear);
      if (foundIndex === -1) {
        setValue('GraduateYear', hashedYears[currentYear], { shouldValidate: true });
        setCurrentYear(hashedYears[currentYear]);
      }
    }
    // eslint-disable-next-line
  }, [yearsList, isGregorianDate, currentYear]);

  //to show the uniform mark for the returned requests
  useEffect(() => setQualificationType(+getValues('The_last_qualification_degree')), []);

  useEffect(() => {
    if (qualificationType === 2) {
      calcUniformMarkVal();
      setShowUniformMarkInput(true);
      setGpaSysValue(100);
      setValue('GPA_System', 100);
    } else setShowUniformMarkInput(false);
    // eslint-disable-next-line
  }, [qualificationType]);

  const calcUniformMarkVal = () => {
    const highSchoolPercent = +getValues('GPA') * 0.3;
    const quduratPercent = +getValues('Alqudurat_Degree') * 0.3;
    const tahsseliPercent = +getValues('Tahsseli_Degree') * 0.4;
    if (!highSchoolPercent || !quduratPercent || !tahsseliPercent) {
      setUniformMarkVal('');
      return;
    }
    const uniformMark = highSchoolPercent + quduratPercent + tahsseliPercent;
    setUniformMarkVal(Number(uniformMark.toFixed(2)));
    setValue('uniform_mark', Number(uniformMark.toFixed(2)));
  };
  return (
    <Fragment>
      {width > 767 ? (
        <>
          <p className='app-contact-title ms-0'>{t('educationalQualifications')}</p>
          <p className='app-contact-subtitle'>{t('p_educationalQualifications')} </p>
        </>
      ) : null}

      <form onSubmit={handleSubmit(handleChange)}>
        <div className='row row-cols-md-2 row-cols-xxl-3'>
          <Controller
            name='The_last_qualification_degree'
            control={control}
            rules={{
              required: 'fieldRequired',
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                title='The_last_qualification_degree'
                options={EDUCATION}
                cssClass='w-100'
                onChange={(e) => {
                  setQualificationType(+e.target.value);
                  field?.onChange?.(e.target.value);
                }}
                required
                error={errors.The_last_qualification_degree && errors.The_last_qualification_degree.message}
              />
            )}
          />
          <div>
            <Controller
              name='GraduateYear'
              control={control}
              rules={{
                required: 'fieldRequired',
              }}
              render={({ field }) => {
                return (
                  <SelectField
                    {...field}
                    title='GraduateYear'
                    options={yearsList}
                    cssClass='w-100'
                    classNameFormGroup='input'
                    classNameLabel='mt-0'
                    required
                    onChange={(e) => {
                      setCurrentYear(e.target.value);
                      field?.onChange?.(e.target.value);
                    }}
                  />
                );
              }}
            />
            <small className={'input__error'}>
              <Trans t={t}>{errors.GraduateYear && errors.GraduateYear.message}</Trans>
            </small>
            <div style={{ marginTop: errors.GraduateYear && errors.GraduateYear.message ? 25 : 0 }}>
              <Controller
                name='IsGraduateYearUseGregorianDate'
                control={control}
                render={({ field }) => {
                  return (
                    <Checkbox
                      {...field}
                      title='GregorianCalendar'
                      ref={gregorianCalendarRef}
                      name='GregorianCalendar'
                      onChange={(e) => {
                        field?.onChange?.(e.target.checked);
                        setIsGregorianDate(e.target.checked);
                      }}
                      className='form-check__graduate'
                      classNameLabel='form-check__check-labelu'
                      style={{ backgroundSize: '18px 8px', width: '16px', height: '16px', fontSize: '20px', borderRadius: '4px' }}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className='input'>
            <label htmlFor='GPA_System' className='form-label-c mt-0'>
              نظام المعدل
            </label>
            <select
              name='GPA_System'
              {...register('GPA_System', {
                required: 'fieldRequired',
              })}
              value={gpaSysValue}
              disabled={qualificationType === 2}
              style={{ backgroundColor: qualificationType === 2 && '#EFEFEF' }}
              onChange={(e) => {
                setGpaSysValue(e.target.value);
                setValue('GPA', '');
                clearErrors('GPA');
                resetField('GPA');
              }}
              className='form-select form-input options-edu w-100'
            >
              {options.map((option, index) => (
                <option value={index === 0 ? '' : option.value} key={index} hidden={index === 0}>
                  {option.label}
                </option>
              ))}
            </select>
            <small className={'input__error'}>
              <Trans t={t}>{!gpaSysValue && errors.GPA_System && errors.GPA_System.message}</Trans>
            </small>
          </div>
          <InputField
            title='GPA'
            type='text'
            name='GPA'
            step='.001'
            maxLength={4}
            className='inputTextEd'
            required
            error={errors.GPA && errors.GPA.message}
            className_error='input__error_form'
            {...register('GPA', {
              required: 'fieldRequired',
              pattern: {
                value: New,
                message: 'idInvalid',
              },
              validate: {
                notEmpty: (v) => GPAValue !== 0 || Number(watch('GPA_System')) !== 0 || 'الرجاء اختيار نظام المعدل اولا',
                lessThanSelceted: (v) =>
                  Number(v) <= (gpaSysValue || Number(watch('GPA_System'))) || `يجب أن لا يتعدى المعدل ${Number(watch('GPA_System'))} `,
                moreThanZero: (v) => Number(v) > 0 || `يجب أن يكون المعدل اكبر من صفر`,
              },
              onChange: (e) => {
                setValue(
                  'GPA',
                  // eslint-disable-next-line
                  !/^\d|^(\d*\.\d]+)*$/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                );
                calcUniformMarkVal();
              },
            })}
          />

          <InputField
            title='Alqudurat_Degree'
            type='number'
            name='Alqudurat_Degree'
            required
            // style={{ backgroundColor: 'white', width: '270px', height: '36px' }}
            className='inputTextEd'
            error={errors.Alqudurat_Degree && errors.Alqudurat_Degree.message}
            maxLength={3}
            className_error='input__error_form'
            {...register('Alqudurat_Degree', {
              required: 'fieldRequired',
              pattern: {
                value: GPAs,
                message: 'GATAandSAATInvalid',
              },
              onChange: (e) => {
                setValue(
                  'Alqudurat_Degree',
                  /[^\d+$]/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                );
                calcUniformMarkVal();
              },
            })}
          />
          <InputField
            title='Tahsseli_Degree'
            type='number'
            name='Tahsseli_Degree'
            required
            // style={{ backgroundColor: 'white', width: '270px', height: '36px' }}
            className='inputTextEd'
            error={errors.Tahsseli_Degree && errors.Tahsseli_Degree.message}
            maxLength={3}
            className_error='input__error_form'
            {...register('Tahsseli_Degree', {
              required: 'fieldRequired',
              pattern: {
                value: GPAs,
                message: 'GATAandSAATInvalid',
              },

              onChange: (e) => {
                setValue(
                  'Tahsseli_Degree',
                  /[^\d+$]/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                );
                calcUniformMarkVal();
              },
            })}
          />
          {showUniformMarkInput && (
            <InputField
              title='uniformMark'
              name='uniform_mark'
              value={uniformMarkVal}
              required
              disabled={true}
              className='inputTextEd'
              style={{ backgroundColor: '#EFEFEF' }}
              {...register('uniform_mark')}
            />
          )}
        </div>
        <div style={{ marginTop: '4rem' }}>
          <label className='form-label'>{t('diplomaLabel')}</label>

          <Controller
            control={control}
            rules={{
              required: 'fieldRequired',
            }}
            name='Copy_of_The_Latest_academic_qualification'
            render={({ field: { onChange } }) => (
              <Upload
                allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                onChange={onChange}
                savedFileStatus={formData.Copy_of_The_Latest_academic_qualification === '' ? false : true}
                submitedfile={
                  formData.Copy_of_The_Latest_academic_qualification ? formData.Copy_of_The_Latest_academic_qualification : null
                }
                maxSize={10}
                t={t}
              />
            )}
          />
          <small className={'input__error'}>
            <Trans t={t}>
              {errors.Copy_of_The_Latest_academic_qualification && errors.Copy_of_The_Latest_academic_qualification.message}
            </Trans>
          </small>
        </div>
        <div>
          <label className='form-label'>{t('quduratLabel')}</label>

          <Controller
            control={control}
            rules={{
              required: 'fieldRequired',
            }}
            name='Copy_of_qudurat_certificate'
            render={({ field: { onChange } }) => (
              <Upload
                allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                onChange={onChange}
                savedFileStatus={formData?.Copy_of_qudurat_certificate === '' ? false : true}
                submitedfile={formData?.Copy_of_qudurat_certificate ? formData?.Copy_of_qudurat_certificate : null}
                maxSize={10}
                t={t}
              />
            )}
          />
          <small className={'input__error'}>
            <Trans t={t}>{errors.Copy_of_qudurat_certificate && errors.Copy_of_qudurat_certificate.message}</Trans>
          </small>
        </div>
        <div>
          <label className='form-label'>{t('tahsseliLabel')}</label>

          <Controller
            control={control}
            rules={{
              required: 'fieldRequired',
            }}
            name='Copy_of_tahsseli_certificate'
            render={({ field: { onChange } }) => (
              <Upload
                allowedTypes={['.pdf', '.png', '.jpg', '.jpeg']}
                onChange={onChange}
                savedFileStatus={formData.Copy_of_tahsseli_certificate === '' ? false : true}
                submitedfile={formData.Copy_of_tahsseli_certificate ? formData.Copy_of_tahsseli_certificate : null}
                maxSize={10}
                t={t}
              />
            )}
          />
          <small className={'input__error'}>
            <Trans t={t}>{errors.Copy_of_tahsseli_certificate && errors.Copy_of_tahsseli_certificate.message}</Trans>
          </small>
        </div>

        {width > 767 ? (
          <div className='steps-action' style={{ marginTop: '40px' }}>
            <button type='submit' className='step-btn'>
              التالي
            </button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        ) : (
          <div className='steps-action2' style={{ marginTop: '40px' }}>
            <button className='step-btn' disabled={!isValid}>
              التالي
            </button>

            <button
              className='step-btn-prev'
              onClick={() => {
                setCurrent(current - 1);
              }}
              disabled={current === 0}
            >
              السابق
            </button>
          </div>
        )}
        {width > 767 ? null : <hr className='line-form'></hr>}
      </form>
    </Fragment>
  );
};

export default EducationalForm;
