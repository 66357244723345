// Project dependency
import React from 'react'
import './PasswordValidation.scss'

function PasswordValidation({ capsLetterFlag, smallLetterFlag, numberFlag, specialCharFlag, pwdLengthFlag, customStyle }) {
  return (
    <div>
      <p className='password__error'>يجب ان تتضمن كلمة المرور 8 احرف على الاقل وتستوفي النقاط التالية:</p>
      <div className={`password__error-check ${customStyle}`}>
        <p className={capsLetterFlag}>حرف كبير (A-Z)</p>
        <p className={smallLetterFlag}>حرف صغير (a-z)</p>
        <p className={numberFlag}>ارقام (0-9)</p>
        <p className={specialCharFlag}>رموز خاصة @#$%-</p>
      </div>
    </div>
  )
}

export default PasswordValidation
