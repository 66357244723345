import React from 'react'
import { useTranslation } from 'react-i18next'

import './Button.scss'

export default function Button({ titleButton, classNameBtn, disabled, buttonStyle, customStyle, onClick }) {
  const { t } = useTranslation()

  const STYLES = ['btn--primary', 'btn--secondary']
  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]

  return (
    <>
      <button className={classNameBtn?classNameBtn:`btn ${checkButtonStyle} ${customStyle}`} disabled={disabled} onClick={onClick}>
        <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='arrow bi bi-arrow-left' viewBox='0 0 16 16'>
          <path
            fillRule='evenodd'
            d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
          />
        </svg>
        {t(titleButton)}
      </button>
    </>
  )
}
