import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import withRouter from '../../../shared/utils/with-router';
import composeEnhancers from '../../../shared/utils/compose-enhancers';
import loginAndValidationApI from '..';
import OtpLogin from './OtpLogin';
import * as userDataActions from '../../../redux/actions/userData';

const mapStateToProps = () =>
  createStructuredSelector({
    fetching: loginAndValidationApI.otpLoginApi.otpLoginSelectors.getOtpLoginFetching,
    error: loginAndValidationApI.otpLoginApi.otpLoginSelectors.getOtpLoginError,
    success: loginAndValidationApI.otpLoginApi.otpLoginSelectors.getOtpLoginSuccess,
    anotherOtpLoginFetching: loginAndValidationApI.otpLoginApi.otpLoginSelectors.getAnotherOtpLoginFetching,
    anotherOtpLoginFetchingError: loginAndValidationApI.otpLoginApi.otpLoginSelectors.getAnotherOtpLoginError,
    anotherOtpLoginSuccess: loginAndValidationApI.otpLoginApi.otpLoginSelectors.getAnotherOtpLoginSuccess,
  });

const mapDispatchToProps = (dispatch) => ({
  otpLoginValidationActions: bindActionCreators(loginAndValidationApI.otpLoginApi.otpLoginActions, dispatch),
  requestUserData: bindActionCreators(userDataActions, dispatch),
});

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(OtpLogin);
