import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import withRouter from '../../../shared/utils/with-router'
import composeEnhancers from '../../../shared/utils/compose-enhancers'
import ApplicantRegistration from './ApplicantRegistration'
import registerAndValidationApI from '..'

const mapStateToProps = () =>
  createStructuredSelector({
    fetching: registerAndValidationApI.registerApi.registerSelectors.getRegisterFetching,
    error: registerAndValidationApI.registerApi.registerSelectors.getRegisterError,
    success: registerAndValidationApI.registerApi.registerSelectors.getRegisterSuccess,
  })

const mapDispatchToProps = (dispatch) => ({
  registerActions: bindActionCreators(registerAndValidationApI.registerApi.registerActions, dispatch),
})

export default composeEnhancers(withRouter, connect(mapStateToProps, mapDispatchToProps))(ApplicantRegistration)
