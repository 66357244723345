import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './AvatarUploader.scss';

const AvatarUploader = ({ name, logo, value, onChange, error, cssClass }, ref) => {
  const uploadRef = useRef();
  const { t } = useTranslation();

  return (
    <div>
      <div className={'avatar ' + cssClass}>
        <img
          name={name}
          onClick={() => uploadRef.current.click()}
          src={error ? logo : value ? URL.createObjectURL(value) : logo}
          width='115'
          height='115'
          alt=''
          className={error ? '' : value ? 'avatar-border' : ''}
        />
        <input
          name={name}
          accept='.jpg, .jpeg, .png '
          ref={uploadRef}
          type='file'
          className='avatar-input'
          onChange={(e) => onChange(e.target.files[0])}
        />
      </div>
      {error && <span className='avatar-error'>{t(error)}</span>}
    </div>
  );
};

export default React.forwardRef(AvatarUploader);
