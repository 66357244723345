import React, { useEffect, useState } from 'react';
import './AuditorList.scss';
import { activeInactiveAdutor, customeAuthGetAuditorList, getAuditorList, ExportAuditorList } from '../../../services/axios/api';
import TableList from '../../../shared/components/TableList';
import { message } from 'antd';
import activeDeactivBtn from '../../../markup/images/activeDeactivBtn.svg';


const ToggleItem = ({ id, fetchData, toggleThisElement }) => {
  const assign = async () => {
    try {
      if (toggleThisElement === true) {
        await activeInactiveAdutor(id, 3);
        // for updating status
        fetchData();
        setTimeout(() => {
          message.success({
            type: 'success',
            content: 'تم تعطيل الحساب',
            duration: 3,
          });
        }, 2);
      } else if (toggleThisElement === false) {
        await activeInactiveAdutor(id, 2);
        // for updating status
        fetchData();
        setTimeout(() => {
          message.success({
            type: 'success',
            content: 'تم تفعيل الحساب',
            duration: 3,
          });
        }, 2);
      }
    } catch (error) {
      console.log('error ', error);
    }
  };

  return (
    <div className='single-history' key={id}>
      <button
        type='submit'
        className='table-procedures-btn'
        onClick={() => {
          assign();
        }}
      >
        <p style={{ paddingTop: '4px', marginBottom: '-4px' }}>
          <img src={activeDeactivBtn} alt='active Deactiv Btn' />
        </p>
        <p style={{ marginTop: '2px' }}> {toggleThisElement ? 'تعطيل الحساب' : 'تنشيط الحساب'}</p>
      </button>
    </div>
  );
};

const AuditorList = (props) => {
  const [auditor, setAuditor] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchData = async (query) => {
    setLoading(true);
    let data = {};
    query = query ? query : 1;
    if (query || searchText !== '') {
      data = await customeAuthGetAuditorList('/user/users/?account_type=2&page=' + query + '&search=' + searchText);
    } else {
      data = await getAuditorList();
    }
    setAuditor(data);
    setLoading(false);
  };
  const exportAuditorList = async () => {
    setTimeout(() => {
      message.success({
        type: 'success',
        content: 'تم ارسال الملف الى البريد الإلكتروني ',
        duration: 3,
      });
    }, 2);
    await ExportAuditorList();
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const searchHanddler = async (e) => {
    e.preventDefault();
    fetchData();
  };

  return (
    <div>
      <div className='auditorList-container'>

        <TableList
          headers={['الاسم', 'البريد الإلكتروني', 'رقم الجوال', 'الحالة', 'الإجراءات']}
          title='إدارة المدققين'
          subTitle='يمكنك إضافة، تنشيط وتعطيل حسابات المدققين'
          searchInput={
            <div>
              <div className="search-container">
                <div className="search-input">
                  <form onSubmit={searchHanddler}>
                    <input
                      placeholder="ابحث بالاسم او البريد الالكتروني"
                      type="text"
                      onChange={(e) => setSearchText(e.target.value)}
                      value={searchText}
                      style={{
                        fontFamily: 'DIN Regular',
                        backgroundColor: '#12284F0D',
                        width: '255px',
                        height: '40px',
                        marginTop: '10px',
                        fontWeight: 'bold',
                        paddingRight: '20px',
                        borderRadius: '15px',
                      }}
                      onBlur={searchHanddler}
                    />

                  </form>

                </div>
              </div>
              <div>
                <button onClick={exportAuditorList} className="export-button">تصدير</button>
              </div>

            </div>
          }
          data={auditor}
          fetchNewPage={fetchData}
          loading={loading}
          isAdmin={true}
          addAduditor={true}
          ToggleAuditor={ToggleItem}
        />
      </div>
    </div>
  );
};

export default AuditorList;
