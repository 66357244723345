export default class JWTToken {
  constructor(name) {
    this.name = name;
    this.token = localStorage.getItem(this.name) || '';
  }

  get token() {
    return localStorage.getItem(this.name) || '';
  }

  set token(token) {
    localStorage.setItem(this.name, token);
  }

  removeToken() {
    localStorage.removeItem(this.name);
  }

  urlBase64Decode(str) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/').replace(/JWT /g, '');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return decodeURIComponent(window.atob(output));
  }

  decodeToken() {
    const parts = this.token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must be 3 parts!');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode token!');
    }
    return JSON.parse(decoded);
  }

  getExpirationDate() {
    if (this.token) {
      return new Date(this.decodeToken().exp * 1000).getTime();
    }
    return 0;
  }

  getUserType() {
    if (this.token) {
      switch (this.decodeToken().account_type) {
        case 1:
          return 'Admin';
        case 2:
          return 'Auditor';
        case 3:
          return 'Student';
        case 4:
          return 'ServiceProvider';
        default:
          return 'Illegal account type!';
      }
    }
    return 0;
  }
}
