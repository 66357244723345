import React from 'react';
import { FormProvider } from './Context/FormContext';
import ServiceProviderFormSteps from './ServiceProviderFormSteps';

function ApplicantForm() {
  return (
    <div className='bg-ApplicantForm'>
      <div className='row-header py-5'>
        <div className='col-header'>
          <div className='header-link'>
            <a className='underline' href='/'>
              الرئيسية <i className='arrow_ left'></i>
            </a>
            <a className='underline' href='/home'>
              خدماتي <i className='arrow_ left'></i>
            </a>
            <p href='/'>نموذج تقديم خدمة</p>
          </div>
        </div>
      </div>
      <FormProvider>
        <ServiceProviderFormSteps />
      </FormProvider>
    </div>
  );
}

export default ApplicantForm;
