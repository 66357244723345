import { setPassword, passwordValidate, setServiceProviderPassword } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import {
  setPasswordFetch,
  setPasswordFetchFinished,
  setPasswordFetchFailed,
  resetPasswordValidationFetch,
  resetPasswordValidationFetchFinished,
  resetPasswordValidationFetchFailed,
} from '../actions/setPassword';

function* setPasswordAsync(action) {
  yield put(setPasswordFetch());
  try {
    yield call(setPassword, action.payload);
    yield put(setPasswordFetchFinished());
  } catch (error) {
    yield put(setPasswordFetchFailed(error.response.data));
  }
}
function* setServiceProviderPasswordAsync(action) {
  yield put(setPasswordFetch());
  try {
    yield call(setServiceProviderPassword, action.payload);
    yield put(setPasswordFetchFinished());
  } catch (error) {
    yield put(setPasswordFetchFailed(error.response.data));
  }
}

function* setPassworValidationdAsync(action) {
  yield put(resetPasswordValidationFetch());
  try {
    yield call(passwordValidate, action.payload);
    yield put(resetPasswordValidationFetchFinished());
  } catch (error) {
    yield put(resetPasswordValidationFetchFailed(error.response.data));
  }
}

export default function* setPasswordSega() {
  yield all([
    takeLatest(Actions.REQUEST_SET_PASSWORD, setPasswordAsync),
    takeLatest(Actions.REQUEST_SET_PASSWORD_SERVICE_PROVIDER, setServiceProviderPasswordAsync),
    takeLatest(Actions.REQUEST_RESET_PASSWORD_VALIDATION, setPassworValidationdAsync),
  ]);
}
