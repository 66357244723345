import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import authManager from '../axios/authManager';
const PrivateRoute = ({ children, accountType, shouldBeLogged }) => {
  const currentUserType = authManager.getAccountType();
  const user = useSelector((state) => state?.userData?.fetchingReducer?.data);
  const valid = useRef(false);

  if (shouldBeLogged && user?.email) return <Navigate to={'/'} replace />;
  else if (shouldBeLogged) return children;

  if (accountType === 1 && currentUserType === 'Admin') valid.current = true;
  if (accountType === 2 && currentUserType === 'Auditor') valid.current = true;
  if (accountType === 3 && currentUserType === 'Student') valid.current = true;
  if (accountType === 4 && currentUserType === 'ServiceProvider') valid.current = true;

  if (
    typeof accountType === 'object' &&
    ((accountType.includes(1) && currentUserType === 'Admin') ||
      (accountType.includes(2) && currentUserType === 'Auditor') ||
      (accountType.includes(3) && currentUserType === 'Student') ||
      (accountType.includes(4) && currentUserType === 'ServiceProvider'))
  ) {
    valid.current = true;
  }

  return valid.current ? (
    children
  ) : accountType === 1 || accountType === 2 ? (
    <Navigate to='/login' replace />
  ) : (
    <Navigate to='/applicant-login' replace />
  );
};

export default PrivateRoute;
