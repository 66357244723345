import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { Card, LogoHeader, Button, Toaster, InputField, PasswordValidation } from '../../../shared/components';
import { PASSWORD_PATTERN } from '../../../shared/utils/regax';
import '../../ResetPassword/SetPassword/index.scss';

export default function ChangePassword(props) {
  const accountType = useSelector((state) => state?.userData?.fetchingReducer?.data?.account_type);
  const { changePasswordActions } = props;
  const Navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  // const { setPasswordRequest } = props
  // const { token } = useParams()
  const [passworError] = useState('');
  const [specialChar, setSpecialChar] = useState('');
  const [number, setNumber] = useState('');
  const [uppercase, setUppercase] = useState('');
  const [lowercase, setLowercase] = useState('');
  const [showResults, setShowResults] = useState({ message: '', type: '' });

  const handleChange = (data) => {
    changePasswordActions.changePassword(data);
    //   setPasswordRequest.setPassword({ new_password: data.password, re_new_password: data.confirmPassword, token })
  };
  useEffect(() => {
    if (props.success) {
      setShowResults({ message: '', type: '' });
      setTimeout(() => {
        message.success({
          type: 'success',
          content: 'تم تحديث كلمة المرور بنجاح',

          duration: 0.8,
        });
      }, 1);
      if (accountType === 1 || accountType === 2) return Navigate('/profile');
      else return Navigate('/myprofile');
    } else if (props.error) {
      setShowResults({ message: props.error, type: 'warning' });
    }

    // eslint-disable-next-line
  }, [props]);

  return (
    <div className='admin__page--background'>
      <LogoHeader noMargin='logo-header_noMargin' />

      <div className='flex flex--center'>
        <Card
          title='CHANGE_PASSWORD'
          toaster={
            showResults.message.length ? (
              <Toaster message={showResults.message} type={showResults.type} />
            ) : (
              <div className='without_toster' />
            )
          }
        >
          <div className='validation-margin'>
            <PasswordValidation
              capsLetterFlag={uppercase}
              smallLetterFlag={lowercase}
              numberFlag={number}
              specialCharFlag={specialChar}
              customStyle={'custom-password-validation'}
            />
          </div>
          <form onSubmit={handleSubmit(handleChange)} className='form_padding'>
            <div className='card__body--wide'>
              <InputField
                name='old_password'
                title='currentPassword'
                placeholder='password'
                type='password'
                error={passworError}
                {...register('old_password', {
                  //    validate: {
                  //     //   specialChar: (value) =>
                  //     //     /[#!@$%^&*]/.test(value)
                  //     //       ? setSpecialChar('valid-1')
                  //     //       : value.length
                  //     //         ? setSpecialChar('invalid-1')
                  //     //         : setSpecialChar('default-list'),
                  //     //   number: (value) =>
                  //     //     /[0-9]/.test(value) ? setNumber('valid-1') : value.length ? setNumber('invalid-1') : setNumber('default-list'),
                  //     //   uppercase: (value) =>
                  //     //     /[A-Z]/.test(value)
                  //     //       ? setUppercase('valid-1')
                  //     //       : value.length
                  //     //         ? setUppercase('invalid-1')
                  //     //         : setUppercase('default-list'),
                  //     //   lowercase: (value) =>
                  //     //     /[a-z]/.test(value)
                  //     //       ? setLowercase('valid-1')
                  //     //       : value.length
                  //     //         ? setLowercase('invalid-1')
                  //     //         : setLowercase('default-list'),
                  //       eightCharsOrGreater: (value) => PASSWORD_PATTERN.test(value),
                  // },
                })}
              />

              <InputField
                name='password'
                title='NEW_PASSWORD'
                placeholder='password'
                type='password'
                error={passworError}
                {...register('password', {
                  validate: {
                    specialChar: (value) =>
                      /[#!@$%^&*]/.test(value)
                        ? setSpecialChar('valid-1')
                        : value.length
                        ? setSpecialChar('invalid-1')
                        : setSpecialChar('default-list'),
                    number: (value) =>
                      /[0-9]/.test(value) ? setNumber('valid-1') : value.length ? setNumber('invalid-1') : setNumber('default-list'),
                    uppercase: (value) =>
                      /[A-Z]/.test(value)
                        ? setUppercase('valid-1')
                        : value.length
                        ? setUppercase('invalid-1')
                        : setUppercase('default-list'),
                    lowercase: (value) =>
                      /[a-z]/.test(value)
                        ? setLowercase('valid-1')
                        : value.length
                        ? setLowercase('invalid-1')
                        : setLowercase('default-list'),
                    eightCharsOrGreater: (value) => PASSWORD_PATTERN.test(value),
                  },
                })}
              />

              <InputField
                name='confirmPassword'
                title='CONFIRM_NEW_PASSWORD'
                placeholder='password'
                type='password'
                error={errors.confirmPassword && errors.confirmPassword.message}
                {...register('confirmPassword', {
                  required: 'fieldRequired',
                  validate: (value) => value === watch('password') || 'matchPasswordInvalid',
                })}
              />
            </div>

            <div className='card__btn'>
              <Button buttonStyle={true ? 'btn--secondary' : 'btn--primary'} titleButton='CHANGE_PASSWORD' disabled={!isValid} />
            </div>
            {/* <div style={{ position: 'absolute', top: "var(--customTop)" }}>
                        {error &&
                            <small style={{ color: '#B41515' }} className='otp__input__error'>
                                {t(error)}
                            </small>
                        }
                    </div> */}
          </form>
        </Card>
      </div>
    </div>
  );
}
