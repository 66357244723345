import { combineReducers } from 'redux';
import * as Actions from '../../action-types';

const initialFetchingState = {
  fetching: null,
  fetchingError: null,
  success: null,
  anotherOtpFetching: null,
  anotherOtpFetchingError: null,
  anotherOtpSuccess: null,
};

const fetchingReducer = (state = initialFetchingState, action) => {
  switch (action.type) {
    case Actions.OTP_FETCH_START:
      return {
        ...state,
        fetching: true,
      };
    case Actions.OTP_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        anotherOtpFetching: null,
        anotherOtpFetchingError: null,
        anotherOtpSuccess: null,
        fetchingError: action.payload,
      };
    case Actions.OTP_FETCH_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case Actions.ANOTHER_OTP_START:
      return {
        ...state,
        anotherOtpFetching: true,
      };
    case Actions.ANOTHER_OTP_FAILED:
      return {
        ...state,
        anotherOtpFetching: false,
        anotherOtpFetchingError: action.payload,
      };
    case Actions.ANOTHER_OTP_SUCCESS:
      return {
        ...state,
        anotherOtpSuccess: true,
      };
    case Actions.RESET_STATE:
      return initialFetchingState;
    default:
      return state;
  }
};

export default combineReducers({
  fetchingReducer,
});
