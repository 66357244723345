import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import Remove from '../../../markup/images/trash.svg';
import noOrders from '../../../assets/images/shared/no-orders.svg';
import noOrdersProvider from '../../../assets/images/shared/no-orders-provider.svg';
import activeDeactivBtn from '../../../markup/images/activeDeactivBtn.svg';
import { useTranslation } from 'react-i18next';
import noContent from '../../../assets/images/shared/no-content.svg';
import { useWindowSize } from '@react-hook/window-size';
import { resendInvitation, getUserQiyas, getUserData as getApplicantAndSPData } from '../../../services/axios/api';
import { message } from 'antd';
import { SERVICE_DETAILS, SERVICE_TYPE } from '../../../pages/ServiceProvider/constants';

const TableList = ({
  title,
  subTitle,
  headers,
  data,
  searchInput,
  addAduditor,
  newList,
  addService,
  fetchNewPage,
  loading,
  isApplicant,
  isProvider,
  canOpenRequest,
  isAdmin,
  ToggleAuditor,
  displayServiceForAdmin,
  isMemberList,
  isAuditor,
  toggleModal,
  onUserStatusChange,
  onQiyasStatusChange,
}) => {
  const { t } = useTranslation();
  let pages = Math.ceil(data?.count / 7);
  const [current, setCurrent] = useState(1);
  const changePage = (page) => setCurrent(page);
  const [midPages, setMidPages] = useState([]);
  const [hasQiyasAccess, setHasQiyasAccess] = useState(false);
  const [width] = useWindowSize();
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);

  useEffect(() => {
    if (pages > 5) {
      setMidPages(
        [...new Array(pages >= 6 ? 3 : 0).fill(current <= 3 ? 2 : current > pages - 3 ? pages - 3 : current - 1)].map((e, i) => e + i)
      );
    } else if (pages > 2) {
      pages === 3 ? setMidPages([2]) : pages === 4 ? setMidPages([2, 3]) : setMidPages([2, 3, 4]);
    } else setMidPages([]);
    // eslint-disable-next-line
  }, [current, data]);

  useEffect(() => {
    validateUserQiyas();
    // eslint-disable-next-line
  }, []);
  
  const Status = {
    1: 'بانتظار المعالجة',
    2: 'قبول أولي',
    3: 'بانتظار التعديل',
    4: 'مرفوض',
    5: 'قبول نهائي',
  };
  const StatusApplicant = {
    1: 'بانتظار المعالجة',
    2: 'بانتظار المعالجة',
    3: 'بانتظار التعديل',
    4: 'مرفوض',
    5: 'مقبول',
  };

  const resend = async (id) => {
    await resendInvitation(id);
    setTimeout(() => {
      message.success({
        type: 'success',
        content: 'تم اعادة ارسال الاضافه',
        duration: 2,
      });
    }, 1);
  };

  const validateUserQiyas = async () => {
    const data = await getUserQiyas();
    const response = await getApplicantAndSPData();
    data.accept_status && setHasQiyasAccess(true);
    response.gender && response.social_status && response.user.family_members && setIsProfileCompleted(true);
  };
  const userActiveBadge = {
    color: '#12284F',
    background: '#D6EAD7',
    textAlign: 'center',
    borderRadius: '5px',
    padding: '5px 20px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  };
  const userDisabledBadge = {
    color: '#12284F',
    background: '#D8D8D9',
    textAlign: 'center',
    borderRadius: '5px',
    padding: '5px 20px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  };
  return (
    <div className='auditorList'>
      <div className={`orderes-header ${isProvider ? 'flex-row--align-center' : ''}`}>
        {isProvider && (
          <Link to={'/service-provider/new'} state={{ details: null }} className='nav-btn'>
            + خدمة جديدة
          </Link>
        )}
        {isApplicant ? (
          <div className='newReqApplicant'>
            <Link
              to={canOpenRequest && hasQiyasAccess && isProfileCompleted ? '/applicant-form' : '#'}
              tabIndex={(!canOpenRequest || !hasQiyasAccess || !isProfileCompleted) && -1}
              state={{ details: null }}
              className={`${(!canOpenRequest || !hasQiyasAccess || !isProfileCompleted) && `btn-disabled`} nav-btn nav-btn-resize`}
            >
              + طلب جديد
            </Link>
            {!canOpenRequest && (
              <p style={{ color: '#717171', fontSize: '13px', width: '70%', textAlign: 'center' }}>
                يمكنك إنشاء طلب اخر بعد انتهاء المدققين من مراجعة طلبك الحالي
              </p>
            )}
            {!hasQiyasAccess && (
              <p style={{ color: '#717171', fontSize: '13px', width: '85%', textAlign: 'center' }}>
                يؤسفنا ابلاغك بأنك غير مؤهل/ـة للإستفادة من الخدمة
              </p>
            )}
            {!isProfileCompleted && (
              <p style={{ color: '#717171', fontSize: '13px', width: '85%', textAlign: 'center' }}>
                يرجى استكمال بيانات الملف الشخصي للإستفادة من الخدمة
              </p>
            )}
          </div>
        ) : (
          <>
            {addAduditor && (
              <div className='newReqApplicant'>
                <Link to='/add-auditor' className='btn-aduter-list'>
                  <span style={{ marginLeft: '14px', fontSize: '25px', marginBottom: '2px' }}> +</span>
                  إضافة مدقق
                </Link>
              </div>
            )}
            {addService && (
              <div className='newReqApplicant'>
                <Link to='/add-services' className='btn-aduter-list'>
                  <span style={{ marginLeft: '14px', fontSize: '25px', marginBottom: '2px' }}> +</span>
                  إضافة خدمة
                </Link>
              </div>
            )}
          </>
        )}
        <div className='header-right'>
          <p className='orders-title' style={{ color: isApplicant ? '#513b71' : '#12284F' }}>
            {title}
          </p>
          <p className='orders-subtitle' style={isApplicant && { color: '#513b71' }}>
            {subTitle}
          </p>
          {searchInput && <>{searchInput}</>}
        </div>
      </div>

      {(addAduditor || isApplicant) && width < 770 ? (
        <>
          {loading ? (
            <div className='no-orders'>
              <div className='no-orders-texts'>
                <p>جار التحميل</p>
                <p>يرجى الانتظار ..</p>
              </div>
            </div>
          ) : (
            <>
              {data?.results?.length ? (
                data?.results?.map((row, i) => (
                  <div key={i}>
                    <div className='mobile-hr' />
                    <div className='mobile-list'>
                      <div className='mobile-head' style={{ color: isApplicant || isProvider ? '#513b71' : '#12284F' }}>
                        {headers.map((header, i) => (
                          <p key={i}>{header}</p>
                        ))}
                      </div>
                      <div className='mobile-data'>
                        {isProvider ? (
                          <>
                            <p>{row?.id}</p>
                            <p>{row?.created_at?.slice(0, 10).replace(/-/g, '/')}</p>
                            <p>
                              {row?.service_info?.service_type === 5
                                ? row?.service_info?.service_details_other
                                : SERVICE_DETAILS.find((x) => x.some((a) => a.value === row?.service_info?.service_details))?.filter(
                                    (x) => x.value === row?.service_info?.service_details
                                  )[0]?.ar_label}
                            </p>
                          </>
                        ) : addService ? (
                          <>
                            <p>{row?.id}</p>
                            <p>{row?.name}</p>
                            <p>
                              {row.active ? (
                                <button
                                  type='submit'
                                  className='table-procedures-btn flex-btn justify-content-evenly align-items-center btn-danger'
                                  onClick={() => toggleModal(row?.id)}
                                >
                                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' width='20'>
                                    <path d='M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z' />
                                    <path d='M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z' />
                                    <path d='M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z' />
                                  </svg>

                                  <p>تعطيل الخدمة</p>
                                </button>
                              ) : (
                                <button
                                  type='submit'
                                  className='table-procedures-btn flex-btn justify-content-evenly align-items-center'
                                  onClick={() => toggleModal(row?.id)}
                                >
                                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' width='20'>
                                    <path d='M12 15a3 3 0 100-6 3 3 0 000 6z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z'
                                      clipRule='evenodd'
                                    />
                                  </svg>

                                  <p>تفعيل الخدمة</p>
                                </button>
                              )}
                            </p>
                          </>
                        ) : isMemberList && isAdmin ? (
                          <>
                            <p>{row?.user?.national_id_number}</p>
                            <p>{row?.email}</p>
                            <p>{row?.mobile_number}</p>
                            <p style={row?.status === 2 ? userActiveBadge : userDisabledBadge}>{row?.status === 2 ? 'نشط' : 'غير نشط'}</p>
                            <p>
                              <button
                                type='submit'
                                className='table-procedures-btn d-inline-flex justify-content-evenly align-items-center me-2'
                                onClick={() => onUserStatusChange(row?.id, row?.status)}
                              >
                                <img src={Remove} alt='active Deactiv Btn' />
                                <p> {row?.status === 2 ? 'تعطيل الحساب' : 'تنشيط الحساب'}</p>
                              </button>
                            </p>
                            <p>
                              <button
                                type='submit'
                                className='table-procedures-btn d-inline-flex justify-content-evenly align-items-center'
                                onClick={() => onQiyasStatusChange(row?.id, row?.user?.qiyas_exception)}
                              >
                                <img src={Remove} alt='active Deactiv Btn' />
                                <p> {row?.user?.qiyas_exception ? 'تنشيط الشرط' : 'تعطيل الشرط'}</p>
                              </button>
                            </p>
                          </>
                        ) : (
                          <>
                            {!addAduditor && !displayServiceForAdmin ? row?.id : row.name}
                            {displayServiceForAdmin && row.establishment_info.establishment_name}

                            <p>
                              {!displayServiceForAdmin && row?.created_at?.slice(0, 10).replace(/-/g, '/')}
                              {displayServiceForAdmin &&
                                (row?.service_info?.service_type === 5
                                  ? row?.service_info?.service_details_other
                                  : SERVICE_DETAILS.find((x) => x.some((a) => a.value === row?.service_info?.service_details))?.filter(
                                      (x) => x.value === row?.service_info?.service_details
                                    )[0]?.ar_label)}
                              {addAduditor && row.email}
                            </p>
                            {row.AuditorMobileNumber && <p>{row.AuditorMobileNumber}</p>}
                            {addAduditor && <td>{<p>{row.mobile_number}</p>}</td>}
                            {!displayServiceForAdmin &&
                              (newList || isApplicant ? (
                                <p>{row.service?.reduce((a, e) => (a += t(e.name) + ', '), '').slice(0, -2)}</p>
                              ) : !addAduditor ? (
                                <p
                                  className='table-status'
                                  style={{
                                    backgroundColor:
                                      row.status === 4
                                        ? '#F4DBDB'
                                        : row.status === 3
                                        ? '#F4B654'
                                        : row.status === 2 || row.status === 5
                                        ? '#D6EAD7'
                                        : '#B5D3ED',
                                    color:
                                      row.status === 4
                                        ? '#B41515'
                                        : row.status === 5 || row.status === 2
                                        ? '#005031'
                                        : row.status === 3
                                        ? '#66380F'
                                        : '#1B2D58',
                                  }}
                                >
                                  {Status[row.status]}
                                </p>
                              ) : (
                                <p
                                  className='table-status-2'
                                  style={{
                                    backgroundColor: row.invitation_confirmed
                                      ? row.status === 2
                                        ? '#d6ead7'
                                        : row.status === 3 && '#D8D8D9'
                                      : '#b5d3ed',
                                  }}
                                >
                                  {row.invitation_confirmed
                                    ? row.status === 2
                                      ? 'نشط'
                                      : row.status === 3 && 'غير نشط'
                                    : 'بإنتظار التفعيل'}
                                </p>
                              ))}

                            {isApplicant && (
                              <p
                                className='table-status'
                                style={{
                                  backgroundColor:
                                    row.status === 4
                                      ? '#F4DBDB'
                                      : row.status === 3
                                      ? '#F4B654'
                                      : (row.status === 2 && !isApplicant) || row.status === 5
                                      ? '#D6EAD7'
                                      : '#B5D3ED',
                                  color:
                                    row.status === 4
                                      ? '#B41515'
                                      : row.status === 5 || (row.status === 2 && !isApplicant)
                                      ? '#005031'
                                      : row.status === 3
                                      ? '#66380F'
                                      : '#1B2D58',
                                }}
                              >
                                {StatusApplicant[row.status]}
                              </p>
                            )}
                            {row.statusAdmin && (
                              <p
                                className='table-status'
                                style={{
                                  backgroundColor:
                                    row.statusAdmin === 'مغلق' || row.statusAdmin === 'غير نشط'
                                      ? '#D8D8D9'
                                      : row.statusAdmin === 'بانتظار التفعيل'
                                      ? '#B5D3ED'
                                      : '#d6ead7',
                                }}
                              >
                                {row.statusAdmin}
                              </p>
                            )}
                            {!addAduditor ? (
                              <Link
                                to={
                                  Status[row.status] === 'بانتظار التعديل' && isApplicant
                                    ? `/applicant-form/${row.id}`
                                    : displayServiceForAdmin
                                    ? `/admin-services-provider-details/${row.id}`
                                    : `/request-details/${row.id}`
                                }
                                state={{ newList, details: row, noBottomBtns: isApplicant ? true : false, isAdmin, isAuditor, isApplicant }}
                                className={displayServiceForAdmin ? 'table-procedures-btn-admin ' : 'table-procedures'}
                              >
                                <p>التفاصيل</p>
                                <p style={{ paddingTop: '4px', marginBottom: '-4px' }}>←</p>
                              </Link>
                            ) : row.invitation_confirmed ? (
                              <ToggleAuditor id={row.id} toggleThisElement={row.status === 2} fetchData={fetchNewPage} />
                            ) : (
                              <p>
                                <span> في حالة انتهاء صلاحية الرابط يمكنك</span>
                                {'    '}
                                <span
                                  className='underline'
                                  style={{ color: '#686FC7', cursor: 'pointer', fontSize: '15px' }}
                                  onClick={() => resend(row.id)}
                                >
                                  إعادة ارسال الإضافة
                                </span>
                              </p>
                            )}
                            {row.procedures?.btn && (
                              <button className='table-procedures-btn' onClick={row.procedures.onClick}>
                                <p style={{ paddingTop: '4px', marginBottom: '-4px' }}>
                                  <img src={activeDeactivBtn} alt='active Deactiv Btn' />
                                </p>
                                <p
                                  style={{
                                    marginTop: '2px',
                                  }}
                                >
                                  {row.procedures.btn}
                                </p>
                              </button>
                            )}
                            {row.procedures?.btnService && (
                              <button className='table-procedures-btn' onClick={row.procedures.onClick}>
                                <p style={{ paddingTop: '4px', marginBottom: '-4px' }}>
                                  <img src={activeDeactivBtn} alt='active Deactiv Btn' />
                                </p>
                                <p
                                  style={{
                                    marginTop: '2px',
                                  }}
                                >
                                  {row.procedures.btnService}
                                </p>
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : isApplicant ? (
                <div className='content-list'>
                  <div className='no-content'>
                    <img className='no-content-img' src={noContent} alt='' width='18%' />
                    <p>لا توجد طلبات</p>
                    <p className='no-content-paragraph'>قم بالتقديم على طلب جديد</p>
                  </div>
                </div>
              ) : isProvider ? (
                <div className='no-orders' style={{ marginTop: '15vh' }}>
                  <img src={noOrdersProvider} alt='No orders found' className='no-orders-img' />
                  <div className='no-orders-texts mt-0'>
                    <p className='text-purple'>لا توجد خدمات</p>
                    <p className='text-purple'>قم بتقديم خدمات جديدة</p>
                  </div>
                </div>
              ) : addService ? (
                <div className='no-orders' style={{ marginTop: '15vh' }}>
                  <img src={noOrdersProvider} alt='No orders found' className='no-orders-img' />
                  <div className='no-orders-texts mt-0'>
                    <p className='text-purple'>لا توجد خدمات</p>
                    <p className='text-purple'>قم باضافة خدمة جديدة</p>
                  </div>
                </div>
              ) : (
                <div className='no-orders'>
                  <img src={noOrders} alt='No orders found' className='no-orders-img' />
                  <div className='no-orders-texts'>
                    <p>لا يوجد طلبات</p>
                    <p>قم بإسناد طلبات جديدة لتتمكن من معالجتها</p>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <table className='auditor-table' style={(isApplicant || isProvider) && { backgroundColor: '#f7f6f8' }}>
          <thead className='table-head' style={isApplicant && { backgroundColor: '#e8e6ee', color: '#513b71' }}>
            <tr>
              {headers.map((header, i) => (
                <th key={i}>{header}</th>
              ))}
            </tr>
          </thead>
          {!loading ? (
            <>
              {data?.results?.length ? (
                data?.results?.map((row, i) => (
                  <tbody key={i}>
                    {addService ? (
                      <tr key={i}>
                        <td>
                          <p>{row?.id}</p>
                        </td>
                        <td>
                          <p>{row?.name}</p>
                        </td>
                        <td>
                          {row.active ? (
                            <button
                              type='submit'
                              className='table-procedures-btn flex-btn justify-content-evenly align-items-center btn-danger'
                              onClick={() => toggleModal(row?.id)}
                            >
                              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' width='20'>
                                <path d='M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z' />
                                <path d='M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z' />
                                <path d='M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z' />
                              </svg>

                              <p>تعطيل الخدمة</p>
                            </button>
                          ) : (
                            <button
                              type='submit'
                              className='table-procedures-btn flex-btn justify-content-evenly align-items-center'
                              onClick={() => toggleModal(row?.id)}
                            >
                              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' width='20'>
                                <path d='M12 15a3 3 0 100-6 3 3 0 000 6z' />
                                <path
                                  fillRule='evenodd'
                                  d='M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z'
                                  clipRule='evenodd'
                                />
                              </svg>

                              <p>تفعيل الخدمة</p>
                            </button>
                          )}
                        </td>
                      </tr>
                    ) : isProvider ? (
                      <tr key={i}>
                        <td>
                          <p>{row?.id}</p>
                        </td>
                        <td>
                          <p>{!displayServiceForAdmin && row?.created_at?.slice(0, 10).replace(/-/g, '/')}</p>
                          <p>
                            {displayServiceForAdmin &&
                              SERVICE_TYPE.filter((x) => x.value === row?.service_info?.service_type).map((x) => x.ar_label)}
                          </p>
                        </td>
                        <td>
                          <p>
                            {row?.service_info?.service_type === 5
                              ? row?.service_info?.service_details_other
                              : SERVICE_DETAILS.find((x) => x.some((a) => a.value === row?.service_info?.service_details))?.filter(
                                  (x) => x.value === row?.service_info?.service_details
                                )[0]?.ar_label}
                          </p>
                        </td>
                      </tr>
                    ) : isAdmin && isMemberList ? (
                      <tr key={i}>
                        <td>
                          <p>{row?.user?.national_id_number}</p>
                        </td>
                        <td>
                          <p>{row?.email}</p>
                        </td>
                        <td>
                          <p>{row?.mobile_number}</p>
                        </td>
                        <td>
                          <p style={row?.status === 2 ? userActiveBadge : userDisabledBadge}>{row?.status === 2 ? 'نشط' : 'غير نشط'}</p>
                        </td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                          <button
                            type='submit'
                            className='table-procedures-btn d-inline-flex justify-content-evenly align-items-center me-2'
                            onClick={() => onUserStatusChange(row?.id, row?.status)}
                          >
                            <img src={Remove} alt='active Deactiv Btn' />
                            <p> {row?.status === 2 ? 'تعطيل الحساب' : 'تنشيط الحساب'}</p>
                          </button>
                        </td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                          <button
                            type='submit'
                            className='table-procedures-btn d-inline-flex justify-content-evenly align-items-center'
                            onClick={() => onQiyasStatusChange(row?.id, row?.user?.qiyas_exception)}
                          >
                            <img src={Remove} alt='active Deactiv Btn' />
                            <p> {row?.user?.qiyas_exception ? 'تنشيط الشرط' : 'تعطيل الشرط'}</p>
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={i}>
                        <td>
                          <p>{!addAduditor && !displayServiceForAdmin ? row?.id : row.name}</p>
                          <p>{isMemberList && row.auditor_name}</p>
                          <p>{displayServiceForAdmin && row.establishment_info.establishment_name}</p>
                        </td>
                        <td>
                          <p>
                            {!displayServiceForAdmin && row?.created_at?.slice(0, 10).replace(/-/g, '/')}
                            {addAduditor && row.email}
                          </p>
                          <p>
                            {displayServiceForAdmin &&
                              (row?.service_info?.service_type === 5
                                ? row?.service_info?.service_details_other
                                : SERVICE_DETAILS.find((x) => x.some((a) => a.value === row?.service_info?.service_details))?.filter(
                                    (x) => x.value === row?.service_info?.service_details
                                  )[0]?.ar_label)}
                          </p>
                        </td>
                        {addAduditor && <td>{<p>{row.mobile_number}</p>}</td>}
                        {row.AuditorMobileNumber && (
                          <td>
                            <p>{row.AuditorMobileNumber}</p>
                          </td>
                        )}
                        {!displayServiceForAdmin && (
                          <td>
                            {newList || isApplicant ? (
                              <p>{row.service?.reduce((a, e) => (a += t(e.name) + ', '), '').slice(0, -2)}</p>
                            ) : !addAduditor ? (
                              <p
                                className='table-status'
                                style={{
                                  backgroundColor:
                                    row.status === 4
                                      ? '#F4DBDB'
                                      : row.status === 3
                                      ? '#F4B654'
                                      : row.status === 2 || row.status === 5
                                      ? '#D6EAD7'
                                      : '#B5D3ED',
                                  color:
                                    row.status === 4
                                      ? '#B41515'
                                      : row.status === 5 || row.status === 2
                                      ? '#005031'
                                      : row.status === 3
                                      ? '#66380F'
                                      : '#1B2D58',
                                }}
                              >
                                {Status[row.status]}
                              </p>
                            ) : (
                              <p
                                className='table-status-2'
                                style={{
                                  backgroundColor: row.invitation_confirmed
                                    ? row.status === 2
                                      ? '#d6ead7'
                                      : row.status === 3 && '#D8D8D9'
                                    : '#b5d3ed',
                                }}
                              >
                                {row.invitation_confirmed ? (row.status === 2 ? 'نشط' : row.status === 3 && 'غير نشط') : 'بإنتظار التفعيل'}
                              </p>
                            )}
                          </td>
                        )}
                        {isApplicant && (
                          <td style={{ minWidth: '7vw' }}>
                            <p
                              className='table-status'
                              style={{
                                backgroundColor:
                                  row.status === 4
                                    ? '#F4DBDB'
                                    : row.status === 3
                                    ? '#F4B654'
                                    : (row.status === 2 && !isApplicant) || row.status === 5
                                    ? '#D6EAD7'
                                    : '#B5D3ED',
                                color:
                                  row.status === 4
                                    ? '#B41515'
                                    : row.status === 5 || (row.status === 2 && !isApplicant)
                                    ? '#005031'
                                    : row.status === 3
                                    ? '#66380F'
                                    : '#1B2D58',
                              }}
                            >
                              {StatusApplicant[row.status]}
                            </p>
                          </td>
                        )}
                        {row.statusAdmin && (
                          <td>
                            <p
                              className='table-status'
                              style={{
                                backgroundColor:
                                  row.statusAdmin === 'مغلق' || row.statusAdmin === 'غير نشط'
                                    ? '#D8D8D9'
                                    : row.statusAdmin === 'بانتظار التفعيل'
                                    ? '#B5D3ED'
                                    : '#d6ead7',
                              }}
                            >
                              {row.statusAdmin}
                            </p>
                          </td>
                        )}
                        <td>
                          {!addAduditor ? (
                            <Link
                              to={
                                Status[row.status] === 'بانتظار التعديل' && isApplicant
                                  ? `/applicant-form/${row.id}`
                                  : displayServiceForAdmin
                                  ? `/admin-services-provider-details/${row.id}`
                                  : `/request-details/${row.id}`
                              }
                              state={{ newList, details: row, noBottomBtns: isApplicant ? true : false, isAdmin, isAuditor, isApplicant }}
                              className={displayServiceForAdmin ? 'table-procedures-btn-admin ' : 'table-procedures'}
                            >
                              <p>التفاصيل</p>
                              <p style={{ paddingTop: '4px', marginBottom: '-4px' }}>←</p>
                            </Link>
                          ) : row.invitation_confirmed ? (
                            <ToggleAuditor id={row.id} toggleThisElement={row.status === 2} fetchData={fetchNewPage} />
                          ) : (
                            <p>
                              <span> في حالة انتهاء صلاحية الرابط يمكنك</span>
                              {'    '}
                              <span
                                className='underline'
                                style={{ color: '#686FC7', cursor: 'pointer', fontSize: '15px' }}
                                onClick={() => resend(row.id)}
                              >
                                إعادة ارسال الإضافة
                              </span>
                            </p>
                          )}
                        </td>
                        {row.procedures?.btn && (
                          <td>
                            <button className='table-procedures-btn' onClick={row.procedures.onClick}>
                              <p style={{ paddingTop: '4px', marginBottom: '-4px' }}>
                                <img src={activeDeactivBtn} alt='active Deactiv Btn' />
                              </p>
                              <p
                                style={{
                                  marginTop: '2px',
                                }}
                              >
                                {row.procedures.btn}
                              </p>
                            </button>
                          </td>
                        )}

                        {row.procedures?.btnService && (
                          <td>
                            <button className='table-procedures-btn' onClick={row.procedures.onClick}>
                              <p style={{ paddingTop: '4px', marginBottom: '-4px' }}>
                                <img src={activeDeactivBtn} alt='active Deactiv Btn' />
                              </p>
                              <p
                                style={{
                                  marginTop: '2px',
                                }}
                              >
                                {row.procedures.btnService}
                              </p>
                            </button>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                ))
              ) : isApplicant ? (
                <tbody>
                  <tr>
                    <td colSpan={5}>
                      <div className='content-list'>
                        <div className='no-content'>
                          <img className='no-content-img' src={noContent} alt='' width='18%' />
                          <p>لا توجد طلبات</p>
                          <p className='no-content-paragraph'>قم بالتقديم على طلب جديد</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : isProvider ? (
                <tbody>
                  <tr>
                    <td colSpan={3}>
                      <div className='no-orders bg-light-purple-grey'>
                        <img src={noOrdersProvider} alt='No orders found' className='no-orders-img' />
                        <div className='no-orders-texts mt-0'>
                          <p className='text-purple'>لا توجد خدمات</p>
                          <p className='text-purple'>قم بتقديم خدمات جديدة</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : addService ? (
                <tbody>
                  <tr>
                    <td colSpan={3}>
                      <div className='no-orders' style={{ marginTop: '15vh' }}>
                        <img src={noOrdersProvider} alt='No orders found' className='no-orders-img' />
                        <div className='no-orders-texts mt-0'>
                          <p className='text-purple'>لا توجد خدمات</p>
                          <p className='text-purple'>قم باضافة خدمة جديدة</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={5}>
                      <div className='no-orders'>
                        <img src={noOrders} alt='No orders found' className='no-orders-img' />
                        <div className='no-orders-texts'>
                          <p>لا يوجد طلبات</p>
                          <p>قم بإسناد طلبات جديدة لتتمكن من معالجتها</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <div className='no-orders'>
                    <div className='no-orders-texts'>
                      <p>جار التحميل</p>
                      <p>يرجى الانتظار ..</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      )}
      {pages > 1 && (
        <div className='pagination'>
          <p
            onClick={() => {
              if (current > 1) {
                setCurrent((prev) => prev - 1);
                fetchNewPage(current - 1);
              }
            }}
            className={`${current === 1 && 'disablePage'}`}
          >
            السابق
          </p>
          <p
            className={`${current === 1 && 'currentPage'}`}
            onClick={() => {
              setCurrent(1);
              fetchNewPage(1);
            }}
          >
            1
          </p>
          {current >= 4 && pages >= 6 && <div>...</div>}
          {midPages.length > 0 &&
            midPages.map((page, i) => (
              <p
                className={`${current === page && 'currentPage'}`}
                onClick={() => {
                  changePage(page);
                  fetchNewPage(page);
                }}
                key={i}
              >
                {page}
              </p>
            ))}
          {current <= pages - 3 && pages >= 6 && <div>...</div>}
          <p
            className={`${current === pages && 'currentPage'}`}
            onClick={() => {
              setCurrent(pages);
              fetchNewPage(pages);
            }}
          >
            {pages}
          </p>
          <p
            onClick={() => {
              if (current < pages) {
                setCurrent((prev) => prev + 1);
                fetchNewPage(current + 1);
              }
            }}
            className={`${current === pages && 'disablePage'}`}
          >
            التالي
          </p>
        </div>
      )}
    </div>
  );
};

export default TableList;
