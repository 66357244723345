import React, { useEffect } from 'react';
import { useState } from 'react';
import './AddAuditor.scss';
import { useForm } from 'react-hook-form';
import iconeClosePopup from '../../../markup/images/close-popup.png';
import { EMAIL_PATTERN, MOBILE_PATTERN } from '../../../shared/utils/regax';
import { message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import sharedApi from '../../../shared';
import { useTranslation } from 'react-i18next';

const { InputField } = sharedApi.components;

export default function AdminDashboard(props) {
  const { t } = useTranslation();
  const { addAdutorActions, error } = props;
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [submited, setSubmited] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name_Of_aduitor: '',
      email_Of_aduitor: '',
      mobileNumber_Of_aduitor: '',
    },
    mode: 'all',
  });

  const onSubmit = (data) => {
    data = { ...data, email_Of_aduitor: data.email_Of_aduitor.toLowerCase() };
    setSubmited(true);
    addAdutorActions.addAdutor(data);
  };

  useEffect(() => {
    if (props.success && submited) {
      navigate('/auditor-list');
      setTimeout(() => {
        message.success({
          type: 'success',
          content: `تمت اضافة

                  بنجاح.يمكنك الإطلاع على حالة حساب المدقق من خلال قائمة المدققين`,
          duration: 1.5,
        });
      }, 1);
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, submited]);

  return (
    <div className='home-container-2'>
      <div className='request-header-link' style={{ marginTop: '10vh' }}>
        <Link className='underline' to='/auditor-list'>
          المدققين <i className='arrow_ left'></i>
        </Link>
        <p href='/'> إضافة مدقق</p>
      </div>
      <div className='orders-details-2'>
        <div className='header-page'>
          <p> إضافة مدقق </p>
        </div>
        <div className='second-header-page' style={{ width: '75%' }}>
          <p>
            عند إضافة مدقق سيقوم النظام بإرسال رابط تنشيط الحساب الى ايميل المدقق، و يجب عليه التنشيط قبل انتهاء صلاحية الرابط ٣ ايام من
            تاريخ الإرسال
          </p>
        </div>
        {error && (
          <small style={{ color: '#B41515' }} className='otp__input__error'>
            {t(error)}
          </small>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginTop: '55px' }} className={error ? 'container-page__error' : 'container-page'}>
            <div className={error ? 'header-number__error' : 'header-number'}></div>
            <div className='sub-filed'>
              <InputField
                title='name'
                type='text'
                name={`name_Of_aduitor`}
                className_div_input='inputAdditionalCertificates'
                className={'add_auditor_input'}
                error={errors.name_Of_aduitor && errors.name_Of_aduitor.message}
                {...register(`name_Of_aduitor`, {
                  required: 'fieldRequired',
                })}
              />
              <InputField
                title='email'
                type='email'
                name={`email_Of_aduitor`}
                className_div_input='inputAdditionalCertificates'
                // className={error ? 'add_input_error' : null}
                className={'add_auditor_input'}
                error={errors.email_Of_aduitor && errors.email_Of_aduitor.message}
                {...register(`email_Of_aduitor`, {
                  required: 'fieldRequired',
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: 'registrEmailInvalid',
                  },
                })}
              />
              <InputField
                title='mobileNumber'
                type='number'
                name={`mobileNumber_Of_aduitor`}
                className_div_input='inputAdditionalCertificates'
                placeholder={'9665XXXXXXXX'}
                // className={otpErr ? 'add_input_error' : null}
                className={'add_auditor_input'}
                error={errors.mobileNumber_Of_aduitor && errors.mobileNumber_Of_aduitor.message}
                {...register(`mobileNumber_Of_aduitor`, {
                  required: 'fieldRequired',
                  pattern: {
                    value: MOBILE_PATTERN,
                    message: 'mobileInvalid',
                  },
                })}
              />
            </div>
          </div>

          <div className='addAuditorBtns btn-sm' style={{ alignSelf: 'flex-end' }}>
            <button className='add-adutor-btn' type='submit' disabled={!isValid}>
              إضافة
            </button>
            <button type='button' className='cancel' onClick={toggleModal}>
              تراجع
            </button>
          </div>

          {modal && (
            <div className='modal'>
              <div onClick={toggleModal} className='overlay'></div>
              <div className='modal-content'>
                <button onClick={toggleModal} className='icone-close-popup'>
                  <img src={iconeClosePopup} alt='' width='35%' />
                </button>
                <p className='header-popup'>هل أنت متأكد </p>
                <p className='sub-title-popup'>لن يقوم النظام بحفظ معلومات الطلب عند مغادرتك الصفحة</p>

                <button
                  className='close_popup'
                  onClick={() => {
                    toggleModal();
                    reset({
                      name_Of_aduitor: '',
                      email_Of_aduitor: '',
                      mobileNumber_Of_aduitor: '',
                    });
                    navigate('/auditor-list');
                  }}
                >
                  مغادرة
                </button>
                <button type='button' className='cancel_close' onClick={toggleModal}>
                  تراجع
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
