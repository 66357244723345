import * as Actions from '../../action-types';

const initialFetchingState = {
  acceptInviteFetching: null,
  acceptInviteError: null,
  acceptInviteSuccess: null,
};

export const auditorInvitation = (state = initialFetchingState, action) => {
  switch (action.type) {
    case Actions.AUDITOR_INVITATION_FETCH_START:
      return {
        ...state,
        acceptInviteFetching: true,
      };
    case Actions.AUDITOR_INVITATION_FETCH_FAILED:
      return {
        ...state,
        acceptInviteFetching: false,
        acceptInviteError: action.payload,
      };
    case Actions.AUDITOR_INVITATION_FETCH_SUCCESS:
      return {
        ...state,
        acceptInviteSuccess: true,
      };
    case Actions.RESET_STATE:
      return initialFetchingState;
    default:
      return state;
  }
};
