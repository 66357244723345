import React from 'react'
import moment from 'moment-hijri'
import { useEffect } from 'react'
import $ from 'jquery'

function YearsList({ onChange }) {
  let yearsList = []
  // Generate a select options of all supported years
  for (let i = 1356; i <= moment().iYear('iYYYY') + 10; i = i + 1) {
    yearsList.push(
      <li className='option' value={i} key={i}>
        {i}
      </li>
    )
  }
  useEffect(() => {
    $('.year-select ul li.option').click(function () {
      $(this).siblings().children().remove()
      $(this).siblings().toggle()
      var selectedYear = $(this).attr('value')
      var intYear = parseInt(selectedYear)
      onChange(intYear)
    })
  }, [onChange])

  return (
    <div className='year-select ' id='selectbox'>
      <ul style={{ overflow: 'auto' }} className='year-select__input closed' id='selectboxYear'>
        {yearsList}
      </ul>
    </div>
  )
}

export default YearsList
