import * as Actions from '../../action-types';
import { combineReducers } from 'redux';

const initialFetchingState = {
  fetching: null,
  fetchingError: null,
  success: null,
  responseData: null,
};

export const fetchingReducer = (state = initialFetchingState, action) => {
  switch (action.type) {
    case Actions.APPLICANT_FORM_SUBMISSION_START:
      return {
        ...state,
        fetching: true,
      };
    case Actions.APPLICANT_FORM_SUBMISSION_FAILED:
      return {
        ...state,
        fetching: false,
        fetchingError: action.payload,
      };
    case Actions.APPLICANT_FORM_SUBMISSION_SUCCESS:
      return {
        ...state,
        success: true,
        responseData: action.payload,
      };
    case Actions.RESET_STATE:
      return initialFetchingState;
    default:
      return state;
  }
};

export default combineReducers({
  fetchingReducer,
});
