import { otp, anotherOtp, otpServiceProvider, anotherOtpServiceProvider } from '../../services/axios/api';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as Actions from '../action-types';
import {
  otpFetch,
  otpFetchFailed,
  otpFetchFinished,
  AnotherOtpFetch,
  AnotherOtpFetchFinished,
  AnotherOtpFetchFailed,
} from '../actions/otp';

function* otpValidationAsync(action) {
  yield put(otpFetch());
  try {
    yield call(otp, action.payload);
    yield put(otpFetchFinished());
  } catch (error) {
    yield put(otpFetchFailed(error.response.data));
  }
}

function* otpServiceProviderValidationAsync(action) {
  yield put(otpFetch());
  try {
    yield call(otpServiceProvider, action.payload);
    yield put(otpFetchFinished());
  } catch (error) {
    yield put(otpFetchFailed(error.response.data));
  }
}

function* anotherOtpAsync(action) {
  yield put(AnotherOtpFetch());
  try {
    yield call(anotherOtp, action.payload);
    yield put(AnotherOtpFetchFinished());
  } catch (error) {
    yield put(AnotherOtpFetchFailed(error.response.data));
  }
}

function* anotherOtpServiceProviderAsync(action) {
  yield put(AnotherOtpFetch());
  try {
    yield call(anotherOtpServiceProvider, action.payload);
    yield put(AnotherOtpFetchFinished());
  } catch (error) {
    yield put(AnotherOtpFetchFailed(error.response.data));
  }
}

export default function* otpValidationSega() {
  yield all([
    takeLatest(Actions.OTP_VALIDATION, otpValidationAsync),
    takeLatest(Actions.OTP_SERVICE_PROVIDER_VALIDATION, otpServiceProviderValidationAsync),
    takeLatest(Actions.REQUEST_ANOTHER_OTP, anotherOtpAsync),
    takeLatest(Actions.REQUEST_ANOTHER_OTP_SERVICE_PROVIDER, anotherOtpServiceProviderAsync),
  ]);
}
