import React, { useRef } from 'react';
import addImg from '../../../../assets/images/shared/add-img-cms.svg';

const UploadImage = ({ name, onChange }) => {
  const uploadRef = useRef();
  return (
    <>
      <div role='button' style={{ cursor: 'pointer' }} onClick={() => uploadRef.current.click()}>
        <img src={addImg} width='58' alt='' />
      </div>
      <input
        type='file'
        ref={uploadRef}
        name={name}
        accept='.png, .jpg, .jpeg,.svg'
        className='d-none'
        onChange={(e) => onChange(e.target.files[0])}
      />
    </>
  );
};

export default React.forwardRef(UploadImage);
