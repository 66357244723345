// lib dependencies
import React, { useEffect, useRef } from 'react';
// asset dependencies
import './AdminLogin.scss';
import { useNavigate } from 'react-router-dom';
// component dependencies
import LoginForm from '../Components/LoginForm';
import sharedApi from '../../../shared';
import authManager from '../../../services/axios/authManager';

const { Card, Toaster, LogoHeader } = sharedApi.components;

const AdminLogin = (props) => {
  const { error, loginActions, requestUserData } = props;
  const submited = useRef(false)
  const Navigate = useNavigate();
  const onSubmit = (data) => {
    submited.current=true
    data.email = data.email.toLowerCase();
    loginActions.adminLogin(data);
  };

  useEffect(() => {
    if (props.success && submited.current) {
      requestUserData.UserData();
      const userType = authManager.getAccountType();
      userType === 'Admin' ? Navigate('/application-request') : Navigate('/applications');
    }
    // eslint-disable-next-line 
  }, [props]);
  return (
    <div className='admin__page--background'>
      <LogoHeader />
      <div className='flex flex--center'>
        <Card
          title='login'
          subTitle='قم بإدخال البيانات المطلوبة للدخول للنظام'
          toaster={error && <Toaster message={error} type='warning' />}
        >
          <LoginForm admin onSubmit={onSubmit} {...props} />
        </Card>
      </div>
    </div>
  );
};

export default AdminLogin;
